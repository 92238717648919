export const roundLocale = (value = 0, maxDigits = 1) => {
	if (value !== null || value !== undefined || value > 0) {
		return value.toLocaleString(undefined, {
			maximumFractionDigits: maxDigits,
		});
	} else {
		return null;
	}
};

export const roundLocaleFiat = (value = 0, maxDigits = 1) => {
	if (value !== null || value !== undefined || value > 0) {
		value += 1;
		return value.toLocaleString(undefined, {
			maximumFractionDigits: maxDigits,
		});
	} else {
		return null;
	}
};