
import { defineComponent, onMounted, ref } from 'vue';
import useVimeo from '@/composable/useVimeo';

export default defineComponent({
  name: 'CardVideo',
  components: {},

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    vimeoId: {
      type: Number,
      required: true
    },
    vimeoHash: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { videoData, getVideoData } = useVimeo();
    const loading = ref(true);

    onMounted(async () => {
      videoData.value = await getVideoData(props.vimeoId as number, props.vimeoHash as string);
      loading.value = false;
    });

    const clickVideo = () => {
      emit('clickVideo', videoData.value);
    };

    return {
      videoData,
      loading,
      clickVideo
    };
  }
});
