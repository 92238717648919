<template>
    <div class="page-wrap">
      <section class="blog-section">
        <div id="iframe_virtual"></div>
      </section>
    </div>
    <!-- end page-wrap -->
  </template>
  
  <script>
  // Import component data. You can change the data in the store to reflect in all component
  import SectionData from '@/store/store.js';
  import {onMounted, onUnmounted} from 'vue';
  import {isSSR} from "@/helpers";
  
  export default {
    name: 'MetaExpoAngelesSioli',
    components: {},
    data() {
      return {
        SectionData
      };
    },
    setup() {
      let windowHeight;
      const handleResize = () => {
        if (!isSSR()) {
          windowHeight = window.innerHeight;
        }
      };
   
      onMounted(() => {
        const f = document.createElement('iframe');
        f.src = 'https://meta.yonders.io/tour/expo-de-la-rosa-y-espinas';
        f.style.border = 'none';
        f.style.height = windowHeight + 'px';
        f.style.width = '100%';
        f.style.transition = 'all 0.5s ease';
        const d = document.getElementById('iframe_virtual');
        d.appendChild(f);
      });
  
      onUnmounted(() => {
        if (!isSSR()) {
          window.removeEventListener('resize', handleResize);
        }
      });
  
      if (!isSSR()) {
        window.addEventListener('resize', handleResize);
        handleResize();
      }
  
      return {
        windowHeight,
        handleResize
      };
    }
  };
  </script>
  