<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>

      <HeaderBreadcrumbs
          classname="hero-title"
          :title="$t('trans.breadcrumbs.titles.privacy')"
          :breadcrumb-items="[
					{
						title: $t('trans.breadcrumbs.privacy'),
					}
				]"
      ></HeaderBreadcrumbs>
    </header>
    <!-- contact section -->
    <privacyDetails></privacyDetails>
    <!-- Footer  -->
    <Footer classname="bg-dark on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Privacy',
  components: {},
  data() {
    return {
      SectionData
    }
  }
}
</script>
