<template>
  <div v-if="highlightedArtists.length > 0">
    <!-- section heading -->
    <SectionHeading
        classname="text-center"
        :text="$t('trans.home.highlighted_artists.title')"
        :content="$t('trans.home.highlighted_artists.content')"
        isMargin="mb-3"
    ></SectionHeading>
    <!-- swiper sldier -->
    <ArtistsHighlightedSlider
        :artists="highlightedArtists"
    ></ArtistsHighlightedSlider>
    <div class="text-center mt-5">
      <router-link class="btn-link btn-link-s1" :to="{ name: $t('routes.artists.name') }">
        {{ $t('trans.showMore') }}
      </router-link>
   </div>
  </div>

</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import store from "@/store";
import ArtistsHighlightedSlider from "@/components/common/ArtistHighlightedSlider";

export default {
  name: "ArtistHighlighted",
  components: {ArtistsHighlightedSlider},
  computed: {
    highlightedArtists() {
      return store.getters["artist/getHighlightedArtist"];
    },
  },
  data() {
    return {
      SectionData,
    };
  },
  async created() {
    await store.dispatch("artist/setHighlightedArtist");
  },
  async serverPrefetch() {
    await store.dispatch("artist/setHighlightedArtist");
  },
};
</script>
