
import useCookies from './composable/UseCookies';
import { isSSR } from '@/helpers';
import Web3Service from '@/core/services/Web3Service';
import store from '@/store';
import sanctum from "@/plugins/sanctum";

export default {
  name: 'App',
  async beforeMount() {
    // await this.$sanctum.getUser();
  },
  computed: {
    isUserAuthenticated() {
      return this.$store.state.isUserAuthenticated;
    }
  },
  setup() {
    if (!isSSR()) {
      const { showBanner, okClicked } = useCookies();
      const okBannerClicked = () => okClicked();
      return {
        showBanner,
        okBannerClicked
      };
    }
  },
  async created() {
    if (!isSSR()) {
      try {
        this.checkBetaUser();

        if (this.isUserAuthenticated) {
          await Web3Service.init();
          Web3Service.currentWallet()
            .then(async (currentWallet) => {
              await store.dispatch("saveWallet", currentWallet);
              await sanctum.getUser();
            })
            .catch(() => {
              this.$swal({
                title: this.$t('trans.alert.metamask.title'),
                text: this.$t('trans.alert.metamask.content'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText:
                  '<a target="_blank" href="https://metamask.io/">' +
                  this.$t('trans.alert.metamask.confirm_button') +
                  '</a>',
                cancelButtonText: this.$t('trans.alert.metamask.cancel_button'),
                showCloseButton: true
              });
              // 2.1 if the user cancels the login prompt
              // throw Error("Please select an account");
            });
        }
      } catch (e) {
        console.log('error', 'app', e);
      }
    }
  },
  methods: {
    checkBetaUser() {
      const isBetaChecked = store.state.isBetaChecked;

      // if (isBetaChecked !== true) {
      //   this.$swal.fire({
      //     title: 'Código de acceso',
      //     icon: 'question',
      //     input: 'password',
      //     inputLabel: 'Password',
      //     inputPlaceholder: 'Código',
      //     allowOutsideClick: false,
      //     inputAttributes: {
      //       maxlength: 20,
      //       autocapitalize: 'off',
      //       autocorrect: 'off'
      //     },
      //     showCancelButton: false,
      //     showCloseButton: false,
      //   }).then((result) => {
      //     console.log("result", result, result.value, result.value === 'Qwerty123456');
      //     if (result.value === 'Qwerty123456') {
      //       store.commit("setBetaCheck", true);
      //     } else {
      //       store.commit("setBetaCheck", false);
      //       window.location.replace('https://auth4art.com');
      //     }
      //   })
      // }
    }
  }
};
