<template>
  <section class="section-space-b">
    <div class="container">
      <div class="row">
        <div class="col-xl-3">
          <AuthorSidebar
            :content="artist.description"
            :links="links"
            :datetext="SectionData.authorSidebarData.sidebarWidgetThree.date"
          ></AuthorSidebar>
        </div>
        <!-- end col -->
        <div class="col-xl-9 ps-xl-4">
          <div class="author-items-wrap">
            <ul class="nav nav-tabs nav-tabs-s1" id="myTab" role="tablist">
              <li class="nav-item" role="presentation" id="tabBio">
                <button class="nav-link" :id="1" data-bs-toggle="tab" :data-bs-target="'#bio'" type="button">
                  {{ $t('trans.artist.nav_bar.biography') }}
                </button>
              </li>
              <li class="nav-item" role="presentation" id="tabObras">
                <button class="nav-link active" :id="2" data-bs-toggle="tab" :data-bs-target="'#on-sale'" type="button">
                  {{ $t('trans.artist.nav_bar.artworks') }}
                </button>
              </li>
              <li class="nav-item" v-bind:style="tabDocuStyle" role="presentation" id="tabDocu">
                <button class="nav-link" :id="3" data-bs-toggle="tab" :data-bs-target="'#docs'" type="button">
                  {{ $t('trans.artist.nav_bar.documentation') }}
                </button>
              </li>
            </ul>
            <div class="gap-2x"></div>
            <!-- end gap-2x -->
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade" id="on-sale" role="tabpanel" aria-labelledby="on-sale-tab">
                <div class="row g-gs">
                  <div class="col-md-4" v-for="artwork in artist.artworks" :key="artwork.hash">
                    <Artwork :artwork="artwork"></Artwork>
                  </div>
                  <!-- end col -->
                </div>
                <!-- row -->
              </div>
              <!-- end tab-pane -->

              <!--              <div class="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">-->
              <!--                <div class="row g-gs">-->
              <!--                  <div class="col g-gs">-->
              <!--                    <AuthorCollections></AuthorCollections>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>&lt;!&ndash; end tab-pane &ndash;&gt;-->

              <div class="tab-pane fade show active" id="bio" role="tabpanel" aria-labelledby="bio-tab">
                <div class="row g-gs">
                  <AuthorDetailSection :biographyImages="biographyImages"></AuthorDetailSection>
                </div>
                <!-- row -->
              </div>
              <!-- end tab-pane -->

              <div v-if="artist.attachments" class="tab-pane fade" id="docs" role="tabpanel" aria-labelledby="docs-tab">
                <div class="row g-gs">
                  <AuthorDocsSection></AuthorDocsSection>
                </div>
                <!-- row -->
              </div>
              <!-- end tab-pane -->
            </div>
            <!-- end tab-content -->
          </div>
          <!-- end author-items-wrap -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- .container -->
    <!-- Modal -->
  </section>
  <!-- end author-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import store from '@/store';
import Artwork from '@/components/section/Artwork';

export default {
  name: 'AuthorSection',
  props: ['biographyImages'],
  components: {
    Artwork
  },
  data() {
    return {
      SectionData,
      tabDocuStyle: '',
      links: null
    };
  },
  computed: {
    artist() {
      return store.getters['artist/getArtist'];
    }
  },
  mounted() {
    if (this.artist.has_public_profile === 0) {
      this.$router.push('/');
    }

    this.refreshDocuStyle();
  },
  methods: {
    refreshDocuStyle() {
      if (this.artist.hash == '61346dd47af46c49b60320e6eb57ec4b') {
        this.tabDocuStyle = 'display:none';
      } else {
        this.tabDocuStyle = '';
      }
      document.getElementById('1').classList.add('active');
      document.getElementById('2').classList.remove('active');
      document.getElementById('3').classList.remove('active');
    }
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.author-link,
.card-price-wrap {
  z-index: 2;
  position: relative;
}
</style>
