<template>
  <!--begin::Activities-->
  <!--  TODO Idioma -->
  <!--	<div :class="{ 'center-login-md-up': !isUserAuthenticated }">-->
  <div v-if="isUserAuthenticated">
    <span>
      <button type="button" class="icon-btn icon-btn-s1" data-bs-toggle="dropdown">
        <i class="fa-solid fa-user"></i>
      </button>
      <ul class="dropdown-menu card-generic card-generic-s3 dropdown-menu-end mt-2">
        <li>
          <h6 class="dropdown-header">{{ $t('hello') }} {{ user.name }}!</h6>
        </li>

        <div v-if="isAdmin">
          <li v-for="list in SectionData.headerData.adminUser" :key="list.id">
            <router-link
              class="dropdown-item card-generic-item"
              :to="{
                name: $t(list.path)
              }"
            >
              <i class="me-2" :class="list.icon"></i>
              {{ $t(list.titleI18nKey) }}
            </router-link>
          </li>
        </div>
        <div v-else-if="isPrivateUser">
          <li v-for="list in SectionData.headerData.privateUser" :key="list.id">
            <router-link
              class="dropdown-item card-generic-item"
              :to="{
                name: $t(list.path)
              }"
            >
              <i class="me-2" :class="list.icon"></i>
              {{ $t(list.titleI18nKey) }}
            </router-link>
          </li>
        </div>
        <div v-else>
          <li v-for="list in SectionData.headerData.user" :key="list.id">
            <router-link
              class="dropdown-item card-generic-item"
              :to="{
                name: $t(list.path)
              }"
            >
              <i class="me-2" :class="list.icon"></i>
              {{ $t(list.titleI18nKey) }}
            </router-link>
          </li>
        </div>

        <li><hr class="dropdown-divider" /></li>
        <li>
          <button class="dropdown-item card-generic-item" @click="logout()">
            <i class="fa-solid fa-power-off me-2"></i>Logout
          </button>
        </li>
      </ul>
    </span>
  </div>
  <div v-else class="menu_user_login">
    <ul>
      <li class="flex-grow-1">
        <router-link :to="{ name: $t('routes.login.name') }">
          {{ $t('signIn') }}
        </router-link>
      </li>
    </ul>
  </div>
  <!--end::Activities-->
</template>

<script>
import sanctum from '@/plugins/sanctum';
import SectionData from '@/store/store';

export default {
  name: 'ButtonUser',
  computed: {
    user() {
      return this.$store.state.user;
    },
    isUserAuthenticated() {
      return this.$store.state.isUserAuthenticated;
    },
    isPublicUser() {
      return this.$store.state.isPublicUser;
    },
    isPrivateUser() {
      return this.$store.state.isPrivateUser;
    },
    isAdmin() {
      return this.$store.state.isAdminUser;
    }
  },
  data() {
    return {
      SectionData
    };
  },
  methods: {
    async logout() {
      if (await sanctum.logout()) {
        this.$router.push({ name: this.$t('routes.home.name') });
      }
    }
  }
};
</script>
<style scoped></style>
