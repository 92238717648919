<template>
  <div>
    <div class="row" v-if="isSSR">
      <div class="col-md-4 col" v-for="artist in artists.slice(0, 3)"
           :key="artist.hash">
        <div class="card card-full card-creator">
          <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }" class="d-block">
            <img :src="require('@/images/artist/' + artist.hash + '/artist-list.jpg')" class="card-img-top" alt="birds art image">
          </router-link>
          <div class="card-body card-body-s1">
            <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }" class="avatar avatar-1">
              <img :src="require('@/images/artist/' + artist.hash + '/avatar.jpg')" alt="avatar" class="rounded-circle">
            </router-link><!-- end avatar -->
            <h4 class="card-title mt-4 pt-2">
              <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }">{{ artist.name }} {{ artist.surname }}
              </router-link>
            </h4>
            
            <p class="text-dark-gray">
              <span class="me-3 me-xl-4"><strong class="text-black">{{
                  artist.artworks.length
                }}</strong> {{ $t('artworks') }} </span>
            </p>
            <p class="mb-4 mt-1"> </p>
            <ul class="btns-group">
              <li>
                <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }" class="btn bg-dark-dim"> {{ $t('trans.home.highlighted_artists.button') }}
                </router-link>
              </li>
            </ul>
          </div><!-- end card-body -->
        </div><!-- end card -->
      </div>
    </div>
    <swiper
        v-else
        :modules="modules"
        :slides-per-view="3"
        :breakpoints="{
    0: {
      slidesPerView: 1
    },
    767: {
      slidesPerView: 2
    },
    992: {
      slidesPerView: 3
    }
  }"
       :navigation="{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }"

        :pagination="{ clickable: true }"
      >
      <swiper-slide v-for="artist in artists"
                    :key="artist.hash">
        <div class="card card-full card-creator">
          <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }" class="d-block">
            <img :src="getHeaderImageUri(artist)" class="card-img-top" alt="birds art image">
          </router-link>
          <div class="card-body card-body-s1">
            <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }" class="avatar avatar-1">
              <img :src="getAvatarImageUri(artist)" alt="avatar" class="rounded-circle">
            </router-link><!-- end avatar -->
            <h4 class="card-title mt-4 pt-2">
              <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }">{{ artist.name }} {{ artist.surname }}
              </router-link>
            </h4>
            <p class="text-dark-gray">
              <span class="me-3 me-xl-4"><strong class="text-black">{{
                  artist.artworks.length
                }}</strong> {{ $t('trans.home.highlighted_artists.artworks') }} </span>
            </p>
            <p class="mb-4 mt-1"></p>
            <ul class="btns-group">
              <li>
                <router-link :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artist.hash,
                          slug: artist.slug,
                        },
                      }" class="btn bg-dark-dim"> {{$t('trans.home.highlighted_artists.button')}}
                </router-link>
              </li>
            </ul>
          </div><!-- end card-body -->
        </div><!-- end card -->
      </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <!-- pagination 
       :navigation="{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }"

        :pagination="{ clickable: true }"
      
            <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      
      -->
    </swiper>

  </div>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

// core version + navigation, pagination modules:
import SwiperCore, {Pagination} from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {isSSR} from "@/helpers";

export default {
  name: 'ArtistsHighlightedSlider',
  props: ['artists'],
  methods: {
    getAvatarImageUri(artist) {
      		return artist.images.filter(
      		  (image) => {
      		    return image.type == "avatar";
      		  }
      		)[0].thumbnails.filter(
      		  (thumbnail) => {
      		    return thumbnail.aspect_ratio == "1_1" && thumbnail.size == this.size;
      		  }
      		)[0].uri;
    	},
      getHeaderImageUri(artist) {
      		return artist.images.filter(
      		  (image) => {
      		    return image.type == "header_list";
      		  }
      		)[0].thumbnails.filter(
      		  (thumbnail) => {
      		    return thumbnail.aspect_ratio == "2_1" && thumbnail.size == this.size;
      		  }
      		)[0].uri;
    	},
      onResize() {
      		this.size = !isSSR() ? window.innerWidth < 992 ? 'sm' : 'lg' : 'lg';
    	}
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      SectionData,
      isSSR: isSSR(),
      size: !isSSR() ? window.innerWidth < 992 ? 'sm' : 'lg' : 'lg',
    }
  },
  setup() {
    return {
      modules: [Pagination]
    }
  },
  mounted() {
  	  this.$nextTick(() => {
  	    window.addEventListener('resize', this.onResize);
  	  });
  	},
  	beforeUnmount() { 
  	  window.removeEventListener('resize', this.onResize); 
  	},
};
</script>