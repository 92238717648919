
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import store from "@/store";
import axios from "@/plugins/axios";

interface personalInformation {
  name: string;
  surname: string;
  phone: string;
  contact_email: string;
}

interface changePasswordData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

export default {
  name: 'AccountSection',
  data() {
    return {
      SectionData,
      loadingPersonalInformation: false,
      loadingResendEmail: false,
      loadingChangePassword: false,
    }
  },
  computed: {
    user() {
      return store.state.user;
    },
    hasConfirmedEmail() {
      return this.$store.state.user?.email_verified;
    },
    hasPaymentMethodsFiat() {
      return store.getters["hasUserPaymentMethodFiat"];
    },
    personalInformation(): personalInformation {
      return {
        name: this.user.role[0]?.name,
        surname: this.user.role[0]?.surname,
        phone: this.user.role[0]?.phone,
        contact_email: this.user.role[0]?.contact_email
      }
    },
    changePasswordData(): changePasswordData {
      return {
        old_password: "",
        password: "",
        password_confirmation: "",
      }
    },
  },
  methods: {
    savePersonalInformation() {

      try {
        this.loadingPersonalInformation = true;
        const data = this.personalInformation;

        this.$swal({
          title: this.$t("trans.alert.action"),
          text: this.$t("trans.alert.notReversible"),
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: this.$t("trans.alert.continue"),
          cancelButtonText: this.$t("trans.alert.cancel"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async (inputValue: any) => {
            return await axios.post(
                "/api/v1/public/profile/update-personal-information",
                data
            ).then(async (response) => {
              //Refrescamos el usuario autenticado
              await this.$sanctum.getUser();
              this.changePasswordData = {
                old_password: "",
                password: "",
                password_confirmation: "",
              };
              this.$swal(this.$t("trans.alert.success.title"), this.$t("trans.alert.success.message"), 'success')
            }).catch((error) => {
              this.$swal(this.$t("trans.alert.error.title"), this.$t("trans.alert.error.message"), 'error')
            });
          },
        }).then((result) => {
          console.log("result", result);
          if (!result.value) {
            this.$swal(this.$t("trans.alert.canceled"), this.$t("trans.alert.noAction"), 'info')
          }
        })
        this.loadingPersonalInformation = false;
      } catch (e) {
        this.loadingPersonalInformation = false;
        this.$swal(this.$t("trans.alert.error.title"), this.$t("trans.alert.error.message"), 'error')
      }
    },
    changePassword() {
      try {
        this.loadingChangePassword = true;
        const data = this.changePasswordData;

        this.$swal({
          title: this.$t("trans.alert.action"),
          text: this.$t("trans.alert.notReversible"),
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: this.$t("trans.alert.continue"),
          cancelButtonText: this.$t("trans.alert.cancel"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async (inputValue: any) => {
            return await axios.post(
                "/api/v1/public/profile/update-password",
                data
            ).then(async (response) => {
              //Refrescamos el usuario autenticado
              await this.$sanctum.getUser();
              this.$swal(this.$t("trans.alert.success.title"), this.$t("trans.alert.success.message"), 'success')
            }).catch((error) => {
              this.$swal(this.$t("trans.alert.error.title"), this.$t("trans.alert.error.message"), 'error')
            });
          },
        }).then((result) => {
          console.log("result", result);
          if (!result.value) {
            this.$swal(this.$t("trans.alert.canceled"), this.$t("trans.alert.noAction"), 'info')
          }
        })
        this.loadingChangePassword = false;
      } catch (e) {
        this.loadingChangePassword = false;
        this.$swal(this.$t("trans.alert.error.title"), this.$t("trans.alert.error.message"), 'error')
      }
    },
    async resendEmailConfirmation() {
      this.loadingResendEmail = true;
      await axios.post(
          "/api/v1/auth/email-verify-resend",
      ).then(async (response) => {
        this.$swal(this.$t("trans.alert.success.title"), this.$t("trans.alert.success.message"), 'success')
      }).catch((error) => {
        this.$swal(this.$t("trans.alert.error.title"), this.$t("trans.alert.error.message"), 'error')
      });

      this.loadingResendEmail = false;
    }
  },
  mounted() {
    /*===========SHOW UPLOADED IMAGE ================== */
    function uploadImage(selector) {
      let elem = document.querySelectorAll(selector)
      if (elem.length > 0) {
        elem.forEach(item => {
          item.addEventListener('change', function () {
            if (item.files && item.files[0]) {
              let img = document.getElementById(item.dataset.target) as HTMLImageElement
              img.onload = function () {
                URL.revokeObjectURL(img.src)
              }
              img.src = URL.createObjectURL(item.files[0])

              let allowedExtensions = ['jpg', 'JPEG', 'JPG', 'png']
              let fileExtension = this.value.split('.').pop()
              var lastDot = this.value.lastIndexOf('.')
              var ext = this.value.substring(lastDot + 1)
              if (!allowedExtensions.includes(fileExtension)) {
                alert(ext + ' file type not allowed, Please upload jpg, JPG, JPEG, or png file');
                img.src = "";
              }
            }
          })
        })
      }
    }

    uploadImage('.upload-image')

    /* =========== Show/Hide passoword ============== */
    function showHidePassword(selector) {
      let elem = document.querySelectorAll(selector);
      if (elem.length > 0) {
        elem.forEach(item => {
          item.addEventListener("click", function (e) {
            e.preventDefault();
            let target = document.getElementById(item.getAttribute("href")) as HTMLInputElement;
            if (target.type == "password") {
              target.type = "text";
              item.classList.add("is-shown");
            } else {
              target.type = "password";
              item.classList.remove("is-shown");
            }
          });

        });
      }
    }

    showHidePassword(".password-toggle");

  }
}
