<template>
	<div v-if="!loading" class="page-wrap">
		<!-- header  -->
		<header class="header-section has-header-main bg-pattern-3">
			<!-- Header main -->
			<HeaderMain></HeaderMain>
			<!-- hero -->
			<CollectionHero
				avatarSize="avatar-3"
				:isCopyInput="true"
				:coverimg="require('@/images/portada_logo_hor.png')"
				:img="SectionData.collectionDetaillData.img"
				:title="collection.name"
				:username="collection.owner.name"
				:btntext="SectionData.collectionDetaillData.btnText"
				:btnlink="SectionData.collectionDetaillData.btnLink"
			></CollectionHero>
		</header>
		<div class="pt-110 container">
		<div class="row">

		<!--   Imagen en Mobile   -->
      	<div class="col ms-auto d-block d-lg-none">
      	  <div class="item-detail-content">
      	    <div
      	        class="item-detail-img-container item-detail-img-full mb-4"
      	    >
      	      <img :src="require('@/images/artwork/n-a/nft_detail.jpg')"
      	           alt=""
      	           class="w-100 rounded-3"
      	      />
      	    </div>
      	    <!-- end item-detail-img-container -->
      	  </div>
      	  <!-- end item-detail-content -->
      	</div>
      	<!-- end col -->

		<div class="col-lg-6 mt-5 mt-lg-0">
        	<h1 class="item-detail-title mb-2">
          	    {{ collection.name }}
          	</h1>			
          	<div class="item-detail-meta d-flex flex-wrap align-items-center mb-3">
          		<span class="item-detail-text-meta">
          			<router-link class="details"
          		     :to="{
          		        name: $t('routes.artist.name'),
          		        params: {
          		          hash: collection.owner?.hash,
          		          slug: collection.owner?.slug,
          		        },
          		      }">
          		      {{ collection.owner?.name }}
          			</router-link>
          		</span>
          	</div>
          	<p class="item-detail-text mb-4" align="justify">
          		{{ collection.description }}            
          	</p>          
        </div>
		
		<!--   Imagen en LG   -->
      <div class="col-lg-5 ms-auto d-none d-lg-block">
        <div class="item-detail-content">
          <div
              class="item-detail-img-container item-detail-img-full mb-4"
          >
            <img 
                 :src="require('@/images/artwork/n-a/nft_detail.jpg')"
                 alt=""
                 class="w-100 rounded-3"
            />
          </div>
          <!-- end item-detail-img-container -->
        </div>
        <!-- end item-detail-content -->
      </div>
      <!-- end col -->
	  </div>
	  </div>
		<hr>
		<!-- Detail section  -->
		<CollectionDetailSection></CollectionDetailSection>
		<!-- Footer  -->
		<Footer classname="bg-dark on-dark"></Footer>
	</div>
	<!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import store from "@/store";
import {useRoute} from "vue-router";

export default {
	name: "collectionDetail",
	data() {
		return {
			SectionData,
			loading: true,
		};
	},
	computed: {
    	collection() {
    	  return store.getters["collection/getCollection"];
    	},
	},
  	async serverPrefetch() {
    	const route = useRoute();
    	await store.dispatch("collection/setCollection", route.params.hash);
    	this.loading = false;
  	},
	async created() {
    	const route = useRoute();
    	await store.dispatch("collection/setCollection", route.params.hash);
    	this.loading = false;
  	}
};
</script>
