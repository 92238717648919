<template>
  <section class="section-space-t" v-if="latestMarketplace.length > 0">
    <div class="container">
      <!-- section heading  -->
      <SectionHeading classname="text-center" :text="$t('trans.home.marketplace.title')"></SectionHeading>
      <!-- product -->
      <swiper
        v-if="latestMarketplace.length > 0"
        :slides-per-view="4"
        :breakpoints="{
          0: {
            slidesPerView: 1
          },
          767: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4
          }
        }"
        :pagination="{ clickable: true }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }"
      >
        <swiper-slide v-for="artwork in latestMarketplace" :key="artwork.hash">
          <Artwork :artwork="artwork"></Artwork>
        </swiper-slide>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </swiper>
      <!-- <p v-else class="text-center">{{ $t('trans.auction.soon') }}</p> -->
    </div>
  </section>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
// core version + navigation, pagination modules:
import SwiperCore, { Pagination } from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import store from '@/store';
import Artwork from '@/components/section/Artwork';

export default {
  name: 'Marketplace',
  components: {
    Swiper,
    SwiperSlide,
    Artwork
  },
  computed: {
    latestMarketplace() {
      return store.getters['marketplace/getLatestMarketplaces'];
    }
  },
  data() {
    return {
      SectionData
    };
  },
  created() {
    store.dispatch('marketplace/setLatestMarketplaces');
  },
  serverPrefetch() {
    store.dispatch('marketplace/setLatestMarketplaces');
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.author-linkm,
.card-price-wrap {
  z-index: 2;
  position: relative;
}
</style>
