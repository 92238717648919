const routes = {
  home: {
    name: 'Home__es',
    path: ''
  },
  email: {
    confirm: {
      name: 'EmailConfirm__es',
      path: 'confirmacion-correo'
    }
  },
  legal: {
    name: 'Legal__es',
    path: 'legal'
  },
  terms: {
    name: 'Terms__es',
    path: 'terminos'
  },
  privacy: {
    name: 'Privacy__es',
    path: 'privacidad'
  },
  cookies: {
    name: 'Cookie__es',
    path: 'cookie'
  },
  contact: {
    name: 'Contact__es',
    path: 'contacta'
  },
  services: {
    name: 'Services__es',
    path: 'servicios'
  },
  martelive: {
    name: 'Martelive__es',
    path: 'martelive'
  },
  artworks: {
    name: 'Artworks__es',
    path: 'obras'
  },
  artwork: {
    name: 'Artwork__es',
    path: 'obra'
  },
  artworkChild: {
    name: 'Artwork_Child__es',
    path_1: 'edicion',
    path_2: 'obra'
  },
  editArtwork: {
    name: 'Edit_Artwork__es',
    path: 'editar-obra'
  },
  artists: {
    name: 'Artists__es',
    path: 'artistas'
  },
  artist: {
    name: 'Artist__es',
    path: 'artista'
  },
  collections: {
    name: 'Collections__es',
    path: 'colecciones'
  },
  collection: {
    name: 'Collection__es',
    path: 'coleccion'
  },
  marketplace: {
    name: 'Marketplace__es',
    path: 'marketplace'
  },

  login: {
    name: 'Login__es',
    path: 'login'
  },
  expoVirtual: {
    name: 'Expo_Virtual__es',
    path: 'exposiciones-virtuales/bernardo-perez'
  },
  metaExpos: {
    name: 'Meta_Expos__es',
    path: 'meta-exposiciones/'
  },
  metaExpos_Pararecordarte: {
    name: 'Meta_Expos_Pararecordarte__es',
    path: 'meta-exposiciones/pararecordarte'
  },
  metaExpos_Pararecordarte_UMA: {
    name: 'Meta_Expos_Pararecordarte_UMA__es',
    path: 'meta-exposiciones-uma/pararecordarte'
  },
  metaExpos_BernardoPerez: {
    name: 'Meta_Expos_Bernardo_Perez__es',
    path: 'meta-exposiciones/bernardo-perez'
  },
  metaExpos_BernardoPerez_UMA: {
    name: 'Meta_Expos_Bernardo_Perez_UMA__es',
    path: 'meta-exposiciones-uma/bernardo-perez'
  },
  metaExpos_PierreGonnord: {
    name: 'Meta_Expos_Pierre_Gonnord__es',
    path: 'meta-exposiciones/pierre-Gonnord'
  },
  metaExpos_PierreGonnord_UMA: {
    name: 'Meta_Expos_Pierre_Gonnord_UMA__es',
    path: 'meta-exposiciones-uma/pierre-Gonnord'
  },
  metaExpos_AngelesSioli: {
    name: 'Meta_Expos_Angeles_Sioli__es',
    path: 'meta-exposiciones/angeles-sioli'
  },
  metaExpos_AngelesSioli_UMA: {
    name: 'Meta_Expos_Angeles_Sioli_UMA__es',
    path: 'meta-exposiciones-uma/angeles-sioli'
  },
  metaExpos_JondoSonidosMaquinicos: {
    name: 'Meta_Expos_Jondo_Sonidos_Maquinicos__es',
    path: 'meta-exposiciones/jondo-sonidos-maquinicos'
  },
  metaExpos_JondoSonidosMaquinicos_UMA: {
    name: 'Meta_Expos_Jondo_Sonidos_Maquinicos_UMA__es',
    path: 'meta-exposiciones-uma/jondo-sonidos-maquinicos'
  },
  metaExpos_ArchivoSombraCarlosCanal: {
    name: 'Meta_Expos_Archivo_Sombra_Carlos_Canal__es',
    path: 'meta-exposiciones/archivo-sombra-carlos-canal'
  },
  metaExpos_ArchivoSombraCarlosCanal_UMA: {
    name: 'Meta_Expos_Archivo_Sombra_Carlos_Canal_UMA__es',
    path: 'meta-exposiciones-uma/archivo-sombra-carlos-canal'
  },
  formEvent: {
    name: 'Form_Event__es',
    path: 'exposiciones/bernardo-perez/registro'
  },

  // profile: {
  // 	name: "Profile__es",
  // 	path: "mi-perfil",
  // },

  dashboard: {
    name: 'Dashboard__es',
    path: 'mi-perfil',
    private: {
      name: 'DashboardPrivate__es',
      path: 'ir-a-mi-perfil'
    },
    auctions: {
      name: 'DashboardAuctions__es',
      path: 'mi-escritorio/mis-pujas'
    },
    artworks: {
      name: 'DashboardArtworks__es',
      path: 'mi-escritorio/mis-obras'
    },
    favourites: {
      name: 'DashboardFavourites__es',
      path: 'mi-escritorio/mis-favoritos'
    },
    transactions: {
      name: 'DashboardTransactions__es',
      path: 'mi-escritorio/transacciones'
    },
    payments: {
      name: 'DashboardPayments__es',
      path: 'mi-perfil/metodos-pago'
    },
    academy: {
      name: 'DashboardAcademy__es',
      path: 'mi-escritorio/recursos'
    }
  }
};

export default routes;
