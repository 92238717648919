<template>
  <div class="col-lg-9 ps-xl-5">
    <div class="user-panel-title-box">
      <h3>{{ $t("dashboardTab.auctions") }}</h3>
    </div>
    <div v-if="hasActiveAuctions" class="row g-gs">
      
        <div
            class="col-xl-6 col-lg-6 col-sm-6"
            v-for="bid in activeAuctions"
            :key="bid.auction?.hash"
        >
          <div class="card card-full">
            <div class="card-body card-body-s1">
              <div class="card-media mb-3">
                <div class="card-media-img flex-shrink-0 bb">
                  <img
                      :src="getHeaderImageUri(bid.auction.artwork)"
                      alt="media image"
                  />
                </div>
                <!-- card-media-img -->
                <div class="card-media-body">
                  <h4>
                    <router-link
                        class="details"
                        :to="{
										name: $t('routes.artwork.name'),
										params: {
											hash: bid.auction.artwork.hash,
                      slug: bid.auction.artwork.slug,
										},
									}"
                    >
                      {{ bid.auction?.artwork.title }}
                    </router-link>
                  </h4>
                  <div class="fs-15">
								<span class="me-1 card-author-by">{{
                    $t("auctionslist.by")
                  }}</span>
                    <div class="custom-tooltip-wrap">
                      <router-link
                          to="/author"
                          class="author-link"
                      >
                        {{ bid.auction?.artwork?.artist?.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- end card-media-body -->
              </div>
              <!-- end card-media -->
              <div class="card-media mb-3">
                <div class="card-media-body">
							<span class="fw-medium fs-13">
                {{ $t('trans.profile.my_bid') }}
                </span>
                  <p class="fw-medium text-black fs-14">
                    {{
                      bid.amount
                          ? bid.amount
                          : $t("translate.priceNotFound")
                    }}
                    {{
                      bid.auction.currency_type == 'matic'
                          ? 'MATIC'
                          : '€'
                    }}
                  </p>
                </div>
                <div class="card-media-body">
							<span class="fw-medium fs-13">
                  {{ $t('trans.profile.last_bid') }}
                </span>
                  <p class="fw-medium text-black fs-14">
                    {{
                      bid.auction?.last_bid
                          ? bid.auction.last_bid.amount
                          : $t("translate.priceNotFound")
                    }}
                    {{
                      bid.auction.currency_type == 'matic'
                          ? 'MATIC'
                          : '€'
                    }}
                  </p>
                </div>
              </div>
              <!-- end d-flex -->
              <ul class="btns-group">
                <li>
                  <span
                      class="badge fw-medium"
                      :class="getBidClass(bid)">
                    {{ getBidText(bid) }}
                  </span>
                </li>
              </ul>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <div v-else>
        <div class="alert alert-warning" role="alert">
          <span>
            {{ $t('trans.profile.noAuctions') }}
          </span>
          <router-link
            class="btn btn-sm btn-dark float-md-end mb-1 pb-1 ms-3 ms-md-0"
            :to="{
              name: $t('routes.artworks.name'),
            }">
            {{ $t('trans.profile.noAuctionsbt') }}
          </router-link>
        </div>
        <!-- end card -->
      </div>
    
    <!-- end user-panel-title-box  -->
  </div>
  <!-- end col-lg-8 -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import store from "@/store";
import {isSSR} from "@/helpers";

export default {
  name: "DashboardAuctionsSection",
  data() {
    return {
      size: !isSSR() ? window.innerWidth < 992 ? 'sm' : 'lg' : 'lg',
    };
  },
  computed: {
    activeAuctions() {
      return store.getters["auction/getUserActiveAuctions"];
    },
    hasActiveAuctions() {
      return store.getters["auction/getUserActiveAuctions"].length > 0;
    },
  },
  created() {
    store.dispatch("auction/setUserActiveAuctions");
  },
  serverPrefetch() {
    store.dispatch("auction/setUserActiveAuctions");
  },
  methods: {
    getHeaderImageUri(artwork) {

      return artwork.images.filter(
          (image) => {
            return image.type === "header_list";
          }
      )[0].thumbnails.filter(
          (thumbnail) => {
            return thumbnail.aspect_ratio === "3_2" && thumbnail.size === this.size;
          }
      )[0].uri;
    },
    getBidClass(bid) {
      if (bid.amount >= bid.auction?.last_bid?.amount) {
        return "bg-success";
      } else {
        return "bg-danger";
      }
    },
    getBidText(bid) {
      if (bid.amount >= bid.auction?.last_bid?.amount) {
        return 'Puja más alta';
      } else {
        return 'Puja superada';
      }
    },
  },
};
</script>
<style>
.card-media-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
