import { App } from 'vue';
import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css'; // optional for styling

export const registerTippy = (app: App): void => {
  const Vue = app;
  Vue.use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'right',
        allowHTML: true
      } // => Global default options * see all props
    }
  );
};
