<template>
    <footer class="footer-section" :class="classname">
        <!-- footer section -->
        <FooterSection></FooterSection>

        <PaymentMethodWalletModal :col='"col-12"' v-if="isUserAuthenticated"></PaymentMethodWalletModal>

        <GoogleAnalytics></GoogleAnalytics>

    </footer><!-- end footer-section -->
</template>

<script>
import PaymentMethodWalletModal from "@/components/common/PaymentMethodWalletModal";
import GoogleAnalytics from '@/components/common/GoogleAnalytics.vue';

export default {
  name: 'Footer',
  props: ['classname'],
  components: {
    PaymentMethodWalletModal,
    GoogleAnalytics
  },
  computed: {
    isUserAuthenticated() {
      return this.$store.state.isUserAuthenticated;
    },
  }
}
</script>
