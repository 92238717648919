<template>
  <section class="explore-section pt-2">
    <div class="container">
      <div class="filter-box pb-5">
        <div class="filter-box-filter justify-content-between align-items-center">
          <a class="btn filter-btn d-xl-none" data-bs-toggle="collapse" href="#filters" role="button">Filtros</a>
          <div class="filter-box-filter-item ms-lg-auto">
            <!-- end filter-box-search-mobile -->
            <div class="filter-box-btn-group-mobile dropdown">
              <a class="btn filter-btn" data-bs-toggle="dropdown" href="#" role="button">
                Ordenar por
                <em class="ni ni-sort"></em>
              </a>
              <div class="dropdown-menu dropdown-menu-end card-generic mt-2 p-3">
                <div class="filter-btn-group filter-btn-group-s1">
                  <a
                    v-for="order in orderByItems"
                    :key="order.slug"
                    href="#"
                    class="btn filter-btn"
                    :class="getActiveClass(order.slug)"
                    @click.prevent="
                      recent(order.slug);
                      activeId = order.slug;
                    "
                    >{{ order.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionData from '@/store/store.js';
export default {
  name: 'FilterHeader',
  props: ['orderByItems', 'orderByInitial', 'optionPlaceholder'],
  watch: {
    optionSelected(newOption, oldOption) {
      this.$emit('option', newOption.value);
    }
  },
  data() {
    return {
      SectionData,
      name: '',
      activeId: this.$props.orderByInitial
    };
  },
  methods: {
    recent(slug) {
      this.$emit('order', slug);
    },
    getActiveClass(id) {
      if (id === this.activeId) {
        return 'active';
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.author-link {
  z-index: 2;
  position: relative;
}
</style>
