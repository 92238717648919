<template>
  <div class="nav-tabs-wrap">
       <div class="row">
            <div class="col-lg-8 mx-auto">
                <ul class="nav nav-pills nav-pills-s1 mb-5 justify-content-center" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation" v-for="list in SectionData.howItWorkData.tabNav" :key="list.id">
                        <button class="nav-link" :class="list.isActive" :id="list.slug" data-bs-toggle="pill" :data-bs-target="list.bsTarget" type="button">
                            {{ $t(list.title) }}
                        </button>
                    </li>
                </ul>
            </div><!-- end col -->
            <div class="col-lg-12">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane show active" id="pills-connect-wallet" role="tabpanel" aria-labelledby="pills-connect-wallet-tab">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-4">
                                <div class="card-tab-img mb-5 mb-lg-0">
                                    <img :src="require('@/images/thumb/tabs01-img.png')" alt="" class="img" style="max-width:350px">
                                </div>
                            </div><!-- end col -->
                            <div class="col-lg-7 ps-lg-5">
                                <div class="card-tab">
                                    <h3 class="card-tab-title">{{$t('trans.home.about.ecosystem.title')}}</h3>
                                    <p class="card-tab-text"></p>
                                    <ul class="list-item list-item-icon">
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.ecosystem.list_1')}}</li>
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.ecosystem.list_2')}}</li>
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.ecosystem.list_3')}}</li>
                                     <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.ecosystem.list_4')}}</li>
            
                                    </ul>
                                    <ButtonLink :text="$t('trans.home.about.ecosystem.button')" link="/es/servicios" classname="btn btn-outline-dark"></ButtonLink>
                                </div><!-- end card-tab -->
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div><!-- end tab-pane -->
                    <div class="tab-pane" id="pills-create-nft-store" role="tabpanel" aria-labelledby="pills-create-nft-store-tab">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-4">
                                <div class="card-tab-img mb-5 mb-lg-0">
                                    <img :src="require('@/images/thumb/tabs02-img.png')" alt="" class="img" style="max-width:350px">
                                </div>
                            </div><!-- end col -->
                            <div class="col-lg-7 ps-lg-5">
                                <div class="card-tab">
                                    <h3 class="card-tab-title">{{$t('trans.home.about.our_plans.title')}}</h3>
                                    <p class="card-tab-text">{{$t('trans.home.about.our_plans.content')}}</p>
                                    <ul class="list-item list-item-icon">
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.our_plans.list_1')}}</li>
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.our_plans.list_2')}}</li>
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.our_plans.list_3')}}</li>
                                    </ul>
                                    <ButtonLink :text="$t('trans.home.about.our_plans.button')" link="/es/servicios/#planes" classname="btn btn-outline-dark"></ButtonLink>
                                </div><!-- end card-tab -->
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div><!-- end tab-pane -->
                    <div class="tab-pane" id="pills-start-selling" role="tabpanel" aria-labelledby="pills-start-selling-tab">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-4">
                                <div class="card-tab-img mb-5 mb-lg-0">
                                    <img :src="require('@/images/thumb/tabs03-img.png')" alt="" class="img" style="max-width:350px">
                                </div>
                            </div><!-- end col -->
                            <div class="col-lg-7 ps-lg-5">
                                <div class="card-tab">
                                    <h3 class="card-tab-title">{{$t('trans.home.about.our_devices.title')}}</h3>
                                    <p class="card-tab-text">{{$t('trans.home.about.our_devices.content')}}</p>
                                    <ul class="list-item list-item-icon">
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.our_devices.list_1')}}</li>
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.our_devices.list_2')}}</li>
                                        <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> {{$t('trans.home.about.our_devices.list_3')}}</li>
                                    </ul>
                                    <ButtonLink :text="$t('trans.home.about.our_devices.button')" link="/es/servicios/#dispositivos" classname="btn btn-outline-dark"></ButtonLink>
                                </div><!-- end card-tab -->
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div><!-- end -->
                </div>
            </div><!-- end col -->
        </div>
  </div><!-- end nav-tabs-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Tab',
  data () {
    return {
      SectionData
    }
  }
}
</script>