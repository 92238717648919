<template>
	<div class="page-wrap">
		<!-- header  -->
		<header class="header-section has-header-main bg-pattern-3">
			<!-- Header main -->
			<HeaderMain></HeaderMain>
			<!-- hero -->
			<BuyerHero
				avatarSize="avatar-3"
				:coverimg="SectionData.authorPersonalData.coverImg"
				:img="SectionData.authorPersonalData.img"
				:title="SectionData.authorPersonalData.title"
				:username="SectionData.authorPersonalData.userName"
				:btntext="SectionData.authorPersonalData.btnText"
				:btnlink="SectionData.authorPersonalData.btnLink"
			></BuyerHero>
		</header>
		<!-- auctions section -->
		<section class="user-panel-section section-space">
			<div class="container">
				<div class="row">
					<!-- user sidebar -->
					<UserSidebar
						:title="$t('dashboardTab.auctions')"
						:lists="SectionData.auctionSidebarData.navList"
						:navs="SectionData.auctionSidebarData"
					></UserSidebar>
					<!-- auctions -->
					<DashboardAuctionsSection></DashboardAuctionsSection>
				</div>
				<!-- end row -->
			</div>
			<!-- end container -->
		</section>
		<!-- end user-panel-section -->
		<!-- Footer  -->
		<Footer classname="bg-dark on-dark"></Footer>
	</div>
	<!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import store from "@/store";
import DashboardAuctionsSection from "@/components/section/DashboardAuctionsSection";

export default {
	name: "DashboardAuctions",
  components: {
    DashboardAuctionsSection
  },
	data() {
		return {
			SectionData,
		};
	},
  computed: {
    userIsValidated() {
      return store.getters["getUser"].status === 'validado';
    },
  },
  created() {
    if (!this.userIsValidated) {
      window.location.replace(process.env.VUE_APP_EXT_LOGIN);
    }
  }
};
</script>
