import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row g-gs mb-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "fw-bold" }
const _hoisted_5 = {
  key: 0,
  class: "badge badge-primary ms-2"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "card-footer" }
const _hoisted_12 = { class: "btns-group btn-group-2" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "col-sm-6 mt-4" }
const _hoisted_16 = { class: "card card-credit card-full text-center position-relative border-dashed" }
const _hoisted_17 = { class: "card-body card-body-s1 d-flex align-items-center justify-content-center" }
const _hoisted_18 = { class: "my-2" }
const _hoisted_19 = { class: "card-title text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_DashboardPaymentsBillingAddressModal = _resolveComponent("DashboardPaymentsBillingAddressModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('trans.profile.payments.details.title')), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billingAddresses, (billingAddress, key) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-sm-6 mt-4",
            key: billingAddress.hash
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["card card-credit card-full border-dashed", { border: billingAddress.is_default }])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createTextVNode(" Dirección " + _toDisplayString(key + 1) + " ", 1),
                  (billingAddress.is_default)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('trans.profile.payments.default')), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(billingAddress.type === 'freelance'
                      ? billingAddress.name + ' ' + billingAddress.surname
                      : billingAddress.business_name) + " ", 1),
                    (billingAddress.address != 'XXXX')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: '<br />' + billingAddress.address
                        }, null, 8, _hoisted_6))
                      : _createCommentVNode("", true),
                    (billingAddress.city != 'XXXX')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          innerHTML: '<br />' + billingAddress.city
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true),
                    (billingAddress.postal_code != 'XXXX')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 2,
                          innerHTML: billingAddress.postal_code + ', '
                        }, null, 8, _hoisted_8))
                      : _createCommentVNode("", true),
                    (billingAddress.region.name != 'XXXX')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 3,
                          innerHTML: '<br />' + billingAddress.region.name
                        }, null, 8, _hoisted_9))
                      : _createCommentVNode("", true),
                    (billingAddress.country.name != 'XXXX')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 4,
                          innerHTML: '<br />' + billingAddress.country.name
                        }, null, 8, _hoisted_10))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("ul", _hoisted_12, [
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      onClick: _withModifiers(($event: any) => (_ctx.editBillingAddress(billingAddress)), ["prevent"]),
                      href: "#",
                      class: "btn btn-light btn-sm me-2"
                    }, _toDisplayString(_ctx.$t('trans.profile.payments.details.edit')), 9, _hoisted_13),
                    (!billingAddress.is_default)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          onClick: _withModifiers(($event: any) => (_ctx.deleteBillingAddress(billingAddress)), ["prevent"]),
                          href: "#",
                          class: "btn btn-sm btn-outline-danger"
                        }, _toDisplayString(_ctx.$t('trans.profile.payments.delete')), 9, _hoisted_14))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ], 2)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addBillingAddress && _ctx.addBillingAddress(...args)), ["prevent"])),
              class: "stretched-link"
            }),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_BaseIcon, {
                  width: "120",
                  class: "mb-2",
                  name: "billing_address"
                }),
                _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('trans.profile.payments.details.add_new_address')), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_DashboardPaymentsBillingAddressModal, {
      onFinishAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal())),
      "billing-address": _ctx.billingAddress
    }, null, 8, ["billing-address"])
  ]))
}