
// Import component data. You can change the data in the store to reflect in all component
import {roundLocale} from "@/plugins/helpers";
import store from "@/store";
import {useArtwork} from "@/composable/artwork/artwork";
import {onMounted, ref, watch} from 'vue';

interface CountdownTimer {
  days: number | undefined;
  hours: number | undefined;
  minutes: number | undefined;
  seconds: number | undefined;
  ended: boolean;
  started: boolean;
}

export default {
  name: "CuentaAtrasTab",
  props: {
    showPriceInfo: Boolean,
    currentArtwork: Object
  },
  setup() {

    const countdownTimer = ref<CountdownTimer>({
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      ended: false,
      started: false,
    });

    const {currentAuction, currentAuctionLastBid} = useArtwork();

    const formatNumber = (num) => {
      if (num !== undefined && num !== null) {
        return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      } else {
        return '0';
      }
    };

    watch(currentAuction, (newValue) => {
      const auctionDateEnd = Date.parse(currentAuction.value.date_end);
      const auctionDateStart = Date.parse(currentAuction.value.date_start);
      let diff;

      if (new Date(auctionDateStart).valueOf() > new Date().getTime().valueOf()) {
        countdownTimer.value.started = false;
        diff = new Date(auctionDateStart).valueOf() - new Date().getTime().valueOf();
      } else {
        countdownTimer.value.started = true;
        diff = new Date(auctionDateEnd).valueOf() - new Date().getTime().valueOf();
      }

      countdownTimer.value.days = Math.floor(diff / (1000 * 60 * 60 * 24));
      countdownTimer.value.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      countdownTimer.value.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      countdownTimer.value.seconds = Math.floor((diff % (1000 * 60)) / 1000);

      countDownTimer();
    });

    watch(countdownTimer, (newValue) => {
      if (!countdownTimer.value.ended) {
        countDownTimer();
      }
    });

    onMounted(() => {
      if (currentAuction.value.date_end && currentAuction.value.date_start) {
        const auctionDateEnd = Date.parse(currentAuction.value.date_end);
        const auctionDateStart = Date.parse(currentAuction.value.date_start);
        let diff;

        if (new Date(auctionDateStart).valueOf() > new Date().getTime().valueOf()) {
          countdownTimer.value.started = false;
          diff = new Date(auctionDateStart).valueOf() - new Date().getTime().valueOf();
        } else {
          countdownTimer.value.started = true;
          diff = new Date(auctionDateEnd).valueOf() - new Date().getTime().valueOf();
        }

        countdownTimer.value.days = Math.floor(diff / (1000 * 60 * 60 * 24));
        countdownTimer.value.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        countdownTimer.value.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        countdownTimer.value.seconds = Math.floor((diff % (1000 * 60)) / 1000);

        countDownTimer();
      }
    });

    const countDownTimer = () => {
      setInterval(function () {
        const auctionDateEnd = Date.parse(currentAuction.value.date_end);
        const auctionDateStart = Date.parse(currentAuction.value.date_start);
        let diff;

        if (new Date(auctionDateStart).valueOf() > new Date().getTime().valueOf()) {
          countdownTimer.value.started = false;
          diff = new Date(auctionDateStart).valueOf() - new Date().getTime().valueOf();
        } else {
          countdownTimer.value.started = true;
          diff = new Date(auctionDateEnd).valueOf() - new Date().getTime().valueOf();
        }

        if (diff >= 0) {
          countdownTimer.value.days = Math.floor(diff / (1000 * 60 * 60 * 24));
          countdownTimer.value.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          countdownTimer.value.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          countdownTimer.value.seconds = Math.floor((diff % (1000 * 60)) / 1000);
        } else {
          countdownTimer.value.ended = true;
        }
      }, 1000);
    };

    return {
      formatNumber,
      currentAuction,
      currentAuctionLastBid,
      countdownTimer,
    };
  },
  data() {
    return {
      roundLocale,
    };
  },
};
