<template>
<div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
        <!-- Header main -->
        <HeaderMain></HeaderMain>
        <!-- hero -->
    
    </header>
    <div class="row mt-5">
      <div class="col text-center mb-5">
        <h1 class="mb-5">Confirmación de Email</h1>
        <p class="fw-bold fs-12 mt-5">Gracias, tu email ha sido confirmado. <span v-if="!isUserAuthenticated">Ahora ya puedes iniciar sesión desde el menú superior.</span></p>
        <p class="fw-bold fs-12 mt-5" v-if="isUserAuthenticated">
          <router-link class="btn btn-dark" :to="{ name: $t('routes.dashboard.name') }">
            {{ $t('userDropdown.profile') }}
          </router-link>
        </p>
      </div>
    </div>
    <!-- contact section -->
    <!-- Footer  -->
    <Footer classname="bg-dark on-dark"></Footer>
</div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import store from "@/store";


export default {
  name: 'EmailConfirm',

  data () {
    return {
      SectionData
    }
  },
  computed: {
    hasConfirmedEmail() {
      return this.$store.state.user?.email_verified;
    },
    isUserAuthenticated() {
      return this.$store.state.isUserAuthenticated;
    },
  },
  mounted() {
    this.refreshUser();
  },
  methods: {
    async refreshUser() {
      if (this.isUserAuthenticated && !this.hasConfirmedEmail) {
        //Refrescamos el usuario autenticado
        await this.$sanctum.getUser();
      }
    }
  }
}
</script>
