<template>
    <a href="#" class="theme-toggler" title="Activities">
        <span>
          <em class="ni ni-pen icon theme-toggler-show"></em>
          <em class="ni ni-box icon theme-toggler-hide"></em>
        </span>
        <span class="theme-toggler-text">Activities</span>
    </a>
    

</template>

<script>
export default {
  name: 'BotonEstadisticas',

}
</script>