import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "my-4" }
const _hoisted_2 = { class: "card-price-wrap d-flex align-items-center justify-content-sm-between mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "me-5 me-sm-2"
}
const _hoisted_4 = { class: "card-price-title" }
const _hoisted_5 = { class: "card-price-number" }
const _hoisted_6 = {
  key: 1,
  class: "text-sm-end"
}
const _hoisted_7 = { class: "card-price-title" }
const _hoisted_8 = { class: "card-price-number" }
const _hoisted_9 = { class: "item-detail-btns mt-4" }
const _hoisted_10 = { class: "btns-group d-flex" }
const _hoisted_11 = {
  key: 0,
  class: "flex-grow-1"
}
const _hoisted_12 = {
  key: 1,
  class: "flex-grow-1"
}
const _hoisted_13 = {
  key: 2,
  class: "flex-grow-1"
}
const _hoisted_14 = {
  key: 3,
  class: "flex-grow-1"
}
const _hoisted_15 = { class: "flex-grow-1" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "tooltip-s1 w-100" }
const _hoisted_18 = {
  href: "#",
  class: "btn btn-outline-dark d-block"
}
const _hoisted_19 = { class: "tooltip-s1-text tooltip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonUserCreate = _resolveComponent("ButtonUserCreate")!
  const _component_ButtonUserLogin = _resolveComponent("ButtonUserLogin")!
  const _component_ButtonUserVerifyEmail = _resolveComponent("ButtonUserVerifyEmail")!
  const _component_ArtworkMarketplaceModal = _resolveComponent("ArtworkMarketplaceModal")!
  const _directive_clipboard = _resolveDirective("clipboard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('trans.artwork.for_sale.sell.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      ($setup.currentArtwork.active_artwork_sell.price_euro)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('trans.artwork.sell.euro_price')), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString($setup.formatNumber($setup.currentArtwork.active_artwork_sell.price_euro?.price) + ' €'), 1)
          ]))
        : _createCommentVNode("", true),
      ($setup.currentArtwork.active_artwork_sell.price_matic)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('trans.artwork.sell.matic_price')), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString($setup.currentArtwork.active_artwork_sell.price_matic?.price + ' MATIC'), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("ul", _hoisted_10, [
        (!$setup.currentUser && $setup.trackingExists)
          ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
              _createVNode(_component_ButtonUserCreate, {
                class: _normalizeClass('btn btn-dark d-block'),
                text: 'trans.marketplace.action'
              }, null, 8, ["text"])
            ]))
          : (!$setup.currentUser)
            ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                _createVNode(_component_ButtonUserLogin, {
                  class: _normalizeClass('btn btn-dark d-block'),
                  text: 'trans.marketplace.action'
                }, null, 8, ["text"])
              ]))
            : ($setup.currentUser && !$setup.currentUser.email_verified)
              ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                  _createVNode(_component_ButtonUserVerifyEmail, {
                    class: _normalizeClass('btn btn-dark d-block'),
                    text: 'marketplace.modal.purchase'
                  }, null, 8, ["text"])
                ]))
              : (!$setup.isArtworkMine)
                ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openMarketplaceModal($setup.currentArtwork.active_artwork_sell))),
                      class: "btn btn-dark btn-block w-100"
                    }, _toDisplayString(_ctx.$t('trans.marketplace.action')), 1)
                  ]))
                : _createCommentVNode("", true),
        _createElementVNode("li", _hoisted_15, [
          _createElementVNode("input", {
            hidden: "",
            type: "text",
            class: "copy-input text-white",
            value: $setup.message,
            id: "copy-input",
            readonly: ""
          }, null, 8, _hoisted_16),
          _createElementVNode("div", _hoisted_17, [
            _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('trans.copy_link')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('trans.share.text')), 1)
            ])), [
              [_directive_clipboard, $setup.path, "copy"],
              [_directive_clipboard, $setup.onCopy, "success"]
            ])
          ])
        ])
      ])
    ]),
    ($setup.currentUser && $setup.currentArtwork.active_artwork_sell)
      ? (_openBlock(), _createBlock(_component_ArtworkMarketplaceModal, {
          key: 0,
          artworkHash: $setup.currentArtwork.hash
        }, null, 8, ["artworkHash"]))
      : _createCommentVNode("", true)
  ], 64))
}