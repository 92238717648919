<template>
  <section class="">
    <div class="container">
      <FilterHeader
        :orderByItems="[
          { name: $t('filters.order.date_desc'), slug: 'date_desc' },
          { name: $t('filters.order.date_asc'), slug: 'date_asc' },
          { name: $t('filters.order.name'), slug: 'name' }
        ]"
        :orderByInitial="'date_desc'"
        @order="selectOrder"
      ></FilterHeader>

      <div class="row">
        <div class="collapse d-xl-block col-xl-2 mb-5" id="filters">
          <div class="card card-body">
            <a class="pb-2" data-bs-toggle="collapse" href="#filterCollapseDiscipline" role="button">
              {{ $t('trans.artworksContainer.filters.disciplines.title') }}
              <i class="float-end fa-solid fa-angle-down"></i>
            </a>
            <div class="collapse" id="filterCollapseDiscipline">
              <ul>
                <li v-for="discipline in disciplines" :key="discipline.id">
                  <div class="form-check">
                    <input
                      class="form-check-input check-all-input pr-3"
                      type="checkbox"
                      :id="`discipline_${discipline.id}_id`"
                      :value="discipline.id"
                      v-model="filters.disciplines[discipline.tag]"
                      @change="updateFilters"
                    />
                    <label class="form-check-label form-check-label-s1" :for="`discipline_${discipline.id}`">
                      {{ discipline.name }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="card card-body">
            <a class="pb-2" data-bs-toggle="collapse" href="#filterCollapseArtworkType" role="button">
              {{ $t('trans.artworksContainer.filters.artwork_type.title') }}
              <i class="float-end fa-solid fa-angle-down"></i>
            </a>
            <div class="collapse" id="filterCollapseArtworkType">
              <ul>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input check-all-input pr-3"
                      type="checkbox"
                      id="nftCheckbox"
                      v-model="filters.artwork_type.nft"
                      @change="updateFilters"
                    />
                    <label class="form-check-label form-check-label-s1" for="nftCheckbox">{{
                      $t('trans.artworksContainer.filters.artwork_type.nft')
                    }}</label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input check-all-input pr-3"
                      type="checkbox"
                      id="physicalCheckbox"
                      v-model="filters.artwork_type.physical"
                      @change="updateFilters"
                    />
                    <label class="form-check-label form-check-label-s1" for="physicalCheckbox">{{
                      $t('trans.artworksContainer.filters.artwork_type.physical')
                    }}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="card card-body">
            <a class="pb-2" data-bs-toggle="collapse" href="#filterCollapseSaleType" role="button">
              {{ $t('trans.artworksContainer.filters.sale_type.title') }}
              <i class="float-end fa-solid fa-angle-down"></i>
            </a>
            <div class="collapse" id="filterCollapseSaleType">
              <ul>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input check-all-input pr-3"
                      type="checkbox"
                      id="sellCheckbox"
                      v-model="filters.sale_type.sell"
                      @change="updateFilters"
                    />
                    <label class="form-check-label form-check-label-s1" for="sellCheckbox">{{
                      $t('trans.artworksContainer.filters.sale_type.direct_sell')
                    }}</label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input check-all-input pr-3"
                      type="checkbox"
                      id="auctionCheckbox"
                      v-model="filters.sale_type.auction"
                      @change="updateFilters"
                    />
                    <label class="form-check-label form-check-label-s1" for="auctionCheckbox">{{
                      $t('trans.artworksContainer.filters.sale_type.auction')
                    }}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="card card-body">
            <a class="pb-2" data-bs-toggle="collapse" href="#filterCollapseArtist" role="button">
              {{ $t('trans.artworksContainer.filters.artist.title') }} <i class="float-end fa-solid fa-angle-down"></i>
            </a>
            <div class="collapse" id="filterCollapseArtist">
              <ul>
                <li class="pb-2">
                  <!--                  <input-->
                  <!--                    type="text"-->
                  <!--                    class="form-control form-control-s1"-->
                  <!--                    :placeholder="$t('trans.artworksContainer.filters.artist.name')"-->
                  <!--                    v-model="filters.artist"-->
                  <!--                    @change="updateFilters"-->
                  <!--                  />-->
                  <!--                </li>-->

                  <v-select
                    class="generic-select"
                    @update:modelValue="updateFilters"
                    v-model="filters.artist"
                    placeholder="Escoger..."
                    :reduce="(artist) => artist.code"
                    :options="artists"
                  ></v-select>
                </li>
              </ul>
            </div>
          </div>
          <div class="card card-body">
            <a class="pb-2" data-bs-toggle="collapse" href="#filterCollapseArtwork" role="button">
              {{ $t('trans.artworksContainer.filters.artwork.title') }} <i class="float-end fa-solid fa-angle-down"></i>
            </a>
            <div class="collapse" id="filterCollapseArtwork">
              <ul>
                <li class="pb-2">
                  <input
                    type="text"
                    class="form-control form-control-s1"
                    :placeholder="$t('trans.artworksContainer.filters.artwork.name')"
                    v-model="filters.artwork.name"
                    @change="updateFilters"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-10">
          <div v-if="loading === false && artworks.data.length >= 0" >
            <div v-if="artworks.data.length > 0" class="row g-gs">
              <div v-for="artwork in artworks.data" :key="artwork.hash" class="col-lg-4 col-sm-6">
                <Artwork :artwork="artwork"></Artwork>
              </div>
            </div>
            <div v-else-if="artworks.data.length === 0" class="row g-gs">
              <div class="alert alert-warning" role="alert" style="text-align: center;">
                <span>
                  {{ $t('trans.artworksContainer.no_results') }}
                </span>
              </div>
            </div>
          </div>
          <div v-else class="row g-gs">
            <div class="alert alert-warning" style="text-align:center;background-color: #bb5a2887;height:77px;" role="alert">
              <span>
                <svg style="height:50px;" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                    <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                      <animateTransform 
                        attributeName="transform" 
                        attributeType="XML" 
                        type="rotate"
                        dur="1s" 
                        from="0 50 50"
                        to="360 50 50" 
                        repeatCount="indefinite" />
                  </path>
                </svg>
              </span>
            </div>
          </div>
          <!-- end row -->
        </div>
      </div>
    </div>
    <!-- .container -->
    <div class="text-center mt-4 mt-md-5 mb-5">
      <Pagination
        :records="artworks ? artworks.total : 0"
        v-model="page"
        :per-page="perPage"
        @paginate="setArtworks"
        :options="{
          texts: {
            count: $t('pagination.count'),
            first: $t('pagination.first'),
            last: $t('pagination.last')
          }
        }"
      ></Pagination>
    </div>
  </section>
  <!-- end explore-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import Artwork from '@/components/section/Artwork';
import FilterHeader from '@/components/common/FilterHeader';
import store from '@/store';
import { ssrRunning } from '@/helpers/index.ts';
import Pagination from 'v-pagination-3';
import axios from '@/plugins/axios';
import { nextTick, ref, computed } from 'vue';

export default {
  name: 'ArtworkExploreSection',
  components: {
    Artwork,
    Pagination,
    FilterHeader
  },
  data() {
    return {
      SectionData,
      test: true,
      loading: true,
      category: 'all',
      name: '',
      activeId: 0,
      options: ['all', 'art', 'music', 'games', 'collectibles'],
      perPage: 12,
      page: 1,
      option: null,
      order: 'date_desc',
      disciplines: [],
      artists: [],
      filters: {
        disciplines: {},
        artwork_type: {
          nft: true,
          physical: true
        },
        sale_type: {
          sell: false,
          auction: false,
        },
        artist: null,
        artwork: {
          name: ''
        }
      }
    };
  },
  onMounted() {
    this.test = false;
  },
  // setup() {
  //   const loading = ref(true);

  //   return {
  //     artworks: computed(() => {
  //       if (loading.value) {
  //         // Afficher le loader ici
  //         return [];
  //       } else {
  //         // Récupérer les données du store
  //         return store.getters['artwork/getArtworks'];
  //       }
  //     })
  //   }
  // },
  computed: {
    artworks() {
      return store.getters['artwork/getArtworks'];
    }
  },
  async created() {
    this.loading = true;
    this.setArtworks(1);

    await store.dispatch('artwork/setArtworks', {
      perPage: this.perPage,
      page: 1
    });

    const response = await axios.get('/api/v1/public/artwork/disciplines/list');
    this.disciplines = response.data?.data;

    if (this.disciplines) {
      this.disciplines.forEach((element) => {
        this.filters.disciplines[element.tag] = true;
      });
    }

    const artistsResponse = await axios.get('/api/v1/public/artist/list-public-validated');
    if (artistsResponse.data?.data) {
      this.artists = artistsResponse.data.data.map((element) => {
        return {
          label: element.full_name,
          code: element.hash
        };
      });
    }

    this.computeQueryParams();
  },
  async serverPrefetch() {
    await store.dispatch('artwork/setArtworks', {
      perPage: this.perPage,
      page: 1
    });
  },
  watch: {
    $route() {
      this.computeQueryParams();
    }
  },
  methods: {
    async setArtworks(page) {
      this.loading = true;
      this.page = page;

      if (this.order) {
        this.filters.order = this.order;
      }

      await store.dispatch('artwork/setArtworks', {
        perPage: this.perPage,
        page,
        query: { filters: JSON.stringify(this.filters) }
      });

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    computeQueryParams() {
      const sale_type = this.$route.query.sale_type;
      if (sale_type) {
        Object.keys(this.filters.sale_type).forEach((val) => {
          this.filters.sale_type[val] = sale_type === val;
        });

        this.updateFilters();
      }
    },
    selectOption(value) {
      this.option = value;
      this.page = 1;
      this.setArtworks(this.page);
    },
    selectOrder(value) {
      this.order = value;
      this.page = 1;
      this.setArtworks(this.page);
    },
    updateFilters() {
      nextTick(() => {
        this.page = 1;
        this.setArtworks(this.page);
      });
    }
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.author-link {
  z-index: 2;
  position: relative;
}

.multiselect-tag {
  padding: 5px 8px;
  border-radius: 22px;
  background: #242424;
  margin: 3px 3px 8px;
}

.vue-select {
  width: 60px;
}
</style>
