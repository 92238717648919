<template>
  <section class="section-space-b blog-section">
    <div class="container">
      <TermsSection></TermsSection>
    </div>
  </section>
  >
</template>

<script>
import SectionData from '@/store/store.js';
import HeroFour from '@/components/section/HeroFour.vue';

export default {
  name: 'TermsDetails',
  components: {},
  data() {
    return {
      SectionData
    };
  }
};
</script>
