<template>
  <div class="page-wrap">
        <!-- header  -->
        <header class="header-section has-header-main bg-pattern-3">
          <!-- Header main -->
          <HeaderMain></HeaderMain>
          <!-- hero -->
          <BuyerHero avatarSize="avatar-3"
                     :coverimg="SectionData.authorPersonalData.coverImg"
                     :img="SectionData.authorPersonalData.img">
          </BuyerHero>
        </header>
        <!-- account section -->
        <section class="user-panel-section section-space">
            <div class="container">
                <div class="row">
                    <!-- user sidebar -->
                    <UserSidebar
                        :title="$t('trans.profile.title')"
                    ></UserSidebar>
                    <AccountSection></AccountSection>
                </div><!-- end row -->
            </div><!-- end container -->
        </section><!-- end user-panel-section -->
        <!-- Footer  -->
        <Footer classname="bg-dark on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import UserSidebar from '@/components/common/UserSidebar'
import store from "@/store";
export default {
  name: 'Account',
  components: {
    UserSidebar
  },
  data () {
    return {
      SectionData
    }
  },
  computed: {
    userIsValidated() {
      return store.getters["getUser"].status === 'validado';
    },
    isPublicUser() {
      return this.$store.state.isPublicUser;
    },
    isPrivateUser() {
      return this.$store.state.isPrivateUser;
    },
    isAdmin() {
      return this.$store.state.isAdminUser;
    },
  },
  created() {
    if (!this.userIsValidated) {
      window.location.replace(process.env.VUE_APP_EXT_LOGIN);
    }
  }
}
</script>
