import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { useArtwork } from '@/composable/artwork/artwork';
import axios from '@/plugins/axios';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import * as Yup from 'yup';
import { useMarketplace } from '@/composable/marketplace/marketplace';
import { useWeb3 } from '@/composable/web3/web3';

export interface purchaseAuctionCryptoFormState {
  billing_address: string | null;
  bid_offer: number | null;
  wallet_address: string | null;
  blockchain_transaction_hash: string | null;
}

export function useMarketplaceAuctionCrypto() {
  const { refreshAvailability } = useMarketplace();
  const { t } = useI18n();
  const router = useRouter();
  const { bidToAuctionNFT } = useWeb3();

  const { currentArtwork, currentAuction } = useArtwork();

  const sendingFormPurchaseAuction = ref(false);

  const purchaseAuctionCryptoFormState = ref<purchaseAuctionCryptoFormState>({
    billing_address: null,
    bid_offer: null,
    wallet_address: null,
    blockchain_transaction_hash: null
  });

  const purchaseAuctionSchema = Yup.object().shape({
    billing_address: Yup.string().nullable().required(t('Selecciona una dirección de facturación')),
    bid_offer: Yup.number()
      .typeError('La puja tiene que ser un valor numerico')
      .nullable()
      .required(t('introduce una puja'))
      .test('min-bid', 'Puja incorrecta, debe ser mayor o igual a la puja mínima', (value, context) => {
        return value >= currentAuction.value.minimumBid;
      })
  });

  const purchaseAuction = async () => {
    sendingFormPurchaseAuction.value = true;
    await refreshAvailability(currentAuction.value, currentArtwork.value);

    if (currentAuction.value) {
      Swal.fire({
        title: t('trans.alert.confirm_purchase.title'),
        text: t('trans.alert.confirm_purchase.message'),
        icon: 'warning',
        confirmButtonText: t('trans.alert.continue'),
        cancelButtonText: t('trans.alert.cancel'),
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2'
        }
      }).then(async (result) => {
        if (result.value) {
          await purchaseAuctionCrypto();
        } else {
          Swal.fire({
            title: t('trans.alert.cancelled_purchase.title'),
            text: t('trans.alert.cancelled_purchase.message'),
            icon: 'info',
            confirmButtonText: t('trans.alert.continue'),
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2',
              cancelButton: 'btn btn-outline-primary mx-2',
              denyButton: 'btn btn-danger mx-2'
            }
          });
        }
        sendingFormPurchaseAuction.value = false;
      });
    }
  };

  const purchaseAuctionCrypto = async () => {
    await refreshAvailability(currentAuction.value, currentArtwork.value);

    Swal.fire({
      title: t('trans.alert.default_loading.title'),
      text: t('trans.alert.default_loading.message'),
      icon: 'info',
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading(null);
        await bidToAuctionNFT(currentArtwork.value, currentAuction.value, purchaseAuctionCryptoFormState.value);
      },
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-dark mx-2',
        cancelButton: 'btn btn-outline-primary mx-2',
        denyButton: 'btn btn-danger mx-2'
      }
    });
  };

  return {
    purchaseAuction,
    purchaseAuctionSchema,
    sendingFormPurchaseAuction,
    purchaseAuctionCryptoFormState
  };
}
