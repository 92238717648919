<template>
   <section class="section-space-b featured-creator-section">
            <div class="container">
                <!-- section heading -->
              <!-- swiper sldier -->
                <FeaturedCollectionSlider></FeaturedCollectionSlider>
                <div class="text-center mt-5">
                </div>
            </div><!-- .container -->
        </section><!-- end featured-creator-section -->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'FeaturedCollections',
  data () {
    return {
      SectionData,
    }
  }
}
</script>
