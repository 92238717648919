import { useStore } from 'vuex';
import { computed } from 'vue';
import { PlanSubscription, Role, User } from '@/types/interfaces/global/UserInterface';

export function useUserAuth() {
  const store = useStore();

  const currentUser = computed((): User => {
    return store.getters['getUser'];
  });
  const isUserVerified = computed(() => {
    return store.getters['getUser'].email_verified;
  });
  const currentRole = computed((): Role => {
    return store.getters['getCurrentRole'];
  });
  const isPublicUser = computed((): Role => {
    return store.getters['getIsPublicUser'];
  });
  const isPrivateUser = computed((): Role => {
    return store.getters['getIsPrivateUser'];
  });
  const isAdminUser = computed((): Role => {
    return store.getters['getIsAdminUser'];
  });

  return {
    currentUser,
    isUserVerified,
    currentRole,
    isPublicUser,
    isPrivateUser,
    isAdminUser
  };
}
