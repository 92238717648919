import Thumbnail from '@/types/artist';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { isSSR } from '@/helpers';

export default function useArtistImage(artist) {
  const size = ref();
  const headerImageUri = ref();
  const avatarImageUri = ref();
  const biographyImagesUris = ref();

  const onResize = () => {
    size.value = !isSSR() ? (window.innerWidth < 992 ? 'sm' : 'lg') : 'lg';
  };

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
  });

  onMounted(async () => 
  {
    onResize();
    computeImages();
    nextTick(() => {
      window.addEventListener('resize', onResize);
    });
  });

  const computeImages = () => {
    headerImageUri.value = getThumbnailUri('header', '4_1');
    avatarImageUri.value = getThumbnailUri('avatar', '1_1');
    biographyImagesUris.value = getBiographyImagesUris();
  };

  watch([artist, size], async () => {
    computeImages();
  });

  const getBiographyImagesUris = () => {
    const uris = [];
    if (!artist.value) {
      return uris;
    }
    const images = artist.value.images;
    if (images) {
      const biografyImages = images.filter((element) => {
        return element.type === 'biography';
      });

      if (biografyImages.length > 0) {
        biografyImages.sort((a, b) => (a.order > b.order ? 1 : -1));

        biografyImages.forEach((biografyImage) => {
          const thumbnail = biografyImage.thumbnails.find((thumbnail) => {
            return thumbnail.aspect_ratio === '1_1' && thumbnail.size === size.value;
          });

          if (thumbnail) {
            uris.push(thumbnail.uri);
          }
        });
      }
    }

    return uris;
  };

  const getThumbnail = (type: string, aspectRadio: string): Thumbnail | null => {
    const images = artist.value?.images;

    if (images) {
      const image = images.find((element) => {
        return element.type === type;
      });

      if (image) {
        return image.thumbnails.find((thumbnail) => {
          return thumbnail.aspect_ratio === aspectRadio && thumbnail.size === size.value;
        });
      }
    }

    return null;
  };

  const getThumbnailUri = (type: string, aspectRadio: string): string | null => {
    const thumbnail = getThumbnail(type, aspectRadio);

    return thumbnail?.uri;
  };

  return {
    size,
    headerImageUri,
    avatarImageUri,
    biographyImagesUris,
    getThumbnail,
    getThumbnailUri
  };
}
