<template>
	<div class="page-wrap">
		<!-- header  -->
		<header class="header-section has-header-main">
			<!-- Header main -->
			<HeaderMain></HeaderMain>
			<!-- hero -->
			<HeaderBreadcrumbs
				classname="hero-title"
				:title="$t('trans.breadcrumbs.titles.artworks')"
				:breadcrumb-items="[
					{
						title: $t('trans.breadcrumbs.artworks'),
						routeName: $t('routes.artworks.name')
					}
				]"
			></HeaderBreadcrumbs>
		</header>
		<!-- Explore section  -->
		<ArtworkExploreSection></ArtworkExploreSection>
		<!-- top creators 
		<TopCreators classname="section-space-t"></TopCreators>-->
		<!-- Collections 
		<Collections></Collections>-->
		<!-- Footer  -->
		<Footer classname="bg-dark on-dark"></Footer>
	</div>
	<!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";

export default {
	name: "ArtworkExplore",
	data() {
		return {
			SectionData,
		};
	},
};
</script>
