import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "g-gs mb-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "fw-bold" }
const _hoisted_5 = {
  key: 0,
  class: "badge badge-primary ms-2"
}
const _hoisted_6 = { class: "d-flex mt-3" }
const _hoisted_7 = { class: "text-primary" }
const _hoisted_8 = { class: "card-footer" }
const _hoisted_9 = { class: "btns-group btn-group-2" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "col-sm-6 mt-4" }
const _hoisted_13 = { class: "card card-credit card-full text-center position-relative border-dashed" }
const _hoisted_14 = { class: "card-body card-body-s1 d-flex align-items-center justify-content-center" }
const _hoisted_15 = { class: "my-4" }
const _hoisted_16 = { class: "card-title" }
const _hoisted_17 = { class: "row g-gs mb-4" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_PaymentMethodWallet = _resolveComponent("PaymentMethodWallet")!
  const _component_PaymentMethodStripeModal = _resolveComponent("PaymentMethodStripeModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('trans.profile.payments.fiat.title')), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethodsFiat, (paymentMethod, key) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-sm-6 mt-4",
            key: paymentMethod.hash
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["card card-credit card-full border-dashed", { border: paymentMethod.is_default }])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.$t('trans.profile.payments.credit_card')) + " " + _toDisplayString(key + 1) + " ", 1),
                  (paymentMethod.is_default)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('trans.profile.payments.default')), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_BaseIcon, {
                    width: "80",
                    height: "60",
                    name: _ctx.getIcon(paymentMethod.pm_type),
                    class: "border border-gray-400 rounded me-3"
                  }, null, 8, ["name"]),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_7, " **** **** **** " + _toDisplayString(paymentMethod.pm_last_four), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("ul", _hoisted_9, [
                  _createElementVNode("li", null, [
                    (!paymentMethod.is_default)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          onClick: _withModifiers(($event: any) => (_ctx.setAsDefault(paymentMethod)), ["prevent"]),
                          href: "#",
                          class: "btn btn-light btn-sm me-2"
                        }, _toDisplayString(_ctx.$t('trans.profile.payments.use_as_default')), 9, _hoisted_10))
                      : _createCommentVNode("", true),
                    (!paymentMethod.is_default)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          onClick: _withModifiers(($event: any) => (_ctx.deletePaymentMethod(paymentMethod)), ["prevent"]),
                          href: "#",
                          class: "btn btn-sm btn-outline-danger"
                        }, _toDisplayString(_ctx.$t('trans.profile.payments.delete')), 9, _hoisted_11))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ], 2)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addPaymentMethod && _ctx.addPaymentMethod(...args)), ["prevent"])),
              class: "stretched-link"
            }),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_BaseIcon, {
                  width: "120",
                  class: "mb-2",
                  name: "payment_card"
                }),
                _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('trans.profile.payments.add_payment_method')), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("h5", _hoisted_18, _toDisplayString(_ctx.$t('trans.profile.payments.crypto.title')), 1),
      _createElementVNode("div", _hoisted_19, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethodsCrypto, (paymentMethodCrypto, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: "col-6 mt-3"
          }, [
            _createVNode(_component_PaymentMethodWallet, {
              wallet: paymentMethodCrypto.wallet
            }, null, 8, ["wallet"])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_PaymentMethodStripeModal, { onFinishCreate: _ctx.closeModal }, null, 8, ["onFinishCreate"])
  ]))
}