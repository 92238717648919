import { createStore } from 'vuex';
import auth from './modules/auth';
import artwork from './modules/artwork';
import artist from './modules/artist';
import collection from './modules/collection';
import auction from './modules/auction';
import marketplace from './modules/marketplace';
import axios from '@/plugins/axios';
// Create a new store instance.
const store = createStore({
  state() {
    return {
      user: null,
      isUserAuthenticated: false,
      isBetaChecked: false,
      isPublicUser: false,
      isPrivateUser: false,
      isAdminUser: false,
      currentWallet: null,
      currentWalletMin: null,
      currentWalletMinMicro: null
    };
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getCurrentRole(state) {
      if (!state.user) {
        return false;
      }
      if (state.user.role === undefined) {
        return false;
      }

      return state.user.role[0];
    },
    getIsPublicUser(state) {
      return state.isPublicUser;
    },
    getIsPrivateUser(state) {
      return state.isPrivateUser;
    },
    getIsAdminUser(state) {
      return state.isAdminUser;
    },
    hasUserPaymentMethodFiat(state) {
      if (state.user.role === undefined) {
        return false;
      }
      return state.user.role[0].payment_methods_fiat[0] !== undefined;
    },
    getUserPaymentMethodFiat(state) {
      if (state.user.role === undefined) {
        return [];
      }
      return state.user.role[0].payment_methods_fiat;
    },
    hasUserPaymentMethodCrypto(state) {
      if (state.user.role === undefined) {
        return false;
      }
      return state.user.role[0].payment_methods_crypto[0] !== undefined;
    },
    getUserPaymentMethodCrypto(state) {
      if (state.user.role === undefined) {
        return [];
      }
      return state.user.role[0].payment_methods_crypto;
    },
    getUserBillingAddresses(state) {
      if (state.user.role === undefined) {
        return null;
      }
      return state.user.role[0].billing_addresses;
    },
    getUserShippingAddress(state) {
      if (state.user.role === undefined) {
        return null;
      }
      return state.user.role[0].shipping_addresses[0];
    },
    hasUserShippingAddress(state) {
      if (state.user.role === undefined) {
        return false;
      }
      return state.user.role[0].shipping_addresses[0] !== undefined;
    },
    getUserOrderPublics(state) {
      if (state.user.role === undefined) {
        return [];
      }
      return state.user.role[0].order_publics;
    }
    // hasUserOrderPublics(state) {
    //   if (state.user.role === undefined) {
    //     return false;
    //   }
    //   return state.user.role[0].order_publics[0] !== undefined;
    // }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setIsUserAuthenticated(state, logged) {
      state.isUserAuthenticated = logged;
    },
    setBetaCheck(state, checked) {
      state.isBetaChecked = checked;
    },
    setIsPublicUser(state, value) {
      state.isPublicUser = value;
    },
    setIsPrivateUser(state, value) {
      state.isPrivateUser = value;
    },
    setIsAdminUser(state, value) {
      state.isAdminUser = value;
    },
    setCurrentWallet(state, wallet) {
      state.currentWallet = wallet;
    },
    setCurrentWalletMin(state, wallet) {
      state.currentWalletMin = wallet;
    },
    setCurrentWalletMinMicro(state, wallet) {
      state.currentWalletMinMicro = wallet;
    }
  },
  actions: {
    async saveWallet({ commit }, wallet) {
      let error;
      try {
        let response = await axios.post('/api/v1/public/profile/payment-method/createWalletPaymentMethod', {
          wallet_address: wallet
        });
        var subsMin = wallet.substring(8, 34); //ell
        var subsMinMicro = wallet.substring(3, 39); //ell
        store.commit('setCurrentWallet', wallet);
        store.commit('setCurrentWalletMin', wallet.replace(subsMin, '...'));
        store.commit('setCurrentWalletMinMicro', wallet.replace(subsMinMicro, '...'));

        if (response) {
          response = response.data?.data;
        }
      } catch (e) {
        error = e;
      }
    }
  },
  modules: {
    auth,
    artwork,
    artist,
    collection,
    auction,
    marketplace
  }
});

export default store;
