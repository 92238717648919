<template>
  <swiper
      :modules="modules"
      :slides-per-view="1"
      :effect="'fade'"
      :loop="true"
      :navigation="{ clickable: true }"
      class="swiper-button-s1 swiper-button-s2 on-dark animated m-0"
      style="min-height: 490px; height:490px;"
  >
    <swiper-slide
        v-for="item in SectionData.heroDataEight"
        :key="item.id"
        class="h-auto p-0"
        style="min-height: 490px; height:490px;"
    >
      <div class="slider-full-screen set-bg" :data-set-bg="item.fileName" v-if="!isVideo(item.fileName)">
        <div class="row">
          <div class="slider-full-body col-lg-8 col-md-8 col-sm-9 margin-0-0-25-25">
            <h2 class="mb-3 card-title">{{ $t(item.title) }}</h2>
            <p class="mb-4 card-text">{{ $t(item.content) }}</p>

            <ul class="btns-group" :class="classname" v-if="item.titlebt && item.path">
              <li>
                <router-link :to="{
                        name: $t(item.path),
                        params: item.params,
                      }" class="btn" :class="item.btnClass">{{ $t(item.titlebt) }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="slider-full-screen set-bg" v-if="isVideo(item.fileName)">
        <div class="video-container">
          <video class="slide-video-bg" :ref="`videoPlayer-${item.id}`" autoplay loop :muted="!item.soundOn" playsinline>
            <source :src="item.fileName" type="video/mp4" />
          </video>
        </div>
        <div class="video-action">
          <button @click="toggleVideoSound(item.id)" class="sound-button" style="width:30px;margin-left: 20px;">
            <img :src="item.soundOn ? item.soundOnImage : item.soundOffImage" class="invert-colors">
          </button>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";

// core version + navigation, pagination modules:
import SwiperCore, {Navigation, EffectFade, Autoplay} from "swiper";

// configure Swiper to use modules
SwiperCore.use([Navigation, EffectFade, Autoplay]);

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";

export default {
  name: "HomeSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      SectionData,
    };
  },
  methods: {
    isVideo(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      return extension === 'mp4';
    },
    toggleVideoSound(itemId) {
      const item = this.SectionData.heroDataEight.find(item => item.id === itemId);
      if (item) {
        item.soundOn = !item.soundOn;
        const videoElement = this.$refs[`videoPlayer-${itemId}`];
        if (videoElement) {
          videoElement.muted = !item.soundOn;
        }
      }
    },
  },
  mounted() {
    /*  ================================================================
      Set background image
    ==================================================================== */
    function setBg(selector) {
      var list = document.querySelectorAll(selector);
      for (var i = 0; i < list.length; i++) {
        var src = list[i].getAttribute("data-set-bg");
        list[i].style.backgroundImage = "url('" + src + "')";
      }
    }

    setBg(".set-bg");
  },
  setup() {
    return {
      modules: [Navigation, EffectFade, Autoplay],
    };
  },
};
</script>
<style>
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.video-action {
  padding: 25px;
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.slide-video-bg {
  background-color: black;
  width: 140%;
}
.invert-colors {
  mix-blend-mode: difference;
  filter: brightness(150%) invert(1);
}
.sound-button {
  width: 30px;
  border: 0;
  background: transparent;
  margin-left: 20px;
}
.slider-full-screen {
  background-color: black;
  padding:0;
}
.margin-0-0-25-25 {
  margin: 0 0 25px 25px;
}

@media (max-width:480px) {
  .slide-video-bg {
    width: 350%;
  }
  .video-action {
    display: none;
  }
  .slider-full-body {
    margin-left: 12px;
    margin-bottom: 20px;
  }
  .margin-0-0-25-25 {
  margin: 0 0 15px 15px;
}
}
</style>