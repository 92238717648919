
import { defineComponent } from 'vue';

interface Items {
  id: number | string;
}

export default defineComponent({
  name: 'BaseRadioButtonGroup',
  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },
    active: {
      type: Boolean,
      default: false,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const selectOption = (value) => {
      emit('update:modelValue', props.modelValue === value ? '' : value);
    };

    return {
      selectOption
    };
  }
});
