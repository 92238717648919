import axios from '../../plugins/axios';

export default {
  namespaced: true,
  state: () => ({
    marketplace: {},
    latestMarketplaces: []
  }),
  mutations: {
    setMarketplace(state, marketplace) {
      state.marketplace = marketplace;
    },
    setLatestMarketplaces(state, marketplaces) {
      state.latestMarketplaces = marketplaces;
    }
  },
  getters: {
    getMarketplace(state) {
      return state.marketplace;
    },
    getLatestMarketplaces(state) {
      return state.latestMarketplaces;
    }
  },
  actions: {
    async setMarketplace({ commit }, hash) {
      let error;
      try {
        let marketplace = await axios.get('/api/v1/public/marketPlace/view/' + hash);
        if (marketplace) {
          marketplace = marketplace.data?.data;
        }
        commit('setMarketplace', marketplace);
      } catch (e) {
        error = e;
      }
    },
    async setLatestMarketplaces({ commit }) {
      let error;
      try {
        let response = await axios.get('/api/v1/public/artwork/listWithChildsMarketplace');
        if (response) {
          response = response.data?.data;
        }
        commit('setLatestMarketplaces', response);
      } catch (e) {
        error = e;
      }
    }
  }
};
