import { ref, watch, computed } from 'vue';
import { useCookie } from 'vue-cookie-next';
import { bootstrap, setOptions } from 'vue-gtag'

const allowCookies = ref<boolean>();

export default function useCookies() {
    const cookie = useCookie();

    if (cookie.isCookieAvailable('cookies_consent')) {
        allowCookies.value = cookie.getCookie('cookies_consent') === 'true';
        // this.$gtag.optIn();
    } else {
        allowCookies.value = undefined;
    }

    watch(allowCookies, () => {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        if (allowCookies.value != undefined) {
            if (allowCookies.value) {
                cookie.setCookie('cookies_consent', allowCookies.value.toString(), {
                    expire: date,
                });
                // this.$gtag.optIn();
                // setOptions({
                //     config: { id: 'G-TY60X03731' }
                // })
                // bootstrap().then((gtag) => {
                //
                //     // all done!
                // })
            } else {
                // this.$gtag.optOut();
                cookie.removeCookie('cookies_consent');
            }
        }
    });

    const showBanner = computed(() => {
        return allowCookies.value === undefined;
    });

    const okClicked = () => (allowCookies.value = true);

    return {
        allowCookies,
        showBanner,
        okClicked,
    };
}