<template>
  <section class="about-section">
            <div class="container">
                <div class="row align-items-center flex" :class="isReverse">
                  
				    <div class="col-lg-6 mb-5 mb-lg-0 d-none d-lg-block" :class="isClassThree">
                   <IconFactSection classname="" :items="SectionData.funfactData.funfactListTwo"></IconFactSection>
                   
                    </div><!-- end col-lg-6 -->
                    <div class="col-lg-6" :class="isClassTwo">
                        <div class="about-content-wrap">
                            <h2 class="mb-3">{{$t('trans.about.plans_devices.devices.title')}}</h2>
                            <p :class="isClass">{{$t('trans.about.plans_devices.devices.content')}}</p>
                             <p>{{$t('trans.about.plans_devices.devices.content_2')}}</p>
							<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataDis"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.devices.button')}}
							</span>
							</a>
                        </div>
                    </div><!-- end col-lg-6 -->
 					<div class="col-lg-6 mb-5 mb-lg-0  d-block d-lg-none" :class="isClassThree">
                   <IconFactSection classname="" :items="SectionData.funfactData.funfactListTwo"></IconFactSection>
                   
                    </div><!-- end col-lg-6 -->

                </div><!-- end row -->
            </div><!-- end container -->
        </section><!-- end about-section -->
         <div
				class="modal fade"
				id="smarttag"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_smarttag.title')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
            <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_smarttag.subtitle')}}</label><br>
								<br>
               <p align="justify">{{$t('trans.about.plans_devices.devices.modal_smarttag.content_1')}}
              <br></p><br>
               <p align="justify">{{$t('trans.about.plans_devices.devices.modal_smarttag.content_2')}}<br></p>
						<br>
				 <b>{{$t('trans.about.plans_devices.devices.modal_smarttag.price')}}</b> {{$t('trans.about.plans_devices.devices.modal.tax_included')}}<br>
						<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataSmarttag"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.devices.modal_smarttag.button')}}
							</span>
							</a>	</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>


         <div
				class="modal fade"
				id="artdevices"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_artdevice.title')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_artdevice.subtitle')}}</label><br>
								<br>
               <p align="justify">{{$t('trans.about.plans_devices.devices.modal_artdevice.content_1')}}<br></p>
               <ul class="list-item list-item-icon">
                  <li><span class='ni ni-check icon icon-circle icon-wbg icon-xs'></span>{{$t('trans.about.plans_devices.devices.modal_artdevice.content_1_list_1')}}</li>
                  <li><span class='ni ni-check icon icon-circle icon-wbg icon-xs'></span>{{$t('trans.about.plans_devices.devices.modal_artdevice.content_1_list_2')}}</li>
                  <li><span class='ni ni-check icon icon-circle icon-wbg icon-xs'></span>{{$t('trans.about.plans_devices.devices.modal_artdevice.content_1_list_3')}}</li></ul>
              <br>
               <p align="justify">{{$t('trans.about.plans_devices.devices.modal_artdevice.content_2')}}<br></p>
                  <p>&nbsp;</p><p align="justify">
{{$t('trans.about.plans_devices.devices.modal_artdevice.content_3')}}<br></p>
<br>
				 <span v-html="$t('trans.about.plans_devices.devices.modal_artdevice.price')"></span> {{$t('trans.about.plans_devices.devices.modal.tax_included')}}<br>
						<br>
		<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataArtdevice"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.devices.modal_artdevice.button')}}
							</span>
							</a>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>




     <div
				class="modal fade"
				id="beacon"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_artbeacon.title')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_artbeacon.subtitle')}}</label><br>
								<br>
                <p align="justify">{{$t('trans.about.plans_devices.devices.modal_artbeacon.content_1')}}<br></p>
 <br><p align="justify">
{{$t('trans.about.plans_devices.devices.modal_artbeacon.content_2')}}<br></p> 
 <br><p align="justify">
{{$t('trans.about.plans_devices.devices.modal_artbeacon.content_3')}}<br></p>
<br>
				 <span v-html="$t('trans.about.plans_devices.devices.modal_artbeacon.price')"></span> {{$t('trans.about.plans_devices.devices.modal.tax_included')}}<br>
						<br>
						<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataBeacon"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.devices.modal_artbeacon.button')}}
							</span>
							</a>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>




<div
				class="modal fade"
				id="ContrataDis"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_devices.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
				   <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_devices.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.devices.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en los dispositivos')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" id="floatingInputInteres" value="Interesado en los dispositivos"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.devices.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.devices.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{$t('trans.about.plans_devices.devices.modal.button')}}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>



   <div
				class="modal fade"
				id="ContrataSmarttag"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_smarttag.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_smarttag.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.devices.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en SmartTag')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" value="Interesado en SmartTag"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>                                            
										  <label for="floatingInputEmail">{{$t('trans.about.plans_devices.devices.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.devices.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{$t('trans.about.plans_devices.devices.modal.button')}}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>




   <div
				class="modal fade"
				id="ContrataArtdevice"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_artdevice.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_artdevice.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.devices.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en ArtDevice')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" value="Interesado en ArtDevice"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.devices.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.devices.modal.message')}}.</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{$t('trans.about.plans_devices.devices.modal.button')}}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>


 <div
				class="modal fade"
				id="ContrataBeacon"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.devices.modal_artbeacon.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.devices.modal_artbeacon.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.devices.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en plan ArtBeacon')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" value="Interesado en plan ArtBeacon"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.devices.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.devices.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{$t('trans.about.plans_devices.devices.modal.button')}}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>





</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import axios from "@/plugins/axios";

export default {
  name: 'DevicesSection',
  props: ['img', 'title', 'content', 'contenttwo', 'contentthree', 'contentfour', 'isRound', 'isClass', 'isClassTwo', 'isClassThree', 'isReverse'],
  data () {
    return {
      SectionData,
	  form: {           
                email: '',             
                subject: '',
                message: '',                
            }
    }
  },
  methods:{
        async submitForm($subject){
			this.form.subject = $subject;
            const response = await axios.post('/api/v1/public/info', this.form)
                 .then((res) => {
                     this.$swal(this.$t("trans.alert.message_sent.title"), this.$t("trans.alert.message_sent.message"), 'success');
                 })
                 .catch((error) => {
                     this.$swal(this.$t("trans.alert.message_error.title"), this.$t("trans.alert.message_error.message"), 'error');
                 });
        }
},
}
</script>
