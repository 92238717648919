import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "page-wrap" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col ms-auto d-block d-lg-none" }
const _hoisted_4 = { class: "item-detail-content" }
const _hoisted_5 = { class: "item-detail-img-container item-detail-img-full mb-4" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-lg-6 mt-3 mt-lg-0" }
const _hoisted_8 = { class: "item-detail-content mb-5 mb-lg-0" }
const _hoisted_9 = { class: "item-detail-title mb-2" }
const _hoisted_10 = { class: "item-detail-meta d-flex flex-wrap align-items-center mb-3" }
const _hoisted_11 = { class: "item-detail-text-meta" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "item-detail-text mb-4" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { class: "row g-3 nav nav-tabs nav-tabs-s2 justify-content-left" }
const _hoisted_20 = { class: "nav-item col-3 col-sm-4 col-lg-2" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "nav-item col-3 col-sm-4 col-lg-2" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 0,
  class: "nav-item col-3 col-sm-4 col-lg-2"
}
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "col-lg-5 ms-auto d-none d-lg-block" }
const _hoisted_27 = { class: "item-detail-content" }
const _hoisted_28 = { class: "item-detail-img-container item-detail-img-full mb-4" }
const _hoisted_29 = ["src"]
const _hoisted_30 = { class: "section-space-t" }
const _hoisted_31 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ArtworkChildsContainer = _resolveComponent("ArtworkChildsContainer")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            ($setup.currentArtwork)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: $setup.detailVerticalImageUri,
                  alt: "",
                  class: "w-100 rounded-3"
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, _toDisplayString($setup.currentArtwork.title), 1),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, [
              ($setup.currentArtwork.artist.images.length > 0)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "details",
                    to: {
                  name: _ctx.$t('routes.artist.name'),
                  params: {
                    hash: $setup.currentArtwork.artist.hash,
                    slug: $setup.currentArtwork.artist.slug
                  }
                }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.currentArtwork.artist.full_name), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($setup.currentArtwork.artist.full_name), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", {
              innerHTML: $setup.currentArtwork.description
            }, null, 8, _hoisted_14),
            (!$setup.currentArtwork.is_nft)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  ($setup.currentArtwork.type)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString($setup.currentArtwork.type.name), 1))
                    : _createCommentVNode("", true),
                  ($setup.currentArtwork.size_height && $setup.currentArtwork.size_width)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, "- " + _toDisplayString($setup.currentArtwork.size_height) + " x " + _toDisplayString($setup.currentArtwork.size_width) + " cm", 1))
                    : _createCommentVNode("", true),
                  ($setup.currentArtwork.has_firm)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, "- " + _toDisplayString(_ctx.$t('signed')), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("ul", _hoisted_19, [
            _createElementVNode("li", _hoisted_20, [
              _withDirectives(_createElementVNode("img", {
                src: require('@/images/thumb/pintura2.svg'),
                class: "icon icon-svg",
                alt: ""
              }, null, 8, _hoisted_21), [
                [_directive_tippy, { content: $setup.currentArtwork.discipline.name, arrow: true, theme: 'dark', placement: 'bottom' }]
              ])
            ]),
            _createElementVNode("li", _hoisted_22, [
              _withDirectives(_createElementVNode("img", {
                src: require("@/images/thumb/edicion_ficha.svg"),
                class: "icon icon-svg",
                alt: ""
              }, null, 8, _hoisted_23), [
                [_directive_tippy, { content:'Edición', arrow: true, theme: 'dark', placement: 'bottom',}]
              ])
            ]),
            ($setup.currentArtwork.is_nft)
              ? (_openBlock(), _createElementBlock("li", _hoisted_24, [
                  _withDirectives(_createElementVNode("img", {
                    src: require('@/images/thumb/nft_ficha.svg'),
                    class: "icon icon-svg",
                    alt: ""
                  }, null, 8, _hoisted_25), [
                    [_directive_tippy, { content: 'NFT', arrow: true, theme: 'dark', placement: 'bottom' }]
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            ($setup.currentArtwork)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: $setup.detailVerticalImageUri,
                  alt: "",
                  class: "w-100 rounded-3"
                }, null, 8, _hoisted_29))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_ArtworkChildsContainer)
      ])
    ])
  ]))
}