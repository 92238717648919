
import useVimeo from '@/composable/useVimeo';
import { onMounted, ref } from 'vue';

export default {
  name: 'VideoModal',
  components: {},
  props: {
    vimeoId: {
      type: Number,
      required: false
    },
    vimeoHash: {
      type: String,
      required: false
    },
    vimeoData: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const { videoData, getVideoData } = useVimeo();
    const loading = ref(true);

    onMounted(async () => {
      if (props.vimeoData) {
        videoData.value = props.vimeoData;
      } else {
        videoData.value = await getVideoData(props.vimeoId as number, props.vimeoHash as string);
      }

      loading.value = false;
    });

    return {
      videoData
    };
  }
};
