<template>
  <div v-if="!loading" class="page-wrap">
    <header class="header-section has-header-main">
      <HeaderMain></HeaderMain>
      <AuthorHero
        avatarSize="avatar-3"
        :isDropdown="true"
        :isCopyInput="true"
        :coverimg="headerImageUri"
        :img="avatarImageUri"
        :title="sectionData.authorPublicData.title"
        :username="sectionData.authorPublicData.userName"
        :btntext="sectionData.authorPublicData.btnText"
        :btnlink="sectionData.authorPublicData.btnLink"
      ></AuthorHero>

      <HeaderBreadcrumbs
        classname="hero-title"
        :breadcrumb-items="[
          {
            title: $t('trans.breadcrumbs.artists'),
            routeName: $t('routes.artists.name')
          },
          {
            title: $t('trans.breadcrumbs.artist'),
            routeName: $t('routes.artworks.name')
          }
        ]"
      ></HeaderBreadcrumbs>
    </header>

    <AuthorSection :biographyImages="biographyImagesUris"></AuthorSection>
    <Footer classname="bg-dark on-dark"></Footer>
  </div>
</template>

<script>
import SectionData from '@/store/store.js';
import { useRoute } from 'vue-router';
import useArtistImage from '@/composable/useArtistImage';
import { computed, defineComponent, onMounted, onServerPrefetch, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ArtistDetail',
  setup() {
    const loading = ref(true);
    const sectionData = ref(SectionData);
    const store = useStore();

    const artist = computed(() => {
      return store.getters['artist/getArtist'];
    });
    const { size, headerImageUri, avatarImageUri, biographyImagesUris } = useArtistImage(artist);

    const loadArtist = async () => {
      const store = useStore();
      const route = useRoute();
      await store.dispatch('artist/setArtist', route.params.hash);
      loading.value = false;
    };

    onServerPrefetch(async () => {
      await loadArtist();
    });

    onMounted(async () => {
      await loadArtist();
    });

    return {
      size,
      sectionData,
      loading,
      artist,
      headerImageUri,
      avatarImageUri,
      biographyImagesUris
    };
  }
});
</script>
