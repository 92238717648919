<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
      <!-- hero -->
      <HeaderBreadcrumbs
          classname="hero-title"
          :title="$t('trans.breadcrumbs.titles.legal')"
          :breadcrumb-items="[
					{
						title: $t('trans.breadcrumbs.contact'),
					}
				]"
      ></HeaderBreadcrumbs>
    </header>
    <!-- contact section -->
    <TermsDetails></TermsDetails>
    <!-- Footer  -->
    <Footer classname="bg-dark on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Legal',
  components: {},
  data() {
    return {
      SectionData
    }
  }
}
</script>
