<template>
  <section class="author-section section-space">
    <div class="container">
      <div class="author-items-wrap">
        <SectionHeading
          classname="text-center"
          :text="$t('trans.collections.detail.title')"
          :content="$t('trans.collections.detail.content', { number: collection.artworks.length })"
          isMargin="mb-3"
        ></SectionHeading>

        <div class="gap-2x"></div>
        <!-- end gap -->
        <div class="row g-gs">
          <div class="col-md-3" v-for="artwork in collection.artworks" :key="artwork.hash">
            <Artwork :artwork="artwork"></Artwork>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- end author-items-wrap -->
    </div>
    <!-- .container -->
    <!-- Modal -->
  </section>
  <!-- end author-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import store from '@/store';
import Artwork from '@/components/section/Artwork';

export default {
  name: 'CollectionDetailSection',
  components: {
    Artwork
  },
  data() {
    return {
      SectionData
    };
  },
  computed: {
    collection() {
      return store.getters['collection/getCollection'];
    }
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.btn,
.author-link,
.card-price-wrap {
  z-index: 2;
  position: relative;
}
</style>
