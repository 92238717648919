
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import useCookies from '../composable/UseCookies';
import {isSSR} from "@/helpers";

export default {
  name: 'Cookies',
  components: {},
  data() {
    return {
      SectionData
    }
  },
  setup() {
    if (!isSSR()) {
      const {allowCookies} = useCookies();
      return {
        allowCookies,
      };
    }
  },
}
