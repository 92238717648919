import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["nav-link", { active: _ctx.modelValue === _ctx.value }]),
      id: _ctx.id,
      "data-bs-toggle": "tab",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectOption(_ctx.value)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 10, _hoisted_2)
  ]))
}