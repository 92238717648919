
interface BreadcrumbItem {
	title: string;
	routeName: string;
}

export default {
	props: {
		items: {
			type: Array as () => BreadcrumbItem[],
			default: () => [],
		},
	},
};
