<template>
  <a href="#" :class="this.class" @click="loginAlert()">
    {{ $t(this.text) }}
  </a>
</template>
<script>
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ButtonUserLogin',
  props: {
    class: String,
    text: String
  },
  data() {
    return {};
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const loginAlert = () => {
      Swal.fire({
        title: t('trans.alert.login.required.title'),
        text: t('trans.alert.login.required.text'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('trans.alert.login.required.continue'),
        cancelButtonText: t('trans.alert.login.required.cancel'),
        showCloseButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2',
          denyButton: 'btn btn-danger mx-2'
        },
      }).then((result) => {
        if (result.value) {
          router.push({ name: t('routes.login.name') });
        }
      });
    };

    return {
      loginAlert
    };
  }
};
</script>
