<template>

		<div class="container">
			<!-- section heading -->
		
			<!-- product -->
			<swiper
				:slides-per-view="4"
				:breakpoints="{
					0: {
						slidesPerView: 1,
					},
					767: {
						slidesPerView: 2,
					},
					992: {
						slidesPerView: 3,
					},
					1200: {
						slidesPerView: 4,
					},
				}"
				:pagination="{ clickable: true }"
			>
				<swiper-slide
					v-for="item in highlightedArtworks"
					:key="item.hash"
				>
					<div class="card card-full">
						<div class="card-image">
<!--							<img-->
<!--								:src="require('@/images/thumb/nft-2.jpg')"-->
<!--								class="card-img-top"-->
<!--								alt="art image"-->
<!--							/>-->
						</div>
						<div class="card-body p-4">
							<h5 class="card-title text-truncate mb-0">
								{{ item.seo_title }}
							</h5>
							<div
								class="card-author mb-1 d-flex align-items-center"
							>
								<span class="me-1 card-author-by">By</span>
								<div class="custom-tooltip-wrap">
									<router-link
										to="/author"
										class="author-link"
										>{{ item.artist?.name }}</router-link
									>
								</div>
							</div>
							<!-- end card-author -->
							<div
								class="card-price-wrap d-flex align-items-center justify-content-between mb-3"
							>
								<div class="me-2">
									<span class="card-price-title">Price</span>
									<span class="card-price-number"
										>€14</span
									>
								</div>
								<div>
									<span class="card-price-title"
										>Current bid</span
									>
									<span class="card-price-number"
										>1.42 ETH</span
									>
								</div>
							</div>
							<!-- end card-price-wrap -->
							<span class="btn btn-sm btn-dark">Ver archivo</span>
						</div>
						<!-- end card-body -->
						<router-link
							class="details"
							:to="{
								name: $t('routes.artwork.name'),
								params: {
									hash: item.hash,
									slug: item.slug,
								},
							}"
						>
						</router-link>
					</div>
					<!-- end card -->
				</swiper-slide>
			</swiper>
		</div>
		<!-- .container -->

	<!-- end related-product-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
// core version + navigation, pagination modules:
import SwiperCore, { Pagination } from "swiper";

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import store from "@/store";
import { ssrRunning } from "@/helpers";

export default {
	name: "RelatedProduct",
	components: {
		Swiper,
		SwiperSlide,
	},
	computed: {
		highlightedArtworks() {
			return store.getters["artwork/getHighlightedArtworks"];
		},
	},
	data() {
		return {
			SectionData,
		};
	},
	created() {
		if (!ssrRunning()) {
			store.dispatch("artwork/setHighlightedArtworks");
		}
	},
	serverPrefetch() {
		store.dispatch("artwork/setHighlightedArtworks");
	},
};
</script>

<style lang="css" scoped>
.details {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.author-linkm,
.card-price-wrap {
	z-index: 2;
	position: relative;
}
</style>
