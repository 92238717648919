import axios from "axios";
import { isSSR } from "@/helpers";

const instance = createInstance(process.env.VUE_APP_BASE_URL);

function createInstance(baseURL) {
	let params;
	if (isSSR) {
		params = {
			baseURL,
			withCredentials: true,
			data: {},
			Referer: process.env.VUE_APP_DOMAIN,
			Accept: "application/json",
			"X-Requested-With": "XMLHttpRequest",
			"Content-Type": "application/json",
		};
	} else {
		params = {
			baseURL,
			withCredentials: true,
		};
	}
	return axios.create(params);
}

export default instance;
