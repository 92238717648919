import axios from './axios';
import store from '@/store';

async function getUser() {
  const user = await fetchUser();
  if (user) {
    store.commit('setUser', user);
    store.commit('setIsUserAuthenticated', true);
    if (user.is_admin) {
      store.commit('setIsAdminUser', user.is_admin);
      store.commit('setIsPublicUser', false);
      store.commit('setIsPrivateUser', false);
    } else {
      store.commit('setIsPublicUser', user.has_buyers);
      store.commit('setIsPrivateUser', user.has_artists || user.has_museums);
    }
  } else {
    return false;
  }
}

async function fetchUser() {
  try {
    const response = await axios.get('/api/v1/public/profile/me');
    return response.data.data;
  } catch (e) {
    // console.log(e);
    return false;
  }
}

async function logout() {
  try {
    await axios.get('/api/v1/auth/logout');
    store.commit('setUser', null);
    store.commit('setIsUserAuthenticated', false);
    return true;
  } catch (e) {
    return false;
  }
}

export default { getUser, logout };
