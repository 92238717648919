import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c57048"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "py-3 w-100 bg-black text-white fixed-bottom",
  style: {"z-index":"99999999","opacity":"0.9"}
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "join-form-wrap" }
const _hoisted_4 = { class: "row g-gs align-items-center" }
const _hoisted_5 = { class: "col-9" }
const _hoisted_6 = { class: "fs-12" }
const _hoisted_7 = { class: "fs-15" }
const _hoisted_8 = { class: "col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageMetaTeleport = _resolveComponent("PageMetaTeleport")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PageMetaTeleport),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.path
    })),
    (_ctx.showBanner)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('trans.cookies.message.title')), 1),
                  _createElementVNode("p", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t('trans.cookies.message.content')) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: { name: _ctx.$t('routes.cookies.name') }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('trans.cookies.message.more_info')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    class: "btn btn-sm bg-white d-md-block",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.okBannerClicked && _ctx.okBannerClicked(...args)))
                  }, _toDisplayString(_ctx.$t('trans.cookies.message.button')), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}