<template>
  <div class="col-lg-9 ps-xl-5">
    <div class="user-panel-title-box">
      <h3>{{ $t("dashboardTab.favourites") }}</h3>
    </div>
    <div v-if="hasFavourites" class="row g-gs">
      <div
          class="col-xl-4 col-lg-6 col-sm-6"
          v-for="artwork in userFavourites"
          :key="artwork.hash"
      >
        <div class="card card-full">
          <div class="card-image">
            <router-link
                class="details"
                :to="{
                    name: $t('routes.artwork.name'),
                    params: {
                      hash: artwork.hash,
                      slug: artwork.slug,
                    },
                  }"
            >
              <img
                  class="card-img-top"
                  :src="getHeaderImageUri(artwork)"
                  alt="media image"
              />
            </router-link>
          </div>
          <div class="card-body p-4">
            <h5 class="card-title text-truncate mb-0">
              {{ artwork.title }}
            </h5>
            <div class="card-author mb-1 d-flex align-items-center">
						<span class="me-1 card-author-by">{{
                $t("auctionslist.by")
              }}</span>
              <div class="custom-tooltip-wrap">
                <router-link
                    :to="{
                        name: $t('routes.artist.name'),
                        params: {
                          hash: artwork.artist.hash,
                          slug: artwork.artist.slug,
                        },
                      }"
                    class="author-link"
                >
                  {{ artwork.artist.name }}
                </router-link>
              </div>
            </div>
            <!-- end card-author -->
            <div
                class="card-price-wrap d-flex align-items-center justify-content-between mb-3"
            >
              <div class="badge" :class="{ 'bg-danger':!artwork.is_public, 'bg-success':artwork.is_public }">
                {{ artwork?.is_public ? $t("auctionslist.public") : $t("auctionslist.private") }}
              </div>
            </div>
            <!-- end card-price-wrap -->
            <router-link
                class="details"
                :to="{
                    name: $t('routes.artwork.name'),
                    params: {
                      hash: artwork.hash,
                      slug: artwork.slug,
                    },
                  }"
            >
              <span class="btn btn-sm btn-dark">
                {{ $t("auctionslist.viewDetail") }}
              </span>
            </router-link>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
    </div>
    <div v-else>
      <div class="alert alert-warning" role="alert">
          <span>
            {{ $t('trans.profile.noFavourites') }}
          </span>
        <router-link
            class="btn btn-sm btn-dark float-md-end mb-1 pb-1 ms-3 ms-md-0"
            :to="{
              name: $t('routes.artworks.name'),
            }">
          {{ $t('trans.profile.noFavouritesbt') }}
        </router-link>
      </div>
    </div>
  </div>
  <!-- end user-panel-title-box  -->

  <!-- end col-lg-8 -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import store from "@/store";
import {isSSR} from "@/helpers";

export default {
  name: "DashboardFavouritesSection",
  data() {
    return {
      SectionData,
      size: !isSSR() ? window.innerWidth < 992 ? 'sm' : 'lg' : 'lg',
    };
  },
  computed: {
    userFavourites() {
      return store.getters["artwork/getUserFavourites"];
    },
    hasFavourites() {
      return store.getters["artwork/getUserFavourites"].length > 0;
    },
  },
  methods: {
    getHeaderImageUri(artwork) {

      return artwork.images.filter(
          (image) => {
            return image.type === "header_list";
          }
      )[0].thumbnails.filter(
          (thumbnail) => {
            return thumbnail.aspect_ratio === "3_2" && thumbnail.size === this.size;
          }
      )[0].uri;
    },
  },
  created() {
    store.dispatch("artwork/setUserFavourites");
  },
  serverPrefetch() {
    store.dispatch("artwork/setUserFavourites");
  },
};
</script>
