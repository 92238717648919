<template>
    <a href="#" :class="this.class" @click="loginTrackingAlert()">
      {{ $t(this.text) }}
    </a>
</template>
<script>
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import store from '@/store';
import Web3Service from '@/core/services/Web3Service';
import sanctum from '@/plugins/sanctum';
  
export default {
    name: 'ButtonUserLogin',
    props: {
      class: String,
      text: String
    },
    data() {
      return {};
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const { t } = useI18n();
  
        const loginTrackingAlert = () => {
            Swal.fire({
                title: 'Registrate en tres pasos para comprar tu obra',
                text: t('trans.alert.login.required.text'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: t('trans.alert.login.required.cancel'),
                showCloseButton: true,
                buttonsStyling: false,
                customClass: {
                confirmButton: 'btn btn-outline-dark mx-2',
                cancelButton: 'btn btn-outline-primary mx-2',
                denyButton: 'btn btn-danger mx-2'
                },
                html: `
                <form id="swal-form">
                    <input type="email" id="swal-input1" class="swal2-input" placeholder="Correo electrónico" required>
                    <input type="text" id="swal-input2" class="swal2-input" placeholder="Nombre" required>
                    <input type="text" id="swal-input3" class="swal2-input" placeholder="Apellido" required>
                    <input type="tel" id="swal-input4" class="swal2-input" placeholder="Teléfono" pattern="[0-9]*" required>
                    <input type="text" id="swal-input5" class="swal2-input" placeholder="NIF, DNI o NIE" style="text-transform: uppercase;" required>
                    <div class="swal2-checkbox">
                        <input type="checkbox" id="swal-checkbox" required>
                        <label for="swal-checkbox">
                            <small>
                                <a href="https://auth4art.com/Terms_Auth4Art.pdf" class="link" target="_blank">Acepta los términos y condiciones</a>
                            </small>
                        </label>
                    </div>
                </form>
                `,
                preConfirm: async () => {
                    //http://beta.auth4art.com.local:8080/es/obra/3f8345b0c210c3ec9aecebc8b14a78d7/un-test-de-vente-direct?tracking=e2lkOjEwMH0=
                    const email = Swal.getPopup().querySelector('#swal-input1').value;
                    const name = Swal.getPopup().querySelector('#swal-input2').value;
                    const surname = Swal.getPopup().querySelector('#swal-input3').value;
                    const phone = Swal.getPopup().querySelector('#swal-input4').value;
                    const nif = Swal.getPopup().querySelector('#swal-input5').value;
                    const toc = Swal.getPopup().querySelector('#swal-checkbox').checked;
                    const tracking = route?.query?.tracking;

                    if (!email || !name || !surname || !phone || !nif || !toc) {
                        Swal.showValidationMessage(`Por favor, rellene todos los campos y acepte los términos y condiciones`);
                        return false;
                    }

                    Swal.showLoading(null);

                    try {
                        const response = await store.dispatch('auth/setRegisterForTracking', { email, name, surname, phone, nif, toc, tracking });

                        const redirect = router.currentRoute.value?.query?.redirect ?? false;

                        const url = new URL(window.location.href);
                        const urlWithoutQueryParams = url.origin + url.pathname;
                        const currentUrl = encodeURIComponent(urlWithoutQueryParams);

                        const url_env = new URL(process.env.VUE_APP_EXT_LOGIN);
                        const baseUrl = url_env.protocol + "//" + url_env.hostname + (url_env.port ? ':' + url_env.port : '');
                        window.location.href = `${baseUrl}/proceso-de-registro?redirect=${currentUrl}`;

                        if (response.data?.error) {
                            Swal.showValidationMessage(`Error: ${response.data.error}`);
                            throw new Error(response.data.error);
                        }

                    } catch (error) {
                        if (error.response && error.response.status === 422) {
                            Swal.showValidationMessage(`Ya existe una cuenta con este correo!`);
                        } else {
                            Swal.showValidationMessage(`Request failed: ${error.message}`);
                        }
                    }
                }
            }).then((result) => {
                if (result.value) {
                    console.log(result.value)
                    //router.push({ name: t('routes.login.name') });
                }
            });
        };
  
        return {
            loginTrackingAlert
        };
    }
};
</script>
<style>
.swal2-input {
width: 80% !important;
height: 2.2em !important;
}
.link {
    text-decoration: underline !important;
    font-size: 16px !important;
}
</style>
  