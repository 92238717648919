
import PaymentMethodWallet from '@/components/common/PaymentMethodWallet.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'PaymentMethodWalletModal',
  components: {
    PaymentMethodWallet
  },
  setup() {
    const store = useStore();
    const paymentMethodsCrypto = computed(() => {
      return store.getters.getUserPaymentMethodCrypto;
    });

    const currentWallet = store.state.currentWallet;

    const currentMethodCrypto = computed(() => {
      return paymentMethodsCrypto.value.find((element) => {
        return currentWallet === element.wallet.address;
      });
    });

    return {
      currentMethodCrypto,
      currentWallet
    };
  }
};
