
import SectionData from '@/store/store.js';
import {roundLocale} from '@/plugins/helpers';
import store from '@/store';
import CuentaAtrasTab from '@/components/section/CuentaAtrasTab.vue';
import ArtworkAuctionFiatModal from '@/components/common/ArtworkAuctionFiatModal.vue';
import ArtworkAuctionCryptoModal from '@/components/common/ArtworkAuctionCryptoModal.vue';
import ButtonUserLogin from '@/components/common/ButtonUserLogin.vue';
import ButtonUserVerifyEmail from '@/components/common/ButtonUserVerifyEmail.vue';
import {isSSR} from '@/helpers/index.ts';
import {useI18n} from 'vue-i18n';
import {useUserAuth} from '@/composable/user/user';
import {useArtwork} from '@/composable/artwork/artwork';
import {useMarketplace} from '@/composable/marketplace/marketplace';
import {computed, onMounted, onUnmounted} from 'vue';
import {ArtworkSell, Auction} from "@/types/interfaces/global/MarketInterface";

export default {
  name: 'ArtworkDetailTabAuction',
  components: {
    ButtonUserVerifyEmail,
    ArtworkAuctionFiatModal,
    ArtworkAuctionCryptoModal,
    CuentaAtrasTab,
    ButtonUserLogin
  },
  props: {
    artworkHash: String
  },
  setup() {
    let intervalId;
    const {currentUser} = useUserAuth();
    const {currentArtwork, currentAuction, openMarketplaceModal} = useArtwork();
    const {refreshAvailability} = useMarketplace();
    const {t} = useI18n();

    const isUserAuthenticated = computed(() => {
      return store.state.isUserAuthenticated;
    });

    const message = t('trans.share.text');
    let path = 'https://auth4art.com'; //No permite -> $t('trans.share');
    if (!isSSR()) {
      path += window.location.pathname;
    }
    const onCopy = (e) => {
      let target = e.trigger.querySelector('.tooltip-text');
      let prevText = target.innerHTML;
      target.innerHTML = t('trans.share.copied');
      setTimeout(function () {
        target.innerHTML = prevText;
      }, 1000);
    };

    const isArtworkMine = computed(() => {
      const userArtworks = store.getters['artwork/getUserArtworks'];
      return userArtworks.find((element) => element.hash === currentArtwork.value.hash) !== undefined;
    });

    onUnmounted(async () => {
      clearInterval(intervalId);
      store.commit('auction/setAuction', {});
      store.commit('auction/setLastBid', {});
    });

    onMounted(async () => {
      if (isUserAuthenticated.value) {
        await store.dispatch('artwork/setUserArtworks');
      }

      intervalId = setInterval(async function () {
         await refreshAvailability(currentAuction.value, currentArtwork.value);
      }, 1500);
    });

    const openAuctionModal = async (marketplace: ArtworkSell | Auction) => {
      await openMarketplaceModal(marketplace)
    };

    return {
      currentArtwork,
      currentUser,
      currentAuction,
      message,
      path,
      onCopy,
      openAuctionModal,
      isArtworkMine,
    };
  },
  data() {
    return {
      SectionData,
      bidOffer: null,
      roundLocale,
      higherBidNumber: 20,
      loading: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
      bidFinished: false,
      outbid: false,
      fetchDone: false
    };
  },
  computed: {
    date() {
      const createdAt = store.getters['artwork/getArtwork']?.created_at;
      if (createdAt) {
        return new Date(createdAt).toLocaleDateString();
      }
      return '';
    }
  }
};
