<template>
	<div class="page-wrap">
		<!-- header  -->
		<header class="header-section has-header-main bg-pattern-3">
			<!-- Header main -->
			<HeaderMain></HeaderMain>
			<!-- hero -->
			<BuyerHero
				avatarSize="avatar-3"
				:coverimg="SectionData.obraDashboardData.coverImg"
				:img="SectionData.obraDashboardData.img"
			></BuyerHero>
		</header>
		<!-- artworks section -->
		<section class="user-panel-section section-space">
			<div class="container">
				<div class="row">
					<!-- user sidebar -->
					<UserSidebar
						:title="$t('dashboardTab.payments')"
						:lists="SectionData.paymentsSidebarData.navList"
						:navs="SectionData.artworksTabNavMobile"
					></UserSidebar>
					<!-- artworks -->
					<DashboardPaymentsSection></DashboardPaymentsSection>
				</div>
				<!-- end row -->
			</div>
			<!-- end container -->
		</section>
		<!-- end user-panel-section -->
		<!-- Footer  -->
		<Footer classname="bg-dark on-dark"></Footer>
	</div>
	<!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
export default {
	name: "DashboardPayments",
	data() {
		return {
			SectionData,
		};
	},
};
</script>
