<template>
  <section class="related-product-section section-space-b">
    <div class="container">
      <!-- section heading -->
      <SectionHeading classname="text-center" :text="SectionData.relatedProductData.title"></SectionHeading>
      <!-- product -->
      <swiper
        :slides-per-view="4"
        :breakpoints="{
          0: {
            slidesPerView: 1
          },
          767: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4
          }
        }"
        :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="artwork in highlightedArtworks" :key="artwork.hash">
          <Artwork :artwork="artwork"></Artwork>
        </swiper-slide>
      </swiper>
    </div>
    <!-- .container -->
  </section>
  <!-- end related-product-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
// core version + navigation, pagination modules:
import SwiperCore, { Pagination } from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import store from '@/store';
import Artwork from '@/components/section/Artwork';

export default {
  name: 'RelatedProduct',
  components: {
    Swiper,
    SwiperSlide,
    Artwork
  },
  computed: {
    highlightedArtworks() {
      return store.getters['artwork/getHighlightedArtworks'];
    }
  },
  data() {
    return {
      SectionData
    };
  },
  created() {
    store.dispatch('artwork/setHighlightedArtworks');
  },
  serverPrefetch() {
    store.dispatch('artwork/setHighlightedArtworks');
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.author-linkm,
.card-price-wrap {
  z-index: 2;
  position: relative;
}
</style>
