<template>
	<div class="hero-slider-wrap pb-0">
		<div class="containersl">
			<!-- Featured Item Slider -->
      <div class="slider-full-screen set-bg" v-if="isSSR" :style="{
        'background-image': `url(${require('@/images/thumb/auth4art_jondo-sonidos-maquinicos.jpg')})`
        }">
      </div>
      <!-- end card -->
			<HomeSlider v-else></HomeSlider>
		</div>
		<!-- .container-->
	</div>
	<!-- end hero-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import { isSSR } from "@/helpers";
export default {
	name: "HeroHome",
	data() {
		return {
			isSSR: isSSR(),
			SectionData,
		};
	},
};
</script>

<style lang="css" scoped>
.containersl{

	padding-top:0px;
}

.hero-slider-wrap {
    padding-top: 0px;
    padding-bottom: 80px;
}
</style>