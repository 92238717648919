import { useStore } from 'vuex';
import { computed, ref, nextTick } from 'vue';
import { Artwork } from '@/types/interfaces/global/ArtworkInterface';
import { usePaymentMethod } from '@/composable/user/payment_method';
import { ArtworkSell, Auction, Bid } from '@/types/interfaces/global/MarketInterface';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import axios from '@/plugins/axios';
import { isSSR } from '@/helpers';

export function useArtwork() {
  const store = useStore();
  const { t } = useI18n();
  const { hasUserPaymentMethodFiat, hasUserPaymentMethodCrypto, payment_methods_crypto } = usePaymentMethod();

  const currentArtwork = computed((): Artwork => {
    return store.getters['artwork/getArtwork'];
  });

  const currentArtworkSell = computed((): ArtworkSell => {
    return store.getters['marketplace/getMarketplace'];
  });

  const currentAuction = computed((): Auction => {
    return store.getters['auction/getAuction'];
  });

  const currentAuctionLastBid = computed((): Bid => {
    return store.getters['auction/getLastBid'];
  });

  function isArtworkSell(object: any): object is ArtworkSell {
    if (object === null || object === undefined) {
      return false;
    } else {
      return 'price_euro' in object || 'price_matic' in object;
    }
  }

  function isAuction(object: any): object is Auction {
    if (object === null || object === undefined) {
      return false;
    } else {
      return 'start_price' in object;
    }
  }

  function errorMarketplace() {
    Swal.fire({
      title: t('trans.alert.payment_method_confirm_delete.title'),
      text: t('trans.alert.payment_method_confirm_delete.title'),
      icon: 'warning',
      confirmButtonText: t('trans.alert.continue'),
      showCloseButton: true,
      showConfirmButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-dark mx-2',
        cancelButton: 'btn btn-outline-primary mx-2'
      }
    });
  }

  function errorNoPaymentsMethods() {
    Swal.fire({
      title: 'Necesitas un método de pago válido',
      text: 'Para poder realizar una compra en esta obra, necesita una tarjeta de crédito o una Wallet válida',
      icon: 'error',
      confirmButtonText: t('trans.alert.continue'),
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-dark mx-2',
        cancelButton: 'btn btn-outline-primary mx-2',
        denyButton: 'btn btn-danger mx-2'
      }
    });
  }

  function errorNoWalletSaved() {
    Swal.fire({
      title: 'No tienes ninguna Wallet configurada',
      text: 'Necesitas una Wallet válida para poder vincular el NFT.',
      icon: 'error',
      confirmButtonText: t('trans.alert.continue'),
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-dark mx-2',
        cancelButton: 'btn btn-outline-primary mx-2',
        denyButton: 'btn btn-danger mx-2'
      }
    });
  }

  const markAsFavourite = async (artworkHash) => {
    try {
      await axios.post('/api/v1/public/artwork/favourite/mark/' + artworkHash);

      await store.dispatch('artwork/setArtwork', artworkHash);
      Swal.fire({
        title: t('trans.alert.favourites_added.title'),
        text: t('trans.alert.favourites_added.message'),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: t('trans.alert.continue'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2',
          denyButton: 'btn btn-danger mx-2'
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: 'Error',
        text: 'Se produjo un error, inténtalo de nuevo más tarde',
        icon: 'error',
        confirmButtonText: t('trans.alert.continue'),
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2',
          denyButton: 'btn btn-danger mx-2'
        }
      });
    }
  };

  const unmarkAsFavourite = async (artworkHash) => {
    try {
      await axios.post('/api/v1/public/artwork/favourite/unMark/' + artworkHash);
      await store.dispatch('artwork/setArtwork', artworkHash);

      Swal.fire({
        title: t('trans.alert.favourites_removed.title'),
        text: t('trans.alert.favourites_removed.message'),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: t('trans.alert.continue'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2',
          denyButton: 'btn btn-danger mx-2'
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: 'Error',
        text: 'Se produjo un error, inténtalo de nuevo más tarde',
        icon: 'error',
        confirmButtonText: t('trans.alert.continue'),
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2',
          denyButton: 'btn btn-danger mx-2'
        }
      });
    }
  };

  const openMarketplaceModal = async (marketplace: ArtworkSell | Auction) => {
    if (isArtworkSell(marketplace)) {
      if (marketplace.price_matic && marketplace.price_euro) {
        if (hasUserPaymentMethodFiat.value || hasUserPaymentMethodCrypto.value) {
          if (hasUserPaymentMethodFiat.value && hasUserPaymentMethodCrypto.value) {
            openArtworkSellModal();
          } else if (!hasUserPaymentMethodFiat.value) {
            Swal.fire({
              title: 'No tienes ninguna tarjeta de crédito configurada',
              text: 'Para poder realizar una compra con Euros en esta obra, necesita una tarjeta de crédito válida',
              icon: 'info',
              confirmButtonText: t('trans.alert.continue'),
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-outline-dark mx-2',
                cancelButton: 'btn btn-outline-primary mx-2',
                denyButton: 'btn btn-danger mx-2'
              }
            }).then(async (result) => {
              openArtworkSellModal();
            });
          } else if (!hasUserPaymentMethodCrypto.value) {
            errorNoWalletSaved();
          }
        } else {
          errorNoPaymentsMethods();
        }
      } else if (marketplace.price_matic) {
        console.log('marketplace.price_matic', marketplace.price_matic);
        if (hasUserPaymentMethodCrypto.value) {
          openArtworkSellModal();
        } else {
          errorNoPaymentsMethods();
        }
      } else if (marketplace.price_euro) {
        //console.log('marketplace.price_euro', marketplace.price_euro);
        // Necesita ambas para poder enviar el NFT a una Wallet
        if (hasUserPaymentMethodFiat.value && hasUserPaymentMethodCrypto.value) {
          openArtworkSellModal();
        } else if (hasUserPaymentMethodFiat.value) {
          errorNoWalletSaved();
        } else {
          errorNoPaymentsMethods();
        }
      } else {
        errorMarketplace();
      }
    } else if (isAuction(marketplace)) {
      if (new Date(Date.parse(marketplace.date_start)).valueOf() > new Date().getTime().valueOf()) {
        Swal.fire({
          title: 'Próximamente',
          text: 'Esta subasta aún no está abierta, podrás realizar pujas una vez se haya abierto.',
          icon: 'info',
          confirmButtonText: t('trans.alert.continue'),
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-outline-dark mx-2'
          }
        });
      } else {
        if (marketplace.currency_type === 'matic') {
          if (hasUserPaymentMethodCrypto.value) {
            openAuctionCryptoModal();
          } else {
            errorNoPaymentsMethods();
          }
        } else if (marketplace.currency_type === 'euro') {
          // Necesita ambas para poder enviar el NFT a una Wallet
          if (hasUserPaymentMethodFiat.value && hasUserPaymentMethodCrypto.value) {
            openAuctionModal();
          } else if (hasUserPaymentMethodFiat.value) {
            errorNoWalletSaved();
          } else {
            errorNoPaymentsMethods();
          }
        } else {
          errorMarketplace();
        }
      }
    } else {
      errorMarketplace();
    }
  };

  const openArtworkSellModal = () => {
    nextTick(async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        const modal = new Modal(document.getElementById('marketplaceModal')!);
        modal.show();
      }
    });
  };
  const openAuctionModal = () => {
    nextTick(async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        const modal = new Modal(document.getElementById('placeBidModal')!);
        modal.show();
      }
    });
  };
  const openAuctionCryptoModal = () => {
    nextTick(async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        const modal = new Modal(document.getElementById('auctionCryptoModal')!);
        modal.show();
      }
    });
  };

  const hasChildsOnSale = (artwork: Artwork) => {
    if (artwork.childs) {
      return (
        artwork.childs.filter((child) => {
          return !!(child.active_artwork_sell || child.active_auction);
        }).length > 0
      );
    } else {
      return false;
    }
  };

  return {
    currentArtwork,
    currentArtworkSell,
    currentAuction,
    currentAuctionLastBid,
    openMarketplaceModal,
    openAuctionModal,
    markAsFavourite,
    unmarkAsFavourite,
    hasChildsOnSale
  };
}
