
import { roundLocale, roundLocaleFiat } from '@/plugins/helpers';
import CuentaAtrasTab from '@/components/section/CuentaAtrasTab.vue';
import { useUserAuth } from '@/composable/user/user';
import { useArtwork } from '@/composable/artwork/artwork';
import { usePaymentMethod } from '@/composable/user/payment_method';
import { useMarketplace } from '@/composable/marketplace/marketplace';
import { helpers } from '@/types/enums/PaymentCards';
import { onMounted, computed, ref } from 'vue';
import { Form, ErrorMessage, Field } from 'vee-validate';
import BaseIcon from '@/components/common/BaseIcon.vue';
import BaseRadioButtonGroup from '@/components/common/BaseRadioButtonGroup.vue';
import BaseFormButton from '@/components/common/BaseFormButton.vue';
import { useMarketplaceAuctionEuro } from '@/composable/marketplace/marketplaceAuctionEuro';
import { useBillingAddress } from '@/composable/address';

export default {
  components: {
    CuentaAtrasTab,
    Form,
    ErrorMessage,
    Field,
    BaseIcon,
    BaseRadioButtonGroup,
    BaseFormButton
  },
  props: {
    artworkHash: String
  },
  setup() {
    const { currentUser } = useUserAuth();
    const { currentArtwork, currentAuction, currentAuctionLastBid } = useArtwork();
    const { hasUserPaymentMethodFiat, payment_methods_fiat, payment_methods_crypto } = usePaymentMethod();
    const { refreshAvailability } = useMarketplace();
    const { billingAddresses } = useBillingAddress();
    const { purchaseAuctionFormState, sendingFormPurchaseAuction, purchaseAuctionSchema, purchaseAuction } =
        useMarketplaceAuctionEuro();
    const bidOffer = ref<number>(0);
    const getIcon = (key: string) => {
      return helpers.getInfo(key)?.icon;
    };

    const isValidBid = computed(() => {
      if (bidOffer.value > 0) {
        return bidOffer.value >= currentAuction.value.minimumBid;
      } else {
        return false;
      }
    });

    const formatNumber = (num) => {
      if (num !== undefined && num !== null) {
        return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      } else {
        return '0';
      }
    };

    onMounted(async () => {
      //await refreshAvailability(currentAuction.value, currentArtwork.value);

      payment_methods_fiat.value.forEach((paymentMethod) => {
        if (paymentMethod.is_default) {
          purchaseAuctionFormState.value.payment_method = paymentMethod.hash;
        }
      });

      payment_methods_crypto.value.forEach((paymentMethod) => {
        if (paymentMethod.is_default) {
          purchaseAuctionFormState.value.wallet_payment_method = paymentMethod.hash;
        }
      });

      billingAddresses.value.forEach((billingAddress) => {
        if (billingAddress.is_default) {
          purchaseAuctionFormState.value.billing_address = billingAddress.hash;
        }
      });
    });

    return {
      currentArtwork,
      currentAuction,
      currentAuctionLastBid,
      currentUser,
      hasUserPaymentMethodFiat,
      payment_methods_fiat,
      isValidBid,
      formatNumber,
      bidOffer,
      getIcon,
      purchaseAuctionFormState,
      sendingFormPurchaseAuction,
      purchaseAuctionSchema,
      purchaseAuction,
      billingAddresses,
      payment_methods_crypto
    };
  },
  data() {
    return {
      roundLocale,
      roundLocaleFiat,
      loading: false,
      expired: false
    };
  }
};
