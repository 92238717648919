import { useStore } from 'vuex';
import Thumbnail from '@/types/artist';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { isSSR } from '@/helpers';

export default function useArtworkImage(artwork) {
  const size = ref(!isSSR() ? (window.innerWidth < 992 ? 'sm' : 'lg') : 'lg');
  const headerImageUri = ref();
  const detailImageUri = ref();
  const detailVerticalImageUri = ref();

  const onResize = () => {
    size.value = !isSSR() ? (window.innerWidth < 992 ? 'sm' : 'lg') : 'lg';
  };

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
  });

  onMounted(async () => {
    computeImages();
    nextTick(() => {
      window.addEventListener('resize', onResize);
    });
  });

  const computeImages = () => {
    headerImageUri.value = getThumbnailUri('header_list', '3_2');
    detailImageUri.value = getThumbnailUri('detail', '4_1');
    detailVerticalImageUri.value = getThumbnailUri('detail', '2_3');
  };

  watch([artwork, size], async ([newArtwork, newSize]) => {
    computeImages();
  });

  const getThumbnail = (type: string, aspectRadio: string): Thumbnail | null => {
    const images = artwork.images;

    if (images) {
      const image = images.find((element) => {
        return element.type === type;
      });

      if (image) {
        return image.thumbnails.find((thumbnail) => {
          return thumbnail.aspect_ratio === aspectRadio && thumbnail.size === size.value;
        });
      }
    }

    return null;
  };

  const getThumbnailUri = (type: string, aspectRadio: string): string | null => {
    const thumbnail = getThumbnail(type, aspectRadio);

    return thumbnail?.uri;
  };

  return {
    size,
    headerImageUri,
    detailImageUri,
    detailVerticalImageUri,
    getThumbnail,
    getThumbnailUri
  };
}
