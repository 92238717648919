<template>
  <div class="page-wrap">
    <header class="header-section has-header-main">
      <HeaderMain></HeaderMain>

      <HeaderBreadcrumbs
        classname="hero-title"
        :title="$t('trans.breadcrumbs.titles.artwork')"
        :breadcrumb-items="[
          {
            title: $t('userDropdown.profile'),
            routeName: $t('routes.dashboard.name')
          },
          {
            title: $t('trans.breadcrumbs.artworks'),
            routeName: $t('routes.dashboard.artworks.name')
          },
          {
            title: $t('trans.breadcrumbs.artwork'),
            routeName: $t('routes.editArtwork.name')
          }
        ]"
      ></HeaderBreadcrumbs>
    </header>
    <section class="section-space-b">
      <div class="container" v-if="!loading">
        <div v-if="!hash">
          <NotFoundCommon></NotFoundCommon>
        </div>
        <div>
          <div class="page-wrap">
            <div class="row">
              <div class="col-10 mx-auto ms-auto d-block d-lg-none">
                <div class="item-detail-content">
                  <div class="item-detail-img-container mb-4">
                    <img v-if="slug" :src="getDetailImageUri(artwork)" alt="" class="w-100 rounded-3" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-3 mt-lg-0">
                <div class="item-detail-content mb-5 mb-lg-0">
                  <h1 class="item-detail-title mb-2">
                    {{ title }}
                  </h1>

                  <div class="item-detail-meta d-flex flex-wrap align-items-center mb-3">
                    <span class="item-detail-text-meta" v-if="!loading">
                      <router-link
                        v-if="artistHasImages"
                        class="details"
                        :to="{
                          name: $t('routes.artist.name'),
                          params: {
                            hash: artist.hash,
                            slug: artist.slug
                          }
                        }"
                      >
                        {{ artistName }}
                      </router-link>
                      <span v-else>
                        {{ artistName }}
                      </span>
                    </span>
                    <span v-if="!isNFT" class="dot-separeted"></span>
                    <span v-if="!isNFT" class="item-detail-text-meta">
                      {{ date }}
                    </span>
                  </div>

                  <p class="item-detail-text mb-4" align="justify">
                    <span v-html="description"></span>
                    <span v-if="!isNFT">
                      - {{ type }} - {{ height }} x {{ width }} cm
                      <span v-if="hasFirm"> - {{ $t('signed') }} </span>
                    </span>
                  </p>
                  <ul class="row g-3 nav nav-tabs nav-tabs-s2 justify-content-left">
                    <li class="nav-item col-3 col-sm-4 col-lg-2">
                      <img
                        :src="require('@/images/thumb/pintura2.svg')"
                        class="icon icon-svg"
                        alt=""
                        v-tippy="{ content: discipline, arrow: true, theme: 'dark', placement: 'bottom' }"
                      />
                    </li>
                    <li class="nav-item col-3 col-sm-4 col-lg-2">
                      <img
                        :src="require('@/images/thumb/nft_ficha.svg')"
                        class="icon icon-svg"
                        alt=""
                        v-tippy="{ content: 'NFT', arrow: true, theme: 'dark', placement: 'bottom' }"
                      />
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <div class="item-detail-tab">
                    <ul class="nav nav-tabs nav-tabs-s1" id="artworkTab" role="tablist" style="font-size: 15px">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          :class="'active'"
                          :id="'options-tab'"
                          data-bs-toggle="tab"
                          :data-bs-target="'#options'"
                          type="button"
                        >
                          {{ $t('trans.artwork.options.title') }}
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          :class="''"
                          :id="'owners-tab'"
                          data-bs-toggle="tab"
                          :data-bs-target="'#owners'"
                          type="button"
                        >
                          {{ $t('trans.artwork.properties.title') }}
                        </button>
                      </li>
                      <li v-if="artwork.latestBlockchainTrack" class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          :class="''"
                          :id="'history-tab'"
                          data-bs-toggle="tab"
                          :data-bs-target="'#history'"
                          type="button"
                        >
                          {{ $t('trans.artwork.authenticity.title') }}
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content mt-3" id="artworkTabContent">
                      <div class="tab-pane fade show active" id="options" role="tabpanel" aria-labelledby="options-tab">
                        <div class="item-detail-tab-wrap">
                          <div class="form-item mb-3">
                            <div class="switch-wrap">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="me-2 position-relative">
                                  <a class="stretched-link" href="#" @click.prevent="changeIsPublic()"></a>
                                  <h5 class="mb-1">Obra pública</h5>
                                  <p class="form-text">Para poder poner en venta tu obra tiene que ser pública</p>
                                </div>
                                <div class="form-check form-switch form-switch-s1">
                                  <input
                                    class="form-check-input checkbox-switcher"
                                    data-target="switch-content-unlock"
                                    type="checkbox"
                                    :checked="artwork.is_public"
                                    @click.prevent="changeIsPublic()"
                                    id="publicArtworkCheckbox"
                                  />
                                </div>
                              </div>
                              <div class="switch-content-unlock mt-4" id="switch-content-unlock">
                                <input
                                  type="text"
                                  name="text"
                                  class="form-control form-control-s1"
                                  placeholder="Access key, code to redeem or link to a file..."
                                />
                              </div>
                            </div>
                          </div>

                          <ArtworkToSell v-if="false" :artwork="artwork" v-show="artwork && artwork.is_public"></ArtworkToSell>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                        <div class="item-detail-tab-wrap">
                          <ul
                            class="row g-2 nav nav-tabs nav-tabs-s2 justify-content-center"
                            id="propertiesTab"
                            role="tablist"
                            style="font-size: 14px"
                          >
                            <li class="nav-item col-6 col-sm-6 col-lg-3" role="presentation">
                              <button
                                class="nav-link active"
                                :id="'Autor'"
                                data-bs-toggle="tab"
                                :data-bs-target="'#author'"
                                type="button"
                              >
                                <img
                                  v-tippy="{
                                    content: $t('trans.artwork.properties.author'),
                                    arrow: true,
                                    theme: 'dark',
                                    placement: 'top'
                                  }"
                                  :src="require('@/images/thumb/author.svg')"
                                  class="icon icon-svg"
                                  alt=""
                                />
                                <span class="nav-link-title2 mt-3 d-block">{{ artistName }}</span>
                              </button>
                            </li>
                            <li class="nav-item col-6 col-sm-6 col-lg-3" role="presentation">
                              <button
                                class="nav-link"
                                :id="'Certificado'"
                                data-bs-toggle="tab"
                                :data-bs-target="'#cert'"
                                type="button"
                              >
                                <img
                                  v-tippy="{
                                    content: $t('trans.artwork.properties.certificate'),
                                    arrow: true,
                                    theme: 'dark',
                                    placement: 'top'
                                  }"
                                  :src="require('@/images/thumb/cert.svg')"
                                  class="icon icon-svg"
                                  alt=""
                                />
                                <span class="nav-link-title2 mt-3 d-block">Auth 4 Art</span>
                              </button>
                            </li>
                            <li class="nav-item col-6 col-sm-6 col-lg-3" role="presentation">
                              <button
                                class="nav-link"
                                :id="'Fecha'"
                                data-bs-toggle="tab"
                                :data-bs-target="'#date'"
                                type="button"
                              >
                                <img
                                  v-tippy="{
                                    content: $t('trans.artwork.properties.date'),
                                    arrow: true,
                                    theme: 'dark',
                                    placement: 'top'
                                  }"
                                  :src="require('@/images/thumb/cal.svg')"
                                  class="icon icon-svg"
                                  alt=""
                                />
                                <span class="nav-link-title2 mt-3 d-block">{{ mintDate }}</span>
                              </button>
                            </li>
                            <li class="nav-item col-6 col-sm-6 col-lg-3" role="presentation">
                              <button
                                class="nav-link"
                                :id="'Fecha'"
                                data-bs-toggle="tab"
                                :data-bs-target="'#date'"
                                type="button"
                              >
                                <img
                                  v-tippy="{
                                    content: $t('trans.artwork.properties.edition'),
                                    arrow: true,
                                    theme: 'dark',
                                    placement: 'top'
                                  }"
                                  :src="require('@/images/thumb/edicion.svg')"
                                  class="icon icon-svg"
                                  alt=""
                                />
                                <span v-if="artwork.original" class="nav-link-title2 mt-3 d-block"
                                  >{{ artwork.child_order }} {{ $t('trans.artwork.properties.of') }}
                                  {{ artwork.original.childs.length }}</span
                                >
                                <span v-else class="nav-link-title2 mt-3 d-block"
                                  >1 {{ $t('trans.artwork.properties.of') }} 1</span
                                >
                              </button>
                            </li>
                            <!-- <li class="nav-item col-3 col-sm-3 col-lg-4" role="presentation"
                        v-for="list in SectionData.propertiesNavList" :key="list.id">
                      <button
                          class="nav-link" :class="list.class" :id="list.slug" data-bs-toggle="tab"
                          :data-bs-target="list.bsTarget" type="button">
                        <img v-tippy="{ content: list.slug, arrow: true, theme: 'dark', placement: 'top',}"
                             :src="list.icon" class="icon icon-svg" alt="">
                        <span class="nav-link-title2 mt-3 d-block">{{ list.title }}</span>
                      </button>
                    </li> -->
                          </ul>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="hystory-tab">
                        <div class="profile-setting-panel">
                          <div class="mb-3 text-black balance-wrap">
                            <AuthenticationTab></AuthenticationTab>
                          </div>
                          <p>
                            {{ $t('trans.artwork.authenticity.security') }}
                            <a href="/es/servicios/#dispositivos">{{
                              $t('trans.artwork.authenticity.find_out_more')
                            }}</a>
                          </p>
                          <ul class="styled-icon mt-3">
                            <li>
                              <a
                                href="#"
                                v-tippy="{
                                  content: 'ArtDevice no Presente',
                                  arrow: true,
                                  theme: 'dark',
                                  placement: 'bottom'
                                }"
                              >
                                <em class="icon fa fa-location-arrow" alt="ArtDevice"></em
                              ></a>
                            </li>
                            <li>
                              <a
                                href="#"
                                v-tippy="{
                                  content: 'SmarTag no Presente',
                                  arrow: true,
                                  theme: 'dark',
                                  placement: 'bottom'
                                }"
                              >
                                <em class="icon fa fa-qrcode"></em>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                v-tippy="{
                                  content: 'ArtBeacon no Presente',
                                  arrow: true,
                                  theme: 'dark',
                                  placement: 'bottom'
                                }"
                              >
                                <em class="icon fa fa-share-alt"></em>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 ms-auto d-none d-lg-block">
                <div class="item-detail-content">
                  <div class="item-detail-img-container mb-4">
                    <img v-if="slug" :src="getDetailImageUri(artwork)" alt="" class="w-100 rounded-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer classname="bg-dark on-dark"></Footer>
  </div>
</template>

<script>
import SectionData from '@/store/store.js';
import { useRoute } from 'vue-router';
import store from '@/store';
import { roundLocale } from '@/plugins/helpers';
import { directive } from 'vue-tippy';
import NotFoundCommon from '@/components/common/NotFoundCommon';
import ArtworkToSell from '@/components/common/ArtworkToSell';
import axios from '@/plugins/axios';
import { isSSR } from '@/helpers';

export default {
  name: 'ArtworkDetailEdit',
  directives: {
    tippy: directive
  },
  components: {
    NotFoundCommon,
    ArtworkToSell
  },
  data() {
    return {
      SectionData,
      id: this.$route.params.id,
      bidOffer: null,
      roundLocale,
      higherBidNumber: 20,
      loading: true,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
      bidFinished: false,
      outbid: false,
      fetchDone: false,
      size: !isSSR() ? (window.innerWidth < 992 ? 'sm' : 'lg') : 'lg'
    };
  },
  computed: {
    hash() {
      return store.getters['artwork/getUserArtwork']?.hash;
    },
    title() {
      return store.getters['artwork/getUserArtwork']?.title;
    },
    metaText() {
      return store.getters['artwork/getUserArtwork']?.seo_keywords;
    },
    description() {
      return store.getters['artwork/getUserArtwork']?.description;
    },
    discipline() {
      return store.getters['artwork/getUserArtwork']?.discipline?.name;
    },
    content() {
      return store.getters['artwork/getUserArtwork']?.content;
    },
    artistName() {
      return store.getters['artwork/getUserArtwork']?.artist?.full_name;
    },
    artist() {
      return store.getters['artwork/getUserArtwork']?.artist;
    },
    isNFT() {
      return store.getters['artwork/getUserArtwork']?.is_nft;
    },
    highlightedArtworks() {
      return store.getters['artwork/highlightedArtworks'];
    },
    user() {
      return store.state.user.name;
    },
    date() {
      const createdAt = store.getters['artwork/getUserArtwork']?.created_at;
      if (createdAt) {
        return new Date(createdAt).toLocaleDateString();
      }
      return '';
    },
    ownerName() {
      return (
        store.getters['artwork/getUserArtwork']?.owner?.name +
        ' ' +
        store.getters['artwork/getUserArtwork']?.owner?.surname
      );
    },
    type() {
      return store.getters['artwork/getUserArtwork']?.type?.name;
    },
    height() {
      return store.getters['artwork/getUserArtwork']?.size_height;
    },
    width() {
      return store.getters['artwork/getUserArtwork']?.size_width;
    },
    hasFirm() {
      return store.getters['artwork/getUserArtwork']?.has_firm;
    },
    isAuction() {
      return typeof store.getters['auction/getAuction']?.hash !== 'undefined';
    },
    isMarketplace() {
      return typeof store.getters['marketplace/getMarketplace']?.hash !== 'undefined';
    },
    slug() {
      return store.getters['artwork/getUserArtwork']?.slug;
    },
    artwork() {
      return store.getters['artwork/getUserArtwork'];
    },
    artistHasImages() {
      return store.getters['artwork/getArtwork']?.artist?.images.length > 0;
    },
    isParentArtwork() {
      if (store.getters['artwork/getUserArtwork'].childs !== undefined) {
        return store.getters['artwork/getUserArtwork'].childs.length > 0;
      }
      return false;
    },
    isChildArtwork() {
      return store.getters['artwork/getUserArtwork'].child_order;
    },
    mintDate() {
      const mintDate = store.getters['artwork/getUserArtwork']?.mint_date;
      if (mintDate) {
        return new Date(mintDate).toLocaleDateString();
      }
      return '';
    }
  },
  async serverPrefetch() {
    await this.setData();
    this.loading = false;
  },
  async created() {
    await this.setData();
    this.loading = false;
  },
  beforeCreate() {
    store.dispatch('artwork/clearUserArtwork');
  },
  methods: {
    getDetailImageUri(artwork) {
      return artwork.images
        .filter((image) => {
          return image.type === 'detail';
        })[0]
        .thumbnails.filter((thumbnail) => {
          return thumbnail.aspect_ratio === '2_3' && thumbnail.size === this.size;
        })[0].uri;
    },
    async setData() {
      let error;
      const route = useRoute();
      await store.dispatch('artwork/setUserArtwork', route.params.hash);
      const auction = store.getters['artwork/getUserArtwork'].active_auction;
      const marketplace = store.getters['artwork/getUserArtwork'].active_artwork_sell;

      if (auction) {
        //Sin Marketplace
        store.commit('marketplace/setMarketplace', {});
        store.commit('auction/setAuction', auction);
      } else if (marketplace) {
        //Sin Auction
        store.commit('auction/setAuction', {});
        store.commit('marketplace/setMarketplace', marketplace);
      } else {
        //Sin nada
        store.commit('auction/setAuction', {});
        store.commit('marketplace/setMarketplace', {});
      }
    },
    async changeIsPublic() {
      this.$swal({
        title: this.$t('trans.alert.setIsPublic.title'),
        text: this.$t('trans.alert.setIsPublic.message'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: this.$t('trans.alert.setIsPublic.continue'),
        cancelButtonText: this.$t('trans.alert.setIsPublic.cancel'),
        showCloseButton: true
      }).then((result) => {
        if (result.value) {
          const response = axios
            .post('/api/v1/public/profile/artwork/setIsPublic/' + this.artwork.hash, {
              is_public: !this.artwork.is_public
            })
            .then((res) => {
              store.dispatch('artwork/setUserArtwork', this.artwork.hash);
              this.$swal(this.$t('trans.alert.success.title'), this.$t('trans.alert.success.message'), 'success');
            })
            .catch((error) => {
              this.$swal(this.$t('trans.alert.error.title'), this.$t('trans.alert.error.message'), 'error');
            });
        }
      });
    }
  },
  setup() {
    return {};
  }
};
</script>
<style>
.item-detail-btns {
  padding-top: 20px;
}
</style>
