import { useStore } from 'vuex';
import { Artwork } from '@/types/interfaces/global/ArtworkInterface';
import { ArtworkSell, Auction } from '@/types/interfaces/global/MarketInterface';

import { useArtwork } from '@/composable/artwork/artwork';

export function useMarketplace() {
  const store = useStore();

  const { currentArtwork } = useArtwork();

  function isArtworkSell(object: any): object is ArtworkSell {
    if (object === null || object === undefined) {
      return false;
    } else {
      return 'price_euro' in object || 'price_matic' in object;
    }
  }

  function isAuction(object: any): object is Auction {
    if (object === null || object === undefined) {
      return false;
    } else {
      return 'start_price' in object;
    }
  }

  const refreshAvailability = async (marketplace: ArtworkSell | Auction, artwork: Artwork) => {
    await store.dispatch('artwork/setArtwork', artwork.hash);

    if (isArtworkSell(marketplace)) {
      if (currentArtwork.value.active_artwork_sell) {
        store.commit('marketplace/setMarketplace', currentArtwork.value.active_artwork_sell);
      } else {
        store.commit('marketplace/setMarketplace', {});
      }
    } else if (isAuction(marketplace)) {
      if (currentArtwork.value.active_auction) {
        store.commit('auction/setAuction', currentArtwork.value.active_auction);
        //Cargamos la última puja
        await store.dispatch('auction/setLastBid', currentArtwork.value.active_auction.hash);
      } else {
        store.commit('auction/setAuction', {});
        store.commit('auction/setLastBid', {});
      }
    } else {
      store.commit('auction/setAuction', {});
      store.commit('auction/setLastBid', {});
      store.commit('marketplace/setMarketplace', {});
    }
  };

  return {
    refreshAvailability
  };
}
