<template>
  <div>
    <div class="sidebar mb-5 mb-xl-0 row">
      <div class="col-md-6 col-lg-6 col-xl-12 sidebar-widget">
        <h3 class="mb-3">{{ $t('trans.artist.presentation') }}</h3>
        <p class="sidebar-text mb-3" align="justify">{{ content }}</p>
      </div>
      <!-- end col -->
      <div class="col-md-6 col-lg-6 col-xl-12 sidebar-widget">
        <h3 class="mb-3">{{ $t('trans.artist.links') }}</h3>
        <ul class="social-links">
          <li v-if="artist.webpage_url">
            <a class="ps-3 fs-14" :href="artist.webpage_url" target="_blank"
              ><span class="ni icon, ni-globe pe-1"></span><span v-text="parseUrl(artist.webpage_url)"></span></a
            >
          </li>
          <li v-if="artist.facebook_url">
            <a class="ps-3 fs-14" :href="artist.facebook_url" target="_blank"
              ><span class="ni icon, ni-facebook-f pe-1"></span>Facebook</a
            >
          </li>
          <li v-if="artist.twitter_url">
            <a class="ps-3 fs-14" :href="artist.twitter_url" target="_blank"
              ><span class="ni icon, ni-twitter pe-1"></span>Twitter</a
            >
          </li>
          <li v-if="artist.instagram_url">
            <a class="ps-3 fs-14" :href="artist.instagram_url" target="_blank"
              ><span class="ni icon, ni-instagram pe-1"></span>Instagram</a
            >
          </li>
        </ul>
      </div>
      <!-- end col -->
      <div class="col-md-6 col-lg-6 col-xl-12 sidebar-widget">
        <h3 class="mb-2">{{ $t('trans.artist.disciplines') }}</h3>
        <p class="sidebar-text">{{ datetext }}</p>

        <ul class="row g-3 nav nav-tabs nav-tabs-s2 justify-content-left" id="myTab" role="tablist">
          <li
            class="nav-item col-3 col-sm-4 col-lg-2"
            role="presentation"
            v-for="list in SectionData.discipinaNavList"
            :key="list.id"
          >
            <img
              :src="list.icon"
              class="icon icon-svg"
              alt=""
              v-tippy="{ content: list.slug, arrow: true, theme: 'dark', placement: 'bottom' }"
            />
            <span class="nav-link-title mt-3 d-block">{{ list.title }}</span>
          </li>
        </ul>
      </div>
      <!-- end col -->
    </div>
    <!-- end sidebar -->
  </div>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import store from '@/store';
import { directive } from 'vue-tippy';

export default {
  name: 'AuthorSidebar',
  directives: {
    tippy: directive
  },
  props: ['content', 'followingnum', 'followingtext', 'followernum', 'followertext', 'datetext', 'avatars', 'links'],
  data() {
    return {
      SectionData,
      webpage_url: ''
    };
  },
  computed: {
    artist() {
      return store.getters['artist/getArtist'];
    }
  },
  methods: {
    parseUrl(url) {
      let formattedUrl = url.replace(/^https?:\/\/www./,'');
      formattedUrl = formattedUrl.replace(/\/+$/, '');
      return formattedUrl;
    },
  }
};
</script>
