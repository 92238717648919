<template>
  <nav class="header-menu menu nav">
    <MenuList></MenuList>
    <ul class="menu-btns menu-btns-2 pt-0">
      <li v-if="false" class="d-none d-lg-inline-block">
        <LanguageSwitcher></LanguageSwitcher>
      </li>
      <li v-if="currentUser && !currentUser.email_verified">
        <button type="button" class="icon-btn icon-btn-s1 bg-warning d-none d-lg-inline-block">
          <router-link :to="{ name: $t('routes.dashboard.name') }">
            <i class="fa-solid fa-user-shield text-dark"></i>
          </router-link>
        </button>
      </li>
      <li :class="{ 'd-none d-lg-inline-block': currentUser }" class="menu-item" v-if="currentUser">
        <button
          type="button"
          class="icon-btn icon-btn-s1 d-none d-lg-inline-block"
          :class="{ 'bg-danger': !hasCurrentWallet }"
          data-bs-toggle="modal"
          data-bs-target="#addWalletModal"
        >
          <i class="fa-solid fa-wallet" :class="{ 'text-dark': hasCurrentWallet, 'text-white': !hasCurrentWallet }"></i>
        </button>
      </li>
      <li :class="{ 'd-none d-lg-inline-block': currentUser }" class="menu-item"><ButtonUser></ButtonUser></li>

      <li v-if="false" class="menu-item d-lg-none">
        <LanguageSwitcher></LanguageSwitcher>
      </li>
    </ul>
  </nav>
</template>

<script>
import SectionData from '@/store/store.js';
import MenuList from '@/components/common/MenuList.vue';
import { useStore } from 'vuex';
import { useUserAuth } from '@/composable/user/user';

export default {
  name: 'Menu',
  props: ['classname'],
  components: {
    MenuList
  },
  setup() {
    const store = useStore();
    const { currentUser } = useUserAuth();

    return {
      currentUser
    };
  },
  computed: {
    hasCurrentWallet() {
      return this.$store.state.currentWalletMin !== null;
    }
  },
  data() {
    return {
      SectionData
    };
  }
};
</script>
