import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card card-full card-blog" }
const _hoisted_3 = { class: "d-block card-image" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "card-img-top px-4 pt-2"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "card-body card-body-s1" }
const _hoisted_8 = { class: "card-title mb-3" }
const _hoisted_9 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clickVideo()), ["prevent"])),
            class: "stretched-link"
          }),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, "cargando..."))
              : (!_ctx.videoData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, "No existe el video"))
                : (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: _ctx.videoData?.thumbnail_url_with_play_button,
                    class: "card-img-top",
                    alt: ""
                  }, null, 8, _hoisted_6))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.description), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}