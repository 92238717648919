<template>
  <div class="page-wrap">
    <header class="header-section has-header-main bg-pattern-3">
      <HeaderMain></HeaderMain>
      <BuyerHero
        avatarSize="avatar-3"
        :coverimg="SectionData.obraDashboardData.coverImg"
        :img="SectionData.obraDashboardData.img"
      ></BuyerHero>
    </header>
    <section class="user-panel-section section-space">
      <div class="container">
        <div class="row">
          <UserSidebar
            :title="$t('dashboardTab.artworks')"
            :lists="SectionData.favouritesSidebarData.navList"
            :navs="SectionData.artworksTabNavMobile"
          ></UserSidebar>
          <DashboardAcademySection></DashboardAcademySection>
        </div>
      </div>
    </section>

    <Footer classname="bg-dark on-dark"></Footer>
  </div>
</template>

<script>
import SectionData from '@/store/store.js';
import DashboardAcademySection from '@/components/section/DashboardAcademySection.vue';
import store from '@/store';
export default {
  name: 'DashboardAcademy',
  components: {
    DashboardAcademySection
  },
  data() {
    return {
      SectionData
    };
  },
  computed: {
    userIsValidated() {
      return store.getters['getUser'].status === 'validado';
    }
  },
  created() {
    if (!this.userIsValidated) {
      window.location.replace(process.env.VUE_APP_EXT_LOGIN);
    }
  }
};
</script>
