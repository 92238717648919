import { toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("title", _normalizeProps(_guardReactiveProps(_ctx.attributes({}))), _toDisplayString(_ctx.head.title), 17),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.head.meta, (meta, i) => {
      return (_openBlock(), _createElementBlock("meta", _mergeProps({
        key: `meta-${i}`
      }, _ctx.attributes(meta)), null, 16))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.head.links, (link, i) => {
      return (_openBlock(), _createElementBlock("link", _mergeProps({
        key: `link-${i}`
      }, _ctx.attributes(link)), null, 16))
    }), 128))
  ], 64))
}