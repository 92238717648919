import { createMemoryHistory, createWebHistory, createRouter } from 'vue-router';
import { isSSR } from '@/helpers';
import { routes } from './routes';
import i18n from '@/translations/i18n';
import sanctum from '@/plugins/sanctum';
import store from '@/store/index';
declare module 'vue-router' {
  interface RouteMeta {
    locale: string;
  }
}

export function createApplicationRouter(): any {
  const router = createRouter({
    history: isSSR() ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (from !== to) {
        return { top: 0, left: 0, behavior: 'smooth' };
      }
    }
  });

  router.beforeEach(async (to, from, next) => {
    const locale = to.meta.locale;
    if (locale === undefined) {
      i18n.global.locale.value = 'es';
    }
    if (i18n.global.locale.value !== locale) {
      i18n.global.locale.value = locale;
    }

    if (!isSSR()) {
      const modalBackground = document.querySelector('.modal-backdrop');
      const body = document.querySelector('body');

      if (modalBackground) {
        modalBackground.remove();
      }
      body.style.removeProperty('overflow');
      body.style.removeProperty('padding-right');
      body.classList.remove('modal-open');
    }

    await sanctum.getUser();

    const isUserAuthenticated = store.state.isUserAuthenticated;
    const user = store.state.user;

    if (isUserAuthenticated) {
      if (user.status !== 'validado') {
        return next({ name: 'login' });
      }
    }

    if (to.matched.some((routes) => routes.meta.requiresAuth)) {
      if (isUserAuthenticated) {
        return next();
      } else {
        return next({ name: 'login' });
      }
    } else {
      next();
    }
  });

  return router;
}
