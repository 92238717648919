import axios from '../../plugins/axios';

export default {
  namespaced: true,
  state: () => ({
    register: {},
  }),
  mutations: {
    setRegisterForTracking(state, register) {
      state.register = register;
    },
  },
  getters: {
    getRegisterForTracking(state) {
      return state.register;
    },
  },
  actions: {
    async setRegisterForTracking({ commit }, payload) {
      let error;
      try {
        let response = await axios.post('/api/v1/public/register/fullRegisterForBuyerTracking', payload);
        if (response) {
          response = response.data?.data;
        }
        commit('setRegisterForTracking', response);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  }
};
