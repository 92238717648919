const DNI_REGEX = /^(\d{8})([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

export const LEGAL_ENTITY_CONTROL_LETTERS = 'JABCDEFGHI';
export const LEGAL_ENTITY_NIF_REGEX = /^[ABCDEFGHJNPQRSUVW][\d]{7}[\dA-J]$/i;
const HAS_CONTROL_LETTER_REGEX = /^[PQRSW]/;
const HAS_CONTROL_LETTER_IDENTIFIER = '00';
const HAS_CONTROL_NUMBER_REGEX = /^[ABEH]/;

function sumEvenPositions(legalEntityNumbers: string): number {
  return +legalEntityNumbers[1] + +legalEntityNumbers[3] + +legalEntityNumbers[5];
}

function calculateOddPosition(num: number): number {
  const doubledNum = num * 2;
  if (doubledNum < 10) return doubledNum;

  const splittedNum = `${doubledNum}`.split('');
  return +splittedNum[0] + +splittedNum[1];
}

function calculateOddPositions(legalEntityNumbers: string): number {
  return (
    calculateOddPosition(+legalEntityNumbers[0]) +
    calculateOddPosition(+legalEntityNumbers[2]) +
    calculateOddPosition(+legalEntityNumbers[4]) +
    calculateOddPosition(+legalEntityNumbers[6])
  );
}

function getLegalEntityNumbers(legalEntityNif: string): string {
  return legalEntityNif.slice(1, -1);
}

function getLegalEntityNifControlNumber(nif: string): number {
  const legalEntityNumbers = getLegalEntityNumbers(nif);
  const keyNumber = +`${sumEvenPositions(legalEntityNumbers) + calculateOddPositions(legalEntityNumbers)}`.slice(-1);
  return keyNumber === 0 ? keyNumber : 10 - keyNumber;
}

function isControlCodeLetter(legalEntityNif: string): boolean {
  return HAS_CONTROL_LETTER_REGEX.test(legalEntityNif) || legalEntityNif[0] === HAS_CONTROL_LETTER_IDENTIFIER;
}

function isControlCodeNumber(legalEntityNif: string): boolean {
  return HAS_CONTROL_NUMBER_REGEX.test(legalEntityNif);
}

/**
 * Checks if the legal entity nif control code (letter or number)
 * provided is valid.
 *
 * @WARNING It does not check the `LEGAL_ENITY_NIF_REGEX`.
 * @throws May throw an error if the string is not long enough (9 characters)
 * @param legalEntityNif
 * @returns
 */
export function isValidLegalEntityNifControlCode(legalEntityNif: string): boolean {
  const controlCodeToVerify = legalEntityNif.slice(-1);
  const controlNumber = getLegalEntityNifControlNumber(legalEntityNif);

  if (isControlCodeLetter(legalEntityNif)) return LEGAL_ENTITY_CONTROL_LETTERS[controlNumber] === controlCodeToVerify;

  if (isControlCodeNumber(legalEntityNif)) return controlNumber === +controlCodeToVerify;

  return isNaN(+controlCodeToVerify)
    ? LEGAL_ENTITY_CONTROL_LETTERS[controlNumber] === controlCodeToVerify
    : controlNumber === +controlCodeToVerify;
}

/**
 * Checks if the legalEntityNif provided is valid.
 *
 * It does not include old K, L and M formats.
 * @param legalEntityNif
 * @returns true for valid input and false for invalid input.
 */
export function isValidLegalEntityNif(legalEntityNif: string): boolean {
  return LEGAL_ENTITY_NIF_REGEX.test(legalEntityNif) && isValidLegalEntityNifControlCode(legalEntityNif);
}

export interface SpanishIdValidated {
  type: string | undefined;
  valid: boolean;
}

const validateSpanishID = function (str): SpanishIdValidated {
  // Ensure upcase and remove whitespace
  str = str.toUpperCase().replace(/\s/, '');

  let valid = false;
  const type = spainIdType(str);

  switch (type) {
    case 'dni':
      valid = validDNI(str);
      break;
    case 'nie':
      valid = validNIE(str);
      break;
    case 'cif':
      valid = validCIF(str);
      break;
  }

  return {
    type: type,
    valid: valid
  };
};

const spainIdType = function (str) {
  if (str.match(DNI_REGEX)) {
    return 'dni';
  }
  if (str.match(CIF_REGEX)) {
    return 'cif';
  }
  if (str.match(NIE_REGEX)) {
    return 'nie';
  }
};

const validDNI = function (dni) {
  const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

  return letter == dni.charAt(8);
};

const validNIE = function (nie) {
  // Change the initial letter for the corresponding number and validate as DNI
  let nie_prefix = nie.charAt(0);

  switch (nie_prefix) {
    case 'X':
      nie_prefix = 0;
      break;
    case 'Y':
      nie_prefix = 1;
      break;
    case 'Z':
      nie_prefix = 2;
      break;
  }

  return validDNI(nie_prefix + nie.substr(1));
};

const validCIF = function (cif) {
  return isValidLegalEntityNif(cif);
};

export { validCIF, validNIE, validDNI, validateSpanishID };
