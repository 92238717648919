<template>
  <div class="col-lg-9 ps-xl-5">
    <div class="user-panel-title-box">
      <h3>{{ $t('dashboardTab.payments') }}</h3>
    </div>
    <!-- end user-panel-title-box  -->

    <div v-if="!hasPaymentMethodsFiat" class="alert alert-warning" role="alert">
      <span>
        {{ $t('trans.profile.fiatPaymentMethodEmpty') }}
      </span>
    </div>

    <div v-if="!hasPaymentMethodsCrypto" class="alert alert-warning" role="alert">
      <span>
        {{ $t('trans.profile.fiatPaymentMethodCryptoEmpty') }}
      </span>
    </div>

    <div class="profile-setting-panel-wrap">
      <ul class="nav nav-tabs nav-tabs-s1 nav-tabs-mobile-size" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#payment-method" type="button">
            {{ $t('trans.profile.payments.nav_bar.payment_methods') }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#billing-addresses" type="button">
            {{ $t('trans.profile.payments.nav_bar.billing_details') }}
          </button>
        </li>
        <li v-if="hasUserShippingAddress" class="nav-item" role="presentation">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#shipping-addresses" type="button">
            {{ $t('trans.profile.payments.nav_bar.shipping_addresses') }}
          </button>
        </li>
      </ul>
      <div class="tab-content mt-4" id="myTabContent">
        <div class="tab-pane fade show active" id="payment-method" role="tabpanel" aria-labelledby="payment-method-tab">
          <DashboardPaymentsSectionTabPaymentMethods />
        </div>
        <!-- end tab-pane -->
        <div class="tab-pane fade" id="billing-addresses" role="tabpanel" aria-labelledby="billing-addresses-tab">
          <DashboardPaymentsSectionTabBillingAddress />
        </div>
        <!-- end tab-pane -->
        <div
          v-if="hasUserShippingAddress"
          class="tab-pane fade"
          id="shipping-addresses"
          role="tabpanel"
          aria-labelledby="shipping-addresses-tab"
        >
          <div class="row mb-3">
            <div class="col-md-6">
              <label class="form-label">{{ $t('trans.profile.payments.details.descriptive_name') }}</label>
              <input type="text" class="form-control form-control-solid" :value="shippingAddress.name" readonly />
            </div>
            <div class="col-md-6">
              <label class="form-label">{{ $t('trans.profile.payments.details.phone') }}</label>
              <input type="text" class="form-control form-control-solid" :value="shippingAddress.phone" readonly />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <label class="form-label">{{ $t('trans.profile.payments.details.country') }}</label>
              <input
                type="text"
                class="form-control form-control-solid"
                :value="shippingAddress.country?.name"
                readonly
              />
            </div>
            <div class="col-md-4">
              <label class="form-label">{{ $t('trans.profile.payments.details.region') }}</label>
              <input
                type="text"
                class="form-control form-control-solid"
                :value="shippingAddress.region?.name"
                readonly
              />
            </div>
            <div class="col-md-4">
              <label class="form-label">{{ $t('trans.profile.payments.details.postal_code') }}</label>
              <input
                type="text"
                class="form-control form-control-solid"
                :value="shippingAddress.postal_code"
                readonly
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <label class="form-label">{{ $t('trans.profile.payments.details.address') }}</label>
              <input type="text" class="form-control form-control-solid" :value="shippingAddress.address" readonly />
            </div>
            <div class="col-md-4">
              <label class="form-label">{{ $t('trans.profile.payments.details.city') }}</label>
              <input type="text" class="form-control form-control-solid" :value="shippingAddress.city" readonly />
            </div>
          </div>
        </div>
        <!-- end tab-pane -->
      </div>
      <!-- end tab-content -->
    </div>
    <!-- end profile-setting-panel-wrap-->
  </div>
  <!-- end col-lg-8 -->
</template>

<script>
import store from '@/store';
import DashboardPaymentsSectionTabPaymentMethods from '@/components/section/DashboardPaymentsSectionTabPaymentMethods';
import DashboardPaymentsSectionTabBillingAddress from '@/components/section/DashboardPaymentsSectionTabBillingAddress';

export default {
  name: 'DashboardPaymentsSection',
  components: {
    DashboardPaymentsSectionTabPaymentMethods,
    DashboardPaymentsSectionTabBillingAddress
  },
  data() {
    return {};
  },
  computed: {
    hasPaymentMethodsFiat() {
      return store.getters['hasUserPaymentMethodFiat'];
    },
    paymentMethodsFiat() {
      return store.getters['getUserPaymentMethodFiat'];
    },
    hasPaymentMethodsCrypto() {
      return store.getters['hasUserPaymentMethodCrypto'];
    },
    paymentMethodsCrypto() {
      return store.getters['getUserPaymentMethodCrypto'];
    },
    billingAddress() {
      return store.getters['getUserBillingAddress'];
    },
    shippingAddress() {
      return store.getters['getUserShippingAddress'];
    },
    hasUserShippingAddress() {
      return store.getters['hasUserShippingAddress'];
    }
  }
};
</script>
