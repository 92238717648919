import { useMarketplace } from '@/composable/marketplace/marketplace';
import { ArtworkSell } from '@/types/interfaces/global/MarketInterface';
import { ref } from 'vue';
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';
import sanctum from '@/plugins/sanctum';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useArtwork } from '@/composable/artwork/artwork';
import * as Yup from 'yup';
import { isSSR } from '@/helpers';
import { useUserAuth } from '@/composable/user/user';

export interface PurchaseEuroFormState {
  payment_method: string | null;
  wallet_payment_method: string | null;
  billing_address: string | null;
}

export function useMarketplaceEuro() {
  const { refreshAvailability } = useMarketplace();
  const { t } = useI18n();
  const router = useRouter();
  const { currentArtwork, currentArtworkSell } = useArtwork();
  const { isPublicUser, currentRole } = useUserAuth();

  const sendingFormPurchaseEuro = ref(false);

  const purchaseEuroFormState = ref<PurchaseEuroFormState>({
    payment_method: null,
    wallet_payment_method: null,
    billing_address: null
  });

  const purchaseEuroSchema = Yup.object().shape({
    payment_method: Yup.string().nullable().required(t('Selecciona un método de pago')),
    wallet_payment_method: Yup.string().nullable().required(t('Selecciona una wallet')),
    billing_address: Yup.string().nullable().required(t('Selecciona una dirección de facturación'))
  });

  const purchaseEuro = async () => {
    sendingFormPurchaseEuro.value = true;
    await refreshAvailability(currentArtworkSell.value, currentArtwork.value);

    if (currentArtworkSell.value) {
      Swal.fire({
        title: t('trans.alert.confirm_purchase.title'),
        text: t('trans.alert.confirm_purchase.message'),
        icon: 'warning',
        confirmButtonText: t('trans.alert.continue'),
        cancelButtonText: t('trans.alert.cancel'),
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2'
        }
      }).then(async (result) => {
        if (result.value) {
          await purchaseArtworkSellEuro();
        } else {
          Swal.fire({
            title: t('trans.alert.cancelled_purchase.title'),
            text: t('trans.alert.cancelled_purchase.message'),
            icon: 'info',
            confirmButtonText: t('trans.alert.continue'),
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2'
            }
          });
        }
        sendingFormPurchaseEuro.value = false;
      });
    }
  };

  const purchaseArtworkSellEuro = async () => {
    Swal.fire({
      title: t('trans.alert.default_loading.title'),
      text: t('trans.alert.default_loading.message'),
      icon: 'info',
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading(null);
        await axios
          .post(`/api/v1/public/checkout/fromArtworkSell/${currentArtworkSell.value.hash}`, purchaseEuroFormState.value)
          .then(async function (result) {
            await sanctum.getUser();

            Swal.fire({
              title: 'Compra realizada',
              text: '¡Muchas gracias por confiar en nosotros!',
              icon: 'success',
              confirmButtonText: t('trans.alert.continue'),
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-outline-dark mx-2'
              }
            }).then(async (result) => {
              if (isPublicUser) {
                router.push({ name: t('routes.dashboard.artworks.name') });
              } else {
                router.push({ name: t('routes.dashboard.name') });
              }
            });
          })
          .catch(function (err) {
            Swal.fire({
              title: 'Error en la compra',
              text: 'Ha sucedido un error y no se ha podido finalizar la compra, disculpa las molestias.',
              icon: 'warning',
              confirmButtonText: t('trans.alert.continue'),
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-outline-dark mx-2'
              }
            }).then(async (result) => {
              router.go(0);
            });
          });
      }
    });
  };

  return {
    purchaseEuroFormState,
    purchaseEuro,
    purchaseEuroSchema,
    sendingFormPurchaseEuro
  };
}
