import { App } from 'vue';

// Global page components imported
// vue select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import HeaderMain from '@/components/common/HeaderMain.vue';
import HeaderDashboard from '@/components/common/HeaderDashboard.vue';
import SectionHeading from '@/components/common/SectionHeading.vue';
import SectionHeadingTwo from '@/components/common/SectionHeadingTwo.vue';
import FeaturedCreators from '@/components/section/FeaturedCreators.vue';
import FeaturedCollections from '@/components/section/FeaturedCollections.vue';
import BlogSection from '@/components/section/BlogSection.vue';
import BlogSectionSlider from '@/components/section/BlogSectionSlider.vue';
import Cta from '@/views/Cta.vue';
import CtaAbout from '@/views/CtaAbout.vue';
import CtaSection from '@/components/section/CtaSection.vue';
import CtaAboutSection from '@/components/section/CtaAboutSection.vue';

import Footer from '@/views/Footer.vue';
import FooterSection from '@/components/section/FooterSection.vue';
import ButtonLink from '@/components/common/ButtonLink.vue';
import ButtonGroup from '@/components/common/ButtonGroup.vue';
import LogoLink from '@/components/common/LogoLink.vue';
import Tab from '@/components/common/Tab.vue';
import Featured from '@/components/section/Featured.vue';
import HowItWork from '@/components/section/HowItWork.vue';
import HowItWorkTwo from '@/components/section/HowItWorkTwo.vue';
import HowItWorkThree from '@/components/section/HowItWorkThree.vue';
import AuthenticationTab from '@/components/section/AuthenticationTab.vue';

import HowItWorkItem from '@/components/common/HowItWorkItem.vue';
import HowItWorkFour from '@/components/section/HowItWorkFour.vue';
import HowItWorkSlider from '@/components/common/HowItWorkSlider.vue';
import Category from '@/components/section/Category.vue';
import Newsletter from '@/components/section/Newsletter.vue';
import FeaturedCreatorSlider from '@/components/common/FeaturedCreatorSlider.vue';
import FeaturedCollectionSlider from '@/components/common/FeaturedCollectionSlider.vue';
import AuthorCollectionSlider from '@/components/common/AuthorCollectionSlider.vue';

import FeaturedItemSlider from '@/components/common/FeaturedItemSlider.vue';
import RecentItemSlider from '@/components/common/RecentItemSlider.vue';
import FullScreenSlider from '@/components/common/FullScreenSlider.vue';
import HomeSlider from '@/components/common/HomeSlider.vue';
import FunFactSection from '@/components/section/FunFactSection.vue';
import IconFactSection from '@/components/section/IconFactSection.vue';

import Filters from '@/components/common/Filters.vue';
import Creators from '@/components/common/Creators.vue';
import CreatorsTwo from '@/components/common/CreatorsTwo.vue';
import CreatorsThree from '@/components/common/CreatorsThree.vue';
import CreatorsFour from '@/components/common/CreatorsFour.vue';
import Collections from '@/components/section/Collections.vue';
import AuthorCollections from '@/components/section/AuthorCollections.vue';
import CollectionsTwo from '@/components/section/CollectionsTwo.vue';
import CollectionSlider from '@/components/common/CollectionSlider.vue';

import AuthorHero from '@/components/section/AuthorHero.vue';
import BuyerHero from '@/components/section/BuyerHero.vue';
import CollectionHero from '@/components/section/CollectionHero.vue';

import AuthorSection from '@/components/section/AuthorSection.vue';
import AuthorSidebar from '@/components/common/AuthorSidebar.vue';
import BuyerSidebar from '@/components/common/BuyerSidebar.vue';
import AboutSection from '@/components/section/AboutSection.vue';
import DevicesSection from '@/components/section/DevicesSection.vue';
import PlansSection from '@/components/section/PlansSection.vue';

import BrandSection from '@/components/section/BrandSection.vue';
import BrandSectionTwo from '@/components/section/BrandSectionTwo.vue';
import TeamSection from '@/components/section/TeamSection.vue';
import ActivitySection from '@/components/section/ActivitySection.vue';
import Pagination from '@/components/common/Pagination.vue';
import BlogDetailSection from '@/components/section/BlogDetailSection.vue';

import TermsDetails from '@/components/section/TermsDetails.vue';
import TermsSection from '@/components/section/TermsSection.vue';

import privacyDetails from '@/components/section/PrivacyDetails.vue';
import ConditionDetails from '@/components/section/ConditionDetails.vue';

import AuthorDetailSection from '@/components/section/AuthorDetailSection.vue';
import AuthorDocsSection from '@/components/section/AuthorDocsSection.vue';
import Comments from '@/components/common/Comments.vue';
import Form from '@/components/common/Form.vue';
import Sidebar from '@/components/common/Sidebar.vue';
import ContactSection from '@/components/section/ContactSection.vue';
import GoogleMap from '@/components/common/GoogleMap.vue';
import UserSidebar from '@/components/common/UserSidebar.vue';

import DisplaySection from '@/components/section/DisplaySection.vue';
import DepositSection from '@/components/section/DepositSection.vue';
import ProfileSection from '@/components/section/ProfileSection.vue';
import CollectionDetailSection from '@/components/section/CollectionDetailSection.vue';

import AccountSection from '@/components/section/AccountSection.vue';
import ThemeSwitcher from '@/components/common/ThemeSwitcher.vue';
import BotonEstadisticas from '@/components/common/BotonEstadisticas.vue';
import ButtonUser from '@/components/common/ButtonUser.vue';
import LanguageSwitcher from '@/components/common/LanguageSwitcher.vue';
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import HeaderBreadcrumbs from '@/components/section/HeaderBreadcrumbs.vue';
import FilterHeader from '@/components/common/FilterHeader.vue';

//ArtWorks
import ArtworksHighlightedContainer from '@/components/section/ArtworksHighlightedContainer.vue';
import ArtworkExploreSection from '@/components/section/ArtworkExploreSection.vue';

//Artists
import ArtistHighlighted from '@/components/section/ArtistsHighlighted.vue';
import PageMeta from '@/components/meta/PageMeta.vue';
import PageMetaTeleport from '@/components/meta/PageMetaTeleport.vue';
import RelatedProduct from '@/components/section/RelatedProduct.vue';
import DashboardArtworksSection from '@/components/section/DashboardArtworksSection.vue';
import DashboardFavouritesSection from '@/components/section/DashboardFavouritesSection.vue';
import DashboardAuctionsSection from '@/components/section/DashboardAuctionsSection.vue';
import DashboardPaymentsSection from '@/components/section/DashboardPaymentsSection.vue';
import TransactionsSection from '@/components/section/TransactionsSection.vue';
import AuctionFiat from '@/components/section/AuctionFiat.vue';
import AuctionCrypto from '@/components/section/AuctionCrypto.vue';
import Marketplace from '@/components/section/Marketplace.vue';
import ArtistHighlightedSmall from '@/components/section/ArtistsHighlightedSmall.vue';
import Documentacion from '@/components/section/Documentacion.vue';

export const registerComponents = (app: App): void => {
  const Vue = app;

  Vue.component('v-select', vSelect);
  Vue.component('HeaderMain', HeaderMain);
  Vue.component('HeaderDashboard', HeaderDashboard);
  Vue.component('SectionHeading', SectionHeading);
  Vue.component('SectionHeadingTwo', SectionHeadingTwo);
  Vue.component('FeaturedCreators', FeaturedCreators);
  Vue.component('FeaturedCollections', FeaturedCollections);

  Vue.component('v-select', vSelect);
  Vue.component('HeaderMain', HeaderMain);
  Vue.component('HeaderDashboard', HeaderDashboard);
  Vue.component('SectionHeading', SectionHeading);
  Vue.component('SectionHeadingTwo', SectionHeadingTwo);
  Vue.component('FeaturedCreators', FeaturedCreators);
  Vue.component('FeaturedCollections', FeaturedCollections);

  Vue.component('BlogSection', BlogSection);
  Vue.component('BlogSectionSlider', BlogSectionSlider);
  Vue.component('Cta', Cta);

  Vue.component('CtaSection', CtaSection);
  Vue.component('CtaAboutSection', CtaAboutSection);

  Vue.component('Footer', Footer);
  Vue.component('FooterSection', FooterSection);
  Vue.component('ButtonLink', ButtonLink);
  Vue.component('ButtonGroup', ButtonGroup);
  Vue.component('LogoLink', LogoLink);
  Vue.component('Tab', Tab);
  Vue.component('Featured', Featured);
  Vue.component('HowItWork', HowItWork);
  Vue.component('HowItWorkTwo', HowItWorkTwo);
  Vue.component('HowItWorkThree', HowItWorkThree);
  Vue.component('AuthenticationTab', AuthenticationTab);

  Vue.component('HowItWorkItem', HowItWorkItem);
  Vue.component('HowItWorkFour', HowItWorkFour);
  Vue.component('HowItWorkSlider', HowItWorkSlider);
  Vue.component('Category', Category);
  Vue.component('Newsletter', Newsletter);
  Vue.component('FeaturedCreatorSlider', FeaturedCreatorSlider);
  Vue.component('FeaturedCollectionSlider', FeaturedCollectionSlider);

  Vue.component('AuthorCollectionSlider', AuthorCollectionSlider);
  Vue.component('HowItWorkItem', HowItWorkItem);
  Vue.component('HowItWorkFour', HowItWorkFour);
  Vue.component('HowItWorkSlider', HowItWorkSlider);
  Vue.component('Category', Category);
  Vue.component('Newsletter', Newsletter);
  Vue.component('FeaturedCreatorSlider', FeaturedCreatorSlider);
  Vue.component('FeaturedCollectionSlider', FeaturedCollectionSlider);

  Vue.component('AuthorCollectionSlider', AuthorCollectionSlider);

  Vue.component('FeaturedItemSlider', FeaturedItemSlider);
  Vue.component('RecentItemSlider', RecentItemSlider);
  Vue.component('FullScreenSlider', FullScreenSlider);
  Vue.component('HomeSlider', HomeSlider);
  Vue.component('FunFactSection', FunFactSection);
  Vue.component('IconFactSection', IconFactSection);

  Vue.component('Filters', Filters);
  Vue.component('Creators', Creators);
  Vue.component('CreatorsTwo', CreatorsTwo);
  Vue.component('CreatorsThree', CreatorsThree);
  Vue.component('CreatorsFour', CreatorsFour);
  Vue.component('Collections', Collections);
  Vue.component('AuthorCollections', AuthorCollections);
  Vue.component('CollectionsTwo', CollectionsTwo);
  Vue.component('CollectionSlider', CollectionSlider);
  Vue.component('RelatedProduct', RelatedProduct);

  Vue.component('AuthorHero', AuthorHero);
  Vue.component('BuyerHero', BuyerHero);
  Vue.component('CollectionHero', CollectionHero);

  Vue.component('AuthorSection', AuthorSection);
  Vue.component('AuthorSidebar', AuthorSidebar);
  Vue.component('BuyerSidebar', BuyerSidebar);

  Vue.component('AuthorSection', AuthorSection);
  Vue.component('AuthorSidebar', AuthorSidebar);
  Vue.component('BuyerSidebar', BuyerSidebar);

  Vue.component('AboutSection', AboutSection);
  Vue.component('DevicesSection', DevicesSection);
  Vue.component('PlansSection', PlansSection);

  Vue.component('BrandSection', BrandSection);
  Vue.component('BrandSectionTwo', BrandSectionTwo);
  Vue.component('TeamSection', TeamSection);
  Vue.component('ActivitySection', ActivitySection);
  Vue.component('Pagination', Pagination);
  Vue.component('BlogDetailSection', BlogDetailSection);

  Vue.component('BrandSection', BrandSection);
  Vue.component('BrandSectionTwo', BrandSectionTwo);
  Vue.component('TeamSection', TeamSection);
  Vue.component('ActivitySection', ActivitySection);
  Vue.component('Pagination', Pagination);
  Vue.component('BlogDetailSection', BlogDetailSection);

  Vue.component('TermsDetails', TermsDetails);
  Vue.component('TermsSection', TermsSection);

  Vue.component('TermsDetails', TermsDetails);
  Vue.component('TermsSection', TermsSection);

  Vue.component('privacyDetails', privacyDetails);
  Vue.component('ConditionDetails', ConditionDetails);

  Vue.component('AuthorDetailSection', AuthorDetailSection);
  Vue.component('AuthorDocsSection', AuthorDocsSection);
  Vue.component('Comments', Comments);
  Vue.component('Form', Form);
  Vue.component('Sidebar', Sidebar);
  Vue.component('ContactSection', ContactSection);
  Vue.component('GoogleMap', GoogleMap);
  Vue.component('UserSidebar', UserSidebar);
  Vue.component('DashboardArtworksSection', DashboardArtworksSection);
  Vue.component('DashboardFavouritesSection', DashboardFavouritesSection);
  Vue.component('DashboardAuctionsSection', DashboardAuctionsSection);
  Vue.component('DashboardPaymentsSection', DashboardPaymentsSection);
  Vue.component('TransactionsSection', TransactionsSection);

  Vue.component('DisplaySection', DisplaySection);
  Vue.component('DepositSection', DepositSection);
  Vue.component('ProfileSection', ProfileSection);
  Vue.component('CollectionDetailSection', CollectionDetailSection);

  Vue.component('AccountSection', AccountSection);
  Vue.component('ThemeSwitcher', ThemeSwitcher);
  Vue.component('BotonEstadisticas', BotonEstadisticas);
  Vue.component('ButtonUser', ButtonUser);
  Vue.component('PageMeta', PageMeta);
  Vue.component('PageMetaTeleport', PageMetaTeleport);
  Vue.component('LanguageSwitcher', LanguageSwitcher);
  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.component('HeaderBreadcrumbs', HeaderBreadcrumbs);
  Vue.component('FilterHeader', FilterHeader);
  Vue.component('DisplaySection', DisplaySection);
  Vue.component('DepositSection', DepositSection);
  Vue.component('ProfileSection', ProfileSection);
  Vue.component('CollectionDetailSection', CollectionDetailSection);

  Vue.component('AccountSection', AccountSection);
  Vue.component('ThemeSwitcher', ThemeSwitcher);
  Vue.component('BotonEstadisticas', BotonEstadisticas);
  Vue.component('ButtonUser', ButtonUser);
  Vue.component('PageMeta', PageMeta);
  Vue.component('PageMetaTeleport', PageMetaTeleport);
  Vue.component('LanguageSwitcher', LanguageSwitcher);
  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.component('HeaderBreadcrumbs', HeaderBreadcrumbs);
  Vue.component('FilterHeader', FilterHeader);

  //Home
  Vue.component('AuctionFiat', AuctionFiat);
  Vue.component('AuctionCrypto', AuctionCrypto);
  Vue.component('Marketplace', Marketplace);

  //ArtWorks
  Vue.component('ArtworksHighlightedContainer', ArtworksHighlightedContainer);
  Vue.component('ArtworkExploreSection', ArtworkExploreSection);

  //Artists
  Vue.component('ArtistHighlighted', ArtistHighlighted);
  Vue.component('ArtistHighlightedSmall', ArtistHighlightedSmall);
  Vue.component('Documentacion', Documentacion);
};
