
import { Vue } from "vue-class-component";
import { isSSR } from "@/helpers";
import { HeadMeta, MetaTag } from "@/interfaces";

export default class PageMeta extends Vue {
	attributes(attributes: MetaTag): MetaTag {
		return {
			...(isSSR() && { ssr: true }),
			...attributes,
		};
	}

	mounted() {
		this.$nextTick(() => {
			Array.from(
				document.getElementsByTagName("head")[0].children
			).forEach((node: Element) => {
				if (node.getAttribute("ssr")) {
					node.remove();
				}
			});
		});
	}

	get head(): HeadMeta {
		return {
			title: "Auth4Art | Arte Digital Certificado",
			meta: [
        {
          name: 'description',
          content: 'Arte digital certificado'
        },
        {
          charset: "utf-8",
        },
        {
          'http-equiv': "X-UA-Compatible",
          content: "IE=edge",
        },
        {
          name: "viewport",
          content: "width=device-width,initial-scale=1.0",
        },
        {
          name: "robots",
          content: ( process.env.VUE_APP_BASE_URL === 'https://api.auth4art.com' ? '' : 'noindex'),
        },
			],
			links: [
				{
					rel: "canonical",
					href: "https://auth4art.com/",
				},
				{
					rel: "icon",
					href: "favicon.ico",
				},
      ],
		};
	}
}
