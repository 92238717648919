<template>
  <button
      type="button"
      class="icon-btn icon-btn-s1"
      data-bs-toggle="dropdown"
  >
    <i class="fa-solid fa-globe"></i>
  </button>
  <ul
      class="dropdown-menu card-generic card-generic-s3 dropdown-menu-end mt-2"
  >
    <li v-for="locale in $i18n.availableLocales" :key="locale">
      <span
          class="dropdown-item card-generic-item"
          @click="changeLocale(locale)"
      >
        <i class="fa-solid fa-flag me-2"></i>
        {{ locale?.toUpperCase() }}
      </span>
    </li>
  </ul>
</template>
<script>
import axios from "@/plugins/axios";

export default {  
  name: "LanguageSwitcher",
  methods: {
    changeLocale(locale) {
      const prevLocale = this.$i18n.locale;
      this.$i18n.locale = locale;
      axios.defaults.headers.common['locale'] = locale;
      let routeName = this.$route.name;
      this.$router.push({
        name: routeName.replace("__" + prevLocale, "__" + locale),
      });
    },
  },
};
</script>
