import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { useArtwork } from '@/composable/artwork/artwork';
import axios from '@/plugins/axios';
import { useRouter } from 'vue-router';
import { ref, nextTick } from 'vue';
import * as Yup from 'yup';
import { useMarketplace } from '@/composable/marketplace/marketplace';

export interface PurchaseAuctionFormState {
  payment_method: string | null;
  wallet_payment_method: string | null;
  billing_address: string | null;
  bid_offer: number | null;
}

export function useMarketplaceAuctionEuro() {
  const { refreshAvailability } = useMarketplace();
  const { t } = useI18n();
  const router = useRouter();

  const { currentArtwork, currentAuction } = useArtwork();

  const sendingFormPurchaseAuction = ref(false);

  const purchaseAuctionFormState = ref<PurchaseAuctionFormState>({
    payment_method: null,
    wallet_payment_method: null,
    billing_address: null,
    bid_offer: null
  });

  const purchaseAuctionSchema = Yup.object().shape({
    payment_method: Yup.string().nullable().required(t('Selecciona un método de pago')),
    wallet_payment_method: Yup.string().nullable().required(t('Selecciona una wallet')),
    billing_address: Yup.string().nullable().required(t('Selecciona una dirección de facturación')),
    bid_offer: Yup.number()
      .typeError('La puja tiene que ser un valor numerico')
      .nullable()
      .required(t('introduce una puja'))
      .test('min-bid', 'Puja incorrecta, debe ser mayor o igual a la puja mínima', (value, context) => {
        return value >= currentAuction.value.minimumBid;
      })
      .test('integer', 'La puja debe ser un número entero sin decimales', (value, context) => {
        return Number.isInteger(value);
      })
  });

  const purchaseAuction = async () => {
    sendingFormPurchaseAuction.value = true;
    //await refreshAvailability(currentAuction.value, currentArtwork.value);

    if (currentAuction.value) {
      Swal.fire({
        title: t('trans.alert.confirm_purchase.title'),
        text: t('trans.alert.confirm_purchase.message'),
        icon: 'warning',
        confirmButtonText: t('trans.alert.continue'),
        cancelButtonText: t('trans.alert.cancel'),
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2'
        }
      }).then(async (result) => {
        if (result.value) {
          //Falta comprobar el currency
          await purchaseAuctionEuro();
        } else {
          Swal.fire({
            title: t('trans.alert.cancelled_purchase.title'),
            text: t('trans.alert.cancelled_purchase.message'),
            icon: 'info',
            confirmButtonText: t('trans.alert.continue'),
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2',
              cancelButton: 'btn btn-outline-primary mx-2',
              denyButton: 'btn btn-danger mx-2'
            }
          });
        }
        sendingFormPurchaseAuction.value = false;
      });
    }
  };

  const purchaseAuctionEuro = async () => {
    Swal.fire({
      title: t('trans.alert.default_loading.title'),
      text: t('trans.alert.default_loading.message'),
      icon: 'info',
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading(null);
        await axios
          .post(`/api/v1/public/auction/placeBid/${currentAuction.value.hash}`, purchaseAuctionFormState.value)
          .then(async function (result) {
            Swal.fire({
              title: t('Éxito'),
              text: t('Tu puja se ha guardado correctamente'),
              icon: 'success',
              confirmButtonText: t('trans.alert.continue'),
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-outline-dark mx-2'
              }
            }).then(async (result) => {
              //router.push({ name: t('routes.dashboard.auctions.name') });
              purchaseAuctionFormState.value.bid_offer = null;

              const { Modal } = await import('bootstrap');
              const modalElement = document.getElementById('placeBidModal');
              if (modalElement) {
                const modal = Modal.getInstance(modalElement);
                if (modal) {
                  modal.hide();
                }
              }
            });
          })
          .catch(function (err) {
            if (err.response?.data?.data?.minimum_bid) {
              Swal.fire({
                title: 'Puja superada',
                text: 'Lo sentimos, se ha realizado una puja superior a la tuya.',
                icon: 'warning',
                confirmButtonText: t('trans.alert.continue'),
                allowOutsideClick: false,
                showCloseButton: true,
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-outline-dark mx-2'
                }
              });
            } else {
              Swal.fire({
                title: t('Error'),
                text: t('Se produjo un error al hacer la puja, inténtalo de nuevo más tarde'),
                icon: 'error',
                confirmButtonText: t('trans.alert.continue'),
                allowOutsideClick: false,
                showCloseButton: true,
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-outline-dark mx-2'
                }
              });
            }
          });
      }
    });
  };

  return {
    purchaseAuction,
    purchaseAuctionSchema,
    sendingFormPurchaseAuction,
    purchaseAuctionFormState
  };
}
