<template>
<div class="single-entry mb-5 mb-lg-0">
    <p class="single-entry-text mb-3" align="justify">{{ artist.biography }}</p>
    <div class="row g-gs mb-4">
        <div class="col-lg-6 col-sm-6">
            <img :src="biographyImages[0]" class="w-100 rounded" alt="">
        </div>
        <div class="col-lg-6 col-sm-6">
            <img :src="biographyImages[1]" class="w-100 rounded" alt="">
        </div>
    </div>
    <p class="single-entry-text mb-3" align="justify">{{ artist.biography_2 }}</p>
    <blockquote class="blockquote">
        <p>{{ artist.biography_phrase }}</p>
    </blockquote>
    <p class="single-entry-text mb-4" align="justify">{{ artist.biography_3 }}</p>
    <div class="row g-gs mb-4">
        <div class="col-lg-6 col-sm-6">
            <img :src="biographyImages[2]" class="w-100 rounded" alt="">
        </div>
        <div class="col-lg-6 col-sm-6">
            <img :src="biographyImages[3]" class="w-100 rounded" alt="">
        </div>
    </div><!-- end row -->
   <!-- <h4 class="mb-2">{{ SectionData.blogDetail.title }}</h4>--><br>
    <p class="single-entry-text mb-2" align="justify">{{ artist.biography_4 }}</p>
    <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
</div><!-- end single-entry -->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import store from "@/store";

export default {
  name: 'AuthorDetailSection',
  props: ['biographyImages'],
  data () {
    return {
      SectionData
    }
  },
    computed: {
      artist() {
      return store.getters["artist/getArtist"];
    },
  },
}
</script>
