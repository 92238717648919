<template>
  <div class="page-wrap">
      <!-- header  -->
      <header class="header-section has-header-main">
        <!-- Header main -->
        <HeaderMain></HeaderMain>

        <HeaderBreadcrumbs
            classname="hero-title"
            :title="$t('trans.breadcrumbs.titles.services')"
            :breadcrumb-items="[
					{
						title: $t('trans.breadcrumbs.services'),
					}
				]"
        ></HeaderBreadcrumbs>
      </header>
      <!-- About section  -->
      <AboutSection class="" :img="SectionData.aboutData.img" isClassThree="col-sm-9 ps-xl-5" isRound="ps-xl-4" isReverse="flex-lg-row-reverse" isClassTwo="pe-lg-5"></AboutSection>           

      <div class="container mt-5">
          <div class="cta-box text-center">
              <h1 class="cta-title ">{{$t('trans.about.plans_devices.title')}} </h1>             
          </div>
      </div>

  <p>&nbsp;</p><a id="planes" title="Planes"></a> <p>&nbsp;</p>
      <PlansSection class="section-space-b pt-5 mt-3" :img="SectionData.aboutDataTwo.img" isRound="rounded-3" :title="SectionData.aboutDataTwo.title" isClassTwo="ps-xl-5" :content="SectionData.aboutDataTwo.content" isClass="lead" :contenttwo="SectionData.aboutDataTwo.contentTwo" :contentthree="SectionData.aboutDataTwo.contentThree" :contentfour="SectionData.aboutDataTwo.contentFour"></PlansSection>
    <a id="dispositivos" title="Dispositivos"></a>
      <DevicesSection class="section-space-b pt-5 mt-3 mb-40" :img="SectionData.aboutDataThree.img" isRound="rounded-3" :title="SectionData.aboutDataThree.title" isClassTwo="ps-xl-5" :content="SectionData.aboutDataThree.content" isClass="lead" :contenttwo="SectionData.aboutDataThree.contentTwo" :contentthree="SectionData.aboutDataThree.contentThree" :contentfour="SectionData.aboutDataThree.contentFour"></DevicesSection>
      <p>&nbsp;</p> <p>&nbsp;</p>
<!-- Footer  -->
      <Footer classname="bg-dark on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'AboutUs',
  data () {
    return {
      SectionData
    }
  },
}
</script>
