<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
      <!-- hero -->
      <HeaderBreadcrumbs
          classname="hero-title"
          title="Bienvenidos a Auth4Art"
          :breadcrumb-items="[
          {
            title: 'Exposición Bernardo Perez'
          }
        ]"
      ></HeaderBreadcrumbs>
      <div></div>
    </header>
    <section class="section-space-b blog-section">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <p class="mb-4 fs-8">
              Gracias por visitar la Exposición de <span class="fw-bold">Bernardo Pérez</span>.
            </p>
            <p class="my-2">
              Al finalizar la exposición, el día 14 de enero de 2023, nos pondremos en contacto contigo y te indicaremos
              cómo descargarte el NFT de manera <span class="fw-bold">totalmente gratuita</span>.
            </p>
            <p class="my-2 fw-bold">
              Déjanos tus datos para poder contactarte.
            </p>
          </div>
        </div>
        <div class="row section-space-b justify-content-center mt-4">
          <div class="col-lg-5 d-lg-none">
            <img src="@/assets/img/lona-bernanardo-perez.jpg"/>
          </div>
          <div class="col-lg-7">
            <div id="zf_div_GxGbErAvAF1l-9wHD7t0Wqvz6w8gg8slEolF9waGRws"></div>
          </div>
          <div class="col-lg-5 d-none d-lg-block">
            <img src="@/assets/img/lona-bernanardo-perez.jpg"/>
          </div>
        </div>
      </div>
    </section>

    <Footer classname="bg-dark on-dark"></Footer>
  </div>
  <!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import { onMounted } from 'vue';

export default {
  name: 'FormEvent',
  components: {},
  data() {
    return {
      SectionData
    };
  },
  setup() {
    onMounted(() => {
      var f = document.createElement('iframe');
      f.src =
        'https://forms.zohopublic.eu/authentication4artsl/form/AsistenciaExposicinBernardoPrez/formperma/GxGbErAvAF1l-9wHD7t0Wqvz6w8gg8slEolF9waGRws?zf_rszfm=1';
      f.style.border = 'none';
      f.style.height = '958px';
      f.style.width = '90%';
      f.style.transition = 'all 0.5s ease';
      var d = document.getElementById('zf_div_GxGbErAvAF1l-9wHD7t0Wqvz6w8gg8slEolF9waGRws');
      d.appendChild(f);
      window.addEventListener(
        'message',
        function () {
          var evntData = event.data;
          if (evntData && evntData.constructor == String) {
            var zf_ifrm_data = evntData.split('|');
            if (zf_ifrm_data.length == 2) {
              var zf_perma = zf_ifrm_data[0];
              var zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + 'px';
              var iframe = document
                .getElementById('zf_div_GxGbErAvAF1l-9wHD7t0Wqvz6w8gg8slEolF9waGRws')
                .getElementsByTagName('iframe')[0];
              if (iframe.src.indexOf('formperma') > 0 && iframe.src.indexOf(zf_perma) > 0) {
                var prevIframeHeight = iframe.style.height;
                if (prevIframeHeight != zf_ifrm_ht_nw) {
                  iframe.style.height = zf_ifrm_ht_nw;
                }
              }
            }
          }
        },
        false
      );
    });
    return {};
  }
};
</script>
