
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseFormButton',
  props: {
    title: String,
    variant: String,
    loading: Boolean,
    type: {
      type: String,
      default: 'button'
    }
  },
  setup(props) {
    const className = computed(() => {
      if (!props.variant) {
        return 'btn-primary';
      }

      return props.variant;
    });

    return {
      className
    };
  }
});
