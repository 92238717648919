<template>
  <div>
    <div class="row" v-if="isSSR">
      <div class="col-md-4 col" v-for="artwork in artworks.slice(0, 4)"
           :key="artwork.hash">
        <div class="card card-full">
          <div class="card-image">
            <router-link
                class=""
                :to="{
                name: $t('routes.artwork.name'),
                params: {
                  hash: artwork.hash,
                  slug: artwork.slug,
                },
              }"
            >
              <img
                  :src="getHeaderImageUri(artwork)"
                  class="card-img-top"
                  alt="art image"
              />
            </router-link>

          </div>
          <div class="card-body p-4">

            <h5 class="card-title text-truncate mb-0">
              <router-link
                  class=""
                  :to="{
                name: $t('routes.artwork.name'),
                params: {
                  hash: artwork.hash,
                  slug: artwork.slug,
                },
              }"
              >
                {{ artwork.title }}
              </router-link>
            </h5>
            <div v-if="artwork.artist" class="card-author mb-1 d-flex align-items-center">

              <div class="custom-tooltip-wrap">
                <router-link
                    class="author-link"
                    :to="{
							name: $t('routes.artist.name'),
							params: {
								hash: artwork.artist?.hash,
								slug: artwork.artist?.slug,
							},
						}"
                >
                  {{ artwork.artist?.full_name }}
                </router-link>
              </div>
              <!-- end custom-tooltip-wrap -->
            </div>
            <!-- end card-author -->


            <div
                v-if="artwork.active_auction"
                class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3"
            >
              <div class="me-5 me-sm-2">
                <span class="card-price-title">{{$t('trans.artwork.auction.starts_at')}}</span>
                <span v-if="artwork.active_auction.currency_type == 'euro'"
                      class="card-price-number">{{ artwork.active_auction.start_price + ' €' }}</span>
                <span v-else-if="artwork.active_auction.currency_type == 'matic'"
                      class="card-price-number">{{ artwork.active_auction.start_price + ' MATIC' }}</span>
              </div>
              <div class="text-sm-end">
                <span class="card-price-title">{{$t('trans.artwork.auction.max_bid')}}</span>
                <div v-if="artwork.active_auction.last_bid">
            <span v-if="artwork.active_auction.currency_type == 'euro'"
                  class="card-price-number">{{ artwork.active_auction.start_price + ' €' }}</span>
                  <span v-else-if="artwork.active_auction.currency_type == 'matic'"
                        class="card-price-number">{{ artwork.active_auction.start_price + ' MATIC' }}</span>
                </div>
                <div v-else>
            <span v-if="artwork.active_auction.currency_type == 'euro'"
                  class="card-price-number">{{ artwork.active_auction.start_price + ' €' }}</span>
                  <span v-else-if="artwork.active_auction.currency_type == 'matic'"
                        class="card-price-number">{{ artwork.active_auction.start_price + ' MATIC' }}</span>
                </div>
              </div>
            </div>

            <div
                v-else-if="artwork.active_artwork_sell"
                class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3"
            >
              <div class="me-5 me-sm-2">
                <span class="card-price-title">{{$t('trans.artwork.sell.euro_price')}}</span>
                <span class="card-price-number">{{ artwork.active_artwork_sell.price_euro?.price + ' €' }} </span>
              </div>
              <div class="text-sm-end">
                <span class="card-price-title">{{$t('trans.artwork.sell.matic_price')}}</span>
                <span class="card-price-number">{{ artwork.active_artwork_sell.price_matic?.price + ' MATIC' }} </span>
              </div>
            </div>

            <div
                v-else
                class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3"
            >

              <div v-if="artwork.childs?.length > 0" class="me-5 me-sm-2">
                <span class="card-price-title"
                      v-html="$t('trans.artwork.childs.section.list', { number: artwork.childs.length })"></span>
              </div>
              <div class="mt-5">
              </div>
            </div>

            <!-- end card-price-wrap -->

            <!-- boton con etiquetas tipo  -->
            <div class="row">
              <div class="col-6">
                <router-link
                    class=""
                    :to="{
                name: $t('routes.artwork.name'),
                params: {
                  hash: artwork.hash,
                  slug: artwork.slug,
                },
              }"
                >
                  <span v-if="artwork.active_auction" class="btn btn-sm btn-dark"> {{
                      $t('trans.auction.action')
                    }} </span>
                  <span v-else-if="artwork.active_artwork_sell"
                        class="btn btn-sm btn-dark">{{ $t('trans.marketplace.action') }}</span>
                  <span v-else class="btn btn-sm btn-dark">{{ $t('trans.artwork.showDetails') }}</span>
                </router-link>
              </div>

              <div class="col-6">
                <ul class="row g-1 nav nav-tabs nav-tabs-s2 justify-content-right" id="myTab" role="tablist">
                  <li v-if="artwork.is_nft" class="nav-item col-3 col-lg-4 offset-lg-4 offset-6">
                    <img :src="require('@/images/thumb/nft_tipo.svg')" class="icon icon-svg"
                         v-tippy="{ content:$t('trans.nft'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else class="nav-item col-3 col-lg-4 offset-lg-4 offset-6">
                    <img v-tippy="{ content:'Obra Física Pública', arrow: true, theme: 'dark', placement: 'bottom',}"
                         :src="require('@/images/thumb/fisica_publica.svg')" class="icon icon-svg">
                  </li>

                  <li v-if="artwork.active_artwork_sell?.price_euro && artwork.active_artwork_sell?.price_matic"
                      class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/fijo_mixto.svg')" class="icon icon-svg"
                         v-tippy="{ content:$t('trans.fijo_mixto'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_artwork_sell?.price_euro" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/fijo_euro.svg')" class="icon icon-svg"
                         v-tippy="{ content:$t('trans.fijo_euro'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_artwork_sell?.price_matic" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/fijo_matic.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.fijo_matic'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_auction?.currency_type == 'euro'" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/subasta_euro.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.subasta_euro'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_auction?.currency_type == 'matic'" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/subasta_matic.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.subasta_matic'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>

                  <li v-if="artwork.childs?.length > 0" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/edicion.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.edicion'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                </ul>

              </div>
            </div>
            <!-- fin boton con etiquetas-->
          </div>
        </div><!-- end card -->
      </div>
    </div>
    <swiper
        v-else
        :modules="modules"
        :slides-per-view="4"
        :breakpoints="{
    0: {
      slidesPerView: 1
    },
    767: {
      slidesPerView: 2
    },
    992: {
      slidesPerView: 4
    }
  }"
       :navigation="{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }"

        :pagination="{ clickable: true }"

    >
      <swiper-slide v-for="artwork in artworks"
                    :key="artwork.hash">
        <div class="card card-full card-creator">
          <div class="card-image">
            <router-link
                class=""
                :to="{
                  name: $t('routes.artwork.name'),
                  params: {
                    hash: artwork.hash,
                    slug: artwork.slug,
                  },
                }"
            >
              <img
                  :src="getHeaderImageUri(artwork)"
                  class="card-img-top"
                  alt="art image"
              />
            </router-link>

          </div>
          <div class="card-body p-4">

            <h5 class="card-title text-truncate mb-0">
              <router-link
                  class=""
                  :to="{
                    name: $t('routes.artwork.name'),
                    params: {
                      hash: artwork.hash,
                      slug: artwork.slug,
                    },
                  }"
              >
                {{ artwork.title }}
              </router-link>
            </h5>
            <div v-if="artwork.artist" class="card-author mb-1 d-flex align-items-center">

              <div class="custom-tooltip-wrap">
                <router-link
                    class="author-link"
                    :to="{
                      name: $t('routes.artist.name'),
                      params: {
                        hash: artwork.artist?.hash,
                        slug: artwork.artist?.slug,
                      },
                    }"
                >
                  {{ artwork.artist?.full_name }}
                </router-link>
              </div>
              <!-- end custom-tooltip-wrap -->
            </div>
            <!-- end card-author -->
            <div
                v-if="artwork.active_auction"
                class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-2"
            >
              <div class="me-5 me-sm-2">
                <span class="card-price-title">{{$t('trans.artwork.auction.starts_at')}}</span>
                <span v-if="artwork.active_auction.currency_type == 'euro'"
                      class="card-price-number">{{ artwork.active_auction.start_price + ' €' }}</span>
                <span v-else-if="artwork.active_auction.currency_type == 'matic'"
                      class="card-price-number">{{ artwork.active_auction.start_price + ' MATIC' }}</span>
              </div>
              <div class="text-sm-end">
                <span class="card-price-title">{{$t('trans.artwork.auction.max_bid')}}</span>
                <div v-if="artwork.active_auction.last_bid">
            <span v-if="artwork.active_auction.currency_type == 'euro'"
                  class="card-price-number">{{ artwork.active_auction.start_price + ' €' }}</span>
                  <span v-else-if="artwork.active_auction.currency_type == 'matic'"
                        class="card-price-number">{{ artwork.active_auction.start_price + ' MATIC' }}</span>
                </div>
                <div v-else>
            <span v-if="artwork.active_auction.currency_type == 'euro'"
                  class="card-price-number">{{ artwork.active_auction.start_price + ' €' }}</span>
                  <span v-else-if="artwork.active_auction.currency_type == 'matic'"
                        class="card-price-number">{{ artwork.active_auction.start_price + ' MATIC' }}</span>
                </div>
              </div>
            </div>

            <div
                v-else-if="artwork.active_artwork_sell"
                class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-2"
            >
              <div class="me-5 me-sm-2">
                <span class="card-price-title">{{$t('trans.artwork.sell.euro_price')}}</span>
                <span class="card-price-number">{{ artwork.active_artwork_sell.price_euro?.price + ' €' }} </span>
              </div>
              <div class="text-sm-end">
                <span class="card-price-title">{{$t('trans.artwork.sell.matic_price')}}</span>
                <span class="card-price-number">{{ artwork.active_artwork_sell.price_matic?.price + ' MATIC' }} </span>
              </div>
            </div>

            <div
                v-else
                class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-2"
            >

              <div v-if="artwork.childs?.length > 0" class="me-5 me-sm-2">
                <span class="card-price-title"
                      v-html="$t('trans.artwork.childs.section.list', { number: artwork.childs.length })"></span>
              </div>
              <div class="mt-5">
              </div>
            </div>
            <!-- end card-price-wrap -->
            <!-- boton con etiquetas tipo  -->
            <div class="row">
              <div class="col-6">
                <router-link
                    class=""
                    :to="{
                name: $t('routes.artwork.name'),
                params: {
                  hash: artwork.hash,
                  slug: artwork.slug,
                },
              }"
                >
                  <span v-if="artwork.active_auction" class="btn btn-sm btn-dark"> {{
                      $t('trans.auction.action')
                    }} </span>
                  <span v-else-if="artwork.active_artwork_sell"
                        class="btn btn-sm btn-dark">{{ $t('trans.marketplace.action') }}</span>
                  <span v-else class="btn btn-sm btn-dark">{{ $t('trans.artwork.showDetails') }}</span>
                </router-link>
              </div>

              <div class="col-6">
                <ul class="row g-1 nav nav-tabs nav-tabs-s2 justify-content-right" id="myTab" role="tablist">
                  <li v-if="artwork.is_nft" class="nav-item col-3 col-lg-4 offset-lg-4 offset-6">
                    <img :src="require('@/images/thumb/nft_tipo.svg')" class="icon icon-svg"
                         v-tippy="{ content:$t('trans.nft'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else class="nav-item col-3 col-lg-4 offset-lg-4 offset-6">
                    <img v-tippy="{ content:'Obra Física Pública', arrow: true, theme: 'dark', placement: 'bottom',}"
                         :src="require('@/images/thumb/fisica_publica.svg')" class="icon icon-svg">
                  </li>

                  <li v-if="artwork.active_artwork_sell?.price_euro && artwork.active_artwork_sell?.price_matic"
                      class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/fijo_mixto.svg')" class="icon icon-svg"
                         v-tippy="{ content:$t('trans.fijo_mixto'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_artwork_sell?.price_euro" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/fijo_euro.svg')" class="icon icon-svg"
                         v-tippy="{ content:$t('trans.fijo_euro'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_artwork_sell?.price_matic" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/fijo_matic.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.fijo_matic'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_auction?.currency_type == 'euro'" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/subasta_euro.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.subasta_euro'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                  <li v-else-if="artwork.active_auction?.currency_type == 'matic'" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/subasta_matic.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.subasta_matic'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>

                  <li v-if="artwork.childs?.length > 0" class="nav-item  col-3 col-lg-4">
                    <img :src="require('@/images/thumb/edicion.svg')" class="icon icon-svg"
                         v-tippy="{ content: $t('trans.edicion'), arrow: true, theme: 'dark', placement: 'bottom',}">
                  </li>
                </ul>

              </div>
            </div>
            <!-- fin boton con etiquetas-->
          </div>
        </div><!-- end card -->
      </swiper-slide>
                <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

    </swiper>
  </div>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

// core version + navigation, pagination modules:
import SwiperCore, {Pagination} from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {isSSR} from "@/helpers";

export default {
  name: 'ArtworkHighlightedSlider',
  props: ['artworks'],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      SectionData,
      isSSR: isSSR(),
      size: !isSSR() ? window.innerWidth < 992 ? 'sm' : 'lg' : 'lg',
    }
  },
  methods: {
    getHeaderImageUri(artwork) {

      return artwork.images.filter(
          (image) => {
            return image.type === "header_list";
          }
      )[0].thumbnails.filter(
          (thumbnail) => {
            return thumbnail.aspect_ratio === "3_2" && thumbnail.size === this.size;
          }
      )[0].uri;
    },
  },
  setup() {
    return {
      modules: [Pagination]
    }
  },
};
</script>
