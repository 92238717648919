import { useStore } from 'vuex';
import { computed, nextTick, ref } from 'vue';
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';
import sanctum from '@/plugins/sanctum';
import { useI18n } from 'vue-i18n';
import { isSSR } from '@/helpers';

export function useBillingAddress() {
  const store = useStore();
  const { t } = useI18n();
  const billingAddress = ref({});
  const configurations = computed(() => store.getters.currentConfiguration);
  const modal = ref();

  const billingAddresses = computed(() => {
    return store.getters.getUserBillingAddresses;
  });

  const canAddRecords = computed(
    () => configurations.value.model_limits.BillingAddress > billingAddresses.value.length
  );

  const closeModal = () => {
    modal.value.hide();
  };

  const addBillingAddress = async () => {
    billingAddress.value = {
      type: '',
      business_name: '',
      city: '',
      region_id: '',
      country_id: '',
      postal_code: '',
      is_default: 0,
      email: '',
      name: '',
      surname: '',
      phone: '',
      address: '',
      nif: ''
    };
    nextTick(async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        modal.value = new Modal(document.getElementById('add_billing_address_modal')!);
        modal.value.show();
      }
    });
  };

  const editBillingAddress = async (billing_address) => {
    const billingAddressEdit = { ...billing_address };
    billingAddressEdit.country_id = billing_address.country.id;
    billingAddressEdit.region_id = billing_address.region.id;
    billingAddressEdit.is_default = Boolean(billing_address.is_default);
    delete billingAddressEdit.country;
    delete billingAddressEdit.region;

    billingAddress.value = billingAddressEdit;

    nextTick(async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        modal.value = new Modal(document.getElementById('add_billing_address_modal')!);
        modal.value.show();
      }
    });
  };

  const deleteBillingAddress = async (billingAddress) => {
    Swal.fire({
      title: t('trans.profile.payments.details.alert.confirm_delete_title'),
      text: t('trans.profile.payments.details.alert.confirm_delete_description'),
      icon: 'warning',
      confirmButtonText: t('trans.profile.payments.details.alert.confirm_delete_button_yes'),
      cancelButtonText: t('trans.profile.payments.details.alert.confirm_delete_button_no'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-dark mx-2',
        cancelButton: 'btn btn-outline-primary mx-2',
        denyButton: 'btn btn-danger mx-2'
      },
      showCancelButton: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/v1/public/profile/billing-address/${billingAddress.hash}`);
          await sanctum.getUser();

          Swal.fire({
            title: t('trans.profile.payments.details.alert.create_success_title'),
            text: t('trans.profile.payments.details.alert.create_success_description'),
            icon: 'success',
            confirmButtonText: 'Entendido',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2',
              cancelButton: 'btn btn-outline-primary mx-2',
              denyButton: 'btn btn-danger mx-2'
            }
          });
        } catch (e) {
          Swal.fire({
            title: t('trans.profile.payments.details.alert.error_title'),
            text: t('trans.profile.payments.details.alert.error_description'),
            icon: 'error',
            confirmButtonText: 'Entendido',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2',
              cancelButton: 'btn btn-outline-primary mx-2',
              denyButton: 'btn btn-danger mx-2'
            }
          });
        }
      }
    });
  };

  return {
    addBillingAddress,
    editBillingAddress,
    deleteBillingAddress,
    billingAddresses,
    canAddRecords,
    configurations,
    billingAddress,
    closeModal
  };
}
