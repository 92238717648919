
import { computed, defineComponent, ref } from 'vue';
import PaymentMethodStripeModal from '@/components/common/PaymentMethodStripeModal.vue';
import PaymentMethodWallet from '@/components/common/PaymentMethodWallet.vue';
import { useStore } from 'vuex';
import BaseIcon from '@/components/common/BaseIcon.vue';
import { helpers } from '@/types/enums/PaymentCards';
import axios from '@/plugins/axios';
import Swal from 'sweetalert2';
import sanctum from '@/plugins/sanctum';

import { useI18n } from 'vue-i18n';
import { isSSR } from '@/helpers';

export default defineComponent({
  name: 'DashboardPaymentsSectionTabPaymentMethods',
  components: {
    BaseIcon,
    PaymentMethodWallet,
    PaymentMethodStripeModal
  },
  setup() {
    const store = useStore();
    const modal = ref();
    const { t } = useI18n();

    const paymentMethodsFiat = computed(() => {
      return store.getters.getUserPaymentMethodFiat;
    });

    const hasPaymentMethodsFiat = computed(() => {
      return store.getters.hasUserPaymentMethodFiat;
    });

    const paymentMethodsCrypto = computed(() => {
      return store.getters.getUserPaymentMethodCrypto;
    });

    const getIcon = (key: string) => {
      return helpers.getInfo(key)?.icon;
    };

    const setAsDefault = async (paymentMethod) => {
      Swal.fire({
        title: t('trans.alert.default_loading.title'),
        text: t('trans.alert.default_loading.message'),
        icon: 'info',
        showCancelButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: async () => {
          try {
            await axios.post(`/api/v1/public/profile/payment-method/setAsDefault/${paymentMethod.hash}`);
            await sanctum.getUser();
            Swal.fire(
              t('trans.alert.payment_method_default.title'),
              t('trans.alert.payment_method_default.title'),
              'success'
            ).then(async () => {
              Swal.close();
            });
          } catch (e) {
            Swal.fire('Oops!', 'Ha habido un error', 'error');
          }
        }
      });
    };

    const deletePaymentMethod = async (paymentMethod) => {
      Swal.fire({
        title: t('trans.alert.payment_method_confirm_delete.title'),
        text: t('trans.alert.payment_method_confirm_delete.title'),
        icon: 'warning',
        confirmButtonText: t('trans.alert.continue'),
        cancelButtonText: t('trans.alert.cancel'),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: true,
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-gray'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: t('trans.alert.default_loading.title'),
            text: t('trans.alert.default_loading.message'),
            icon: 'info',

            showCancelButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            showConfirmButton: false,

            didOpen: async () => {
              try {
                await axios.post(`/api/v1/public/profile/payment-method/delete/${paymentMethod.hash}`);
                await sanctum.getUser();
                Swal.fire(
                  t('trans.alert.payment_method_delete.title'),
                  t('trans.alert.payment_method_delete.title'),
                  'success'
                ).then(async () => {
                  Swal.close();
                });
              } catch (e) {
                Swal.fire(t('trans.alert.default_error.title'), t('trans.alert.default_error.title'), 'error');
              }
            }
          });
        }
      });
    };

    const addPaymentMethod = async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        modal.value = new Modal(document.getElementById('add_payment_method_modal')!);
        modal.value.show();
      }
    };

    const closeModal = () => {
      modal.value.hide();
    };

    return {
      paymentMethodsFiat,
      hasPaymentMethodsFiat,
      paymentMethodsCrypto,
      getIcon,
      setAsDefault,
      deletePaymentMethod,
      addPaymentMethod,
      closeModal
    };
  }
});
