import { createApp, createSSRApp } from 'vue';
import { Router } from 'vue-router';
import { createApplicationRouter } from './routing/router';
import { isSSR } from '@/helpers';
import App from './App.vue';

import i18n from './translations/i18n';

// clipboard
import VueClipboard from 'vue3-clipboard';

// template custom css
import './assets/scss/bundles.scss';

//store
import store from './store/index';

// axios
import axios from './plugins/axios';

// custom sanctum
import sanctum from './plugins/sanctum';

//Vue Cookie
import { VueCookieNext } from 'vue-cookie-next';

import { registerComponents } from './plugins/global-components';
import { registerTippy } from './plugins/tippy';
import { registerSweetAlert } from '@/plugins/sweet-alert';
import { registerVueTag } from '@/plugins/vue-tag';
import { initVeeValidate } from '@/plugins/vee-validate';

export function createApplication() {
  const app = isSSR() ? createSSRApp(App) : createApp(App);
  app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true
  });

  app.use(store);

  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$sanctum = sanctum;

  app.use(i18n);

  registerSweetAlert(app);
  registerTippy(app);
  registerVueTag(app);
  registerComponents(app);
  initVeeValidate();

  const router: Router = createApplicationRouter();

  app.use(VueCookieNext);

  app.use(router);

  return { app, router, store };
}
