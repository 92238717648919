<template>
  <div v-if="artist && artist.hash" class="card-creator-v card-creator-v-wbg card-creator-v-wbg-s1 pt-0 pb-4">
    <div class="card-creator-info">
      <router-link
        :to="{
          name: $t('routes.artist.name'),
          params: {
            hash: artist.hash,
            slug: artist.slug
          }
        }"
        class="avatar avatar-1 flex-shrink-0 mb-2"
      >
        <img :src="avatarImageUri" alt="avatar" />
        <!--        <img v-if="artist.is_highlighted" :src="avatarImageUri" alt="avatar" />-->
        <!--        <img v-else :src="require('@/images/thumb/avatar.jpg')" alt="avatar" />-->
        <span class="card-creator-number">{{ artist.artworks.length }}</span>
      </router-link>
      <div class="flex-grow-1">
        <router-link
          :to="{
            name: $t('routes.artist.name'),
            params: {
              hash: artist.hash,
              slug: artist.slug
            }
          }"
          class="card-title"
          >{{ artist.name }}</router-link
        >
        <span class="d-block fw-medium card-amount">{{ artist.surname }}</span>
      </div>
    </div>
  </div>
  <!-- row -->
</template>

<script>
import useArtistImage from '@/composable/useArtistImage';
import { ref } from 'vue';

export default {
  props: ['artist'],
  setup(props) {
    const artist = ref(props.artist);
    const { avatarImageUri, size } = useArtistImage(artist);

    return {
      avatarImageUri,
      size
    };
  }
};
</script>
