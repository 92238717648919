<template>

<div class="row g-gs">

				<div v-for="attachment in attachments" :key="attachment.hash" class="col-lg-4 col-md-6">
					<a
						:href="attachment.uri"
						target="_blank"
						class="card card-full text-center"
					>

						<div class="card-body card-body-s1 d-block">

							<img v-if="attachment.extension == 'pdf'" class="mb-4" :src="require('@/images/thumb/pdf.svg')" alt="" />
							<img v-else class="mb-4" :src="require('@/images/thumb/img.svg')" alt="" />

							<!-- <h4 class="card-title mb-3">{{attachments_categories}}</h4> -->

							<p class="card-text card-text-s1 mb-4">
								 {{attachment.title}}
							</p>


						</div>

						<!-- end card-body -->

					</a>

					<!-- end card -->

				</div>
			</div>

</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import store from "@/store";

export default {
  name: 'AuthorDocsSection',
  data () {
    return {
      SectionData
    }
  },
  computed: {
    hash() {
		return store.getters["artist/getArtist"]?.hash;
	},
	attachments() {
      return store.getters["artist/getArtist"].attachments
	},
	attachments_categories() {
      return store.getters["artist/getArtist"].attachments_categories
	},
  },
  methods: {
          downloadFile(hash, filename) {
              window.open(window.location.origin + '/artist/' + hash + '/' + filename);
          }
      },
}
</script>
