<template>
  <div class="row g-gs">
    <div
        class="col-lg-3 col-sm-6"
        v-for="artist in highlightedArtists"
        :key="artist.hash"
    >
      <div
          class="card-creator-v card-creator-v-wbg card-creator-v-wbg-s1 pt-0 pb-4"
          :class="classname"
      >
        <div class="card-creator-info">
          <router-link
              :to="{
							name: $t('routes.artist.name'),
							params: {
								hash: artist.hash,
								slug: artist.slug,
							},
						}"
              class="avatar avatar-1 flex-shrink-0 mb-2"
          >
            <img
                :src="require('@/images/thumb/avatar.jpg')"
                alt="avatar"
            />
            <span class="card-creator-number">{{
                artist.artworks.length
              }}</span>
          </router-link>
          <div class="flex-grow-1">
            <router-link
                :to="{
								name: $t('routes.artist.name'),
								params: {
									hash: artist.hash,
									slug: artist.slug,
								},
							}"
                class="card-title"
            >
              {{ artist.name }}
            </router-link>
            <span class="d-block fw-medium card-amount">
              {{ artist.surname }}
            </span>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col-lg-4 -->
  </div>
  <!-- row -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import store from "@/store";
import {ssrRunning} from "@/helpers/index.ts";

export default {
  name: "ArtistHighlighted",
  props: ["classname"],
  computed: {
    highlightedArtists() {
      return store.getters["artist/getHighlightedArtist"];
    },
  },
  data() {
    return {
      SectionData,
    };
  },
  created() {
    if (!ssrRunning()) {
      store.dispatch("artist/setHighlightedArtist");
    }
  },
  serverPrefetch() {
    store.dispatch("artwork/setHighlightedArtist");
  },
};
</script>
