const routes = {
  home: {
    name: 'Home__en',
    path: ''
  },
  email: {
    confirm: {
      name: 'EmailConfirm__en',
      path: 'email-confirm'
    }
  },
  legal: {
    name: 'Legal__en',
    private: {
      name: 'DashboardPrivate__es',
      path: ''
    },
    path: 'legal'
  },
  terms: {
    name: 'Terms__en',
    path: 'terms'
  },
  privacy: {
    name: 'Privacy__en',
    path: 'privacy'
  },
  cookies: {
    name: 'Cookie__en',
    path: 'cookie'
  },
  contact: {
    name: 'Contact__en',
    path: 'contact'
  },
  services: {
    name: 'Services__en',
    path: 'services'
  },
  martelive: {
    name: 'Martelive__en',
    path: 'martelive'
  },
  artworks: {
    name: 'Artworks__en',
    path: 'artworks'
  },
  artwork: {
    name: 'Artwork__en',
    path: 'artwork'
  },
  artworkChild: {
    name: 'Artwork_Child__en',
    path_1: 'edition',
    path_2: 'artwork'
  },
  editArtwork: {
    name: 'Edit_Artwork__en',
    path: 'edit-artwork'
  },
  artists: {
    name: 'Artists__en',
    path: 'artists'
  },
  artist: {
    name: 'Artist__en',
    path: 'artist'
  },
  collections: {
    name: 'Collections__en',
    path: 'collections'
  },
  collection: {
    name: 'Collection__en',
    path: 'collection'
  },
  marketplace: {
    name: 'Marketplace__en',
    path: 'marketplace'
  },
  login: {
    name: 'Login__en',
    path: 'login'
  },
  metaExpos: {
    name: 'Meta_Expos__en',
    path: 'meta-exposiciones/'
  },
  metaExpos_Pararecordarte: {
    name: 'Meta_Expos_Bernardo_Perez__en',
    path: 'meta-exposiciones/pararecordarte'
  },
  metaExpos_Pararecordarte_UMA: {
    name: 'Meta_Expos_Pararecordarte_UMA__en',
    path: 'meta-exposiciones-uma/pararecordarte'
  },
  metaExpos_BernardoPerez: {
    name: 'Meta_Expos_Bernardo_Perez__en',
    path: 'meta-exposiciones/bernardo-perez'
  },
  metaExpos_BernardoPerez_UMA: {
    name: 'Meta_Expos_Bernardo_Perez_UMA__en',
    path: 'meta-exposiciones-uma/bernardo-perez'
  },
  metaExpos_PierreGonnord: {
    name: 'Meta_Expos_Pierre_Gonnord__en',
    path: 'meta-exposiciones/pierre-Gonnord'
  },
  metaExpos_PierreGonnord_UMA: {
    name: 'Meta_Expos_Pierre_Gonnord_UMA__en',
    path: 'meta-exposiciones-uma/pierre-Gonnord'
  },
  metaExpos_AngelesSioli: {
    name: 'Meta_Expos_Angeles_Sioli__en',
    path: 'meta-exposiciones/angeles-sioli'
  },
  metaExpos_AngelesSioli_UMA: {
    name: 'Meta_Expos_Angeles_Sioli_UMA__en',
    path: 'meta-exposiciones-uma/angeles-sioli'
  },
  metaExpos_JondoSonidosMaquinicos: {
    name: 'Meta_Expos_Jondo_Sonidos_Maquinicos__en',
    path: 'meta-exposiciones/jondo-sonidos-maquinicos'
  },
  metaExpos_JondoSonidosMaquinicos_UMA: {
    name: 'Meta_Expos_Jondo_Sonidos_Maquinicos_UMA__en',
    path: 'meta-exposiciones-uma/jondo-sonidos-maquinicos'
  },
  metaExpos_ArchivoSombraCarlosCanal: {
    name: 'Meta_Expos_Archivo_Sombra_Carlos_Canal__en',
    path: 'meta-exposiciones/archivo-sombra-carlos-canal'
  },
  metaExpos_ArchivoSombraCarlosCanal_UMA: {
    name: 'Meta_Expos_Archivo_Sombra_Carlos_Canal_UMA__en',
    path: 'meta-exposiciones-uma/archivo-sombra-carlos-canal'
  },
  formEvent: {
    name: 'Form_Event__en',
    path: 'exposiciones/bernardo-perez/registro'
  },

  // profile: {
  // 	name: "Profile__en",
  // 	path: "my-profile",
  // },
  dashboard: {
    name: 'Dashboard__en',
    path: 'my-profile',
    private: {
      name: 'DashboardPrivate__en',
      path: 'go-to-my-profile'
    },
    auctions: {
      name: 'DashboardAuctions__en',
      path: 'my-dashboard/my-artworks'
    },
    favourites: {
      name: 'DashboardFavourites__en',
      path: 'my-dashboard/favourites'
    },
    artworks: {
      name: 'DashboardArtworks__en',
      path: 'my-dashboard/artworks'
    },
    transactions: {
      name: 'DashboardTransactions__en',
      path: 'my-dashboard/transactions'
    },
    payments: {
      name: 'DashboardPayments__en',
      path: 'my-profile/payment-info'
    },
    academy: {
      name: 'DashboardAcademy__en',
      path: 'my-dashboard/academy'
    }
  }
};

export default routes;
