import { createApplication } from "./app";

// Create a new store instance.

import "bootstrap";
const { app, router, store } = createApplication();

const storeInitialState = window["__STATE__"];
if (storeInitialState) {
	store.replaceState(storeInitialState);
}

(async (r, a, s) => {
	await r.isReady();
	a.mount("#app", true);
})(router, app, store);
