<template>
  <!-- Blog  -->
  <section class="section-space-b blog-section">
    <div class="container">
      <!-- blog section-->

      <div class="single-entry mb-5 mb-lg-0">
        <p class="single-entry-text mb-3" v-html="SectionData.ConditionDetail.content" align="justify"></p>
        <p class="single-entry-text mb-3" v-html="SectionData.ConditionDetail.contentTwo" align="justify"></p>

        <p class="single-entry-text mb-3" v-html="SectionData.ConditionDetail.quoteText" align="justify"></p>

        <p class="single-entry-text mb-4" v-html="SectionData.ConditionDetail.contentThree" align="justify"></p>

        <p class="single-entry-text mb-3" v-html="SectionData.ConditionDetail.contentFour" align="justify"></p>
        <p class="single-entry-text mb-3" v-html="SectionData.ConditionDetail.title" align="justify"></p>
        <p class="single-entry-text mb-2" v-html="SectionData.ConditionDetail.contentFive" align="justify"></p>
        <div class="gap-2x"></div>

        <div class="card-body card-body-s1 d-block">
          <a href="https://auth4art.com/Terms_Auth4Art.pdf" target="_blank">
            <img class="mb-4" :src="require('@/images/thumb/pdf.svg')" alt="" />

            <h4 class="card-title mb-3">Términos y condiciones</h4>

            <p class="card-text card-text-s1 mb-4">Descargue nuestros términos y condicones en PDF.</p></a
          >
        </div>
      </div>
    </div>
    <!-- .container -->
  </section>
  <!-- end blog-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';

export default {
  name: 'ConditionDetails',
  components: {},
  data() {
    return {
      SectionData
    };
  }
};
</script>
