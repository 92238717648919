<template>
    <router-link to="/" class="logo-link" >
         <img class="logo-dark logo-img"  :src="require('@/images/logo_light.png')" alt="logo">
   
        <img class="logo-light logo-img"  :src="require('@/images/logo_dark.png')" alt="logo">
    </router-link>
    <router-link to="/" class="fs-12" >
      <span style="font-family: 'A4A'; font-weight: bold; color:#000">
				Auth<span style="font-size:1.3em">4</span>Art</span
      >
    </router-link>
</template>
<script>

export default {
  name: 'LogoLink',
  props: ['classname']
}
</script>
