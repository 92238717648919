<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>

      <HeaderBreadcrumbs
        v-if="!isChildArtwork"
        classname="hero-title"
        :title="$t('trans.breadcrumbs.titles.artwork')"
        :breadcrumb-items="[
          {
            title: $t('trans.breadcrumbs.artworks'),
            routeName: $t('routes.artworks.name')
          },
          {
            title: $t('trans.breadcrumbs.artwork'),
            routeName: $t('routes.artworks.name')
          }
        ]"
      ></HeaderBreadcrumbs>
      <HeaderBreadcrumbs
        v-else
        classname="hero-title"
        :title="$t('trans.breadcrumbs.titles.artwork')"
        :breadcrumb-items="[
          {
            title: $t('trans.breadcrumbs.artworks'),
            routeName: $t('routes.artworks.name')
          },
          {
            title: $t('trans.breadcrumbs.edition'),
            routeName: $t('routes.artwork.name'),
            routeParams: {
              hash: artwork.original.hash,
              slug: artwork.original.slug
            }
          },
          {
            title: $t('trans.breadcrumbs.artwork'),
            routeName: $t('routes.artworks.name')
          }
        ]"
      ></HeaderBreadcrumbs>
    </header>
    <section class="section-space-b">
      <div class="container" v-if="!loading">
        <div v-if="!hash">
          <NotFoundCommon></NotFoundCommon>
        </div>
        <div v-else-if="isParentArtwork">
          <ArtworkDetailParent></ArtworkDetailParent>
        </div>
        <div v-else>
          <div class="row mb-3">
            <button v-if="isChildArtwork" type="button" class="icon-btn icon-btn-s1">
              <router-link
                class="dark-link"
                :to="{
                  name: $t('routes.artwork.name'),
                  params: {
                    hash: artwork.original.hash,
                    slug: artwork.original.slug
                  }
                }"
              >
                <i class="fa-solid fa-arrow-left"></i>
              </router-link>
            </button>
          </div>
          <ArtworkDetailChild></ArtworkDetailChild>
        </div>
        <!-- .container -->
      </div>
    </section>
    <Footer classname="bg-dark on-dark"></Footer>
  </div>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';
import { roundLocale } from '@/plugins/helpers';
import { directive } from 'vue-tippy';
import ArtworkDetailParent from '@/components/section/ArtworkDetailParent';
import ArtworkDetailChild from '@/components/section/ArtworkDetailChild';
import NotFoundCommon from '@/components/common/NotFoundCommon';
import { useStore } from 'vuex';
import { useUserAuth } from '@/composable/user/user';
import { onMounted } from 'vue';

export default {
  name: 'ArtworkDetail',
  directives: {
    tippy: directive
  },
  components: {
    NotFoundCommon,
    ArtworkDetailChild,
    ArtworkDetailParent
  },
  setup() {
    const { currentUser } = useUserAuth();

    return {
      currentUser
    };
  },
  data() {
    return {
      SectionData,
      id: this.$route.params.id,
      bidOffer: null,
      roundLocale,
      higherBidNumber: 20,
      loading: true,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
      bidFinished: false,
      outbid: false,
      fetchDone: false
    };
  },
  computed: {
    hash() {
      return store.getters['artwork/getArtwork']?.hash;
    },
    title() {
      return store.getters['artwork/getArtwork']?.title;
    },
    metaText() {
      return store.getters['artwork/getArtwork']?.seo_keywords;
    },
    description() {
      return store.getters['artwork/getArtwork']?.description;
    },
    discipline() {
      return store.getters['artwork/getArtwork']?.discipline?.name;
    },
    content() {
      return store.getters['artwork/getArtwork']?.content;
    },
    artistName() {
      return store.getters['artwork/getArtwork']?.artist?.full_name;
    },
    artist() {
      return store.getters['artwork/getArtwork']?.artist;
    },
    isNFT() {
      return store.getters['artwork/getArtwork']?.is_nft;
    },
    highlightedArtworks() {
      return store.getters['artwork/highlightedArtworks'];
    },
    date() {
      const createdAt = store.getters['artwork/getArtwork']?.created_at;
      if (createdAt) {
        return new Date(createdAt).toLocaleDateString();
      }
      return '';
    },
    ownerName() {
      return (
        store.getters['artwork/getArtwork']?.owner?.name + ' ' + store.getters['artwork/getArtwork']?.owner?.surname
      );
    },
    type() {
      return store.getters['artwork/getArtwork']?.type?.name;
    },
    height() {
      return store.getters['artwork/getArtwork']?.size_height;
    },
    width() {
      return store.getters['artwork/getArtwork']?.size_width;
    },
    hasFirm() {
      return store.getters['artwork/getArtwork']?.has_firm;
    },
    isAuction() {
      return typeof store.getters['auction/getAuction']?.hash !== 'undefined';
    },
    isMarketplace() {
      return typeof store.getters['marketplace/getMarketplace']?.hash !== 'undefined';
    },
    slug() {
      return store.getters['artwork/getArtwork']?.slug;
    },
    artwork() {
      return store.getters['artwork/getArtwork'];
    },
    isParentArtwork() {
      if (store.getters['artwork/getArtwork'].childs !== undefined) {
        return store.getters['artwork/getArtwork'].childs.length > 0;
      }
      return false;
    },
    isChildArtwork() {
      return store.getters['artwork/getArtwork'].child_order;
    }
  },
  async serverPrefetch() {
    await this.setData();
    this.loading = false;
  },
  async created() {
    await this.setData();
    this.loading = false;
    const currentArtwork = store.getters['artwork/getArtwork'];

    if (!currentArtwork.is_public) {
      this.$router.push({ name: '404' });
    }
  },
  beforeCreate() {
    store.dispatch('artwork/clearArtwork');
  },
  methods: {
    async setData() {
      let error;
      const route = useRoute();

      await store.dispatch('artwork/setArtwork', route.params.hash);
      const auction = store.getters['artwork/getArtwork'].active_auction;
      const marketplace = store.getters['artwork/getArtwork'].active_artwork_sell;

      if (auction) {
        //Sin Marketplace
        store.commit('marketplace/setMarketplace', {});
        store.commit('auction/setAuction', auction);
      } else if (marketplace) {
        //Sin Auction
        store.commit('auction/setAuction', {});
        store.commit('marketplace/setMarketplace', marketplace);
      } else {
        //Sin nada
        store.commit('auction/setAuction', {});
        store.commit('marketplace/setMarketplace', {});
      }
    }
  }
};
</script>
