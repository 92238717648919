<template>
  <!-- section heading -->
  <SectionHeading
    classname="text-center"
    :text="$t('trans.artwork.childs.section.title')"
    :content="$t('trans.artwork.childs.section.content', { number: artwork.childs.length })"
    isMargin="mb-3"
  ></SectionHeading>
  <div class="row g-gs">
    <div class="col-xl-3 col-lg-4 col-sm-6" v-for="artwork in artwork.childs" :key="artwork.hash">
      <Artwork :artwork="artwork"></Artwork>
    </div>
  </div>
  <div class="text-center mt-5">
    <router-link class="btn-link btn-link-s1" :to="{ name: $t('routes.artworks.name') }">
      {{ $t('trans.showMore') }}
    </router-link>
  </div>
</template>

<script>
import SectionData from '@/store/store.js';
import Artwork from '@/components/section/Artwork';
import store from '@/store';

export default {
  name: 'ArtworkChildsContainer',
  components: {
    Artwork
  },
  computed: {
    artwork() {
      return store.getters['artwork/getArtwork'];
    }
  },
  data() {
    return {
      SectionData
    };
  }
};
</script>
