
import { defineComponent } from 'vue';
import BaseIcon from '@/components/common/BaseIcon.vue';
import DashboardPaymentsBillingAddressModal from '@/components/section/DashboardPaymentsBillingAddressModal.vue';

import { useBillingAddress } from '@/composable/address';

export default defineComponent({
  name: 'DashboardPaymentsSectionTabBillingAddress',
  components: {
    BaseIcon,
    DashboardPaymentsBillingAddressModal
  },
  setup() {
    const {
      addBillingAddress,
      editBillingAddress,
      deleteBillingAddress,
      configurations,
      billingAddresses,
      billingAddress,
      canAddRecords,
      closeModal
    } = useBillingAddress();

    return {
      billingAddresses,
      billingAddress,
      addBillingAddress,
      editBillingAddress,
      deleteBillingAddress,
      configurations,
      canAddRecords,
      closeModal
    };
  }
});
