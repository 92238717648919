import { createI18n } from "vue-i18n/index";
import en from "./en/trans-en";
import es from "./es/trans-es";

const messages = {
	en,
	es,
};

const i18n = createI18n({
	legacy: false,
	locale: "es",
	defaultLocale: "es",
	globalInjection: true,
	messages,
	warnHtmlInMessage: "off", // disable of the Detected HTML in message
	warnHtmlMessage: false,
});

export default i18n;
