<template>
    <div class="sub-header-collections" :style="{ backgroundImage: 'url(' + coverimg + ')' }">
    </div><!-- end hero-wrap -->
</template>

<script>

export default {
  name: 'CollectionHero',
  props: ['img', 'avatarSize', 'title', 'username', 'isDropdown', 'btntext', 'isCopyInput', 'btnlink', 'coverimg'],
  setup() {
     const message = 'Compartir';
     const onCopy = (e) => {
        let target = e.trigger.querySelector(".tooltip-text");
        let prevText = target.innerHTML;
        target.innerHTML = "Copied";
        setTimeout(function(){
        target.innerHTML = prevText;
        }, 1000)
    }
    return { message, onCopy }
  }
}
</script>
<style scoped>
.hero-action-wrap {
    visibility: hidden;
}
</style>