<template>
	<div v-if="collection && collection.hash" class="card card-full card-blog">
		<router-link
              			class=""
              			:to="{
              			  name: $t('routes.collection.name'),
              			  params: {
              			    hash: collection.hash,
              			  },
              			}"
          			>
		<div class="d-block card-image">
			<img
				:src="require('@/images/artwork/n-a/nft.jpg')"
				class="card-img-top"
				alt=""
			/>			
		</div>
		<div class="card-body card-body-s1">
			<h4 class="card-title mb-1">{{ collection.name }}</h4>
			<div v-if="collection.owner" class="card-author mb-1 d-flex align-items-center">
        		<div class="custom-tooltip-wrap">
          		<router-link
              		class="author-link"
              		:to="{
							name: $t('routes.artist.name'),
							params: {
								hash: collection.owner?.hash,
								slug: collection.owner?.slug,
							},
						}"
          		>
            		{{ collection.owner?.name }}
          		</router-link>
        		</div>
        		<!-- end custom-tooltip-wrap -->
      		</div>
      		<!-- end card-author -->

		</div>
		</router-link>					
		<!-- end card-body -->
		<hr class="my-0" />
		<div class="card-body card-body-s1 py-3">
			<div class="card-action-info">
				<div class="col-6">
          			<router-link
          			    class=""
          			    :to="{
          			      name: $t('routes.collection.name'),
          			      params: {
          			        hash: collection.hash,
          			      },
          			    }"
          			>          				
          				<span class="btn btn-sm btn-dark">{{ $t('trans.artwork.showDetails') }}</span>
          			</router-link>
        </div>
				<span>
					<span class="me-3"
						><em class="fa fa-image me-1"></em
						>{{ collection.artworks.length }}</span
					>
				</span>
			</div>
			<!-- end card-action-info -->
		</div>
		<!-- end card-body -->
		<router-link class="details" to="/"> </router-link>
	</div>
	<!-- end card -->
</template>
<script>
export default {
	props: ["collection"],
};
</script>
