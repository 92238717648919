<template>
  <!-- Blog  -->
  <section class="section-space-b blog-section">
    <div class="container">
      <!-- blog section -->

      <div class="single-entry mb-5 mb-lg-0">
        <p class="single-entry-text mb-3" align="justify">{{ SectionData.PrivacyDetail.content }}</p>
        <p class="single-entry-text mb-3" v-html="SectionData.PrivacyDetail.contentTwo" align="justify"></p>
        <p>&nbsp;</p>
        <p class="single-entry-text mb-3" v-html="SectionData.PrivacyDetail.quoteText" align="justify"></p>
        <p>&nbsp;</p>
        <p class="single-entry-text mb-3" v-html="SectionData.PrivacyDetail.contentThree" align="justify"></p>
        <p>&nbsp;</p>
        <p class="single-entry-text mb-3" v-html="SectionData.PrivacyDetail.contentFour" align="justify"></p>
        <p>&nbsp;</p>
        <p class="single-entry-text mb-3" v-html="SectionData.PrivacyDetail.title" align="justify"></p>
        <p>&nbsp;</p>
        <p class="single-entry-text mb-3" v-html="SectionData.PrivacyDetail.contentFive" align="justify"></p>

        <div class="gap-2x"></div>
      </div>
      <!-- end single-entry -->
    </div>
    <!-- .container -->
  </section>
  <!-- end blog-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import HeroFour from '@/components/section/HeroFour.vue';

export default {
  name: 'PrivacyDetails',
  components: {},
  data() {
    return {
      SectionData
    };
  }
};
</script>
