
// Import component data. You can change the data in the store to reflect in all component
import { directive } from 'vue-tippy'
import ArtworkChildsContainer from "@/components/section/ArtworkChildsContainer.vue";
import {useUserAuth} from "@/composable/user/user";
import {useRouter} from "vue-router";
import {useArtwork} from "@/composable/artwork/artwork";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import useArtworkImage from "@/composable/useArtworkImage";
import {isSSR} from "@/helpers";
import {computed, onMounted} from "vue";
import Swal from "sweetalert2";

export default {
  name: "ArtworkDetailParent",
  directives: {
    tippy: directive,
  },
  components: {
    ArtworkChildsContainer,
  },
  setup() {
    const { currentUser } = useUserAuth();
    const router = useRouter();
    const { currentArtwork, currentArtworkSell, currentAuction, markAsFavourite, unmarkAsFavourite } = useArtwork();
    const { t } = useI18n();
    const store = useStore();

    const { detailVerticalImageUri } = useArtworkImage(currentArtwork.value);

    const message = t('trans.share.text');
    let path = 'https://auth4art.com'; //No permite -> $t('trans.share');
    if (!isSSR()) {
      path += window.location.pathname;
    }
    const onCopy = (e) => {
      let target = e.trigger.querySelector('.tooltip-text');
      let prevText = target.innerHTML;
      target.innerHTML = t('trans.share.copied');
      setTimeout(function () {
        target.innerHTML = prevText;
      }, 1000);
    };

    const date = computed((): string => {
      if (currentArtwork.value.created_at) {
        return new Date(currentArtwork.value.created_at).toLocaleDateString();
      } else {
        return '';
      }
    });

    const mintDate = computed((): string => {
      if (currentArtwork.value.mint_date) {
        return new Date(currentArtwork.value.mint_date).toLocaleDateString();
      } else {
        return '';
      }
    });

    const isArtworkMine = computed(() => {
      const userArtworks = store.getters['artwork/getUserArtworks'];
      return userArtworks.find((element) => element.hash === currentArtwork.value.hash) !== undefined;
    });

    onMounted(async () => {
      await store.dispatch('artwork/setUserArtworks');
    });

    const loginAlert = () => {
      Swal.fire({
        title: t('trans.alert.login.required.title'),
        text: t('trans.alert.login.required.text'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('trans.alert.login.required.continue'),
        cancelButtonText: t('trans.alert.login.required.cancel'),
        showCloseButton: true
      }).then((result) => {
        if (result.value) {
          router.push({ name: t('routes.login.name') });
        }
      });
    };

    return {
      currentArtwork,
      currentArtworkSell,
      currentAuction,
      currentUser,
      message,
      path,
      onCopy,
      date,
      mintDate,
      markAsFavourite,
      unmarkAsFavourite,
      detailVerticalImageUri,
      loginAlert,
      isArtworkMine
    };
  }
};
