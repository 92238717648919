import Web3Service from '@/core/services/Web3Service';
import { Artwork } from '@/types/interfaces/global/ArtworkInterface';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import axios from '@/plugins/axios';
import { PurchaseCryptoFormState } from '@/composable/marketplace/marketplaceCrypto';
import { ArtworkSell, Auction } from '@/types/interfaces/global/MarketInterface';
import { useRouter } from 'vue-router';
import { purchaseAuctionCryptoFormState } from '@/composable/marketplace/marketplaceAuctionCrypto';

export function useWeb3() {
  const { t } = useI18n();
  const router = useRouter();

  function errorPurchase(text?: string) {
    if (text === undefined) {
      text = 'Se produjo un error al realizar la compra, inténtalo de nuevo mas tarde';
    }
    Swal.fire({
      title: t('Error'),
      text: t(text),
      icon: 'error',
      confirmButtonText: t('trans.alert.continue'),
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-outline-dark mx-2'
      }
    }).then(function () {
      router.go(0);
    });
  }

  const initMetamask = async () => {
    //TODO
  };

  const getJSONMarket = async (data, callback) => {
    const request = new XMLHttpRequest();
    request.open('GET', data, true);

    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        // Success!
        const data = JSON.parse(this.response);
        callback(data);
      } else {
        // We reached our target server, but it returned an error
      }
    };

    request.onerror = function () {
      // There was a connection error of some sort
    };

    request.send();
  };

  const purchaseListedNFT = async (
    artwork: Artwork,
    artworkSell: ArtworkSell,
    purchaseCryptoFormState: PurchaseCryptoFormState
  ) => {
    console.log('purchaseListedNFT');

    const web3 = await Web3Service.init();

    await web3.eth.getAccounts((error, result) => {
      if (error) {
        console.log(error);
      } else {
        if (result.length === 0) {
          Swal.fire({
            title: t('Error'),
            text: t('No tienes ninguna wallet activa'),
            icon: 'error',
            confirmButtonText: t('trans.alert.continue'),
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2'
            }
          });

          return;
        }

        // Dirección que tiene activa el usuario en Metamask
        const fromAddress = result[0];
        //Previous TX parameters calculation
        web3.eth
          .getTransactionCount(fromAddress)
          .then(function (nonce_nft) {
            web3.eth
              .getGasPrice()
              .then(function (gasPrice) {
                getJSONMarket('/sc/parag/abi_nft.json', function (abi) {
                  try {
                    const nft_sc = new web3.eth.Contract(abi, process.env.VUE_APP_BLOCKCHAIN_CONTRACT_MARKETPLACE);
                    const NFT_Id = artwork.latestBlockchainTrack.blockchain_token_id;

                    try {
                      nft_sc.methods
                        .itemPrice(NFT_Id)
                        .call()
                        .then(function (nftprice) {
                          const price = nftprice;

                          nft_sc.methods
                            .buyItem(NFT_Id)
                            .estimateGas({
                              nonce: web3.utils.toHex(nonce_nft),
                              gasPrice: gasPrice, //web3.utils.fromWei(gasPrice,'gwei'),
                              from: fromAddress,
                              chainId: web3.utils.toHex(80001), //56 - (Mainnet)
                              value: price //web3.utils.toWei(price.toString(), "ether")
                            })
                            .then(function (gas) {
                              const gaslimit = web3.utils.toHex(gas);
                              console.log('buyItem() - gaslimit: ' + web3.utils.toHex(gas));

                              nft_sc.methods
                                .buyItem(NFT_Id)
                                .send({
                                  nonce: web3.utils.toHex(nonce_nft),
                                  gasPrice: gasPrice, //web3.utils.fromWei(gasPrice,'gwei'),
                                  gasLimit: gaslimit,
                                  from: fromAddress,
                                  chainId: web3.utils.toHex(80001), //56 - (Mainnet)
                                  value: price //web3.utils.toWei(price.toString(), "ether")
                                })
                                .then(async function (blockchainResult) {
                                  console.log('blockchainResult', blockchainResult);
                                  console.log('fromAddress', fromAddress);

                                  try {
                                    purchaseCryptoFormState.wallet_address = fromAddress;
                                    purchaseCryptoFormState.blockchain_transaction_hash =
                                      blockchainResult.transactionHash;

                                    await axios
                                      .post(
                                        `api/v1/public/checkout/fromArtworkSellCrypto/${artworkSell.hash} `,
                                        purchaseCryptoFormState
                                      )
                                      .then(function () {
                                        Swal.fire({
                                          title: 'Compra realizada',
                                          text: '¡Muchas gracias por confiar en nosotros',
                                          icon: 'success',
                                          confirmButtonText: t('trans.alert.continue'),
                                          allowOutsideClick: false,
                                          showCloseButton: true,
                                          showConfirmButton: true,
                                          buttonsStyling: false,
                                          customClass: {
                                            confirmButton: 'btn btn-outline-dark mx-2'
                                          }
                                        }).then(function () {
                                          router.go(0);
                                        });
                                      });
                                  } catch (e) {
                                    errorPurchase();
                                  }
                                })
                                .catch(function (err) {
                                  console.error('ERROR: buyItem()');
                                  console.error(err);
                                  errorPurchase();
                                });
                            })
                            .catch(function (error) {
                              console.log('ERROR estimateGas() - buyItem()');
                              errorPurchase('No hay fondos suficientes para la transacción.');
                            });
                        })
                        .catch(function (err) {
                          console.error('ERROR: itemPrice()');
                          console.error(err);
                          errorPurchase();
                        });
                    } catch (error) {
                      console.log('catchBuyForitem ', error);
                      errorPurchase();
                    }
                  } catch (e) {
                    errorPurchase();
                    console.log('catchContract', e);
                  }
                });
              })
              .catch(function (error) {
                console.log('getGasPrice() - catch error');
                console.log(error);
                errorPurchase();
              });
          })
          .catch(function (error) {
            this.loading = false;
            console.log('getTransactionCount() - catch error');
            console.log(error);
            errorPurchase();
          });
      }
    });
  };

  const bidToAuctionNFT = async (
    artwork: Artwork,
    auction: Auction,
    purchaseAuctionCryptoFormState: purchaseAuctionCryptoFormState
  ) => {
    const web3 = await Web3Service.init();

    await web3.eth.getAccounts((error, result) => {
      if (error) {
        console.log(error);
      } else {
        if (result.length === 0) {
          Swal.fire({
            title: t('Error'),
            text: t('No tienes ninguna wallet activa'),
            icon: 'error',
            confirmButtonText: t('trans.alert.continue'),
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2'
            }
          });

          return;
        }

        const price = purchaseAuctionCryptoFormState.bid_offer * 10 ** 18;
        // Dirección que tiene activa el usuario en Metamask
        const fromAddress = result[0];
        // console.log('price', price);
        //Previous TX parameters calculation
        web3.eth
          .getTransactionCount(fromAddress)
          .then(function (nonce_nft) {
            web3.eth
              .getGasPrice()
              .then(function (gasPrice) {
                getJSONMarket('/sc/parag/abi_auction.json', function (abi) {
                  try {
                    const nft_sc = new web3.eth.Contract(abi, process.env.VUE_APP_BLOCKCHAIN_CONTRACT_AUCTION);
                    const NFT_Id = artwork.latestBlockchainTrack.blockchain_token_id;

                    try {
                      nft_sc.methods
                        .bidForItem(NFT_Id)
                        .estimateGas({
                          nonce: web3.utils.toHex(nonce_nft),
                          gasPrice: gasPrice, //web3.utils.fromWei(gasPrice,'gwei'),
                          from: fromAddress,
                          chainId: web3.utils.toHex(80001), //56 - (Mainnet)
                          value: price //web3.utils.toWei(price.toString(), "ether")
                        })
                        .then(function (gas) {
                          const gaslimit = web3.utils.toHex(gas);
                          // console.log('buyItem() - gaslimit: ' + web3.utils.toHex(gas));

                          nft_sc.methods
                            .bidForItem(NFT_Id)
                            .send({
                              nonce: web3.utils.toHex(nonce_nft),
                              gasPrice: gasPrice, //web3.utils.fromWei(gasPrice,'gwei'),
                              gasLimit: gaslimit,
                              from: fromAddress,
                              chainId: web3.utils.toHex(80001), //56 - (Mainnet)
                              value: price //web3.utils.toWei(price.toString(), "ether")
                            })
                            .then(async function (blockchainResult) {
                              // console.log('buyItem() - resultado:');
                              try {
                                purchaseAuctionCryptoFormState.wallet_address = fromAddress;
                                purchaseAuctionCryptoFormState.blockchain_transaction_hash =
                                  blockchainResult.transactionHash;

                                await axios
                                  .post(
                                    `api/v1/public/auction/placeBidCrypto/${auction.hash} `,
                                    purchaseAuctionCryptoFormState
                                  )
                                  .then(function () {
                                    Swal.fire({
                                      title: 'Puja realizada',
                                      text: '¡Muchas gracias por confiar en nosotros',
                                      icon: 'success',
                                      confirmButtonText: t('trans.alert.continue'),
                                      allowOutsideClick: false,
                                      showCloseButton: true,
                                      showConfirmButton: true,
                                      buttonsStyling: false,
                                      customClass: {
                                        confirmButton: 'btn btn-outline-dark mx-2'
                                      }
                                    }).then(function () {
                                      router.go(0);
                                    });
                                  });
                              } catch (e) {
                                errorPurchase();
                              }
                            })
                            .catch(function (err) {
                              errorPurchase();
                            });
                        })
                        .catch(function (err) {
                          // console.error('ERROR: itemPrice()');
                          // console.error(err);
                          errorPurchase();
                        });
                    } catch (error) {
                      // console.log('catchBuyForitem ', error);
                      errorPurchase();
                    }
                  } catch (e) {
                    errorPurchase();
                    // console.log('catchContract', e);
                  }
                });
              })
              .catch(function (error) {
                // console.log('getGasPrice() - catch error');
                // console.log(error);
                errorPurchase();
              });
          })
          .catch(function (error) {
            this.loading = false;
            // console.log('getTransactionCount() - catch error');
            // console.log(error);
            errorPurchase();
          });
      }
    });
  };

  return {
    initMetamask,
    purchaseListedNFT,
    bidToAuctionNFT
  };
}
