<template>
  <div class="col-lg-9 ps-xl-5">
    <div class="user-panel-title-box">
      <h3>{{ $t('dashboardTab.academy') }}</h3>
    </div>
    <div class="row g-gs mt-4">
      <div v-for="video in videos" :key="video.id" class="col-lg-4 col-md-6">
        <CardVideo
          @clickVideo="openVideo"
          :title="video.title"
          :description="video.description"
          :vimeo-id="video.id"
          :vimeo-hash="video.hash"
        />
      </div>
      <VideoModal v-if="showModal" :vimeo-data="videoData" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CardVideo from '@/components/common/CardVideo';
import VideoModal from '@/components/common/VideoModal.vue';
import useVimeoModal from '@/composable/useVimeoModal';

export default {
  name: 'DashboardAcademySection',
  components: {
    CardVideo,
    VideoModal
  },
  setup() {
    const videos = ref([
      {
        title: 'Presentación de Andrés',
        description:
          'Te damos la bienvenida presentándote a tu asistente virtual. Estará a tú disposición cuando lo necesites.',
        id: 779678729,
        hash: '1a0c762db6'
      },
      {
        title: 'Qué es una Wallet',
        description: 'Antes de empezar te explicamos de manera muy sencilla lo que es una wallet',
        id: 786347950,
        hash: 'a58757ce74'
      },
      {
        title: 'Instalar una Wallet',
        description:
          'Ha llegado el momento de instalar tu wallet. Es la base para poder trabajar en Blockchain. Te acompañamos paso a paso.',
        id: 779181713,
        hash: 'b0f388ddc8'
      },
      {
        title: 'Crear una cuenta en la Wallet',
        description:
          'Te enseñamos cómo crear una cuenta dentro de tu Wallet. Es necesario para poder trabajar con activos digitales.',
        id: 779181669,
        hash: 'f0aadc1b05'
      },
      {
        title: 'Añadir red de Blockchain',
        description: 'Añadimos Polygon a nuestra Wallet para poder operar en AUTH4ART',
        id: 779181639,
        hash: '1265229d1a'
      },
      {
        title: 'Comprar MATICs',
        description:
          'Para comprar NFTs y pagar con cypto monedas debemos comprar MATICs. Aquí vemos como se realiza todo el proceso.',
        id: 779682125,
        hash: 'feb59adec0'
      }
    ]);

    const { videoData, showModal, openVideo } = useVimeoModal();

    return {
      videos,
      openVideo,
      showModal,
      videoData
    };
  }
};
</script>
