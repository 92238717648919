<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
      <!-- hero -->
      <HeaderBreadcrumbs
          classname="hero-title"
          :title="$t('trans.breadcrumbs.titles.martelive')"
          :breadcrumb-items="[
					{
						title: $t('trans.breadcrumbs.martelive'),
					}
				]"
      ></HeaderBreadcrumbs>
    </header>
    <!-- Blog  -->
    <section class="section-space-b blog-section">
      <div class="container">
        <!-- blog section -->
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="single-entry mb-5 mb-lg-0">
              <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded mb-3" alt="">
              <div class="row g-gs mb-4">
                <div class="col-sm-6">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
                <div class="col-sm-6">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
              </div><!-- end row -->
              <div class="alert alert-light border-dark fs-15" role="alert">
                <p>
                  Auth4Art, partner del concurso europeo MarteLive
                  Europe con la semifinal
                  en Polonia que tendrá lugar en el castillo Krasiczyn en el Sur de Polonia el 10 septembre a las 18.00.
                  Con el apoyo del Programa Europa Creativa de la Unión Europea, MArteLive está abierto a una amplia
                  gama de artistas de entre 18 y 35 años: sus 16 categorías diferentes van desde la pintura y el teatro
                  hasta el arte callejero y la artesanía. Los artistas seleccionados durante las semifinales serán los
                  protagonistas del gran espectáculo final que se celebrará en Roma, del 18 al 23 de octubre de
                  2022.
                </p>
              </div>
              <p class="single-entry-text mb-3">
                Después de un año y medio de investigación de los mejores talentos de toda Europa, las selecciones de
                MArteLive finalmente están a punto de terminar. Han reunido a más de 1100 artistas de más de 40 países
                que han participado en las audiciones online y ahora es el momento del show en vivo. Los 48
                semifinalistas europeos seleccionados en el área "East-West Connection" de MArteLive fueron anunciados
                en los últimos días y participarán en la semifinal europea prevista para el 10 de septiembre en el
                castillo de Krasiczyn en Polonia del sur a la hora 18.00.
              </p>
              <div class="row g-gs mb-4">
                <div class="col-sm-4">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
                <div class="col-sm-4">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
                <div class="col-sm-4">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
              </div><!-- end row -->
              <div class="alert alert-light border-dark fs-15" role="alert">
                <p>
                  Algunos artistas de la capital española: Fumomiles i Soen Bravo (Streetart) y Cmondeja, artista de
                  origenes cubanas, de Madrid (Digital Illustration).
                </p>
              </div>
              <p class="single-entry-text mb-3">
                Fundacion de Vargas y Auth4Art, que son los partner del festival, se enorgullecen de anunciar que en
                total 10 artistas están representando País España en la semifinal en Polonia dentro de los cuales:
                Valentina Joiu, pintora de Madrid, Daniela Ariza, fotografa de Barcelona, Soen Bravo y Fumomiles, dos
                street artistas de Madrid, Isla Kume, la musico madrileña, due dj: Natalie Blüme, de Sevilla y Manawa,
                La Colectiva Imaginaria, un grupo de teatro de Valencia, Cmondeja de Madrid y Sensetus de Sevilla, dos
                digital illustradores. Los artistas de las artes visuales, no solo españoles, están bajo nuestro
                patrocinio.
              </p>
              <div class="row g-gs mb-4">
                <div class="col-sm-4">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
                <div class="col-sm-4">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
                <div class="col-sm-4">
                  <img src="@/images/thumb/auth4art_logo_home.png" class="w-100 rounded" alt="">
                </div>
              </div><!-- end row -->
              <div class="alert alert-light border-dark fs-15" role="alert">
                <p>
                  Las tres semifinalistas de MArteLive Europe competición europea en Pintura Y Dibujo: Valentina Joiu,
                  pintora de origen rumano de Madrid, estudiante de la Academia de Pintura Cristian Avilés, Claire de
                  Malta y Mrkok de Chipre, que sostituyen las dos semifinalistas anteriores que no pudieron asistir al
                  evento, Zlata Shyshman de Ucraina y Laurent Dumortier (Bélgica).
                </p>
              </div>
              <blockquote class="blockquote">
                <p>
                  Pablo de Vargas, el director de Fundación de Vargas y Auth4Art hace parte del jurado en categoria
                  “Painting and Drawing” junto a sus famosos colegas artistas dentro de los cuales: Emiliano Donaggio
                  (Italia), Katarzyna Bator y Marta Mezynska (Polonia), Ron Hicks (Usa) y Cristian Avilés de Academia de
                  Pintura Cristian Avilés de Madrid (España). Ellos elegirán sólo un pintor, entre Valentina Joiu,
                  Claire (Malta) y MrKok (Chipre), que recibirá un premio en efectivo y la oportunidad de volar a Roma
                  para la final, Bienal MArteLive del 18 al 23 de octubre, que tendrá lugar en 60 sitios diferentes en
                  la Ciudad Eterna, con una audiencia esperada de más de 50,000 personas.
                </p>
              </blockquote>
              <p class="mb-2 fs-8 text-center fw-bold">¡Que ganen los mejores!</p>
            </div><!-- end single-entry -->
          </div><!-- end col-lg-8 -->
        </div><!-- row -->
      </div><!-- .container -->
    </section><!-- end blog-section -->
    <Footer classname="bg-dark on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
export default {
  name: 'MarteLiveLanding',
  data() {
    return {

    }
  },
}
</script>
