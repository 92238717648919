import routes from './routes-es';

const es = {
  routes,
  auctionslist: {
    by: 'De',
    price: 'Salida',
    currentBid: 'Mayor puja',
    noBids: '0',
    cryptobid: 'Pujar en MATIC',
    eurobid: 'Pujar en €',
    public: 'Público',
    private: 'Privado',
    viewDetail: 'Ver detalle'
  },
  marketplace: {
    modal: {
      selectCurrency: 'Elige moneda',
      price: 'Precio',
      purchase: 'Comprar'
    }
  },
  obra: {
    notapuja: 'Nota: Las pujas no se pueden retirar.'
  },
  auth: {
    createAccount: 'Crear una cuenta',
    newHere: '¿Eres nuevo?',
    userAccount: 'Cuenta de usuario <strong>%{valor1}</strong> y clave <strong>demo</strong> para entrar.',
    Email: 'Email',
    Password: 'Contraseña',
    forgotPassword: '¿Olvidaste la contraseña?',
    Continue: 'Continuar',
    or: 'O',
    pleaseWait: ' Por favor espera...',
    continueWithPlat: 'Entrar con %{plat}',
    ChooseAccountType: 'Elija el tipo de cuenta',
    NeedMoreInfo: 'Si necesitass más información, consulta',
    AlreadyAccount: '¿Ya tienes una cuenta?',
    SignInHere: 'Entra aquí',
    FirstName: 'Nombre',
    LastName: 'Apellido',
    ConfirmPassword: 'Confirmar contraseña',
    UseNumbersSymbols: 'Utilice 8 o más caracteres con una combinación de letras, números y símbolos',
    IAgreeConditions: 'Acepto los términos y condiciones',
    Submit: 'Entregar',
    NameRequired: 'El nombre es un campo obligatorio',
    SurnameRequired: 'El apellido es un campo obligatorio',
    EmailRequired: 'El correo electrónico es un campo obligatorio',
    PasswordRequired: 'La contraseña es un campo requerido',
    PasswordConfirmationRequired: 'La confirmación de la contraseña es un campo obligatorio',
    EmailBeValid: 'El correo electrónico debe ser un correo electrónico válido',
    PasswordsMustMatch: 'Las contraseñas deben coincidir',
    PasswordLeastCharacters: 'La contraseña debe tener al menos 4 caracteres',
    CredentialsIncorrect: 'Las credenciales proporcionadas son incorrectas',
    TryAgain: '¡Intentar otra vez!',
    SuccessfullyLogged: '¡Has iniciado sesión correctamente!',
    GotIt: '¡Ok lo tengo!',
    ForgotPassword: '¿Has olvidado tu contraseña?',
    ResetPassword: 'Ingrese su correo electrónico para restablecer su contraseña',
    Cancel: 'Cancelar',
    LogoReg: 'media/logos/logo-reg.svg',
    FillUp: 'Rellena the below fields to create an account'
  },
  navbar: {
    home: 'Inicio',
    metaExpos: 'Virtual META',
    artworks: 'Obras',
    artists: 'Artistas',
    collections: 'Colecciones',
    marketplace: 'Marketplace',
    services: 'Servicios'
  },
  userDropdown: {
    profile: 'Perfil',
    auctions: 'Pujas',
    dashboard: 'Escritorio',
    favourites: 'Favoritos',
    artworks: 'Obras',
    payment: 'Datos de pago',
    transactions: 'Transacciones'
  },
  dashboardTab: {
    account: 'Cuenta',
    auctions: 'Pujas',
    dashboard: 'Escritorio',
    favourites: 'Favoritos',
    artworks: 'Obras',
    transactions: 'Transacciones',
    payments: 'Datos de pago',
    academy: 'Recursos'
  },
  footer: {
    titles: {
      for_sell: 'En venta',
      artists: 'Artistas',
      about_us: 'Nosotros'
    },
    contact: 'Contacto',
    terms: 'Términos',
    privacy: 'Privacidad',
    auctions: {
      fiat: 'Subastas',
      crypto: 'Subastas Crypto'
    },
    marketplace: 'Marketplace',
    artists: 'Artistas',
    collections: 'Colecciones',
    services: 'Servicios'
  },
  pagination: {
    count: "Mostrando {'{from}'} hasta {'{to}'} de {'{count}'} registros{'|'}{'{count}'} registros{'|'}Un registro",
    first: 'Primero',
    last: 'Último'
  },
  filters: {
    order: {
      name: 'Orden Alfabético',
      price_asc: 'Más baratas',
      price_desc: 'Más caras',
      date_asc: 'Más antiguo',
      date_desc: 'Más reciente'
    },
    option: {
      physical: 'Obra física',
      nft: 'NFT',
      all: 'Todos',
      crypto: 'Crypto',
      euro: '€',
      auction: 'subasta',
      fixed: 'fijo'
    },
    searchBy: 'Buscar por {attr}'
  },
  bids: {
    titleModal: 'Realizar una Puja',
    contentModal:
      'Está a punto de hacer una oferta de la obra <strong>{artworkName}</strong> del artista <strong>{artistName}</strong>',
    yourOffer: 'Tu oferta ({currency})',
    userBalance: 'Saldo de usuario',
    expired: 'Esta subasta ya ha terminado',
    bidFinished: 'Se ha realizado la puja',
    bidInfo: {
      price: 'Oferta',
      comission: 'Comision del servicio {percentage}%',
      total: 'Total a abonar',
      remaining: 'Faltan',
      over: 'Sobrepuja de',
      priceLastBid: 'Precio última puja',
      minimumBid: 'Precio puja mínima'
    },
    bid: 'Realizar Puja',
    doingBid: 'Realizando Puja',
    outbid: 'Te han superado la puja, se ha actualizado el precio',
    noUser: 'Tienes que iniciar sesión para realizar una puja',
    insufficientFunds: 'Fondos insuficientes',
    cryptoError: 'Error al comunicarse con el servidor de blockchain'
  },
  siginto: 'Entrar a',
  dashboard: 'Tablero',
  layoutBuilder: 'Constructor de maquetación',
  craft: 'Elaborado',
  pages: 'Paginas',
  profile: 'Perfil',
  profileOverview: 'Descripción general',
  projects: 'Proyectos',
  campaigns: 'Campañas',
  documents: 'Documentos',
  connections: 'Conexiones',
  wizards: 'Magos',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Cuenta',
  accountOverview: 'Descripción general',
  settings: 'Ajustes',
  authentication: 'Autenticación',
  basicFlow: 'Flujo básico',
  signIn: 'Iniciar sesión',
  signUp: 'Registrarse',
  passwordReset: 'Restablecimiento de contraseña',
  multiStepSignUp: 'Regístrese Multi-Pasos',
  error404: 'Error 404',
  error500: 'Error 500',
  apps: 'Aplicaciones',
  chat: 'Chat',
  privateChat: 'Chat privado',
  groupChat: 'Grupo de chat',
  drawerChat: 'Chat del cajón',
  widgets: 'Widgets',
  widgetsLists: 'Liza',
  widgetsStatistics: 'Estadísticas',
  widgetsCharts: 'Gráficos',
  widgetsMixed: 'Mezclada',
  widgetsTables: 'Mesas',
  widgetsFeeds: 'Alimenta',
  changelog: 'Registro de cambios',
  docsAndComponents: 'Documentos & Componentes',
  megaMenu: 'Mega menú',
  exampleLink: 'Enlace de ejemplo',
  modals: 'Modales',
  general: 'General',
  inviteFriends: 'Invitar A Amigos',
  viewUsers: 'Ver Usuarios',
  upgradePlan: 'Plan De Actualización',
  shareAndEarn: 'Compartir Y Ganar',
  forms: 'Formas',
  newTarget: 'Nuevo Objetivo',
  newCard: 'Nueva Tarjeta',
  newAddress: 'Nueva Direccion',
  createAPIKey: 'Crea Clave De Api',
  twoFactorAuth: 'Dos Factores',
  createApp: 'Crear Aplicacion',
  createAccount: 'Crear Una Cuenta',
  activity: 'Actividad',
  documentation: 'Documentación',
  components: 'Componentes',
  resources: 'Recursos',
  customers: 'Clientes',
  gettingStarted: 'Empezando',
  customersListing: 'Listado De Clientes',
  customerDetails: 'Detalles De Los Clientes',
  calendarApp: 'Calendario',
  subscriptions: 'Suscripciones',
  getStarted: 'Empezando',
  subscriptionList: 'Lista De Suscripción',
  addSubscription: 'Añadir Suscripción',
  viewSubscription: 'Suscripción',
  auction: 'Subasta',
  beggining: 'Inicio',
  hello: 'Hola',
  hours: 'Horas',
  days: 'Días',
  minutes: 'Minutos',
  seconds: 'Segundos',
  seller: 'Vendedor',
  signed: 'Firmado',

  trans: {
    copy_link: 'Copiar enlace',
    nft: 'NFT',
    purchase: 'Comprar',
    subasta_matic: 'Subasta en MATIC',
    fijo_euro: 'Venta directa en €',
    fijo_matic: 'Venta directa en MATIC',
    subasta_euro: 'Subasta en €',
    fijo_mixto: 'Venta directa mixta',
    edicion: 'Edición',
    showDetails: 'Ver',
    artworks: 'Obras',
    artists: 'Artistas',
    profile: {
      fiatPaymentMethodEmpty: 'Necesitas un método de pago válido',
      fiatPaymentMethodCryptoEmpty: 'Necesitas una Wallet válida',
      orderPublicsEmpty: 'Aún no tienes transacciones',
      emailConfirmation: 'No has confirmado tu email',
      my_bid: 'Mi puja',
      last_bid: 'Última puja',
      noAuctions: 'No tienes pujas activas',
      noAuctionsbt: 'Ver obras',
      noArtworks: 'No tienes ninguna obra en propiedad',
      noArtworksbt: 'Ver obras',
      noFavourites: 'Ninguna obra marcada como favorita',
      noFavouritesbt: 'Ver obras',
      resendEmail: 'Renviar correo',
      title: 'Perfil',
      nav_options: {
        edit_info: 'Editar información',
        password: 'Contraseña'
      },
      edit: {
        title: 'Editar pefil',
        email: 'Email principal',
        name: 'Nombre',
        surname: 'Apellidos',
        phone: 'Teléfono',
        contact_email: 'Email de contacto',
        personalInformationButton: 'Actualizar datos',
        old_password: 'Contraseña actual',
        new_password: 'Contraseña nueva',
        new_password_confirm: 'Confirma la contraseña nueva',
        changePasswordButton: 'Actualizar contraseña'
      },
      payments: {
        nav_bar: {
          payment_methods: 'Métodos de pago',
          billing_details: 'Datos de facturación',
          shipping_addresses: 'Direcciones de envío'
        },
        add_payment_method: 'Añadir método de pago',
        default: 'Predeterminado',
        credit_card: 'Tarjeta',
        use_as_default: 'Usar por defecto',
        delete: 'Eliminar',
        fiat: {
          title: 'Métodos Fiat',
          modal: {
            addTitle: 'Añadir tarjeta'
          }
        },
        crypto: {
          title: 'Métodos Crypto',
          modal: {
            title: 'Mi Wallet',
            active: 'Activa',
            not_connected: 'Necesitamos que conectes tu cuenta de Metamask',
            button_connect: 'Conectar'
          }
        },
        details: {
          title: 'Direcciones de facturación',
          billing_address: 'Dirección de facturación',
          select_one: 'Selecciona uno',
          company_name: 'Nombre de la empresa',
          freelance: 'Freelance',
          name: 'Nombre',
          surname: 'Apellidos',
          nif: 'NIF',
          phone: 'Teléfono de contacto',
          country: 'País',
          region: 'Provincia',
          postal_code: 'Código Postal',
          address: 'Dirección',
          city: 'Ciudad',
          descriptive_name: 'Nombre descriptivo',
          use_address_default: '¿Usar como dirección por defecto?',
          use_address_default_info: 'Seleccionaremos esta dirección de forma predeterminada en tus compras',
          save: 'Guardar',
          edit: 'Editar',
          add_new_address: 'Añadir Dirección de facturación',
          alert: {
            create_success_title: 'Éxito',
            create_success_description: 'Dirección de facturación añadida',
            edit_success_title: 'Éxito',
            edit_success_description: 'Dirección de facturación editada',
            confirm_delete_title: 'Eliminar',
            confirm_delete_description: '¿Estás seguro que quieres eliminar la dirección de facturación?',
            confirm_delete_button_yes: 'Sí, quiero eliminarla',
            confirm_delete_button_no: 'No',
            error_title: 'Error',
            error_description: 'Ha habido un error al guardar los datos',
            understood: 'Entendido'
          }
        }
      },
      required: {
        billingAddress_business_name: 'Por favor, introduce el nombre de la sociedad',
        billingAddress_name: 'Por favor, indica tu nombre',
        billingAddress_surname: 'Por favor, introduce tus apellidos',
        billingAddress_address: 'Por favor, introduce una dirección',
        billingAddress_nif: 'Por favor, indica un documento legal',
        billingAddress_phone: 'Por favor, un teléfono de contacto',
        billingAddress_country: 'Por favor, selecciona un país',
        billingAddress_region: 'Por favor, selecciona una región',
        billingAddress_city: 'Por favor, introduce una ciudad',
        billingAddress_cp: 'Por favor, introduce un código postal',
        billingAddress_type: 'Por favor, selecciona una opción'
      },
      regex: {
        phone: 'Por favor, introduce un teléfono válido',
        shippingAddress_cp: 'Por favor, introduce un código postal válido',
        billingAddress_nif: 'Por favor, introduce un documento válido',
        billingAddress_cp: 'Por favor, introduce un código postal válido'
      },
      email: {
        contact_email: 'Por favor, introduce un correo válido'
      },
      test: {
        shippingAddress_cp: 'El código postal no coincide con la provincia',
        billingAddress_cp: 'El código postal no coincide con la provincia'
      },
      transactions: {
        table: {
          order_number: 'Nº Pedido',
          artwork: 'Obra',
          date: 'Fecha',
          type: 'Tipo',
          price: 'Precio',
          actions: 'Acciones'
        },
        modal: {
          title: 'Solicitud de factura',
          content: 'Déjanos tu email y te enviaremos la factura de tu pedido a la mayor brevedad posible.',
          email: 'Email',
          message: 'Opcionalmente, escribe aquí tu mensaje...',
          button: 'Enviar'
        }
      }
    },
    artwork: {
      auction: {
        starts_at: 'Salida',
        max_bid: 'Puja máx.'
      },
      sell: {
        euro_price: 'Precio euro',
        matic_price: 'Precio matic'
      },
      showDetails: 'Ver',
      childs: {
        section: {
          title: 'Edición',
          content: ' Esta edición se compone de una serie de {number} elementos',
          list: ' Esta edición se compone de una serie de <b>{number}</b> elementos'
        }
      },
      for_sale: {
        title: 'En venta',
        not_for_sale: 'Actualmente esta obra no está en venta.',
        sell: {
          title: 'Este NFT está en venta directa con los siguientes precios:',
          modal: {
            title: 'Opciones de compra',
            content_euro: 'Los NFTs comprados se transferirán a tu wallet privada',
            content_matic: 'Los NFTs comprados se transferirán a tu Metamask'
          }
        },

        auction: {
          countdown: {
            ends_in: 'La subasta termina en',
            starts_in: 'La subasta empieza en',
            initial_price: 'Precio Inicial:',
            last_bid: 'Última puja:'
          }
        }
      },
      properties: {
        title: 'Propiedades',
        author: 'Autor',
        certificate: 'Certificado',
        date: 'Fecha',
        edition: 'Edición',
        of: 'de'
      },
      authenticity: {
        title: 'Autenticidad',
        safety: 'Seguridad para obras físicas.',
        transaction: {
          title: 'Transacción',
          modal: {
            title: 'Arte digital Autenticado',
            content: 'Hash único de la creación',
            link: 'Transación de creación única'
          }
        },
        blockchain: {
          title: 'Blockchain',
          modal: {
            title: 'Blockchain',
            coin: 'Moneda',
            nft_details: 'DETALLES NFT',
            contract_address: 'Dirección del contrato',
            nft_id: 'Id del NFT',
            token_standard: 'Token standard'
          }
        },
        certificate: {
          title: 'Certificado',
          modal: {
            title: 'Certificado',
            content: 'Auth4Art Certifica esta obra a través de TrustOS Cert',
            link: 'Ver certificado de autenticidad de la obra'
          }
        },
        security: 'Seguridad para obras físicas.',
        find_out_more: 'Saber más.'
      },
      options: {
        title: 'Opciones',
        publicArtwork: 'Obra pública',
        editArtwork: 'Editar'
      }
    },

    alert: {
      success: {
        title: '¡Hecho!',
        message: 'Acción realizada con éxito'
      },
      error: {
        title: '¡Oops!',
        message: 'Ha habido un error'
      },
      info: {
        notfound: {
          title: '¡Oops!',
          message: 'No hemos encontrado lo que buscabas'
        }
      },
      continue: 'Continuar',
      cancel: 'Cancelar',
      action: 'Vas a realizar un cambio',
      notReversible: 'Esta accion es irreversible',
      canceled: 'Acción cancelada',
      noAction: 'No ha habido cambios',
      login: {
        required: {
          title: 'Inicio de sesión necesario',
          text: 'Para poder continuar necesitamos auténticarte dentro de la plataforma',
          continue: 'Ir a iniciar sesión',
          cancel: 'Cancelar'
        }
      },
      email_confirm: {
        required: {
          title: 'Confirmación de correo necesaria',
          text: 'Para poder continuar necesitamos que confirmes tu correo electrónico',
          continue: 'Ir a mi perfil',
          cancel: 'Cancelar'
        }
      },
      metamask: {
        title: '¡Oops!',
        content: 'Necesitamos que inicies sesión en Metamask',
        confirm_button: 'No tengo Metamask',
        cancel_button: 'Cerrar'
      },
      confirm_purchase: {
        title: 'Confirmación',
        message: '¿Quiere proceder con la compra?'
      },
      correct_purchase: {
        title: 'Compra correcta',
        message: 'Proceso de compra completado'
      },
      error_purchase: {
        title: 'Error en la compra',
        message: 'Ha habido un error en el proceso de compra'
      },
      cancelled_purchase: {
        title: 'Cancelado',
        message: 'Compra cancelada'
      },
      payment_method_error: {
        title: '¡Oops!',
        message: 'Ha habido un error en la creación del método de pago'
      },
      payment_method_success: {
        title: '¡Acción realizada!',
        message: 'Hemos guardado correctamente tu método de pago'
      },
      payment_method_default: {
        title: 'Método de pago establecido por defecto',
        message: 'Hemos establecido por defecto tu método de pago'
      },
      payment_method_confirm_delete: {
        title: 'Eliminar método de pago',
        message: '¿Estás seguro de que quieres eliminar el método de pago?'
      },
      payment_method_delete: {
        title: 'Método de pago eliminado',
        message: 'El método de pago se ha eliminado correctamente?'
      },
      favourites_added: {
        title: '¡Acción realizada!',
        message: 'Hemos añadido esta obra a tus favoritas'
      },
      favourites_removed: {
        title: '¡Acción realizada!',
        message: 'Hemos quitado esta obra de tus favoritas'
      },
      coming_soon: {
        title: 'Próximamente',
        message: 'En los próximos días activaremos esta funcionalidad'
      },
      message_sent: {
        title: 'Mensaje enviado',
        message: 'Mensaje enviado correctamente, le responderemos a la mayor brevedad'
      },
      message_error: {
        title: '¡Oops!',
        message: 'Error en el envío del mensaje, vuelva a intentarlo'
      },
      default_loading: {
        title: 'Cargando...!',
        message: 'Esta acción puede durar unos segundos. Por favor, espere.'
      },
      default_error: {
        title: 'Oops!',
        message: 'Ha habido un error, inténtalo de nuevo más tarde'
      },
      setIsPublic: {
        title: 'Cambiar visibilidad',
        message: '¿Quiere cambiar la visibilidad de su obra?',
        continue: 'Continuar',
        cancel: 'Cancelar'
      }
    },
    breadcrumbs: {
      titles: {
        base: 'Auth4Art',
        artist_detail: 'Ficha Artista',
        artwork_detail: 'Detalle obra',
        contact: 'Contacta con nosotros',
        legal: 'Aviso Legal',
        terms: 'Terminos y condiciones',
        privacy: 'Politicas de privacidad',
        services: 'Servicios',
        martelive: 'MarteLive',
        artworks: 'Obras',
        artists: 'Artistas',
        artwork: 'Ficha de obra',
        cookies: 'Cookies'
      },
      home: 'Inicio',
      artwork: 'Obra',
      artworks: 'Obras',
      edition: 'Edición',
      artist: 'Artista',
      artists: 'Artistas',
      collection: 'Colección',
      collections: 'Colecciones',
      contact: 'Contacto',
      legal: 'Legal',
      terms: 'Términos y condiciones',
      privacy: 'Privacidad',
      services: 'Servicios',
      martelive: 'MarteLive',
      cookies: 'Cookies'
    },
    auction: {
      action: 'Pujar',
      soon: 'Próximamente'
    },
    marketplace: {
      action: 'Comprar',
      bidup: 'Pujar'
    },
    aviso_legal: 'Aviso legal',
    showMore: 'Ver más',
    share: {
      text: 'Compartir',
      copied: 'Enlace copiado'
    },
    home: {
      hero_data: {
        2: {
          title: 'Cristóbal Toral',
          content: 'Primeros NFTs creados por el famoso pintor gaditano',
          titlebt: 'Ver Autor'
        },
        3: {
          title: 'DE VARGAS',
          content: 'Presentamos los primeros NFTs creados a partir de una obra física del Artista',
          titlebt: 'Ver Autor'
        },
        4: {
          title: 'Patricia Florez',
          content: 'Arte vectorial al más puro estilo de esta excepcional madrileña',
          titlebt: 'Ver Autora'
        },
        5: {
          title: 'Patrimonio Cultural',
          content: 'Aumentamos las garantías de Autoría, Autenticidad, Pertenencia y Trazabilidad de la obra',
          titlebt: 'Ver'
        },
        6: {
          title: 'Un marketplace para todos',
          content: 'Genera y comercializa NFTs fácilmente',

          titlebt: 'Ver'
        }
      },
      highlighted_artists: {
        title: 'Artistas destacados',
        content: 'Descubre las obras digitales de nuestros mejores artistas',
        artworks: 'Obras',
        button: 'Ver artista'
      },
      highlighted_artworks: {
        title: 'Obras destacadas',
        content: 'Descubre las mejores obras de nuestros artistas'
      },
      auctions: {
        title: 'Subastas'
      },
      crypto_auctions: {
        title: 'Subastas Crypto'
      },
      marketplace: {
        title: 'Marketplace'
      },
      about: {
        title: '¿Qué es Auth4Art?',
        ecosystem: {
          nav: 'Ecosistema',
          title: 'Es un ecosistema en donde podrás:',
          list_1: 'Registrar tus obras de arte, tanto físicas como digitales, en Blockchain.',
          list_2: 'Decidir si cada obra será pública o si solo la podrás ver tú.',
          list_3: 'Generar NFTs a partir de cualquier obra y ponerlos a la venta en modo Subasta o Venta Directa.',
          list_4: 'Elegir si la venta la quieres realizar en €uros o en Cryptomonedas',
          button: 'Saber más'
        },
        our_plans: {
          nav: 'Nuestros planes',
          title: 'Conoce los planes Auth4Art',
          content: 'Descubre los planes de Auth4Art diseñados específicamente para Artistas y Gestores de Colecciones.',
          list_1: 'Free',
          list_2: 'Basic',
          list_3: 'Premium',
          button: 'Saber más'
        },
        our_devices: {
          nav: 'Nuestros dispositivos',
          title: 'Conoce los dispositivos Auth4Art',
          content: 'Los dispositivos Auth4Art están diseñados para añadir una capa más de seguridad.',
          list_1: 'SmartTag',
          list_2: 'Art Device',
          list_3: 'Art Beacon',
          button: 'Saber más'
        },
        why: {
          title: '¿Por qué Auth4Art?',
          advantages: {
            title: '¿Qué ventajas me ofrece Auth4Art?',
            content:
              'Auth4Art me asegura que mis creaciones digitales estarán bajo mi control con certificados de autenticidad añadidos a la seguridad de la blockchain'
          },
          security: {
            title: '¿Cómo asegura Auth4Art mis creaciones digitales?',
            content:
              'Los NFTs de Auth4Art llevan una capa extra de seguridad que garantiza que cualquier venta o traspaso queden registrados y validados por el autor'
          },
          ecosystem: {
            title: 'Ecosistema supervisado',
            content:
              'Tokeniza tu obra y ponla en nuestro Market. Verás todas las facilidades y seguridad que ofrecemos: A todas las ventajas de la blockchain le añadimos una capa de certificados legales en cada compra-venta.'
          },
          audit: {
            title: 'Certificamos la autoría de cualquier obra digital',
            content:
              'En Auth4Art aseguramos las versiones digitales de tus obras en todo su recorrido, en cada momento sabes todas las transacciones.'
          }
        }
      }
    },
    footer: {
      content: 'El primer marketplace digital hecho por y para artistas'
    },
    collections: {
      title: 'Colecciones',
      detail: {
        title: 'Obras de la colección',
        content: 'Esta colección se compone de {number} obras'
      }
    },
    artist: {
      list: {
        no_records: 'Opps! No hay datos',
        no_records_text: 'Actualmente no tenemos artistas disponibles'
      },
      nav_bar: {
        biography: 'Biografía',
        artworks: 'Obras',
        documentation: 'Documentación'
      },
      presentation: 'Presentación',
      links: 'Enlaces',
      disciplines: 'Disciplinas',
      documentation: {
        biography: {
          title: 'Biografía',
          content: 'Documento con información del artista'
        },
        exhibitions: {
          title: 'Exposiciones',
          content: 'Listado de Museos en los que ha participado'
        },
        images: {
          title: 'Imágenes',
          content: 'Otros archivos de imágenes del artista'
        }
      }
    },
    artworksContainer: {
      filters: {
        disciplines: {
          title: 'Disciplinas',
          painting: 'Pintura',
          sculpture: 'Escultura'
        },
        artwork_type: {
          title: 'Tipo de obra',
          nft: 'NFT',
          physical: 'Física'
        },
        sale_type: {
          title: 'Tipo de venta',
          all: 'Todos',
          auction: 'Subasta',
          auction_euro: 'Subasta Euro',
          auction_crypto: 'Subasta Crypto',
          edition: 'Edición',
          direct_sell: 'Venta directa',
          direct_sell_mixed: 'Venta directa mixta',
          direct_sell_euro: 'Venta directa Euro',
          direct_sell_crypto: 'Venta directa Crypto',
          not_for_sale: 'No a la venta'
        },
        artist: {
          title: 'Artista',
          name: 'Nombre',
          surname: 'Apellidos'
        },
        artwork: {
          title: 'Obra',
          name: 'Nombre'
        }
      },
      no_results: 'Sin resultados'
    },
    about: {
      meet_us: {
        title: 'Conócenos',
        content: 'Auth4Art, un ecosistema en donde podrás:',
        list_1: 'Registrar tus obras de arte, tanto físicas como digitales, en Blockchain.',
        list_2: 'Publicar o mantener como privado todo o parte de tus obras.',
        list_3: 'Generar NFTs a partir de cualquier obra y ponerlos a la venta en modo Subasta o Venta Directa.',
        list_4: 'Elegir si la venta la quieres realizar en €uros o en Crypto monedas.',
        list_5: 'Y mucho más.'
      },
      plans_devices: {
        title: 'Planes y dispositivos Auth4Art',
        plans: {
          title: 'Nuestros planes',
          content: 'Descubre los planes de Auth4Art diseñados específicamente para Artistas y Gestores de Colecciones.',
          content_2:
            'Con los planes <strong>Auth4Art</strong> entrarás en el mundo del arte digital sin riesgos y con una facilidad nunca vista.',
          button: 'Contratar planes',
          modal: {
            content: 'Déjanos tu email y nuestro departamento comercial contactará contigo.',
            email: 'Email',
            message: 'Opcionalmente, escribe aquí tu mensaje...',
            button: 'Enviar',
            tax_included: '(Iva incluido)'
          },
          modal_plans: {
            title_contact: 'Contrata Planes',
            subtitle_contact: 'Interesado en los Planes Auth4Art'
          },
          modal_free: {
            title: 'PLAN FREE - HASTA 20 OBRAS',
            subtitle: 'La mejor manera de comenzar a usar nuestra plataforma',
            content_1: 'Podrás registrar hasta <b>20</b> obras.',
            content_2:
              'Una vez registrado tienes la oportunidad de adquirir nuestras Security Tags y nuestros ARTDevices.',
            content_3:
              'Con este producto podrás registrar tus obras en nuestra plataforma añadiendo tanto información concreta de la obra como archivos adjuntos como pueden ser fotos, vídeos, etc. Una vez introducido toda la información será el momento de registrar toda la información en Blockchain.',
            content_4: 'Igualmente podrás especificar si la información registrada será de acceso público o privado.',
            price: 'GRATIS',
            button: 'Contratar este plan',
            title_contact: 'Contrata Plan Free',
            subtitle_contact: 'Interesado en el Plan Free'
          },
          modal_basic: {
            title: 'PLAN BASIC - HASTA 50 OBRAS',
            subtitle: 'Para nuestros clientes más exigentes.',
            content_1: 'Podrás registrar un máximo de <b>50</b> obras.',
            content_2:
              'Una vez registrado tienes la oportunidad de adquirir nuestras Security Tags y nuestros ARTDevices.',
            content_3:
              'Con este producto podrás registrar tus obras en nuestra plataforma añadiendo tanto información concreta de la obra como archivos adjuntos como pueden ser fotos, vídeos, etc. Una vez introducido toda la información será el momento de registrar toda la información en Blockchain.',
            content_4: 'Igualmente podrás especificar si la información registrada será de acceso público o privado.',
            price: '59 € / mes',
            button: 'Contratar este plan',
            title_contact: 'Contrata Plan Basic',
            subtitle_contact: 'Interesado en el Plan Basic'
          },
          modal_premium: {
            title: 'PLAN PREMIUM - HASTA 150 OBRAS',
            subtitle: 'Para nuestros clientes más exigentes.',
            content_1: 'Podrás registrar un máximo de <b>150</b> obras.',
            content_2:
              'Una vez registrado tienes la oportunidad de adquirir nuestras Security Tags y nuestros ARTDevices.',
            content_3:
              'Con este producto podrás registrar tus obras en nuestra plataforma añadiendo tanto información concreta de la obra como archivos adjuntos como pueden ser fotos, vídeos, etc. Una vez introducido toda la información será el momento de registrar toda la información en Blockchain.',
            content_4: 'Igualmente podrás especificar si la información registrada será de acceso público o privado.',
            price: '99 € / mes',
            button: 'Contratar este plan',
            title_contact: 'Contrata Plan Premium',
            subtitle_contact: 'Interesado en el Plan Premium'
          }
        },
        devices: {
          title: 'Nuestros dispositivos',
          content: 'Los dispositivos Auth4Art están diseñados para añadir una capa más de seguridad.',
          content_2:
            'Mediante el uso de nuestros dispositivos en las Obras Físicas, contarás con un factor más para garantizar la: Autoría, Autenticidad y Pertenencia de cada obra.',
          button: 'Contratar dispositivos',
          modal: {
            content: 'Déjanos tu email y nuestro departamento comercial contactará contigo.',
            email: 'Email',
            message: 'Opcionalmente, escribe aquí tu mensaje...',
            button: 'Enviar',
            tax_included: '(Iva incluido)'
          },
          modal_devices: {
            title_contact: 'Contrata Dispositivos',
            subtitle_contact: 'Interesado en los Dispositivos Auth4Art'
          },
          modal_smarttag: {
            title: 'SMART TAGS',
            subtitle: 'Registra tus obras con información concreta de la obra y documentos adjuntos.',
            content_1:
              'Cada Security TAG contiene un chip con un código único, protegido contra escritura, que garantiza la fiabilidad de los datos registrados.',
            content_2:
              'Se adhiere a tu obra quedando asociada a ella con su código único, cualquier manipulación inutiliza la cadena de validación. Los datos de registro son accesibles en todo momento con un dispositivo móvil a través de la tecnología NFC.',
            price: '60 €',
            button: 'Contratar este dispositivo',
            title_contact: 'Contrata Smart Tag',
            subtitle_contact: 'Interesado en el dispositivo Smart Tag'
          },
          modal_artdevice: {
            title: 'ART DEVICES',
            subtitle:
              'Con este dispositivo podrás realizar un seguimiento en tiempo real de la obra al que lo asignes.',
            content_1:
              'Cada dispositivo se asocia a una obra, y a través de nuestra plataforma podrás conocer diferentes valores ambientales:',
            content_1_list_1: 'Ubicación GPS',
            content_1_list_2: 'Temperatura',
            content_1_list_3: 'Humedad',
            content_2:
              'Además, el dispositivo cuenta con un acelerómetro que nos permite detectar cuando la obra se mueve.',
            content_3:
              'En función de las necesidades del cliente podemos programar eventos como cambios de ubicación mientras la obra esté en movimiento. Igualmente envía alertas si se detectan valores determinados de temperatura o humedad.',
            price: '<b>200 €</b> + cuota mensual <b>25 € / mes</b>',
            button: 'Contratar este dispositivo',
            title_contact: 'Contrata Art Device',
            subtitle_contact: 'Interesado en el dispositivo Art Device'
          },
          modal_artbeacon: {
            title: 'ART BEACONS',
            subtitle: 'Protección de varias obras en su entorno.',
            content_1:
              'Son los dispositivos más pequeños que tenemos en nuestro catálogo y se combinan con una unidad de Art Device.',
            content_2:
              'Por lo tanto, para su funcionamiento es necesario disponer de un Art Device. Los Art Beacon se comunican con el Art Device, por lo que tienen que estar en un radio de acción en el que la comunicación sea viable.',
            content_3:
              'Los Art Beacon se pueden configurar para que cuando un visitante se acerque a una obra le llegue una notificación con la información de esa obra. Utilizando como tecnología de comunicación el Bluetooth.',
            price: '<b>50 €</b> + cuota mensual <b>20 € / mes</b>',
            button: 'Contratar este dispositivo',
            title_contact: 'Contrata Art Beacon',
            subtitle_contact: 'Interesado en el dispositivo Art Beacon'
          }
        }
      }
    },
    cookies: {
      message: {
        title: 'Valoramos tu privacidad',
        content: 'En esta web utilizamos cookies para el correcto funcionamiento de la misma.',
        more_info: 'Más información',
        button: 'Aceptar'
      },
      page: {
        content_1:
          'Las cookies son pequeños ficheros de datos que se generan en el ordenador del usuario y que nos permiten conocer su frecuencia de visitas, los contenidos más seleccionados y los elementos de seguridad que pueden intervenir en el control de acceso a áreas restringidas, así como la visualización de publicidad en función de criterios predefinidos por Auth4Art y que se activan por cookies servidas por dicha entidad o por terceros que prestan estos servicios por cuenta de Auth4Art.',
        content_2:
          'Auth4Art utiliza cookies propias y de terceros para la autenticación de su sesión de usuario, realizar análisis estadísticos, mostrarle contenido útil, y mejorar y personalizar determinados servicios mediante el análisis de sus hábitos de navegación.',
        optionals: 'Opcionales:',
        google_analytics: 'Google Analytics',
        necessary: 'Necesarias:',
        log_in: 'Inicio de sesión',
        sign_up: 'Registrarse'
      }
    },
    not_found: {
      title: 'Oops! No hemos encontrado lo que buscabas',
      content: 'Pero, si te interesa, puedes seguir mirando por aquí'
    }
  }
};

export default es;
