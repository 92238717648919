<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-credit card-full border-dashed" :class="{ border: hasCurrentWallet }">
        <div class="position-absolute">
          <div v-if="hasCurrentWallet" class="badge badge-primary mt-2 ms-2">
            {{ $t('trans.profile.payments.crypto.modal.active') }}
          </div>
        </div>

        <div class="card-body">
          <div class="d-flex flex-column align-items-center justify-content-center py-1">
            <img
              :src="require('@/images/brand/metamask.svg')"
              alt="logo"
              class="card-media-img flex-shrink-0 me-0 mb-3"
            />
            <h6 class="text-black">MetaMask</h6>
            <p class="card-text card-text-s1 text-black">
              {{ walletMin }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--    <div v-else class="col-12">-->
    <!--      <div-->
    <!--        role="button"-->
    <!--        @click="initWeb3()"-->
    <!--        class="card-media card-full card-media-s1 flex-column justify-content-center flex-wrap p-4 cursor-p"-->
    <!--      >-->
    <!--        <div class="d-flex flex-column align-items-center justify-content-center py-1">-->
    <!--          <img-->
    <!--            :src="require('@/images/brand/metamask.svg')"-->
    <!--            alt="logo"-->
    <!--            class="card-media-img flex-shrink-0 me-0 mb-2"-->
    <!--          />-->
    <!--          <h6 class="text-black">MetaMask</h6>-->
    <!--          <p class="card-text card-text-s1 text-black text-center">-->
    <!--            {{ $t('trans.profile.payments.crypto.modal.not_connected') }}-->
    <!--          </p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Web3Service from '@/core/services/Web3Service';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'PaymentMethodWallet',
  props: {
    wallet: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const currentWalletMin = store.state.currentWalletMin;

    const walletMin = computed(() => {
      const address = props.wallet.address;
      var subsMin = address.substring(8, 34);
      return address.replace(subsMin, '...');
    });

    const hasCurrentWallet = computed(() => {
      return currentWalletMin === walletMin.value;
    });

    const initWeb3 = () => {
      try {
        Web3Service.init();
      } catch (e) {
        console.log('error', 'PaymentMethodWallet', e);
      }
    };

    return {
      walletMin,
      hasCurrentWallet,
      initWeb3
    };
  }
};
</script>
