<template>
    <div class="page-wrap">
      <!-- header  -->
      <header class="header-section has-header-main">
        <!-- Header main -->
        <HeaderMain></HeaderMain>
        <!-- hero -->
        <HeaderBreadcrumbs
          classname="hero-title"
          title="Exposicion Pararecordarte"
          :breadcrumb-items="[
            {
              title: 'Virtual META',
              routeName: $t('routes.metaExpos.name')
            },
            {
              title: 'Exposición Pararecordarte'
            }
          ]"
        ></HeaderBreadcrumbs>
        <div></div>
      </header>
      <section class="section-space-b blog-section">
        <div id="iframe_virtual"></div>
      </section>
  
      <Footer classname="bg-dark on-dark"></Footer>
    </div>
    <!-- end page-wrap -->
  </template>
  
  <script>
  // Import component data. You can change the data in the store to reflect in all component
  import SectionData from '@/store/store.js';
  import { onMounted } from 'vue';
  
  export default {
    name: 'MetaExpoPararecordarte',
    components: {},
    data() {
      return {
        SectionData
      };
    },
    setup() {
      onMounted(() => {
        const f = document.createElement('iframe');
        f.src =
            'https://meta.yonders.io/tour/expo-pararecordarte';
        // f.src = 'https://api.auth4art.com/test/iframe';
        f.style.border = 'none';
        f.style.height = '800px';
        f.style.width = '100%';
        f.style.transition = 'all 0.5s ease';
        const d = document.getElementById('iframe_virtual');
        d.appendChild(f);
      });
      return {};
    }
  };
  </script>
  