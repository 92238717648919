import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e61cb8ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "aria-label": "breadcrumb" }
const _hoisted_2 = { class: "breadcrumb breadcrumb-s1 justify-content-center mt-3 mb-0" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = {
  key: 0,
  class: "color-current"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.$t('routes.home.name') }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navbar.home")), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, i) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "breadcrumb-item",
          key: i
        }, [
          ($props.items.length === i + 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.title), 1))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: { name: item.routeName, params: item.routeParams }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
        ]))
      }), 128))
    ])
  ]))
}