<template>
  <section class="section-space-b">
    <div class="container">
      <!-- section heading -->
      <SectionHeading classname="text-center" :text="$t('trans.not_found.title')"
                      :content="$t('trans.not_found.content')"
                      isMargin="mb-3"></SectionHeading>
      <div class="row g-gs">

        <div class="col-lg-4 col-6 offset-lg-2">
          <router-link :to="{ name: $t('routes.artworks.name')}" class="card card-cat text-center h-100 text-dark">
            <div class="card-body card-body-s1">
              <span class="icon ni ni-camera mb-3 mx-auto icon-circle icon-wbg icon-lg"></span>
              <h5 class="card-cat-title">
                {{ $t('trans.artworks') }}
              </h5>
            </div>
          </router-link>
        </div><!-- end col -->
        <div class="col-lg-4 col-6">
          <router-link :to="{ name: $t('routes.artists.name')}" class="card card-cat text-center h-100 text-dark">
            <div class="card-body card-body-s1">
              <span class="icon ni ni-user mb-3 mx-auto icon-circle icon-wbg icon-lg"></span>
              <h5 class="card-cat-title">
                {{ $t('trans.artists') }}
              </h5>
            </div>
          </router-link>
        </div><!-- end col -->

      </div><!-- end row -->
    </div><!-- end container -->
  </section><!-- end category-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component

export default {
  name: "NotFoundCommon",
};
</script>
