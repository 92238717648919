<template>
<!-- section heading -->
  <SectionHeading
      classname="text-center"
      :text="$t('trans.home.highlighted_artworks.title')"
      :content="$t('trans.home.highlighted_artworks.content')"
      isMargin="mb-3"
  ></SectionHeading>

  <ArtworkHighlightedSlider
      :artworks="highlightedArtworks"
  ></ArtworkHighlightedSlider>

  <div class="text-center mt-5">
    <router-link class="btn-link btn-link-s1" :to="{ name: $t('routes.artworks.name') }">
      {{ $t('trans.showMore') }}
    </router-link>
  </div>
</template>

<script>
import SectionData from "@/store/store.js";
import ArtworkHighlightedSlider from "@/components/common/ArtworkHighlightedSlider";
import store from "@/store";

export default {
	name: "ArtworksHighlightedContainer",
	components: {
    ArtworkHighlightedSlider
	},
	computed: {
		highlightedArtworks() {
			return store.getters["artwork/getHighlightedArtworks"];
		},
	},
	data() {
		return {
			SectionData,
		};
	},
};
</script>
