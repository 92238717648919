import { nextTick, ref } from 'vue';
import { isSSR } from '@/helpers';

export default function useVimeoModal() {
  const videoData = ref();
  const videoId = ref();
  const showModal = ref(false);
  const videoModal = ref();

  const openVideo = (data = null, id = null) => {
    if (showModal.value) {
      return;
    }
    videoData.value = data;
    videoId.value = id;
    showModal.value = true;
    nextTick(async () => {
      if (!isSSR()) {
        const { Modal } = await import('bootstrap');
        videoModal.value = new Modal(document.getElementById('video_vimeo_modal'));
        videoModal.value.show();
        const myModal = document.getElementById(`video_vimeo_modal`);
        myModal.addEventListener('hide.bs.modal', function () {
          setTimeout(() => {
            showModal.value = false;
          }, 700);
        });
      }
    });
  };

  return {
    videoData,
    videoId,
    showModal,
    videoModal,
    openVideo
  };
}
