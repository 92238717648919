import axios from '../../plugins/axios';

export default {
  namespaced: true,
  state: () => ({
    artist: null,
    highlightedArtists: [],
    artists: { data: [], total: 0 }
  }),
  mutations: {
    setArtist(state, artist) {
      state.artist = artist;
    },
    setHighlightedArtist(state, highlightedArtists) {
      state.highlightedArtists = highlightedArtists;
    },
    setArtists(state, artists) {
      state.artists = artists;
    }
  },
  getters: {
    getArtist(state) {
      return state.artist;
    },
    getHighlightedArtist(state) {
      return state.highlightedArtists;
    },
    getArtists(state) {
      return state.artists;
    }
  },
  actions: {
    async setArtist({ commit }, hash) {
      let error;
      try {
        let artist = await axios.get('/api/v1/public/artist/view/' + hash);
        if (artist) {
          artist = artist.data?.data;
        }
        commit('setArtist', artist);
      } catch (e) {
        error = e;
      }
    },
    async setHighlightedArtist({ commit }) {
      let error;
      try {
        let response = await axios.get('/api/v1/public/artist/listHighlighted');
        if (response) {
          response = response.data?.data;
        }
        commit('setHighlightedArtist', response);
      } catch (e) {
        error = e;
      }
    },
    async setArtists({ commit }, { perPage, page }) {
      let error;
      try {
        let response = await axios.get(`/api/v1/public/artist/list/${perPage}/${page}`);
        if (response) {
          response = response.data?.data;
        }
        commit('setArtists', response);
      } catch (e) {
        error = e;
      }
    }
  }
};
