import { computed } from 'vue';
import { useStore } from 'vuex';
import { useUserAuth } from '@/composable/user/user';

export function usePaymentMethod() {
  const store = useStore();
  const { currentRole } = useUserAuth();

  const payment_methods_crypto = computed(() => {
    return currentRole.value.payment_methods_crypto;
  });

  const hasUserPaymentMethodCrypto = computed(() => {
    return currentRole.value.payment_methods_crypto.length > 0;
  });

  const payment_methods_fiat = computed(() => {
    return currentRole.value.payment_methods_fiat;
  });

  const hasUserPaymentMethodFiat = computed(() => {
    return currentRole.value.payment_methods_fiat.length > 0;
  });

  return {
    payment_methods_crypto,
    hasUserPaymentMethodCrypto,
    payment_methods_fiat,
    hasUserPaymentMethodFiat,
    currentRole
  };
}
