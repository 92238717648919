<template>
  <div class="container">
    <div class="section-space-sm">
      <div class="row">
        <div class="col-lg-3 col-md-9 me-auto">
          <div class="footer-item mb-5 mb-lg-0">
            <LogoLink class="footer-logo-link"></LogoLink> &nbsp;&nbsp;&nbsp;<span class="mb-4"
              ><a href="/"
                ><span style="font-family: 'A4A'; font-weight: normal; color: #fff">
                  Auth<span style="font-size: x-large; color: #fff">4</span>Art</span
                ></a
              ></span
            >
            <p class="my-4 footer-para">{{ $t('trans.footer.content') }}</p>
            <ul class="styled-icon">
              <li>
                <a href="https://www.linkedin.com/company/auth4art/"><em class="icon ni ni-linkedin"></em></a>
              </li>
              <li>
                <a href="https://www.facebook.com/auth4art"><em class="icon ni ni-facebook-f"></em></a>
              </li>
              <li>
                <a href="https://www.instagram.com/auth4art/"><em class="icon ni ni-instagram"></em></a>
              </li>
              <li>
                <a href="https://discord.gg/v5YrF8adz2" alt="Discord"><em class="icon ni ni-flickr"></em></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="row g-gs">
            <div class="col-lg-4 col-md-4 col-sm-4" v-for="item in SectionData.footerData.footerList" :key="item.id">
              <div class="footer-item">
                <h5 class="mb-4">{{ $t(item.titleI18nKey) }}</h5>
                <ul class="list-item list-item-s1">
                  <li v-for="(list, i) in item.navList" :key="i">
                    <router-link v-if="list.hasOwnProperty('path')" :to="{ name: $t(list.path), query: list.params }"
                      >{{ $t(list.titleI18nKey) }}
                    </router-link>
                    <a target="_blank" v-else-if="list.hasOwnProperty('url')" :href="list.url">{{
                      $t(list.titleI18nKey)
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="bg-white-slim my-0" />
    <div class="copyright-wrap d-flex flex-wrap py-3 align-items-center justify-content-between">
      <p class="footer-copy-text py-2">
        Copyright © 2022 Auth4Art.
        <router-link :to="{ name: $t('routes.legal.name') }">{{ $t('trans.aviso_legal') }}</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import SectionData from '@/store/store.js';

export default {
  name: 'FooterSection',
  data() {
    return {
      SectionData
    };
  }
};
</script>
