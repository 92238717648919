<template>
  <div class="single-entry mb-5 mb-lg-0">
    <p class="single-entry-text mb-3" v-html="SectionData.TermDetail.content" align="justify"></p>
    <p class="single-entry-text mb-3" v-html="SectionData.TermDetail.contentTwo" align="justify"></p>

    <p class="single-entry-text mb-3" v-html="SectionData.TermDetail.quoteText" align="justify"></p>

    <p class="single-entry-text mb-4" v-html="SectionData.TermDetail.contentThree" align="justify"></p>

    <p class="single-entry-text mb-3" v-html="SectionData.TermDetail.contentFour" align="justify"></p>
    <p class="single-entry-text mb-3" v-html="SectionData.TermDetail.title" align="justify"></p>
    <p class="single-entry-text mb-2" v-html="SectionData.TermDetail.contentFive" align="justify"></p>
    <div class="gap-2x"></div>
  </div>
  <!-- end single-entry -->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';

export default {
  name: 'TermsSection',
  data() {
    return {
      SectionData
    };
  }
};
</script>
