import routes from './routes-en';

const en = {
  routes,
  auctionslist: {
    by: 'By',
    price: 'Starts',
    currentBid: 'Top bid',
    noBids: '0',
    cryptobid: 'Place MATIC Bid',
    eurobid: 'Place € Bid',
    public: 'Public',
    private: 'Private',
    viewDetail: 'View detail'
  },
  marketplace: {
    modal: {
      selectCurrency: 'Select Currency',
      price: 'Price',
      purchase: 'Buy'
    }
  },

  obra: {
    notapuja: 'Note: A bid can`t be erased.'
  },

  auth: {
    createAccount: 'Create Account ',
    newHere: 'New Here?',
    userAccount: 'User Account <strong>%{valor1}</strong> and password<strong>demo</strong> to continue',
    Email: 'E-mail',
    Password: 'Password',
    forgotPassword: ' Forgot Password ?',
    Continue: 'Continue',
    or: 'OR',
    pleaseWait: ' Please wait...',
    continueWithPlat: 'Continue with %{plat}',
    ChooseAccountType: 'Choose Account Type',
    NeedMoreInfo: 'If you need more info, please check out',
    AlreadyAccount: 'Already have an account?',
    SignInHere: 'Sign in here',
    FirstName: 'First Name',
    LastName: 'Last Name',
    ConfirmPassword: 'Confirm Password',
    UseNumbersSymbols: 'Use 8 or more characters with a mix of letters, numbers & symbols',
    IAgreeConditions: 'I Agree to Terms and conditions',
    Submit: 'Submit',
    NameRequired: 'Name is a required field',
    SurnameRequired: 'Surname is a required field',
    EmailRequired: 'E-mail is a required field',
    PasswordRequired: 'Password is a required field',
    PasswordConfirmationRequired: 'Password Confirmation is a required field',
    EmailBeValid: 'E-mail must be a valid e-mail',
    PasswordsMustMatch: 'Passwords must match',
    PasswordLeastCharacters: 'Password must be at least 4 characters',
    CredentialsIncorrect: 'The provided credentials are incorrect',
    TryAgain: 'Try again!',
    SuccessfullyLogged: 'You have successfully logged in!',
    GotIt: 'Ok, got it!',
    ForgotPassword: 'Forgot Password ?',
    ResetPassword: 'Enter your e-mail to reset your password.',
    Cancel: 'Cancel',
    LogoReg: 'media/logos/logo-reg.svg',
    FillUp: 'Fill up the below fields to create an account'
  },
  navbar: {
    home: 'Home',
    metaExpos: 'Virtual META',
    artworks: 'Artworks',
    artists: 'Artists',
    collections: 'Collections',
    marketplace: 'Marketplace',
    services: 'Services'
  },
  userDropdown: {
    profile: 'Profile',
    auctions: 'Auctions',
    favourites: 'Favourites',
    artworks: 'Artworks',
    dashboard: 'Dashboard',
    payment: 'Payment methods',
    transactions: 'Transactions'
  },
  dashboardTab: {
    account: 'Account',
    auctions: 'Bids',
    favourites: 'Favourites',
    artworks: 'Artworks',
    payments: 'Payment details',
    transactions: 'Transactions',
    academy: 'Academy'
  },
  footer: {
    titles: {
      for_sell: 'For sell',
      artists: 'Artists',
      about_us: 'About us'
    },
    contact: 'Contact',
    terms: 'Terms',
    privacy: 'Privacy',
    auctions: {
      fiat: 'Auctions',
      crypto: 'Auctions Crypto'
    },
    marketplace: 'Marketplace',
    artists: 'Artists',
    collections: 'Collections',
    services: 'Services'
  },
  pagination: {
    count: "Showing {'{from}'} to {'{to}'} of {'{count}'} records{'|'}{'{count}'} records{'|'}One record",
    first: 'First',
    last: 'Last'
  },
  filters: {
    order: {
      name: 'Name',
      price_asc: 'Price: Low',
      price_desc: 'Price: High',
      date_asc: 'Date: Old',
      date_desc: 'Date: New'
    },
    option: {
      physical: 'Physical artwork',
      nft: 'NFT',
      all: 'All',
      crypto: 'Crypto',
      euro: '€',
      auction: 'Auction',
      fixed: 'Fixed'
    },
    searchBy: 'Search by {attr}'
  },
  bids: {
    titleModal: 'Place a bid',
    contentModal: 'You are about to bid on <strong>{artworkName}</strong> by owner <strong>{artistName}</strong>',
    yourOffer: 'Your offer ({currency})',
    userBalance: 'User balance',
    expired: 'This auction is over',
    bidFinished: 'The bid has been placed',
    bidInfo: {
      price: 'Offer',
      comission: 'Comission {percentage}%',
      total: 'Total to pay',
      remaining: 'Missing',
      over: 'Outbid',
      priceLastBid: "Last bid's price",
      minimumBid: 'Minimum bid price'
    },
    bid: 'Place bid',
    doingBid: 'Placing bid',
    outbid: 'You have been outbidded, bid price has been updated',
    noUser: 'You have to log in in order to place a bid'
  },

  siginto: 'Sign In to',
  dashboard: 'Dashboard',
  layoutBuilder: 'Layout builder',
  craft: 'Crafted',
  pages: 'Pages',
  profile: 'Profile',
  profileOverview: 'Overview',
  projects: 'Projects',
  campaigns: 'Campaigns',
  documents: 'Documents',
  connections: 'Connections',
  wizards: 'Wizards',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Account',
  accountOverview: 'Overview',
  settings: 'Settings',
  authentication: 'Authentication',
  basicFlow: 'Basic Flow',
  signIn: 'Sign-in',
  signUp: 'Sign-up',
  passwordReset: 'Password Reset',
  multiStepSignUp: 'Multi-steps Sign up',
  error404: 'Error 404',
  error500: 'Error 500',
  apps: 'Apps',
  chat: 'Chat',
  privateChat: 'Private Chat',
  groupChat: 'Group Chat',
  drawerChat: 'Drawer Chat',
  widgets: 'Widgets',
  widgetsLists: 'Lists',
  widgetsStatistics: 'Statistics',
  widgetsCharts: 'Charts',
  widgetsMixed: 'Mixed',
  widgetsTables: 'Tables',
  widgetsFeeds: 'Feeds',
  changelog: 'Changelog',
  docsAndComponents: 'Docs & Components',
  megaMenu: 'Mega Menu',
  exampleLink: 'Example link',
  modals: 'Modals',
  general: 'General',
  inviteFriends: 'Invite Friends',
  viewUsers: 'View Users',
  upgradePlan: 'Upgrade Plan',
  shareAndEarn: 'Share & Earn',
  forms: 'Forms',
  newTarget: 'New Target',
  newCard: 'New Card',
  newAddress: 'New Address',
  createAPIKey: 'Create API Key',
  twoFactorAuth: 'Two Factor Auth',
  createApp: 'Create App',
  createAccount: 'Create Account',
  documentation: 'Documentation',
  components: 'Components',
  resources: 'Resources',
  activity: 'Activity',
  customers: 'Customers',
  gettingStarted: 'Getting Started',
  customersListing: 'Customers Listing',
  customerDetails: 'Customers Details',
  calendarApp: 'Calendar',
  subscriptions: 'Subscriptions',
  getStarted: 'Getting Started',
  subscriptionList: 'Subscription List',
  addSubscription: 'Add Subscription',
  viewSubscription: 'View Subscription',
  auction: 'Auction',
  beggining: 'Home',
  hello: 'Hello',
  hours: 'Hours',
  days: 'Days',
  minutes: 'Minutes',
  seconds: 'Seconds',
  seller: 'Seller',
  signed: 'Signed',

  trans: {
    artworks: 'Artworks',
    artists: 'Artists',
    copy_link: 'Copy link',
    profile: {
      fiatPaymentMethodEmpty: 'You need a valid payment method',
      fiatPaymentMethodCryptoEmpty: 'You need a valid wallet',
      orderPublicsEmpty: 'No transactions',
      emailConfirmation: 'E-mail not confirmed',
      my_bid: 'My bid',
      last_bid: 'Last bid',
      noAuctions: 'No active bids',
      noAuctionsbt: 'Find artworks',
      noArtworks: 'No artworks in property',
      noArtworksbt: 'Find artworks',
      noFavourites: 'No artworks marked as favourite',
      noFavouritesbt: 'Find artworks',
      resendEmail: 'Resend e-mail',
      title: 'Profile',
      nav_options: {
        edit_info: 'Edit information',
        password: 'Password'
      },
      edit: {
        title: 'Edit profile',
        email: 'Main e-mail',
        name: 'Name',
        surname: 'Surname',
        phone: 'Phone',
        contact_email: 'Contact e-mail',
        personalInformationButton: 'Update data',
        old_password: 'Current password',
        new_password: 'New password',
        new_password_confirm: 'Confirm new password',
        changePasswordButton: 'Updade password'
      },
      payments: {
        nav_bar: {
          payment_methods: 'Payment methods',
          billing_details: 'Billing details',
          shipping_addresses: 'Shipping addresses'
        },
        add_payment_method: 'Add payment method',
        default: 'Default',
        credit_card: 'Credit card',
        use_as_default: 'Use as default',
        delete: 'Delete',
        fiat: {
          title: 'Fiat methods',
          modal: {
            addTitle: 'Add card'
          }
        },
        crypto: {
          title: 'Crypto methods',
          modal: {
            title: 'My wallet',
            active: 'Active',
            not_connected: 'We need you to connect your Metamask account',
            button_connect: 'Connect'
          }
        },
        details: {
          billing_address: 'Dirección de facturación',
          select_one: 'Selecciona uno',
          company_name: 'Company name',
          freelance: 'Freelance',
          name: 'Name',
          surname: 'Surname',
          nif: 'Tax ID Number',
          phone: 'Contact phone',
          country: 'Country',
          region: 'Region',
          postal_code: 'Postal Code',
          address: 'Address',
          city: 'City',
          descriptive_name: 'Descriptive name',
          use_address_default: '¿Usar como dirección por defecto?',
          use_address_default_info: 'Seleccionaremos esta dirección de forma predeterminada en tus compras',
          save: 'Guardar',
          edit: 'Editar',
          add_new_address: 'Añadir Dirección de facturación',
          alert: {
            create_success_title: 'Éxito',
            create_success_description: 'Dirección de facturación añadida',
            edit_success_title: 'Éxito',
            edit_success_description: 'Dirección de facturación editada',
            error_title: 'Error',
            error_description: 'Ha habido un error al guardar los datos',
            understood: 'Entendido'
          }
        }
      },
      required: {
        billingAddress_business_name: 'Por favor, introduce el nombre de la sociedad',
        billingAddress_name: 'Por favor, indica tu nombre',
        billingAddress_surname: 'Por favor, introduce tus apellidos',
        billingAddress_address: 'Por favor, introduce una dirección',
        billingAddress_nif: 'Por favor, indica un documento legal',
        billingAddress_phone: 'Por favor, un teléfono de contacto',
        billingAddress_country: 'Por favor, selecciona un país',
        billingAddress_region: 'Por favor, selecciona una región',
        billingAddress_city: 'Por favor, introduce una ciudad',
        billingAddress_cp: 'Por favor, introduce un código postal',
        billingAddress_type: 'Por favor, selecciona una opción'
      },
      regex: {
        phone: 'Por favor, introduce un teléfono válido',
        shippingAddress_cp: 'Por favor, introduce un código postal válido',
        billingAddress_nif: 'Por favor, introduce un documento válido',
        billingAddress_cp: 'Por favor, introduce un código postal válido'
      },
      email: {
        contact_email: 'Por favor, introduce un correo válido'
      },
      test: {
        shippingAddress_cp: 'El código postal no coincide con la provincia',
        billingAddress_cp: 'El código postal no coincide con la provincia'
      },
      transactions: {
        table: {
          order_number: 'Order number',
          artwork: 'Artwork',
          date: 'Date',
          type: 'Type',
          price: 'Price',
          actions: 'Actions'
        },
        modal: {
          title: 'Invoice request',
          content: 'Leave us your email and we will send you the invoice for your order as soon as possible.',
          email: 'E-mail',
          message: 'Optionally, write your message...',
          button: 'Send'
        }
      }
    },
    artwork: {
      auction: {
        starts_at: 'Starts at',
        max_bid: 'Max. bid'
      },
      sell: {
        euro_price: 'Euro price',
        matic_price: 'Matic price'
      },
      showDetails: 'Show',
      childs: {
        section: {
          title: 'Edition',
          content: ' This edition contains a set of {number} elements',
          list: ' This edition contains a set of <b>{number}</b> elements'
        }
      },
      for_sale: {
        title: 'For sale',
        not_for_sale: 'Currently this artwork is not for sale.',
        sell: {
          title: 'This NFT is for direct sale with the following prices:',
          modal: {
            title: 'Purchase options',
            content_euro: 'Purchased NFTs will be transferred to your private wallet',
            content_matic: 'Purchased NFTs will be transferred to your Metamask'
          }
        },
        auction: {
          countdown: {
            ends_in: 'Auction that ends in',
            starts_in: 'Auction starts in',
            initial_price: 'Initial price:',
            last_bid: 'Last bid:'
          }
        }
      },
      properties: {
        title: 'Properties',
        author: 'Author',
        certificate: 'Certificate',
        date: 'Date',
        edition: 'Edition',
        of: 'of'
      },
      authenticity: {
        title: 'Athenticity',
        transaction: {
          title: 'Transaction',
          modal: {
            title: 'Authenticated digital art',
            content: 'Unique creation hash',
            link: 'Unique creation transaction'
          }
        },
        blockchain: {
          title: 'Blockchain',
          modal: {
            title: 'Blockchain',
            coin: 'Coin',
            nft_details: 'NFT DETAILS',
            contract_address: 'Contract address',
            nft_id: 'NFT Id',
            token_standard: 'Token standard'
          }
        },
        certificate: {
          title: 'Certificate',
          modal: {
            title: 'Certificate',
            content: 'Auth4Art certifies this artwork using TrustOS Cert',
            link: 'Check out artwork authenticity certificate'
          }
        },
        security: 'Safety for physical artworks.',
        find_out_more: 'Find out more.'
      },
      options: {
        title: 'Options',
        publicArtwork: 'Public artwork',
        editArtwork: 'Edit'
      }
    },
    alert: {
      success: {
        title: 'Done!',
        message: 'Action performed successfully'
      },
      error: {
        title: 'Oops!',
        message: 'An error has occured'
      },
      info: {
        notfound: {
          title: 'Oops!',
          message: 'We have not found what you were looking for'
        }
      },
      continue: 'Continue',
      cancel: 'Cancel',
      action: "You're going to make a change",
      notReversible: 'This action is irreversible',
      canceled: 'Canceled Action',
      noAction: 'Nothing happened',
      login: {
        required: {
          title: 'Login required',
          text: 'In order to continue we need to authenticate you within the platform',
          continue: 'Go to login',
          cancel: 'Cancel'
        }
      },
      email_confirm: {
        required: {
          title: 'E-mail confirmation required',
          text: 'In order to continue we need to validate your e-mail',
          continue: 'Go to my profile',
          cancel: 'Cancel'
        }
      },
      metamask: {
        title: 'Oops!',
        content: 'We need you to log in to Metamask',
        confirm_button: "I don't have Metamask",
        cancel_button: 'Close'
      },
      confirm_purchase: {
        title: 'Confirmation',
        message: 'Do you want to proceed with the purchase?'
      },
      correct_purchase: {
        title: 'Correct purchase',
        message: 'Purchase process completed'
      },
      error_purchase: {
        title: 'Purchase error',
        message: 'There has been an error in the purchase process'
      },
      cancelled_purchase: {
        title: 'Cancelled',
        message: 'Purchase canceled'
      },
      payment_method_error: {
        title: 'Oops!',
        message: 'There was an error creating the payment method'
      },
      payment_method_success: {
        title: 'Action performed!',
        message: 'We have successfully saved your payment method'
      },
      payment_method_default: {
        title: 'Default payment method',
        message: 'We have set your payment method by default'
      },
      payment_method_confirm_delete: {
        title: 'Delete payment method',
        message: 'Are you sure you want to remove the payment method?'
      },
      payment_method_delete: {
        title: 'Payment method deleted',
        message: 'The payment method has been successfully deleted'
      },
      favourites_added: {
        title: 'Action performed!',
        message: 'We have added this artwork to your favorites'
      },
      favourites_removed: {
        title: 'Action performed!',
        message: 'We have removed this artwork from your favorites'
      },
      coming_soon: {
        title: 'Coming soon',
        message: 'In the next few days we will activate this functionality'
      },
      message_sent: {
        title: 'Message sent',
        message: 'Message sent correctly, we will respond as soon as possible'
      },
      message_error: {
        title: 'Oops!',
        message: 'Message sending failed, please try again'
      },
      default_loading: {
        title: 'Loading...!',
        message: 'This action may last a few seconds. Please wait.'
      },
      default_error: {
        title: 'Oops!',
        message: 'There was an error, try again later'
      },
      setIsPublic: {
        title: 'Change visibility',
        message: "¿Do you want to change your artwork's visibility?",
        continue: 'Continue',
        cancel: 'Cancel'
      }
    },
    breadcrumbs: {
      titles: {
        base: 'Auth4Art',
        artworks: 'Artworks',
        artwork: 'Artwork details',
        artists: 'Artists',
        artist_detail: 'Artist detail',
        artwork_detail: 'Artwork detail',
        contact: 'Contact us',
        legal: 'Legal advice',
        terms: 'Terms and conditions',
        privacy: 'Privacy conditions',
        services: 'Services',
        martelive: 'MarteLive',
        cookies: 'Cookies'
      },
      home: 'Home',
      artwork: 'Artwork',
      artworks: 'Artworks',
      edition: 'Edition',
      artist: 'Artist',
      artists: 'Artists',
      collection: 'Collection',
      collections: 'Collections',
      contact: 'Contact',
      legal: 'Legal',
      terms: 'Legal terms',
      privacy: 'Privacy',
      services: 'Services',
      martelive: 'MarteLive',
      cookies: 'Cookies'
    },
    auction: {
      action: 'Bid'
    },
    marketplace: {
      action: 'Buy',
      bidup: 'Bid Up'
    },
    aviso_legal: 'Legal',
    showMore: 'Show more',
    share: {
      text: 'Share',
      copied: 'Link copied'
    },
    home: {
      hero_data: {
        2: {
          title: 'Cristóbal Toral',
          content: 'First NFTs created by the famous Cadiz painter',
          titlebt: 'Find out more'
        },
        3: {
          title: 'DE VARGAS',
          content: 'Introducing the first NFTs created from a physical work of the Artist',
          titlebt: 'Find out more'
        },
        4: {
          title: 'Patricia Florez',
          content: 'Vector art in the purest style of this exceptional Madrilenian',
          titlebt: 'Find out more'
        },
        5: {
          title: 'Cultural heritage',
          content: 'We increase the guarantees of Authorship, Authenticity, Ownership and Traceability of the artwork',
          titlebt: 'Find out more'
        },
        6: {
          title: 'A marketplace for everyone',
          content: 'Easily generate and trade NFTs',

          titlebt: 'Find out more'
        }
      },
      highlighted_artists: {
        title: 'Highlighted artists',
        content: "Discover our best artists' digital artworks",
        artworks: 'Artworks',
        button: 'Artist detail'
      },
      highlighted_artworks: {
        title: 'Highlighted artworks',
        content: "Discover our artists' digital artworks"
      },
      auctions: {
        title: 'Auctions'
      },
      crypto_auctions: {
        title: 'Crypto auctions'
      },
      marketplace: {
        title: 'Marketplace'
      },
      about: {
        title: 'What is Auth4Art?',
        ecosystem: {
          nav: 'Ecosystem',
          title: "It's an ecosystem where you will be able to:",
          list_1: 'Register your artworks, physical or digital, on Blockchain',
          list_2: "Decide if your artworks'll be public or only for you to see",
          list_3: 'Generate NFTs from any artwork and put them on sale in auction or direct sell mode',
          list_4: 'Choose between €uro and crypto for your sales',
          button: 'Find out more'
        },
        our_plans: {
          nav: 'Our plans',
          title: "Get to know Auth4Art's plans",
          content: "Discover Auth4Art's plans, specially designed for artists and collection managers.",
          list_1: 'Free',
          list_2: 'Basic',
          list_3: 'Premium',
          button: 'Find out more'
        },
        our_devices: {
          nav: 'Our devices',
          title: "Get to know Auth4Art's devices",
          content: "Auth4Art's devices are designed to add another layer of security.",
          list_1: 'SmartTag',
          list_2: 'Art Device',
          list_3: 'Art Beacon',
          button: 'Find out more'
        },
        why: {
          title: 'Why Auth4Art?',
          advantages: {
            title: 'What advantages does Auth4Art offer me?',
            content:
              'Auth4Art assures me that my digital creations will be under my control with certificates of authenticity added to the security of the blockchain'
          },
          security: {
            title: 'How does Auth4Art secure my digital creations?',
            content:
              'Auth4Art NFTs carry an extra layer of security that guarantees that any sale or transfer is registered and validated by the author'
          },
          ecosystem: {
            title: 'Supervised ecosystem',
            content:
              'Tokenize your artwork and put it on our Market. You will see all the facilities and security that we offer: To all the advantages of the blockchain we add a layer of legal certificates in each purchase-sale.'
          },
          audit: {
            title: 'We certify the authorship of any digital artwork',
            content:
              'At Auth4Art we ensure the digital versions of your artworks throughout their journey, at all times you know all the transactions.'
          }
        }
      }
    },
    footer: {
      content: 'The first digital marketplace made by and for artists'
    },
    collections: {
      title: 'Collections',
      detail: {
        title: "Collection's artworks",
        content: 'This collection contains a set of {number} artworks'
      }
    },
    artworksContainer: {
      filters: {
        disciplines: {
          title: 'Disciplines',
          painting: 'Painting',
          sculpture: 'Sculpture'
        },
        artwork_type: {
          title: 'Artwork type',
          nft: 'NFT',
          physical: 'Physical'
        },
        sale_type: {
          title: 'Sale type',
          all: 'All',
          auction: 'Auction',
          auction_euro: 'Euro auction',
          auction_crypto: 'Crypto auction',
          edition: 'Edition',
          direct_sell: 'Direct sell',
          direct_sell_mixed: 'Mixed direct sell',
          direct_sell_euro: 'Euro direct sell',
          direct_sell_crypto: 'Crypto direct sell',
          not_for_sale: 'Not for sale'
        },
        artist: {
          title: 'Artist',
          name: 'Name',
          surname: 'Surname'
        },
        artwork: {
          title: 'Artwork',
          name: 'Name'
        }
      },
      no_results: 'No results'
    },
    artist: {
      nav_bar: {
        biography: 'Biography',
        artworks: 'Artworks',
        documentation: 'Documentation'
      },
      presentation: 'Presentation',
      links: 'Links',
      disciplines: 'Disciplines',
      documentation: {
        biography: {
          title: 'Biography',
          content: 'Document containing artist information'
        },
        exhibitions: {
          title: 'Exhibitions',
          content: 'List of museums in which the artist has participated'
        },
        images: {
          title: 'Images',
          content: 'Other artist image files'
        }
      }
    },
    about: {
      meet_us: {
        title: 'Meet us',
        content: 'Auth4Art, an ecosystem where you can:',
        list_1: 'Register your works of art, both physical and digital, on the Blockchain.',
        list_2: 'Publish or keep private all or part of your works.',
        list_3: 'Generate NFTs from any work and put them up for sale in Auction or Direct Sale mode.',
        list_4: 'Choose if you want to make the sale in €uros or in Crypto currencies.',
        list_5: 'And much more.'
      },
      plans_devices: {
        title: 'Auth4Art plans and devices',
        plans: {
          title: 'Our plans',
          content: 'Discover the Auth4Art plans designed specifically for Artists and Collection Managers.',
          content_2:
            'With the <strong>Auth4Art</strong> plans you will enter the world of digital art without risks and with an ease never seen before.',
          button: 'Hire plans',
          modal: {
            content: 'Leave us your e-mail and our commercial department will contact you.',
            email: 'E-mail',
            message: 'Optionally, write your message here...',
            button: 'Send',
            tax_included: '(VAT included)'
          },
          modal_plans: {
            title_contact: 'Hire Plans',
            subtitle_contact: 'Interested in Auth4Art Plans'
          },
          modal_free: {
            title: 'FREE PLAN',
            subtitle: 'The best way to start using our platform',
            content_1: 'You can register up to 50 artworks.',
            content_2: 'Once registered you have the opportunity to acquire our Security Tags and our ARTDevices.',
            content_3:
              'With this product you can register your artworks on our platform by adding both specific information about the artwork and attached files such as photos, videos, etc. Once all the information has been entered, it will be time to register all the information in the Blockchain.',
            content_4: 'You can also specify whether the registered information will be public or private.',
            price: 'FREE',
            button: 'Hire this plan',
            title_contact: 'Contract Free Plan',
            subtitle_contact: 'Interested in the Free Plan'
          },
          modal_basic: {
            title: 'BASIC PLAN',
            subtitle: 'For our most demanding customers.',
            content_1: 'You can register a maximum of 100 artworks.',
            content_2: 'Once registered you have the opportunity to acquire our Security Tags and our ARTDevices.',
            content_3:
              'With this product you can register your artworks on our platform by adding both specific information about the artwork and attached files such as photos, videos, etc. Once all the information has been entered, it will be time to register all the information in the Blockchain.',
            content_4: 'You can also specify whether the registered information will be public or private.',
            price: '59 € / month',
            button: 'Hire this plan',
            title_contact: 'Contract Basic Plan',
            subtitle_contact: 'Interested in the Basic Plan'
          },
          modal_premium: {
            title: 'PREMIUM PLAN',
            subtitle: 'For our most demanding customers.',
            content_1: 'You can register a maximum of 200 artworks.',
            content_2: 'Once registered you have the opportunity to acquire our Security Tags and our ARTDevices.',
            content_3:
              'With this product you can register your artworks on our platform by adding both specific information about the artwork and attached files such as photos, videos, etc. Once all the information has been entered, it will be time to register all the information in the Blockchain.',
            content_4: 'Igualmente podrás especificar si la información registrada será de acceso público o privado.',
            price: '99 € / month',
            button: 'Hire this plan',
            title_contact: 'Hire Premium Plan',
            subtitle_contact: 'Interested in the Premium Plan'
          }
        },
        devices: {
          title: 'Our devices',
          content: 'Auth4Art devices are designed to add an extra layer of security.',
          content_2:
            'Through the use of our devices in Physical Artworks, you will have one more factor to guarantee the: Authorship, Authenticity and Ownership of each artwork.',
          button: 'Hire devices',
          modal: {
            content: 'Leave us your e-mail and our commercial department will contact you.',
            email: 'E-mail',
            message: 'Optionally, write your message here...',
            button: 'Send',
            tax_included: '(VAT included)'
          },
          modal_devices: {
            title_contact: 'Hire devices',
            subtitle_contact: 'Interested in Auth4Art Devices'
          },
          modal_smarttag: {
            title: 'SMART TAGS',
            subtitle: 'Register your artworks with specific information about the artwork and attached documents.',
            content_1:
              'Each Security TAG contains a chip with a unique code, protected against writing, which guarantees the reliability of the recorded data.',
            content_2:
              'It adheres to your artwork, being associated with it with its unique code, any manipulation renders the validation chain useless. The registration data is accessible at all times with a mobile device through NFC technology.',
            price: '60 €',
            button: 'Hire this device',
            title_contact: 'Hire Smart Tag',
            subtitle_contact: 'Interested in the Smart Tag device'
          },
          modal_artdevice: {
            title: 'ART DEVICES',
            subtitle: 'With this device you will be able to track in real time the artwork to which you assign it.',
            content_1:
              'Each device is associated with a work, and through our platform you will be able to learn about different environmental values:',
            content_1_list_1: 'GPS Location',
            content_1_list_2: 'Temperature',
            content_1_list_3: 'Humidity',
            content_2: 'In addition, the device has an accelerometer that allows us to detect when the artwork moves.',
            content_3:
              'Depending on the needs of the client, we can program events such as changes of location while the work is in motion. It also sends alerts if certain values ​​of temperature or humidity are detected.',
            price: '<b>200 €</b> + monthly payment <b>25 € / month</b>',
            button: 'Hire this device',
            title_contact: 'Hire Art Device',
            subtitle_contact: 'Interested in Art Device'
          },
          modal_artbeacon: {
            title: 'ART BEACONS',
            subtitle: 'Protection of several artworks in their environment.',
            content_1:
              'They are the smallest devices that we have in our catalog and are combined with an Art Device unit.',
            content_2:
              'Therefore, for its operation it is necessary to have an Art Device. The Art Beacons communicate with the Art Device, so they have to be in a range where communication is viable.',
            content_3:
              'The Art Beacons can be configured so that when a visitor approaches an artwork, they receive information about that work by mobile notification. Using Bluetooth as communication technology.',
            price: '<b>50 €</b> + monthly payment <b>20 € / month</b>',
            button: 'Hire this device',
            title_contact: 'Hire Art Beacon',
            subtitle_contact: 'Interested in the Art Beacon device'
          }
        }
      }
    },
    cookies: {
      message: {
        title: 'We value your privacy',
        content: 'We use cookies on this website for its proper functioning.',
        more_info: 'More information',
        button: 'Ok'
      },
      page: {
        content_1:
          "Cookies are small data files that are generated in the user's computer and that allow us to know their frequency of visits, the most selected contents and the security elements that can intervene in the control of access to restricted areas, as well as the visualization of advertising based on criteria predefined by Auth4Art and that are activated by cookies served by said entity or by third parties that provide these services on behalf of Auth4Art.",
        content_2:
          'Auth4Art uses its own and third-party cookies to authenticate your user session, perform statistical analysis, show you useful content, and improve and personalize certain services by analyzing your browsing habits.',
        optionals: 'Optional:',
        google_analytics: 'Google Analytics',
        necessary: 'Necessary:',
        log_in: 'Log in',
        sign_up: 'Sign up'
      }
    },
    not_found: {
      title: 'Oops! We have not found what you were looking for',
      content: 'But, if you are interested, you can continue browsing here'
    }
  }
};

export default en;
