import { useMarketplace } from '@/composable/marketplace/marketplace';
import { ref } from 'vue';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { useArtwork } from '@/composable/artwork/artwork';
import * as Yup from 'yup';
import { useWeb3 } from '@/composable/web3/web3';

export interface PurchaseCryptoFormState {
  billing_address: string | null;
  wallet_address: string | null;
  blockchain_transaction_hash: string | null;
}

export function useMarketplaceCrypto() {
  const { refreshAvailability } = useMarketplace();
  const { t } = useI18n();
  const { currentArtwork, currentArtworkSell } = useArtwork();
  const { purchaseListedNFT } = useWeb3();

  const sendingFormPurchaseCrypto = ref(false);

  const purchaseCryptoFormState = ref<PurchaseCryptoFormState>({
    billing_address: null,
    wallet_address: null,
    blockchain_transaction_hash: null
  });

  const purchaseCryptoSchema = Yup.object().shape({
    billing_address: Yup.string().nullable().required(t('Selecciona una dirección de facturación'))
  });

  const purchaseCrypto = async () => {
    sendingFormPurchaseCrypto.value = true;
    await refreshAvailability(currentArtworkSell.value, currentArtwork.value);

    if (currentArtworkSell.value) {
      Swal.fire({
        title: t('trans.alert.confirm_purchase.title'),
        text: t('trans.alert.confirm_purchase.message'),
        icon: 'warning',
        confirmButtonText: t('trans.alert.continue'),
        cancelButtonText: t('trans.alert.cancel'),
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-dark mx-2',
          cancelButton: 'btn btn-outline-primary mx-2'
        }
      }).then(async (result) => {
        if (result.value) {
          await purchaseArtworkSellCrypto();
        } else {
          Swal.fire({
            title: t('trans.alert.cancelled_purchase.title'),
            text: t('trans.alert.cancelled_purchase.message'),
            icon: 'info',
            confirmButtonText: t('trans.alert.continue'),
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-outline-dark mx-2'
            }
          });
        }
        sendingFormPurchaseCrypto.value = false;
      });
    }
  };

  const purchaseArtworkSellCrypto = async () => {
    Swal.fire({
      title: t('trans.alert.default_loading.title'),
      text: t('trans.alert.default_loading.message'),
      icon: 'info',
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading(null);
        await purchaseListedNFT(currentArtwork.value, currentArtworkSell.value, purchaseCryptoFormState.value);
      }
    });
  };

  return {
    purchaseCryptoFormState,
    purchaseCryptoSchema,
    sendingFormPurchaseCrypto,
    purchaseCrypto
  };
}
