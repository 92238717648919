
import { useRoute } from 'vue-router';
import SectionData from '@/store/store.js';
import { roundLocale } from '@/plugins/helpers';
import ArtworkMarketplaceModal from '@/components/common/ArtworkMarketplaceModal.vue';
import ButtonUserLogin from '@/components/common/ButtonUserLogin.vue';
import ButtonUserCreate from '@/components/common/ButtonUserCreate.vue';
import ButtonUserVerifyEmail from '@/components/common/ButtonUserVerifyEmail.vue';
import { isSSR } from '@/helpers/index.ts';
import { useUserAuth } from '@/composable/user/user';
import { useArtwork } from '@/composable/artwork/artwork';
import { useI18n } from 'vue-i18n';
import { computed, onMounted } from 'vue';
import { useMarketplace } from '@/composable/marketplace/marketplace';
import { useStore } from 'vuex';

export default {
  name: 'ArtworkDetailTabMarketplace',
  components: { ButtonUserVerifyEmail, ButtonUserLogin, ButtonUserCreate, ArtworkMarketplaceModal },
  props: {
    artworkHash: String
  },
  data() {
    return {
      SectionData,
      roundLocale,
      expMessage: null
    };
  },
  setup() {
    const route = useRoute();
    const trackingExists = computed(() => route?.query?.tracking !== undefined);
    const { currentUser } = useUserAuth();
    const { currentArtwork, currentArtworkSell, openMarketplaceModal } = useArtwork();
    const { refreshAvailability } = useMarketplace();
    const { t } = useI18n();
    const store = useStore();

    const formatNumber = (num) => {
      if (num !== undefined && num !== null) {
        return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      } else {
        return '0';
      }
    };

    const message = t('trans.share.text');
    let path = 'https://auth4art.com'; //No permite -> $t('trans.share');
    if (!isSSR()) {
      path += window.location.pathname;
    }
    const onCopy = (e) => {
      let target = e.trigger.querySelector('.tooltip-text');
      let prevText = target.innerHTML;
      target.innerHTML = t('trans.share.copied');
      setTimeout(function () {
        target.innerHTML = prevText;
      }, 1000);
    };

    const isArtworkMine = computed(() => {
      const userArtworks = store.getters['artwork/getUserArtworks'];
      return userArtworks.find((element) => element.hash === currentArtwork.value.hash) !== undefined;
    });

    onMounted(async () => {
      await store.dispatch('artwork/setUserArtworks');
      await refreshAvailability(currentArtworkSell.value, currentArtwork.value);
    });

    return {
      trackingExists,
      currentArtwork,
      currentUser,
      message,
      path,
      onCopy,
      openMarketplaceModal,
      isArtworkMine,
      formatNumber
    };
  }
};
