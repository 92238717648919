<template>
  <div class="col-lg-9 ps-xl-5">
    <div class="user-panel-title-box">
      <h3>{{ $t('dashboardTab.transactions') }}</h3>
    </div>
    <!-- end user-panel-title-box  -->
    <div class="row g-gs">
      <div v-if="orders.length === 0" class="alert alert-warning" role="alert">
        <span>
          {{ $t('trans.profile.orderPublicsEmpty') }}
        </span>
      </div>
      <div v-else class="profile-setting-panel-wrap">
        <div class="table-responsive">
          <table class="table mb-0 table-s2">
            <thead class="fs-14">
              <tr>
                <th scope="col">{{ $t('trans.profile.transactions.table.order_number') }}</th>
                <th scope="col">{{ $t('trans.profile.transactions.table.artwork') }}</th>
                <th scope="col">{{ $t('trans.profile.transactions.table.date') }}</th>
                <th scope="col">{{ $t('trans.profile.transactions.table.type') }}</th>
                <th scope="col">{{ $t('trans.profile.transactions.table.price') }}</th>
                <th scope="col">{{ $t('trans.profile.transactions.table.actions') }}</th>
              </tr>
            </thead>
            <tbody class="fs-13">
              <tr v-for="order in orders" :key="order.hash">
                <th scope="row">{{ order.numero_pedido }}</th>
                <td>
                  {{ order.products[0].orderable.artwork?.title?.substring(0, 35) }}
                </td>
                <td>
                  {{ order.paid_at }}
                </td>
                <td v-if="isAuction(order)">Venta directa</td>
                <td v-else>Subasta</td>
                <td v-if="order.currency_type === 'matic'">{{ order.total }} MATIC</td>
                <td v-else>{{ order.total }}€</td>
                <td>
                  <button
                    type="button"
                    class="icon-btn icon-btn-s1 d-none d-lg-inline-block"
                    data-bs-toggle="modal"
                    data-bs-target="#requestInvoice"
                    @click="setData(order.hash)"
                  >
                    <i class="fa-solid fa-file-invoice"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="requestInvoice" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ $t('trans.profile.transactions.modal.title') }}</h4>
          <button type="button" class="btn-close icon-btn" data-bs-dismiss="modal" aria-label="Close">
            <em class="ni ni-cross"></em>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            {{ $t('trans.profile.transactions.modal.content') }}<br />
            <br />
            <form v-on:submit.prevent="requestInvoice()" action="#">
              <div class="row g-gs">
                <div class="col-lg-12">
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      v-model="form.message"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                    ></textarea>
                    <label for="floatingTextarea">{{ $t('trans.profile.transactions.modal.message') }}</label>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">
                    {{ $t('trans.profile.transactions.modal.button') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import axios from '@/plugins/axios';
import { computed, onMounted, ref } from 'vue';

export default {
  name: 'TransactionSection',
  data() {
    return {
      form: {
        email: null,
        message: null,
        phone: null,
        name: null,
        orderHash: null
      }
    };
  },
  computed: {
    user() {
      return store.state.user;
    }
  },
  methods: {
    setData(hash) {
      this.form.email = this.user.role[0]?.contact_email;
      this.form.phone = this.user.role[0]?.phone;
      this.form.name = this.user.role[0]?.name;
      this.form.orderHash = hash;
    },
    async requestInvoice() {
      const response = await axios
        .post('/api/v1/public/invoice', this.form)
        .then((res) => {
          this.$swal(this.$t('trans.alert.message_sent.title'), this.$t('trans.alert.message_sent.message'), 'success');
        })
        .catch((error) => {
          this.$swal(this.$t('trans.alert.message_error.title'), this.$t('trans.alert.message_error.message'), 'error');
        });
    }
  },
  setup() {
    const orders = ref([]);

    onMounted(async () => {
      const response = await axios.get('/api/v1/public/profile/order');
      orders.value = response.data.data;
    });

    const getProduct = (order) => {
      return order.products.length > 0 ? order.products[0] : null;
    };

    const isAuction = (order) => {
      const product = getProduct(order);
      if (product) {
        return product.type !== 'App\\Models\\ArtworkSell';
      }
      return true;
    };

    return {
      orders,
      getProduct,
      isAuction
    };
  }
};
</script>
