
import { BillingAddress } from '@/types/profile';
import { computed, onMounted, PropType, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { validateSpanishID } from '@/helpers/validate_spanish_id';
import * as Yup from 'yup';
import { useI18n } from 'vue-i18n';
import BaseFormButton from '@/components/common/BaseFormButton.vue';
import BaseRadioButtonGroup from '@/components/common/BaseRadioButtonGroup.vue';
import axios from '@/plugins/axios';
import Swal from 'sweetalert2';
import sanctum from '@/plugins/sanctum';

export default {
  name: 'DashboardPaymentsBillingAddressModal',
  components: {
    Form,
    Field,
    ErrorMessage,
    BaseFormButton,
    BaseRadioButtonGroup
  },
  props: {
    billingAddress: {
      type: Object as PropType<BillingAddress>,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const billingData = computed({
      get: () => props.billingAddress,
      set: (value) => emit('update:modelValue', value)
    });

    const postalCode = computed({
      get: () => billingData.value.postal_code === 'XXXX' ? '' : billingData.value.postal_code,
      set: (value) => billingData.value.postal_code = value,
    });

    const bilAddress = computed({
      get: () => billingData.value.address === 'XXXX' ? '' : billingData.value.address,
      set: (value) => billingData.value.address = value,
    });

    const bilCity = computed({
      get: () => billingData.value.city === 'XXXX' ? '' : billingData.value.city,
      set: (value) => billingData.value.city = value,
    });

    const sendingForm = ref<boolean>(false);
    const validationSchema = ref({});
    const addressTypes = ref([
      {
        id: 1,
        isActive: billingData.value.type === 'business',
        title: t('trans.profile.payments.details.company_name'),
        slug: 'business'
      },
      {
        id: 2,
        isActive: billingData.value.type === 'freelance',
        title: t('trans.profile.payments.details.freelance'),
        slug: 'freelance'
      }
    ]);

    const countries = ref();

    onMounted(async () => {
      const response = await axios.get('/api/v1/public/country');
      countries.value = response.data.data;

      validationSchema.value = Yup.object().shape({
        billingAddress_type: Yup.string().required(t('trans.profile.required.billingAddress_type')),
        billingAddress_business_name: Yup.string()
          .nullable()
          .when('billingAddress_type', {
            is: 'business',
            then: Yup.string().required(t('trans.profile.required.billingAddress_business_name'))
          }),

        billingAddress_name: Yup.string()
          .nullable()
          .when('billingAddress_type', {
            is: 'freelance',
            then: Yup.string().required(t('trans.profile.required.billingAddress_name'))
          }),
        billingAddress_surname: Yup.string()
          .nullable()
          .when('billingAddress_type', {
            is: 'freelance',
            then: Yup.string().required(t('trans.profile.required.billingAddress_surname'))
          }),
        billingAddress_nif: Yup.string()
          .required(t('trans.profile.required.billingAddress_nif'))
          .test('billingAddressCPTest', t('trans.profile.regex.billingAddress_nif'), (value, context) => {
            if (value) {
              const validation = validateSpanishID(value);
              const type = context.parent.billingAddress_type;
              if (validation.valid && typeof validation.type === 'string') {
                if (type === 'freelance' && ['dni', 'nie'].includes(validation.type)) {
                  return true;
                }

                if (type === 'business' && ['cif'].includes(validation.type)) {
                  return true;
                }
              }
            }
            return false;
          }),
        billingAddress_phone: Yup.string().required(t('trans.profile.required.billingAddress_phone')),
        billingAddress_country: Yup.string().required(t('trans.profile.required.billingAddress_country')),
        billingAddress_region: Yup.string().required(t('trans.profile.required.billingAddress_region')),
        billingAddress_region_cp: Yup.string().optional(),
        billingAddress_cp: Yup.string()
          .required(t('trans.profile.required.billingAddress_cp'))
          .matches(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/, { message: t('trans.profile.regex.billingAddress_cp') })
          .test('billingAddressCPTest', t('trans.profile.test.billingAddress_cp'), (value, context) => {
            const billingAddress_region_id = parseInt(context.parent.billingAddress_region);
            if (value && billingAddress_region_id) {
              if (regions.value.length > 0) {
                const region = regions.value.find((element) => {
                  return element.id === billingAddress_region_id;
                });
                if (region === undefined) {
                  return false;
                }
                return value[0] == region.postal_code[0] && value[1] == region.postal_code[1];
              } else return false;
            } else return false;
          }),
        billingAddress_address: Yup.string().required(t('trans.profile.required.billingAddress_address')),
        billingAddress_city: Yup.string().required(t('trans.profile.required.billingAddress_city'))
      });
    });

    const regions = computed(() => {
      if (countries.value === undefined) {
        return [];
      }
      const country = countries.value.find((element) => {
        return element.id === billingData.value.country_id;
      });

      return country ? country.regions : [];
    });

    const selectaddressTypes = (addressTypes) => {
      billingData.value.type = addressTypes;
    };

    const onSubmit = async () => {
      sendingForm.value = true;
      console.log(billingData.value.hash);
      if (billingData.value.hash === undefined) {
        await createBillingAddress();
      } else {
        await editBillingAddress();
      }
    };

    const createBillingAddress = async () => {
      try {
        const response = await axios.post(`/api/v1/public/profile/billing-address`, billingData.value);
        await sanctum.getUser();
        const billingAddress = response.data.data;
        Swal.fire({
          title: t('trans.profile.payments.details.alert.create_success_title'),
          text: t('trans.profile.payments.details.alert.create_success_description'),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'btn btn-primary fw-bold'
          }
        }).then(() => {
          emit('finish-action', billingAddress);
        });
      } catch (e) {
        Swal.fire({
          title: t('trans.profile.payments.details.alert.error_title'),
          text: t('trans.profile.payments.details.alert.error_description'),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'btn btn-primary fw-bold'
          }
        }).then(() => {
          emit('finish-action');
        });
      } finally {
        sendingForm.value = false;
      }
    };

    const editBillingAddress = async () => {
      try {
        const response = await axios.put(
          `/api/v1/public/profile/billing-address/${billingData.value.hash}`,
          billingData.value
        );
        const billingAddress = response.data.data;
        await sanctum.getUser();

        Swal.fire({
          title: t('trans.profile.payments.details.alert.edit_success_title'),
          text: t('trans.profile.payments.details.alert.edit_success_description'),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'btn btn-primary fw-bold'
          }
        }).then(() => {
          emit('finish-action', billingAddress);
        });
      } catch (e) {
        Swal.fire({
          title: t('trans.profile.payments.details.alert.error_title'),
          text: t('trans.profile.payments.details.alert.error_description'),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'btn btn-primary fw-bold'
          }
        }).then(() => {
          emit('finish-action');
        });
      } finally {
        sendingForm.value = false;
      }
    };

    return {
      billingData,
      addressTypes,
      validationSchema,
      selectaddressTypes,
      onSubmit,
      sendingForm,
      countries,
      regions,
      postalCode,
      bilAddress,
      bilCity
    };
  }
};
