
import { defineComponent, ref, onBeforeMount, toRef } from 'vue';
import axios from '@/plugins/axios';
import { StripeElements, StripeElement } from 'vue-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import sanctum from '@/plugins/sanctum';

export default {
  name: 'PaymentMethodStripeModal',
  components: {
    StripeElements,
    StripeElement
  },
  props: {},
  computed: {},
  data() {
    return {
      errorMessage: null
    };
  },
  setup(props) {
    const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY);
    const instanceOptions = ref({});
    const elementsOptions = ref({});
    const cardOptions = ref({
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#000000',
          color: '#000000',
          fontWeight: '500',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883'
          },
          '::placeholder': {
            color: '#000000'
          }
        },
        invalid: {
          iconColor: '#bb5a29',
          color: '#bb5a29'
        }
      }
    });
    const stripeLoaded = ref(false);
    const card = ref();
    const elms = ref();
    const name = ref('');

    async function saveAndConfirmCard(clientSecret) {
      // Get stripe element
      const cardElement = card.value.stripeElement;
      // Access instance methods, e.g. createToken()
      const result = await elms.value.instance.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name.value
          }
        }
      });
      if (!result.errors && result.setupIntent) {
        const data = {
          type: 'stripe',
          payment_source_id: result.setupIntent.payment_method,
          is_default: 1
        };
        const formData = new FormData();
        for (var key in data) {
          formData.append(key, data[key]);
        }

        await axios.post('/api/v1/public/profile/payment-method/create', formData);

        return true;
      } else {
        // Swal.fire({
        //   title: t('auth.error.title'),
        //   text: t('auth.error.text'),
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: t('auth.error.ok'),
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-primary",
        //   },
        // });

        return false;
      }
    }

    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value);
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });
    return {
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
      name,
      saveAndConfirmCard
    };
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    validateCard(data) {
      this.errorMessage = data?.error?.message;
      if (data?.error?.length || !data.complete) {
        this.$emit('valid', false);
      } else {
        this.$emit('valid', true);
      }
    },
    async initStripe() {
      try {
        const response = await axios.get('/api/v1/public/profile/payment-method/createStripeSetupIntent');
        return response.data.data.client_secret;
      } catch (e) {
        this.$swal(
          this.$t('trans.alert.payment_method_error.title'),
          this.$t('trans.alert.payment_method_error.message'),
          'error'
        );

        return;
      }
    },
    async saveCard() {
      const vm = this;
      const setupIntent = this.initStripe().then(async function (setupIntent) {
        if (setupIntent) {
          vm.$swal({
            title: vm.$t('trans.alert.default_loading.title'),
            text: vm.$t('trans.alert.default_loading.message'),
            icon: 'info',
            showCancelButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: async () => {
              vm.$swal.showLoading();
              const result = await vm.saveAndConfirmCard(setupIntent).then(async function (result) {
                if (result) {
                  await sanctum.getUser();
                  vm.$swal(
                    vm.$t('trans.alert.payment_method_success.title'),
                    vm.$t('trans.alert.payment_method_success.message'),
                    'success'
                  ).then((result) => {
                    vm.$swal.close();
                    vm.$emit('finish-create');
                  });
                } else {
                  vm.$swal(
                    vm.$t('trans.alert.payment_method_error.title'),
                    vm.$t('trans.alert.payment_method_error.message'),
                    'error'
                  );
                }
              });
            }
          });
        }
      });
    }
  }
};
