
import { useUserAuth } from '@/composable/user/user';
import { useArtwork } from '@/composable/artwork/artwork';
import { usePaymentMethod } from '@/composable/user/payment_method';
import { useMarketplace } from '@/composable/marketplace/marketplace';
import { onMounted } from 'vue';
import { helpers } from '@/types/enums/PaymentCards';
import BaseIcon from '@/components/common/BaseIcon.vue';
import BaseRadioButtonGroup from '@/components/common/BaseRadioButtonGroup.vue';
import BaseFormButton from '@/components/common/BaseFormButton.vue';
import { useMarketplaceEuro } from '@/composable/marketplace/marketplaceEuro';
import { useBillingAddress } from '@/composable/address';
import { Form, ErrorMessage, Field } from 'vee-validate';
import { useMarketplaceCrypto } from '@/composable/marketplace/marketplaceCrypto';

export default {
  props: {
    artworkHash: String
  },
  components: {
    BaseIcon,
    BaseRadioButtonGroup,
    BaseFormButton,
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    const { currentUser } = useUserAuth();
    const { currentArtwork, currentArtworkSell } = useArtwork();
    const { hasUserPaymentMethodFiat, payment_methods_fiat, hasUserPaymentMethodCrypto, payment_methods_crypto } =
      usePaymentMethod();

    const { billingAddresses } = useBillingAddress();

    const { refreshAvailability } = useMarketplace();
    const { purchaseEuroFormState, purchaseEuro, sendingFormPurchaseEuro, purchaseEuroSchema } = useMarketplaceEuro();
    const { purchaseCryptoFormState, purchaseCrypto, sendingFormPurchaseCrypto, purchaseCryptoSchema } =
      useMarketplaceCrypto();

    const getIcon = (key: string) => {
      return helpers.getInfo(key)?.icon;
    };

    onMounted(async () => {
      await refreshAvailability(currentArtworkSell.value, currentArtwork.value);

      payment_methods_fiat.value.forEach((paymentMethod) => {
        if (paymentMethod.is_default) {
          purchaseEuroFormState.value.payment_method = paymentMethod.hash;
        }
      });

      payment_methods_crypto.value.forEach((paymentMethod) => {
        if (paymentMethod.is_default) {
          purchaseEuroFormState.value.wallet_payment_method = paymentMethod.hash;
        }
      });

      billingAddresses.value.forEach((billingAddress) => {
        if (billingAddress.is_default) {
          purchaseEuroFormState.value.billing_address = billingAddress.hash;
          purchaseCryptoFormState.value.billing_address = billingAddress.hash;
        }
      });
    });

    const formatNumber = (num) => {
      if (num !== undefined && num !== null) {
        return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      } else {
        return '0';
      }
    };

    return {
      currentArtwork,
      currentArtworkSell,
      currentUser,
      hasUserPaymentMethodFiat,
      payment_methods_fiat,
      hasUserPaymentMethodCrypto,
      payment_methods_crypto,
      getIcon,
      billingAddresses,
      purchaseEuroFormState,
      sendingFormPurchaseEuro,
      purchaseEuroSchema,
      purchaseEuro,
      purchaseCryptoFormState,
      purchaseCrypto,
      sendingFormPurchaseCrypto,
      purchaseCryptoSchema,
      formatNumber
    };
  }
};
