<template>
  <ul class="menu-list ms-lg-auto">
    <li class="menu-item" v-for="nav in SectionData.headerData.navbar" :key="nav.id">
      <router-link v-if="nav.hasOwnProperty('path')" :to="{ name: $t(nav.path) }" class="menu-link">
        {{ $t(nav.titleI18nKey) }}
      </router-link>
      <a v-else-if="nav.hasOwnProperty('action')" href="#" @click.prevent="evalAction(nav.action)" class="menu-link">{{
        $t(nav.titleI18nKey)
      }}</a>
    </li>
  </ul>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';
import { useUserAuth } from '@/composable/user/user';

export default {
  name: 'MenuList',
  data() {
    return {
      SectionData
    };
  },
  setup() {
    const { currentUser } = useUserAuth();
    const router = useRouter();
    const { t } = useI18n();

    const evalAction = (action) => {
      if (action === 'goToMarketPlace') {
        if (!currentUser.value) {
          loginAlert();
        } else {
          router.push({ name: t('routes.dashboard.academy.name') });
        }
      }
    };

    const loginAlert = () => {
      Swal.fire({
        title: t('trans.alert.login.required.title'),
        text: t('trans.alert.login.required.text'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('trans.alert.login.required.continue'),
        cancelButtonText: t('trans.alert.login.required.cancel'),
        showCloseButton: true
      }).then((result) => {
        if (result.value) {
          router.push({ name: t('routes.login.name') });
        }
      });
    };

    return {
      loginAlert,
      evalAction
    };
  }
};
</script>
