<template>
  <div class="hero-wrap sub-header bg-dark">
    <div class="overlay"></div>
    <div class="container">
      <div class="hero-content py-0 d-flex align-items-center">
        <div class="avatar flex-shrink-0" :class="avatarSize">
          <img :src="img" alt="" />
        </div>
        <div class="author-hero-content-wrap d-flex flex-wrap justify-content-between ms-3 flex-grow-1">
          <div class="author-hero-content me-3">
            <h4 v-if="role" class="hero-author-title mb-1 text-white">{{ role.name + ' ' + role.surname }}</h4>
            <p class="hero-author-username mb-1 text-white">{{ $t('trans.profile.title') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'BuyerHero',
  props: ['img', 'avatarSize'],
  computed: {
    user() {
      return store.state.user.name;
    },
    role() {
      if (store.state.user.role === undefined) {
        return null;
      }

      return store.state.user.role[0];
    }
  }
};
</script>
