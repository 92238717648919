<template>
	<div class="page-wrap">
		<!-- header  -->
		<header class="header-section has-header-main">
			<!-- Header main -->
			<HeaderMain></HeaderMain>
			<!-- hero -->

      <HeaderBreadcrumbs
          classname="hero-title"
          :breadcrumb-items="[]"
      ></HeaderBreadcrumbs>
		</header>
		<!-- Explore section  -->

    <NotFoundCommon></NotFoundCommon>

		<!-- Footer  -->
		<Footer classname="bg-dark on-dark"></Footer>
	</div>
	<!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component

import NotFoundCommon from "@/components/common/NotFoundCommon";
export default {
	name: "NotFound",
  components: {NotFoundCommon},
};
</script>
