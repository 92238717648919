<template>
  <div class="header-logo" >
        <!-- logo link -->
        <LogoLink></LogoLink>
    </div><!-- .header-logo -->
</template>

<script>

export default {
  name: 'Logo'
}
</script>
