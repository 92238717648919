/* eslint-disable no-undef */
<template>
  <swiper
    :modules="modules"
    :slides-per-view="2"
    :loop="true"
    :autoHeight="true"
    :pagination="{ clickable: true }"
    :breakpoints="{
      '320': {
        slidesPerView: 1
      },
      '768': {
        slidesPerView: 2
      }
    }"
    class="swiper-button-s1"
  >
    <swiper-slide v-for="item in SectionData.howItWorkData.howItWorkListFour" :key="item.id">
      <div class="card card-hiw card-hiw-s3 cursor-pointer">
        <div class="card-body">
          <h4 class="mb-3 card-title">
            <em class="ni ni-question icon icon-sm text-orange icon-circle icon-wbg shadow-sm me-2"></em>
            {{ $t(item.title) }}
          </h4>
          <p class="card-text">{{ $t(item.content) }}</p>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import SectionData from '@/store/store.js';
import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'HowItWorkSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      SectionData
    };
  },
  setup() {
    return {
      modules: [Pagination]
    };
  }
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
