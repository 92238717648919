import axios from 'axios';
import { ref } from 'vue';

export default function useVimeo() {
  const videoData = ref();
  const modal = ref();
  const loading = ref(true);

  const getVideoData = async (vimeoId: number, vimeoHash: string) => {
    try {
      const urlVideo = `https://vimeo.com/${vimeoId}/${vimeoHash}`;
      const response = await axios.get(`https://vimeo.com/api/oembed.json?url=${urlVideo}`);
      // const response = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/349759079`);

      return {
        ...response.data,
        urlVideo: urlVideo,
        vimeoId: vimeoId,
        vimeoHash: vimeoHash
      };
    } catch (e) {
      console.log(e.response);
    }
  };

  const closeModal = () => {
    modal.value.hide();
  };

  return {
    videoData,
    loading,
    getVideoData,
    closeModal
  };
}
