import axios from "../../plugins/axios";

export default {
	namespaced: true,
	state: () => ({
		collection: {},
		highlightedCollections: [],
		collections: { data: [], total: 0 },
	}),
	mutations: {
		setCollection(state, collection) {
			state.collection = collection;
		},
		setHighlightedCollection(state, highlightedCollections) {
			state.highlightedCollections = highlightedCollections;
		},
		setCollections(state, collections) {
			state.collections = collections;
		},
	},
	getters: {
		getCollection(state) {
			return state.collection;
		},
		getHighlightedCollection(state) {
			return state.highlightedCollections;
		},
		getCollections(state) {
			return state.collections;
		},
	},
	actions: {
		async setCollection({ commit }, hash) {
			let error;
			try {
				let collection = await axios.get(
					"/api/v1/public/collection/view/" + hash
				);
				if (collection) {
					collection = collection.data?.data;
				}
				commit("setCollection", collection);
			} catch (e) {
				error = e;
			}
		},
		async setHighlightedCollection({ commit }) {
			let error;
			try {
				let response = await axios.get(
					"/api/v1/public/collection/listHighlighted"
				);
				if (response) {
					response = response.data?.data;
				}
				commit("setHighlightedCollection", response);
			} catch (e) {
				error = e;
			}
		},
		async setCollections({ commit }, { perPage, page }) {
			let error;
			try {
				let response = await axios.get(
					`/api/v1/public/collection/list/${perPage}/${page}`
				);
				if (response) {
					response = response.data?.data;
				}
				commit("setCollections", response);
			} catch (e) {
				error = e;
			}
		},
	},
};
