<template>
  <div>redirecting to login...</div>
</template>

<script>
import { onMounted } from 'vue';
import { isSSR } from '@/helpers';

export default {
  name: 'Login',
  setup() {
    onMounted(() => {
      console.log('isSSR()');
      console.log(isSSR());
      if (!isSSR()) {
        //console.log('process.env.VUE_APP_EXT_LOGIN');
        //console.log(process.env.VUE_APP_EXT_LOGIN);
        window.location.replace(process.env.VUE_APP_EXT_LOGIN);
      }
    });
  }
};
</script>
