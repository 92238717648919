<template>
<div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main bg-pattern-3">
        <!-- Header main -->
       <HeaderMain></HeaderMain>
        <!-- hero -->
        <BuyerHero
				avatarSize="avatar-3"
				:coverimg="SectionData.obraDashboardData.coverImg"
				:img="SectionData.obraDashboardData.img"
			></BuyerHero>
    </header>
    <!-- offer section -->
    <section class="user-panel-section section-space">
            <div class="container">
                <div class="row">
                    <!-- user sidebar -->
                    <UserSidebar
						          :title="$t('dashboardTab.transactions')"
						          :lists="SectionData.transactionsSidebarData.navList"
						          :navs="SectionData.artworksTabNavMobile"
					></UserSidebar>
                    <!-- offer -->
                    <TransactionsSection class="mt-4 mt-lg-0"></TransactionsSection>
                </div><!-- end row -->
            </div><!-- end container -->
    </section><!-- end user-panel-section -->
    <!-- Footer  -->
    <Footer classname="bg-dark on-dark"></Footer>
</div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
export default {
  name: 'Transactions',
  data () {
    return {
      SectionData
    }
  }
}
</script>
