<template>
  <div v-if="artwork && artwork.hash && artwork.is_public" class="card card-full">
    <div class="card-image">
      <router-link
        class=""
        :to="{
          name: $t('routes.artwork.name'),
          params: {
            hash: artwork.hash,
            slug: artwork.slug
          }
        }"
      >
        <img :src="headerImageUri" class="card-img-top" :alt="artwork.title" onerror="this.onerror=null; this.src='https://auth4art-beta.s3.eu-central-1.amazonaws.com/public/artworks/c10935e46ae0bf6120364636ddfda6c2/header_list/6aa2f59844d32b495f483428be8d11b1/3_2/lg/6ca0534ddd2538ac18a73ddcd1d548e1.';" />
      </router-link>
    </div>
    <div class="card-body p-4">
      <h5 class="card-title text-truncate mb-0">
        <router-link
          class=""
          :to="{
            name: $t('routes.artwork.name'),
            params: {
              hash: artwork.hash,
              slug: artwork.slug
            }
          }"
        >
          {{ artwork.title }}
        </router-link>
      </h5>
      <div v-if="artwork.artist" class="card-author mb-1 d-flex align-items-center">
        <div class="custom-tooltip-wrap">
          <router-link
            class="author-link"
            :to="{
              name: $t('routes.artist.name'),
              params: {
                hash: artwork.artist?.hash,
                slug: artwork.artist?.slug
              }
            }"
          >
            {{ artwork.artist?.full_name }}
          </router-link>
        </div>
      </div>

      <div
        v-if="artwork.active_auction"
        class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3"
      >
        <div class="me-5 me-sm-2">
          <span class="card-price-title">{{ $t('trans.artwork.auction.starts_at') }}</span>
          <span v-if="artwork.active_auction.currency_type === 'euro'" class="card-price-number">{{
            formatNumber(artwork.active_auction.start_price) + ' €'
          }}</span>
          <span v-else-if="artwork.active_auction.currency_type === 'matic'" class="card-price-number">{{
            artwork.active_auction.start_price + ' MATIC'
          }}</span>
        </div>
        <div v-if="artwork.active_auction.last_bid" class="text-sm-end">
          <span class="card-price-title">{{ $t('trans.artwork.auction.max_bid') }}</span>
          <div>
            <span v-if="artwork.active_auction.currency_type === 'euro'" class="card-price-number">{{
              formatNumber(artwork.active_auction.last_bid.amount) + ' €'
            }}</span>
            <span v-else-if="artwork.active_auction.currency_type === 'matic'" class="card-price-number">{{
              artwork.active_auction.last_bid.amount + ' MATIC'
            }}</span>
          </div>
        </div>
      </div>

      <div
        v-else-if="artwork.active_artwork_sell"
        class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3"
      >
        <div v-if="artwork.active_artwork_sell.price_euro" class="me-5 me-sm-2">
          <span class="card-price-title">{{ $t('trans.artwork.sell.euro_price') }}</span>
          <span class="card-price-number">{{ formatNumber(artwork.active_artwork_sell.price_euro?.price) + ' €' }} </span>
        </div>
        <div v-if="artwork.active_artwork_sell.price_matic" class="text-sm-end">
          <span class="card-price-title">{{ $t('trans.artwork.sell.matic_price') }}</span>
          <span class="card-price-number">{{ artwork.active_artwork_sell.price_matic?.price + ' MATIC' }} </span>
        </div>
      </div>

      <div v-else class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3">
        <div v-if="artwork.childs?.length > 0" class="me-5 me-sm-2">
          <span
            class="card-price-title"
            v-html="$t('trans.artwork.childs.section.list', { number: artwork.childs.length })"
          ></span>
        </div>
        <div class="mt-5"></div>
      </div>

      <div class="row">
        <div class="col-6">
          <router-link
            v-if="!artwork.child_order"
            class=""
            :to="{
              name: $t('routes.artwork.name'),
              params: {
                hash: artwork.hash,
                slug: artwork.slug
              }
            }"
          >
            <span v-if="artwork.active_auction" class="btn btn-sm btn-dark"> {{ $t('trans.auction.action') }} </span>
            <span v-else-if="artwork.active_artwork_sell" class="btn btn-sm btn-dark">{{
              $t('trans.marketplace.action')
            }}</span>
            <span v-else-if="hasChildsOnSale(artwork)" class="btn btn-sm btn-dark">
              {{ $t('trans.marketplace.action') }}
            </span>
            <span v-else class="btn btn-sm btn-dark">{{ $t('trans.artwork.showDetails') }}</span>
          </router-link>
          <router-link
            v-else-if="artwork.original"
            class=""
            :to="{
              name: $t('routes.artworkChild.name'),
              params: {
                hashOriginal: artwork.original.hash,
                slugOriginal: artwork.original.slug,
                hash: artwork.hash,
                slug: artwork.slug
              }
            }"
          >
            <span v-if="hasChildsOnSale(artwork)" class="btn btn-sm btn-dark">
              {{ $t('trans.marketplace.action') }}
            </span>
            <span v-else-if="artwork.active_auction" class="btn btn-sm btn-dark"> {{ $t('trans.auction.action') }} </span>
            <span v-else-if="artwork.active_artwork_sell" class="btn btn-sm btn-dark">{{
              $t('trans.marketplace.action')
            }}</span>
            <span v-else class="btn btn-sm btn-dark">{{ $t('trans.artwork.showDetails') }}</span>
          </router-link>
        </div>

        <div class="col-6">
          <ul class="row g-1 nav nav-tabs nav-tabs-s2 justify-content-right" id="myTab" role="tablist">
            <li v-if="artwork.is_nft" class="nav-item col-3 col-lg-4 offset-lg-4 offset-6">
              <img
                :src="require('@/images/thumb/nft_tipo.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.nft'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>
            <li v-else class="nav-item col-3 col-lg-4 offset-lg-4 offset-6">
              <img
                v-tippy="{ content: 'Obra Física Pública', arrow: true, theme: 'dark', placement: 'bottom' }"
                :src="require('@/images/thumb/fisica_publica.svg')"
                class="icon icon-svg"
              />
            </li>

            <li
              v-if="artwork.active_artwork_sell?.price_euro && artwork.active_artwork_sell?.price_matic"
              class="nav-item col-3 col-lg-4"
            >
              <img
                :src="require('@/images/thumb/fijo_mixto.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.fijo_mixto'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>
            <li v-else-if="artwork.active_artwork_sell?.price_euro" class="nav-item col-3 col-lg-4">
              <img
                :src="require('@/images/thumb/fijo_euro.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.fijo_euro'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>
            <li v-else-if="artwork.active_artwork_sell?.price_matic" class="nav-item col-3 col-lg-4">
              <img
                :src="require('@/images/thumb/fijo_matic.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.fijo_matic'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>
            <li v-else-if="artwork.active_auction?.currency_type === 'euro'" class="nav-item col-3 col-lg-4">
              <img
                :src="require('@/images/thumb/subasta_euro.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.subasta_euro'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>
            <li v-else-if="artwork.active_auction?.currency_type === 'matic'" class="nav-item col-3 col-lg-4">
              <img
                :src="require('@/images/thumb/subasta_matic.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.subasta_matic'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>

            <li v-if="artwork.childs?.length > 0" class="nav-item col-3 col-lg-4">
              <img
                :src="require('@/images/thumb/edicion.svg')"
                class="icon icon-svg"
                v-tippy="{ content: $t('trans.edicion'), arrow: true, theme: 'dark', placement: 'bottom' }"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive } from 'vue-tippy';
import useArtworkImage from '@/composable/useArtworkImage';
import {useArtwork} from "@/composable/artwork/artwork";

export default {
  name: 'Artwork',
  directives: {
    tippy: directive
  },
  props: ['artwork'],
  setup(props) {
    const { headerImageUri } = useArtworkImage(props.artwork);
    const {hasChildsOnSale} = useArtwork()

    const formatNumber = (num) => {
      if (num !== undefined && num !== null) {
        return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      } else {
        return '0';
      }
    };

    return {
      headerImageUri,
      hasChildsOnSale,
      formatNumber
    };
  }
};
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.author-link,
.card-price-wrap {
  z-index: 2;
  position: relative;
}
</style>
