import i18n from '@/translations/i18n';
import { isSSR } from '@/helpers';

const locales = ['es', 'en'];
export const routes = locales.flatMap((locale) => {
  const routesI18n = i18n.global.messages.value[locale].routes;
  return [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
    },
    {
      path: `/${locale}`,
      name: routesI18n.home.name,
      component: () => import('../views/Home.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.login.path}`,
      name: routesI18n.login.name,
      component: () => import('../views/Home.vue'),
      props: true,
      meta: {
        locale
      },
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        if (!isSSR()) {
          window.location.replace(process.env.VUE_APP_EXT_LOGIN);
        } else {
          next();
        }
      }
    },
    {
      path: `/${locale}/${routesI18n.email.confirm.path}`,
      name: routesI18n.email.confirm.name,
      component: () => import('../views/EmailConfirm.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.legal.path}`,
      name: routesI18n.legal.name,
      component: () => import('../views/Legal.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.terms.path}`,
      name: routesI18n.terms.name,
      component: () => import('../views/TermsConditions.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.privacy.path}`,
      name: routesI18n.privacy.name,
      component: () => import('../views/Privacy.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.cookies.path}`,
      name: routesI18n.cookies.name,
      component: () => import('../views/Cookies.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.contact.path}`,
      name: routesI18n.contact.name,
      component: () => import('../views/Contact.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.services.path}`,
      name: routesI18n.services.name,
      component: () => import('../views/AboutUs.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.martelive.path}`,
      name: routesI18n.martelive.name,
      component: () => import('../views/MarteliveLanding.vue'),
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    /*
     * Artworks
     */
    {
      path: `/${locale}/${routesI18n.artworks.path}`,
      name: routesI18n.artworks.name,
      component: () => import('../views/ArtworkExplore.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.artwork.path}/:hash/:slug`,
      name: routesI18n.artwork.name,
      component: () => import('../views/ArtworkDetail.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.artworkChild.path_1}/:hashOriginal/:slugOriginal/${routesI18n.artworkChild.path_2}/:hash/:slug`,
      name: routesI18n.artworkChild.name,
      component: () => import('../views/ArtworkDetail.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.editArtwork.path}/:hash/:slug`,
      name: routesI18n.editArtwork.name,
      component: () => import('../views/ArtworkDetailEdit.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true
      }
    },
    // {
    // 	path: `/${locale}/${routesI18n.artwork.path}/:hash`,
    // 	name: routesI18n.artwork.name,
    // 	component: () => import("../views/ObraMarketDetail.vue"),
    // 	props: true,
    // 	meta: {
    // 		locale,
    // 		requiresAuth: false, // No requiere autenticación
    // 	},
    // },
    /*
     * END Artworks
     */

    /*
     * Artists
     */
    {
      path: `/${locale}/${routesI18n.artists.path}`,
      name: routesI18n.artists.name,
      component: () => import('../views/ArtistExplore.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.artist.path}/:hash/:slug?`,
      name: routesI18n.artist.name,
      component: () => import('../views/ArtistDetail.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    /*
     * END Artists
     */

    /*
     * Collections
     */
    {
      path: `/${locale}/${routesI18n.collections.path}`,
      name: routesI18n.collections.name,
      component: () => import('../views/CollectionExplore.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.collection.path}/:hash`,
      name: routesI18n.collection.name,
      component: () => import('../views/CollectionDetail.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // No requiere autenticación
      }
    },

    {
      path: `/${locale}/${routesI18n.metaExpos.path}`,
      name: routesI18n.metaExpos.name,
      component: () => import('../views/metaExpos/MetaExpo.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_Pararecordarte.path}`,
      name: routesI18n.metaExpos_Pararecordarte.name,
      component: () => import('../views/metaExpos/MetaExpoPararecordarte.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_Pararecordarte_UMA.path}`,
      name: routesI18n.metaExpos_Pararecordarte_UMA.name,
      component: () => import('../views/metaExpos/MetaExpoPararecordarteUMA.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_BernardoPerez.path}`,
      name: routesI18n.metaExpos_BernardoPerez.name,
      component: () => import('../views/metaExpos/MetaExpoBernardoPerez.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_BernardoPerez_UMA.path}`,
      name: routesI18n.metaExpos_BernardoPerez_UMA.name,
      component: () => import('../views/metaExpos/MetaExpoBernardoPerezUMA.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_PierreGonnord.path}`,
      name: routesI18n.metaExpos_PierreGonnord.name,
      component: () => import('../views/metaExpos/MetaExpoPierreGonnord.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_PierreGonnord_UMA.path}`,
      name: routesI18n.metaExpos_PierreGonnord_UMA.name,
      component: () => import('../views/metaExpos/MetaExpoPierreGonnordUMA.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_JondoSonidosMaquinicos.path}`,
      name: routesI18n.metaExpos_JondoSonidosMaquinicos.name,
      component: () => import('../views/metaExpos/MetaExpoJondoSonidosMaquinicos.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_JondoSonidosMaquinicos_UMA.path}`,
      name: routesI18n.metaExpos_JondoSonidosMaquinicos_UMA.name,
      component: () => import('../views/metaExpos/MetaExpoJondoSonidosMaquinicosUMA.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_AngelesSioli.path}`,
      name: routesI18n.metaExpos_AngelesSioli.name,
      component: () => import('../views/metaExpos/MetaExpoAngelesSioli.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_AngelesSioli_UMA.path}`,
      name: routesI18n.metaExpos_AngelesSioli_UMA.name,
      component: () => import('../views/metaExpos/MetaExpoAngelesSioliUMA.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_ArchivoSombraCarlosCanal.path}`,
      name: routesI18n.metaExpos_ArchivoSombraCarlosCanal.name,
      component: () => import('../views/metaExpos/MetaExpoArchivoSombraCarlosCanal.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.metaExpos_ArchivoSombraCarlosCanal_UMA.path}`,
      name: routesI18n.metaExpos_ArchivoSombraCarlosCanal_UMA.name,
      component: () => import('../views/metaExpos/MetaExpoArchivoSombraCarlosCanalUMA.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.formEvent.path}`,
      name: routesI18n.formEvent.name,
      component: () => import('../views/FormEvent.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: false // Requiere autenticación
      }
    },

    // {
    //   path: `/${locale}/${routesI18n.formEventCopy.path}`,
    //   name: routesI18n.formEventCopy.name,
    //   component: () => import('../views/FormEventCopy.vue'),
    //   props: true,
    //   meta: {
    //     locale,
    //     requiresAuth: false // Requiere autenticación
    //   }
    // },
    // {
    // 	path: `/${locale}/${routesI18n.artwork.path}/:hash/:slug?`,
    // 	name: routesI18n.artwork.name,
    // 	component: () => import("../views/ArtworkDetail.vue"),
    // 	props: true,
    // 	meta: {
    // 		locale,
    // 	},
    // },
    /*
     * END Collections
     */

    /*
     * Profile - Private
     */
    // {
    // 	path: `/${locale}/${routesI18n.profile.path}`,
    // 	name: routesI18n.profile.name,
    // 	component: () => import("../views/Profile.vue"),
    // 	props: true,
    // 	meta: {
    // 		locale,
    // 		requiresAuth: true, // Requiere autenticación
    // 	},
    // },
    {
      path: `/${locale}/${routesI18n.dashboard.path}`,
      name: routesI18n.dashboard.name,
      component: () => import('../views/Account.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.private.path}`,
      name: routesI18n.dashboard.private.name,
      component: () => import('../views/Account.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      },
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        if (!isSSR()) {
          window.location.replace(process.env.VUE_APP_EXT_LOGIN);
        } else {
          next();
        }
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.auctions.path}`,
      name: routesI18n.dashboard.auctions.name,
      component: () => import('../views/DashboardAuctions.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.artworks.path}`,
      name: routesI18n.dashboard.artworks.name,
      component: () => import('../views/DashboardArtworks.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.favourites.path}`,
      name: routesI18n.dashboard.favourites.name,
      component: () => import('../views/DashboardFavourites.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.transactions.path}`,
      name: routesI18n.dashboard.transactions.name,
      component: () => import('../views/Transactions.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.payments.path}`,
      name: routesI18n.dashboard.payments.name,
      component: () => import('../views/DashboardPayments.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },
    {
      path: `/${locale}/${routesI18n.dashboard.academy.path}`,
      name: routesI18n.dashboard.academy.name,
      component: () => import('../views/DashboardAcademy.vue'),
      props: true,
      meta: {
        locale,
        requiresAuth: true // Requiere autenticación
      }
    },

    /*
     * End Profile
     */

    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login.vue')
    },

    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/NotFound.vue')
    }
  ];
});
