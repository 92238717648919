import axios from "../../plugins/axios";

export default {
	namespaced: true,
	state: () => ({
		artwork: {},
		highlightedArtworks: [],
		userArtworks: [],
		userArtwork: {},
		userFavourites: [],
		artworks: { data: [], total: 0 },
	}),
	mutations: {
		setArtwork(state, artwork) {
			state.artwork = artwork;
		},
		setHighlightedArtworks(state, highlightedArtworks) {
			state.highlightedArtworks = highlightedArtworks;
		},
		setArtworks(state, artworks) {
			state.artworks = artworks;
		},
		setUserArtworks(state, artworks) {
			state.userArtworks = artworks;
		},
		setUserArtwork(state, artwork) {
			state.userArtwork = artwork;
		},
		setUserFavourites(state, artworks) {
			state.userFavourites = artworks;
		},
	},
	getters: {
		getArtwork(state) {
			return state.artwork;
		},
		getHighlightedArtworks(state) {
			return state.highlightedArtworks;
		},
		getArtworks(state) {
			return state.artworks;
		},
		getUserArtworks(state) {
			return state.userArtworks;
		},
		getUserArtwork(state) {
			return state.userArtwork;
		},
		getUserFavourites(state) {
			return state.userFavourites;
		},
	},
	actions: {
		clearArtwork({ commit }) {
			commit("setArtwork", {});
		},
		clearUserArtwork({ commit }) {
			commit("setUserArtwork", {});
		},
		async setArtwork({ commit }, hash) {
			let error;
			try {
				let artwork = await axios.get(
					"/api/v1/public/artwork/view/" + hash
				);
				if (artwork) {
					artwork = artwork.data?.data;
				}
				commit("setArtwork", artwork);
			} catch (e) {
				error = e;
			}
		},
		async setHighlightedArtworks({ commit }) {
			let error;
			try {
				let response = await axios.get(
					"/api/v1/public/artwork/listHighlighted"
				);
				if (response) {
					response = response.data?.data;
				}
				commit("setHighlightedArtworks", response);
			} catch (e) {
				error = e;
			}
		},
		async setUserArtworks({ commit }) {
			let error;
			try {
				let response = await axios.get(
					"/api/v1/public/profile/artwork/list"
				);
				if (response) {
					response = response.data?.data;
				}
				commit("setUserArtworks", response);
			} catch (e) {
				error = e;
			}
		},
		async setUserArtwork({ commit }, hash) {
			let error;
			try {
				let artwork = await axios.get(
					"/api/v1/public/profile/artwork/view/" + hash
				);
				if (artwork) {
					artwork = artwork.data?.data;
				}
				commit("setUserArtwork", artwork);
			} catch (e) {
				error = e;
			}
		},
		async setUserFavourites({ commit }) {
			let error;
			try {
				let response = await axios.get(
					"/api/v1/public/artwork/favourite/list"
				);
				if (response) {
					response = response.data?.data;
				}
				commit("setUserFavourites", response);
			} catch (e) {
				error = e;
			}
		},
		async setArtworks({ commit }, { perPage, page, query }) {
			let error;
			try {
				query = new URLSearchParams(query).toString();
				let response = await axios.get(
					`/api/v1/public/artwork/list/${perPage}/${page}?${query}`
				);
				if (response) {
					response = response.data?.data;
				}
				commit("setArtworks", response);
			} catch (e) {
				error = e;
			}
		},
	},
};
