
import { roundLocale } from "@/plugins/helpers";
import store from "@/store";
import axios from "@/plugins/axios";
import Web3 from "web3";
import CuentaAtrasTab from "@/components/section/CuentaAtrasTab.vue";
import Web3Service from "@/core/services/Web3Service";
import {isSSR} from "@/helpers";
import {useUserAuth} from "@/composable/user/user";
import {useArtwork} from "@/composable/artwork/artwork";
import {usePaymentMethod} from "@/composable/user/payment_method";
import {useMarketplace} from "@/composable/marketplace/marketplace";
import {useBillingAddress} from "@/composable/address";
import {computed, onMounted, ref} from "vue";
import {helpers} from "@/types/enums/PaymentCards";
import {useMarketplaceAuctionCrypto} from "@/composable/marketplace/marketplaceAuctionCrypto";
import BaseRadioButtonGroup from '@/components/common/BaseRadioButtonGroup.vue';
import BaseFormButton from '@/components/common/BaseFormButton.vue';
import {ErrorMessage, Field, Form} from "vee-validate";

export default {
  components: {
    CuentaAtrasTab,
    Form,
    ErrorMessage,
    Field,
    BaseRadioButtonGroup,
    BaseFormButton
  },
  props: {
    artworkHash: String
  },
  setup() {
    const { currentUser } = useUserAuth();
    const { currentArtwork, currentAuction, currentAuctionLastBid } = useArtwork();
    const { hasUserPaymentMethodFiat, payment_methods_fiat, payment_methods_crypto } = usePaymentMethod();
    const { refreshAvailability } = useMarketplace();
    const { billingAddresses } = useBillingAddress();
    const { purchaseAuctionCryptoFormState, sendingFormPurchaseAuction, purchaseAuctionSchema, purchaseAuction } =
        useMarketplaceAuctionCrypto();
    const bidOffer = ref<number>(0);
    const getIcon = (key: string) => {
      return helpers.getInfo(key)?.icon;
    };

    const isValidBid = computed(() => {
      if (bidOffer.value > 0) {
        return bidOffer.value >= currentAuction.value.minimumBid;
      } else {
        return false;
      }
    });

    onMounted(async () => {
      await refreshAvailability(currentAuction.value, currentArtwork.value);

      billingAddresses.value.forEach((billingAddress) => {
        if (billingAddress.is_default) {
          purchaseAuctionCryptoFormState.value.billing_address = billingAddress.hash;
        }
      });
    });

    return {
      currentArtwork,
      currentAuction,
      currentAuctionLastBid,
      currentUser,
      hasUserPaymentMethodFiat,
      payment_methods_fiat,
      isValidBid,
      bidOffer,
      getIcon,
      purchaseAuctionCryptoFormState,
      sendingFormPurchaseAuction,
      purchaseAuctionSchema,
      purchaseAuction,
      billingAddresses,
      payment_methods_crypto,
      roundLocale
    };
  },
};
