<template>
  <a
      href="#"
      :class="this.class"
      @click="verifyAlert()"
  >
    {{ $t(this.text) }}
  </a></template>
<script>
import store from "@/store";

export default {
  name: 'ButtonUserVerifyEmail',
  props: {
    class: String,
    text: String,
  },
  computed: {
    user() {
      return store.state.user.name;
    },
    hasConfirmedEmail() {
      return this.$store.state.user?.email_verified;
    },
  },
  data() {
    return {

    };
  },
  mounted() {
  //
  },
  methods: {
    verifyAlert() {
      this.$swal({
        title: this.$t("trans.alert.email_confirm.required.title"),
        text: this.$t("trans.alert.email_confirm.required.text"),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: this.$t("trans.alert.email_confirm.required.continue"),
        cancelButtonText: this.$t("trans.alert.email_confirm.required.cancel"),
        showCloseButton: true,
      }).then((result) => {
        if(result.value) {
          this.$router.push({ name: this.$t('routes.dashboard.name') });
        }
      })
    },
  },
};
</script>
