import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { App } from 'vue';

export const registerSweetAlert = (app: App): void => {
  const options = {
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-outline-dark mx-2',
      cancelButton: 'btn btn-outline-primary mx-2',
      denyButton: 'btn btn-danger mx-2'
    }
  };

  app.use(VueSweetalert2, options);
};
