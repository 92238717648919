const PaymentCardType = Object.freeze({
  VISA: 'visa',
  AMEX: 'amex',
  MASTER_CARD: 'mastercard',
  CARTES_BANCAIRES: 'cartes_bancaires',
  DINERS: 'diners',
  DISCOVER: 'discover',
  JCB: 'jcb',
  UNION_PAY: 'unionpay'
});

export interface PaymentCardInfo {
  key: string;
  icon: string;
}
export const options: Record<string, PaymentCardInfo> = {
  [PaymentCardType.VISA]: {
    key: PaymentCardType.VISA,
    icon: 'card_visa'
  },
  [PaymentCardType.AMEX]: {
    key: PaymentCardType.AMEX,
    icon: 'card_amex'
  },
  [PaymentCardType.MASTER_CARD]: {
    key: PaymentCardType.MASTER_CARD,
    icon: 'card_mastercard'
  },
  [PaymentCardType.CARTES_BANCAIRES]: {
    key: PaymentCardType.CARTES_BANCAIRES,
    icon: 'card_cartes_bancaires'
  },
  [PaymentCardType.DINERS]: {
    key: PaymentCardType.DINERS,
    icon: 'card_diners'
  },
  [PaymentCardType.DISCOVER]: {
    key: PaymentCardType.DISCOVER,
    icon: 'card_discover'
  },
  [PaymentCardType.JCB]: {
    key: PaymentCardType.JCB,
    icon: 'card_jcb'
  },
  [PaymentCardType.UNION_PAY]: {
    key: PaymentCardType.UNION_PAY,
    icon: 'card_unionpay'
  }
};

export const helpers = {
  getInfo(key: string | null): PaymentCardInfo | null {
    if (!key) {
      return null;
    }
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      return options[key];
    }
    return null;
  }
};
