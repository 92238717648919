<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <HeaderMain></HeaderMain>
      <!-- hero -->
      <HeroHome></HeroHome>
    </header>

    <!-- product -->
    <section class="section-space-t" v-if="hasHighlightedArtists">
      <div class="container">
        <ArtistHighlighted></ArtistHighlighted>
      </div>
    </section>

    <section class="section-space-t" v-if="hasHighlightedArtworks">
      <div class="container">
        <ArtworksHighlightedContainer></ArtworksHighlightedContainer>
      </div>
    </section>

    <AuctionFiat></AuctionFiat>
    <AuctionCrypto></AuctionCrypto>
    <Marketplace></Marketplace>

    <!-- HowItWork  -->
    <HowItWorkTwo></HowItWorkTwo>

    <!-- How it work  -->
    <HowItWorkFour :title="$t('trans.home.about.why.title')"></HowItWorkFour>
    <!-- Footer  -->
    <Footer classname="bg-dark on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import HeroHome from '@/components/section/HeroHome.vue'
import ArtistHighlighted from "@/components/section/ArtistsHighlighted";
import Marketplace from "@/components/section/Marketplace";
import ArtworksHighlightedContainer from "@/components/section/ArtworksHighlightedContainer";
import AuctionCrypto from "@/components/section/AuctionCrypto";
import AuctionFiat from "@/components/section/AuctionFiat";
import store from "@/store";

export default {
  name: 'Home',
  components: {
    AuctionFiat,
    AuctionCrypto,
    Marketplace,
    ArtworksHighlightedContainer,
    ArtistHighlighted,
    HeroHome
  },
  data() {
    return {
      SectionData
    }
  },
  computed: {
    hasHighlightedArtworks() {
      return store.getters["artwork/getHighlightedArtworks"].length > 0;
    },
    hasHighlightedArtists() {
      return store.getters["artist/getHighlightedArtist"].length > 0;
    },
  },
  async created() {
    await store.dispatch("artwork/setHighlightedArtworks");
  },
  async serverPrefetch() {
    await store.dispatch("artwork/setHighlightedArtworks");
  },
}
</script>
<style lang="css" scoped>
.card { 
  min-height: 235px !important;
}
</style>
