<template>

    <div class="container">
  
        <!-- collection slider -->
        <AuthorCollectionSlider></AuthorCollectionSlider>
    </div><!-- .container -->

</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Collections',
  props: ['classname'],
  data () {
    return {
      SectionData
    }
  }
}
</script>
