<template>
	<div class="page-wrap">
		<!-- header  -->
		<header class="header-section has-header-main">
			<!-- Header main -->
			<HeaderMain></HeaderMain>
			<!-- hero -->
			<HeaderBreadcrumbs
				classname="hero-title"
				:title="$t('trans.collections.title')"
				:breadcrumb-items="[
					{
						title: $t('navbar.collections'),
					},
				]"
			></HeaderBreadcrumbs>
		</header>
		<!-- Explore section  -->

		<section class="">
			<div class="container">
				<!-- blog section -->
				<div class="row g-gs">
					<div
						class="col-lg-4 col-sm-6"
						v-for="collection in collections.data"
						:key="collection.hash"
					>
						<CollectionCard
							:collection="collection"
						></CollectionCard>
					</div>
				</div>
				<!--<div v-show="loading" class="row g-gs">
				<div class="col-lg-12 text-center">
					<span>
						<em
							class="ni ni-loader icon-spinner"
							style="font-size: 60px"
						></em>
					</span>
				</div>
			</div>-->
			</div>
		</section>

		<!-- top creators -->
		<div class="text-center mt-4 mt-md-5 mb-5">
			<Pagination
				:records="collections ? collections.total : 0"
				v-model="page"
				:per-page="perPage"
				@paginate="paginate"
				:options="{
					texts: {
						count: $t('pagination.count'),
						first: $t('pagination.first'),
						last: $t('pagination.last'),
					},
				}"
			></Pagination>
		</div>
		<!-- Collections -->
		<!-- Footer  -->
		<Footer classname="bg-dark on-dark"></Footer>
	</div>
	<!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";
import Pagination from "v-pagination-3";
import store from "@/store";
import { ssrRunning } from "@/helpers/index.ts";
import CollectionCard from "@/components/section/CollectionCard.vue";

export default {
	name: "CollectionExplore",
	components: {
		Pagination,
		CollectionCard,
	},
	computed: {
		collections() {
			return store.getters["collection/getCollections"];
		},
	},
	data() {
		return {
			SectionData,
			perPage: 6,
			page: 1,
			loading: false,
		};
	},
	async created() {
		await store.dispatch("collection/setCollections", {
			perPage: this.perPage,
			page: 1,
		});
	},
	async serverPrefetch() {
		await store.dispatch("collection/setCollections", {
			perPage: this.perPage,
			page: 1,
		});
	},
	methods: {
		async paginate(page) {
			this.loading = true;
			await store.dispatch("collection/setCollections", {
				perPage: this.perPage,
				page,
			});
			this.loading = false;
		},
	},
};
</script>
