<template>
	<!--begin::Title-->
	<div style="margin-left: 9px; float: left !important">
		<h3 class="text-hover-primary"><a href="/">
			<span style="font-family: 'A4A'; font-weight: bold; color:#000">
				Auth<span style="font-size:1.3em">4</span>Art</span
			></a>
		</h3>
		<!--end::Title-->
	</div>

	<!--end::Page title-->
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from "@/store/store.js";

export default {
	name: "HeaderSearch",
	props: ["classname"],
	data() {
		return {
			SectionData,
		};
	},
};
</script>
