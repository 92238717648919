<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
      <!-- hero -->
      <HeaderBreadcrumbs
        classname="hero-title"
        :title="$t('trans.breadcrumbs.titles.artists')"
        :breadcrumb-items="[
          {
            title: $t('navbar.artists')
          }
        ]"
      ></HeaderBreadcrumbs>
    </header>
    <div v-if="hasArtists">
      <div class="container">
        <div class="row g-gs">
          <div class="col-lg-4 col-sm-6" v-for="artist in artists.data" :key="artist.hash">
            <ArtistCard :artist="artist"></ArtistCard>
          </div>
        </div>
      </div>
      <div class="text-center mt-4 mt-md-5 mb-5">
        <Pagination
          :records="artists ? artists.total : 0"
          v-model="page"
          :per-page="perPage"
          @paginate="paginate"
          :options="{
            texts: {
              count: $t('pagination.count'),
              first: $t('pagination.first'),
              last: $t('pagination.last')
            }
          }"
        ></Pagination>
      </div>
    </div>
    <div class="container" v-else>
      <SectionHeading
        classname="text-center"
        :text="$t('trans.artist.list.no_records')"
        :content="$t('trans.artist.list.no_records_text')"
        isMargin="mb-3"
      >
      </SectionHeading>
    </div>

    <!--		<TopCreators classname="section-space-t"></TopCreators>-->
    <!-- Explore section
		<ArtistsHighlighted></ArtistsHighlighted>-->
    <!--<ExploreSectionTwo></ExploreSectionTwo>-->
    <!-- top creators -->
    <!-- Collections
		<Collections></Collections>-->
    <section class="section-space-t mb-5" v-if="hasHighlightedArtworks">
      <div class="container">
        <ArtworksHighlightedContainer></ArtworksHighlightedContainer>
      </div>
    </section>
    <!-- Footer  -->
    <Footer classname="bg-dark on-dark"></Footer>
  </div>
  <!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js';
//import ArtistsHighlighted from "@/components/section/ArtistsHighlighted.vue";
// import TopCreators from "@/components/section/TopCreators.vue";
import ArtistCard from '@/components/section/ArtistCard.vue';
import ArtworksHighlightedContainer from '@/components/section/ArtworksHighlightedContainer.vue';
import SectionHeading from '@/components/common/SectionHeading.vue';
import Pagination from 'v-pagination-3';
import store from '@/store';
import { isSSR, ssrRunning } from '@/helpers/index.ts';

export default {
  name: 'ArtistExplore',
  components: {
    // TopCreators,
    ArtistCard,
    Pagination,
    ArtworksHighlightedContainer,
    SectionHeading
  },
  computed: {
    artists() {
      return store.getters['artist/getArtists'];
    },
    hasArtists() {
      return store.getters['artist/getArtists'].data.length > 0;
    },
    hasHighlightedArtworks() {
      return store.getters['artwork/getHighlightedArtworks'].length > 0;
    }
  },
  data() {
    return {
      SectionData,
      perPage: 12,
      page: 1,
      loading: false,
      size: !isSSR() ? (window.innerWidth < 992 ? 'sm' : 'lg') : 'lg'
    };
  },
  async created() {
    await store.dispatch('artist/setArtists', {
      perPage: this.perPage,
      page: 1
    });
    await store.dispatch('artwork/setHighlightedArtworks');
  },
  async serverPrefetch() {
    await store.dispatch('artist/setArtists', {
      perPage: this.perPage,
      page: 1
    });
    await store.dispatch('artwork/setHighlightedArtworks');
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async paginate(page) {
      this.loading = true;
      await store.dispatch('artist/setArtists', {
        perPage: this.perPage,
        page
      });
      this.loading = false;
    },
    onResize() {
      this.size = !isSSR() ? (window.innerWidth < 992 ? 'sm' : 'lg') : 'lg';
    }
  }
};
</script>
