import axios from '../../plugins/axios';

export default {
  namespaced: true,
  state: () => ({
    auction: {},
    lastBid: {},
    latestAuctions: [],
    userActiveAuctions: [],
    latestFiatAuctions: [],
    latestCryptoAuctions: [],
    auctions: { data: [], total: 0 }
  }),
  mutations: {
    setAuction(state, auction) {
      state.auction = auction;
    },
    setLatestAuctions(state, auctions) {
      state.latestAuctions = auctions;
    },
    setLatestFiatAuctions(state, auctions) {
      state.latestFiatAuctions = auctions;
    },
    setLatestCryptoAuctions(state, auctions) {
      state.latestCryptoAuctions = auctions;
    },
    setLastBid(state, lastBid) {
      state.lastBid = lastBid;
    },
    setUserActiveAuctions(state, userActiveAuctions) {
      state.userActiveAuctions = userActiveAuctions;
    }
  },
  getters: {
    getAuction(state) {
      return state.auction;
    },
    getLatestAuctions(state) {
      return state.latestAuctions;
    },
    getLatestFiatAuctions(state) {
      return state.latestFiatAuctions;
    },
    getLatestCryptoAuctions(state) {
      return state.latestCryptoAuctions;
    },
    getLastBid(state) {
      return state.lastBid;
    },
    getUserActiveAuctions(state) {
      return state.userActiveAuctions;
    }
  },
  actions: {
    async setAuction({ commit }, hash) {
      let error;
      try {
        let auction = await axios.get('/api/v1/public/auction/view/' + hash);
        if (auction) {
          auction = auction.data?.data;
        }
        commit('setAuction', auction);
      } catch (e) {
        error = e;
      }
    },
    async setLastBid({ commit }, hash) {
      let error;
      try {
        let lastBid = await axios.get('/api/v1/public/auction/latestBid/' + hash);
        if (lastBid) {
          lastBid = lastBid.data?.data;
        }
        //commit('setLastBid', {});
        commit('setLastBid', lastBid);
      } catch (e) {
        error = e;
      }
    },
    async setLatestAuctions({ commit }) {
      let error;
      try {
        let response = await axios.get('/api/v1/public/auction/list-latest');
        if (response) {
          response = response.data?.data;
        }
        commit('setLatestAuctions', response);
      } catch (e) {
        error = e;
      }
    },
    async setLatestCryptoAuctions({ commit }) {
      let error;
      try {
        let response = await axios.get('/api/v1/public/auction/list-latest-crypto');
        if (response) {
          response = response.data?.data;
        }
        commit('setLatestCryptoAuctions', response);
      } catch (e) {
        error = e;
      }
    },
    async setLatestFiatAuctions({ commit }) {
      let error;
      try {
        let response = await axios.get('/api/v1/public/auction/list-latest-fiat');
        if (response) {
          response = response.data?.data;
        }
        commit('setLatestFiatAuctions', response);
      } catch (e) {
        error = e;
      }
    },
    async setUserActiveAuctions({ commit }) {
      let error;
      try {
        let response = await axios.get('/api/v1/public/auction/roleable-active-bids');
        if (response) {
          response = response.data?.data;
        }
        commit('setUserActiveAuctions', response);
      } catch (e) {
        error = e;
      }
    }
  }
};
