
import { directive } from 'vue-tippy';
import ArtworkDetailTabMarketplace from '@/components/section/ArtworkDetailTabMarketplace.vue';
import ArtworkDetailTabAuction from '@/components/section/ArtworkDetailTabAuction.vue';
import { isSSR } from '@/helpers';
import { useUserAuth } from '@/composable/user/user';
import { useArtwork } from '@/composable/artwork/artwork';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, onUnmounted } from 'vue';
import useArtworkImage from '@/composable/useArtworkImage';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
//import store from '@/store';

export default {
  name: 'ArtworkDetailChild',
  directives: {
    tippy: directive
  },
  components: {
    ArtworkDetailTabMarketplace,
    ArtworkDetailTabAuction
  },
  setup() {
    let intervalId;
    const { currentUser } = useUserAuth();
    const router = useRouter();
    const { currentArtwork, currentArtworkSell, currentAuction, markAsFavourite, unmarkAsFavourite } = useArtwork();

    const artwork = useArtwork();

    const isUserAuthenticated = computed(() => {
      return store.state.isUserAuthenticated;
    });

    intervalId = setInterval(() => {
      const { currentArtwork } = artwork;
      if(!currentArtwork.value.is_public) {
        window.location.href = '/';
      }
    }, 1000);

    const { t } = useI18n();
    const store = useStore();

    const { detailVerticalImageUri } = useArtworkImage(currentArtwork.value);

    onUnmounted(async () => {
      clearInterval(intervalId);
    });

    const message = t('trans.share.text');
    let path = 'https://auth4art.com'; //No permite -> $t('trans.share');
    if (!isSSR()) {
      path += window.location.pathname;
    }
    const onCopy = (e) => {
      let target = e.trigger.querySelector('.tooltip-text');
      let prevText = target.innerHTML;
      target.innerHTML = t('trans.share.copied');
      setTimeout(function () {
        target.innerHTML = prevText;
      }, 1000);
    };

    const date = computed((): string => {
      if (currentArtwork.value.created_at) {
        return new Date(currentArtwork.value.created_at).toLocaleDateString();
      } else {
        return '';
      }
    });

    const mintDate = computed((): string => {
      if (currentArtwork.value.mint_date) {
        return new Date(currentArtwork.value.mint_date).toLocaleDateString();
      } else {
        return '';
      }
    });

    const isArtworkMine = computed(() => {
      const userArtworks = store.getters['artwork/getUserArtworks'];
      return userArtworks.find((element) => element.hash === currentArtwork.value.hash) !== undefined;
    });

    onMounted(async () => {
      if (isUserAuthenticated.value) {
        await store.dispatch('artwork/setUserArtworks');
      }
    });

    const loginAlert = () => {
      Swal.fire({
        title: t('trans.alert.login.required.title'),
        text: t('trans.alert.login.required.text'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('trans.alert.login.required.continue'),
        cancelButtonText: t('trans.alert.login.required.cancel'),
        showCloseButton: true
      }).then((result) => {
        if (result.value) {
          router.push({ name: t('routes.login.name') });
        }
      });
    };

    return {
      currentArtwork,
      currentArtworkSell,
      currentAuction,
      currentUser,
      message,
      path,
      onCopy,
      date,
      mintDate,
      markAsFavourite,
      unmarkAsFavourite,
      detailVerticalImageUri,
      loginAlert,
      isArtworkMine,
      isUserAuthenticated
    };
  }
};
