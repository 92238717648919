<template>
  <section class="about-section"  >
	
            <div class="container" >
               
                <div class="row align-items-center flex" :class="isReverse">
                            <div class="col-lg-6" :class="isClassTwo">
                        <div class="about-content-wrap">
                            <h2 class="mb-3">{{$t('trans.about.plans_devices.plans.title')}}</h2>
                            <p :class="isClass">{{$t('trans.about.plans_devices.plans.content')}}</p>
                        
                            <p v-html="$t('trans.about.plans_devices.plans.content_2')"></p>

							<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataPlan"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.plans.button')}}
							</span>
							</a>
                        </div>
                    </div><!-- end col-lg-6 -->
                
                    <div class="col-lg-6 mb-5 mb-lg-0" :class="isClassThree">
                   
                     <IconFactSection classname="" :items="SectionData.funfactData.funfactListThree"></IconFactSection>
                </div><!-- end col-lg-6 -->
            

                </div><!-- end row -->
            </div><!-- end container -->
        </section><!-- end about-section -->



    

             <div
				class="modal fade"
				id="Free"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_free.title')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label" v-html="$t('trans.about.plans_devices.plans.modal_free.subtitle')"></label><br>
								<br>
                <p align="justify">
                  <span v-html="$t('trans.about.plans_devices.plans.modal_free.content_1')">
                  </span>
                  <br>
                {{$t('trans.about.plans_devices.plans.modal_free.content_2')}}<br>
                <br>
                {{$t('trans.about.plans_devices.plans.modal_free.content_3')}}<br>
                {{$t('trans.about.plans_devices.plans.modal_free.content_4')}}<br></p>
				<br>
				 <b>{{$t('trans.about.plans_devices.plans.modal_free.price')}}</b><br>
					<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataPlanFree"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.plans.modal_free.button')}}
							</span>
							</a>
				
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>


         <div
				class="modal fade"
				id="Basic"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_basic.title')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
								<label class="form-label">{{$t('trans.about.plans_devices.plans.modal_basic.subtitle')}}</label><br>
								<br>
								<p align="justify">
									<span v-html="$t('trans.about.plans_devices.plans.modal_basic.content_1')"></span><br>
									<span v-html="$t('trans.about.plans_devices.plans.modal_basic.content_2')"></span><br>
									<br>
									<span v-html="$t('trans.about.plans_devices.plans.modal_basic.content_3')"></span><br>
									<span v-html="$t('trans.about.plans_devices.plans.modal_basic.content_4')"></span><br>
								</p>
								<br>
				 				<b>{{$t('trans.about.plans_devices.plans.modal_basic.price')}}</b> {{$t('trans.about.plans_devices.plans.modal.tax_included')}}<br>
								<br><a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataPlanBasic"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.plans.modal_basic.button')}}
							</span>
							</a>
							</div>
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>












     <div
				class="modal fade"
				id="Premium"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_premium.title')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.plans.modal_premium.subtitle')}}</label><br>
								<br>
                   <p align="justify">
						<span v-html="$t('trans.about.plans_devices.plans.modal_premium.content_1')"></span><br>
						<span v-html="$t('trans.about.plans_devices.plans.modal_premium.content_2')"></span><br>
						<br>
						<span v-html="$t('trans.about.plans_devices.plans.modal_premium.content_3')"></span><br>
						<span v-html="$t('trans.about.plans_devices.plans.modal_premium.content_4')"></span><br>
					</p>
				<br>
				 <b>{{$t('trans.about.plans_devices.plans.modal_premium.price')}}</b> {{$t('trans.about.plans_devices.plans.modal.tax_included')}}<br>
				<br> <a
                        href="#"
                        class="fs-13 mb-2 d-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#ContrataPlanPremium"
                       > 
							<span class="btn btn-dark">
								 {{$t('trans.about.plans_devices.plans.modal_premium.button')}}
							</span>
							</a>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>




     <div
				class="modal fade"
				id="ContrataPlan"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_plans.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
				   <label class="form-label">{{$t('trans.about.plans_devices.plans.modal_plans.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.plans.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en los planes')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" id="floatingInputInteres" v-model="form.subject" value="Interesado en los planes"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" class="form-control" v-model="form.email" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.plans.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a comment here" v-model="form.message" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.plans.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">
											{{$t('trans.about.plans_devices.plans.modal.button')}}
										</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>



   <div
				class="modal fade"
				id="ContrataPlanFree"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_free.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.plans.modal_free.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.plans.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en plan Free')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" value="Interesado en plan Free"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.plans.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.plans.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{ $t('trans.about.plans_devices.plans.modal.button') }}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>




   <div
				class="modal fade"
				id="ContrataPlanBasic"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_basic.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.plans.modal_basic.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.plans.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en plan Basic')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" value="Interesado en plan Basic"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.plans.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.plans.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{ $t('trans.about.plans_devices.plans.modal.button') }}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>


 <div
				class="modal fade"
				id="ContrataPlanPremium"
				tabindex="-1"
				aria-hidden="true"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
				>
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">{{$t('trans.about.plans_devices.plans.modal_premium.title_contact')}}</h4>
							<button
								type="button"
								class="btn-close icon-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<em class="ni ni-cross"></em>
							</button>
						</div>
						<!-- end modal-header -->
						<div class="modal-body">
							<div class="mb-3">
							
                <label class="form-label">{{$t('trans.about.plans_devices.plans.modal_premium.subtitle_contact')}}</label><br>
								<br>
                {{$t('trans.about.plans_devices.plans.modal.content')}}<br>
                <br>
              <form v-on:submit.prevent="submitForm('Interesado en plan Premium')" action="#">
                                <div class="row g-gs">
                                   <!-- <input type="hidden" v-model="form.subject" value="Interesado en plan Premium"> -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                          <input type="email" v-model="form.email" class="form-control" id="floatingInputEmail" placeholder="name@example.com" required>
                                            <label for="floatingInputEmail">{{$t('trans.about.plans_devices.plans.modal.email')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" v-model="form.message" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                            <label for="floatingTextarea">{{$t('trans.about.plans_devices.plans.modal.message')}}</label>
                                        </div><!-- end form-floating -->
                                    </div><!-- end col -->
                                    <div class="col-lg-12">
                                        <button class="btn btn-dark" data-bs-dismiss="modal" type="submit">{{ $t('trans.about.plans_devices.plans.modal.button') }}</button>
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </form>
							</div>
						
						</div>
						<!-- end modal-body -->
					</div>
					<!-- end modal-content -->
				</div>
				<!-- end modal-dialog -->
			</div>



</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import axios from "@/plugins/axios";

export default {
  name: 'PlansSection',
  props: ['img', 'title', 'content', 'contenttwo', 'contentthree', 'contentfour', 'isRound', 'isClass', 'isClassTwo', 'isClassThree', 'isReverse'],
  data () {
    return {
      SectionData,
	  form: {           
                email: '',             
                subject: '',
                message: '',                
            },
    }
  },
  methods:{
        async submitForm($subject){
			this.form.subject = $subject;
            const response = await axios.post('/api/v1/public/info', this.form)
                 .then((res) => {
                     this.$swal(this.$t("trans.alert.message_sent.title"), this.$t("trans.alert.message_sent.message"), 'success');					 
                 })
                 .catch((error) => {
                     this.$swal(this.$t("trans.alert.message_error.title"), this.$t("trans.alert.message_error.message"), 'error');
                 });
        }
},
}
</script>