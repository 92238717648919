import Web3 from 'web3';

/**
 * @description service to work with Web3
 */
class Web3Service {
  /**
   * @description Web3 Instance
   */
  private static web3: Web3;

  /**
   * @description initialize vue axios
   */
  public static async init() {
    try {
      if (process.env.VUE_APP_BLOCKCHAIN_ALCHEMY_PROVIDER !== undefined) {
        const provider = process.env.VUE_APP_BLOCKCHAIN_ALCHEMY_PROVIDER;
        this.web3 = new Web3(provider);
      } else {
        throw Error('NO_BLOCKCHAIN_PROVIDER');
      }
    } catch (error) {
      // User denied account access
      throw Error('Ya está iniciado');
    }

    if (typeof window.ethereum !== 'undefined') {
      try {
        window.ethereum.autoRefreshOnNetworkChange = false;
      } catch (error) {
        // User denied account access
        throw Error('WALLET_NOT_SELECTED');
      }
      this.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    } else if (window.web3) {
      this.web3 = new Web3(this.web3.currentProvider);
      // no need to ask for permission
    } else {
      throw Error('METAMASK_NOT_INSTALLED');
    }

    return this.web3;
  }

  public static async currentWallet() {
    if (typeof window.ethereum !== 'undefined') {
      return await window.ethereum
        .request({
          method: 'eth_requestAccounts'
        })
        .then((accounts) => accounts[0])
        .catch((error) => {
          // 2.1 if the user cancels the login prompt
          throw new Error('WALLET_NOT_SELECTED');
        });
    } else {
      throw new Error('METAMASK_NOT_INSTALLED');
    }
  }

  public static async accountWallets() {
    return await window.ethereum
      .request({
        method: 'eth_requestAccounts'
      })
      .then((accounts) => accounts)
      .catch(() => {
        throw Error('Por favor, selecciona una cuenta');
      });
    // window.localStorage.setItem("userWalletAddress", selectedAccount);
  }

  public static async currentBalance() {
    // get the user's wallet balance
    return async () => {
      // check if there is global userWalletAddress variable
      try {
        const balance = this.currentWallet().then(async (wallet) => {
          return await this.web3.eth.getBalance(wallet);
        });
        console.log('balance', balance);
        return balance;
      } catch (e) {
        console.log('Error', 'currentBalance', e);
      }
    };
  }

  public static async purchaseListedNFT() {
    // get the user's wallet balance
    return async () => {
      // check if there is global userWalletAddress variable
      try {
        const balance = this.currentWallet().then(async (wallet) => {
          return await this.web3.eth.getBalance(wallet);
        });
        console.log('balance', balance);
        return balance;
      } catch (e) {
        console.log('Error', 'currentBalance', e);
      }
    };
  }
}

export default Web3Service;
