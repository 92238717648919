<template>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
      <!-- hero -->
      <HeaderBreadcrumbs
          classname="hero-title"
          title="Virtual META"
          :breadcrumb-items="[
          {
            title: 'Virtual META'
          }
        ]"
      ></HeaderBreadcrumbs>
      <div></div>
    </header>
    <section class="section-space-b">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-4">
            <router-link :to="{ name: $t('routes.metaExpos_BernardoPerez.name') }" class="card card-full">
              <img
                  :src="require('@/images/thumb/auth4art_expo_virtual_bernardo.png')" class="card-img-top" alt="Bernardo Pérez">
              <div class="card-body p-4">
                <h5 class="card-title">Bernardo Pérez</h5>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <router-link :to="{ name: $t('routes.metaExpos_PierreGonnord.name') }" class="card card-full">
              <img
                :src="require('@/images/thumb/auth4art_expo_virtual_pierre.png')" class="card-img-top" alt="Pierre Gonnord">
              <div class="card-body p-4">
                <h5 class="card-title">Pierre Gonnord</h5>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4">
            <router-link :to="{ name: $t('routes.metaExpos_AngelesSioli.name') }" class="card card-full">
              <img
                :src="require('@/images/thumb/auth4art_angelesa.jpg')" class="card-img-top" alt="Ángeles Sioli">
              <div class="card-body p-4">
                <h5 class="card-title">Ángeles Sioli</h5>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4">
            <router-link :to="{ name: $t('routes.metaExpos_JondoSonidosMaquinicos.name') }" class="card card-full">
              <img
                :src="require('@/images/thumb/auth4art_jondo-sonidos-maquinicos.jpg')" class="card-img-top" alt="Jondo Sonidos Maquínicos">
              <div class="card-body p-4">
                <h5 class="card-title">Jondo Sonidos Maquínicos</h5>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4">
            <router-link :to="{ name: $t('routes.metaExpos_Pararecordarte.name') }" class="card card-full">
              <img
                :src="require('@/images/thumb/auth4art_pararecordarte.jpg')" class="card-img-top" alt="Pararecordarte">
              <div class="card-body p-4">
                <h5 class="card-title">Pararecordarte</h5>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4">
            <router-link :to="{ name: $t('routes.metaExpos_ArchivoSombraCarlosCanal.name') }" class="card card-full">
              <img
                :src="require('@/images/thumb/auth4art_banner_archivo_sombra_carlos_canal.jpg')" class="card-img-top" alt="Carlos Canal">
              <div class="card-body p-4">
                <h5 class="card-title">Carlos Canal</h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <Footer classname="bg-dark on-dark"></Footer>
  </div>
  <!-- end page-wrap -->
</template>

<script>

export default {
  name: 'MetaExpo',
  components: {},
};
</script>
